import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {GridContainer, GridCell} from '../grid';
import {Fab, Typography, Button} from '../styles';

function Gallery({images, filter, isMobile, handleOpenPreview, handleClosePreview}) {
  const [previewedImage, setPreviewedImage] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const handleClickOutside = (event) => {
    if(previewedImage !== undefined) {
      const containedImage = document.getElementById(images[previewedImage].imageId).contains(event.target);
      const containedPrevButton = document.getElementById('gallery-prev')?.contains(event.target);
      const containedNextButton = document.getElementById('gallery-next')?.contains(event.target);
      const containedCloseButton = document.getElementById('gallery-close')?.contains(event.target);
      if(!(containedImage || containedPrevButton || containedNextButton || containedCloseButton)) {
        setPreviewedImage(undefined);
        if(handleClosePreview !== undefined) {
          handleClosePreview();
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside);;
    }
  });

  const handlePrevious = () => {
    if(!processing) {
      const prev = previewedImage - 1;
      if(prev < 0) {
        setPreviewedImage(images.length - 1);
        const check = document.getElementById(images[images.length - 1].imageId).complete;
        if(!check) {
          setProcessing(true);
        }
      } else {
        setPreviewedImage(prev);
        const check = document.getElementById(images[prev].imageId).complete;
        if(!check) {
          setProcessing(true);
        }
      }
    }
  }

  const handleNext = () => {
    if(!processing) {
      const next = previewedImage + 1;
      if(next > (images.length - 1)) {
        setPreviewedImage(0);
        const check = document.getElementById(images[0].imageId).complete;
        if(!check) {
          setProcessing(true);
        }
      } else {
        setPreviewedImage(next);
        const check = document.getElementById(images[next].imageId).complete;
        if(!check) {
          setProcessing(true);
        }
      }
    }
  }

  return (
    <div>
      {images && images.length > 0 &&
        <div>
          <GridContainer>
            {images.map((i, iIndex) => (
              <GridCell
                weight={1}
                key={iIndex}
                style={{
                  minWidth: isMobile ? '50%' : 200,
                  maxWidth: isMobile ? undefined : 200,
                  display: (i.altText || '').toLowerCase().includes((filter || '').toLowerCase()) || (i.tags || '').toLowerCase().includes((filter || '').toLowerCase()) ? '' : 'none',
                }}>
                <div style={{padding: 10}}>
                  <img
                    src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_300,h_300/${i.imageId}`}
                    alt={i.altText}
                    style={{width: '100%', cursor: 'pointer'}}
                    onClick={() => {
                      setProcessing(true);
                      setPreviewedImage(iIndex);
                      if(handleOpenPreview !== undefined) {
                        handleOpenPreview();
                      }
                    }}
                  />
                </div>
              </GridCell>
            ))}
          </GridContainer>

          {/*lightbox*/}
          {previewedImage !== undefined &&
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'fixed',
                zIndex: 101,
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0,0,0,0.8)',
              }}
            >
              <GridContainer style={{height: '100vh'}}>
                <GridCell style={{textAlign: 'center'}} weight={1} center={true}>
                  {processing &&
                    <Typography size='title' style={{color: 'white', margin: 'auto'}}>
                      ...loading
                    </Typography>
                  }
                  {images.map((i, iIndex) => (
                    <img
                      key={iIndex}
                      id={i.imageId}
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_2048/${i.imageId}`}
                      alt={i.altText}
                      onLoad={() => setProcessing(false)}
                      style={{maxWidth: '90%', maxHeight: '80vh', display: iIndex === previewedImage ? 'inline' : 'none', margin: 'auto'}}
                    />
                  ))}
                </GridCell>
              </GridContainer>
              <GridContainer>
                <GridCell weight={1} center={true}/>
                {images?.length > 1 &&
                  <GridCell style={{padding: 20}}>
                    <div id='gallery-prev'>
                      <Fab
                        size='small'
                        disabled={processing}
                        palette='primary'
                        onClick={() => handlePrevious()}
                      >
                        <span className="material-icons md-24" >navigate_before</span>
                      </Fab>
                    </div>
                  </GridCell>
                }
                <GridCell center={true} style={{padding: 20}}>
                  <Button
                    id='gallery-close'
                    size='small'
                    palette='primary'
                    onClick={() => {
                      setPreviewedImage(undefined);
                      if(handleClosePreview !== undefined) {
                        handleClosePreview();
                      }
                    }}
                  >
                    Close Gallery
                  </Button>
                </GridCell>
                {images?.length > 1 &&
                  <GridCell style={{padding: 20}} center={true}>
                    <div id='gallery-next'>
                      <Fab
                        size='small'
                        disabled={processing}
                        palette='primary'
                        onClick={() => handleNext()}
                      >
                        <span className="material-icons md-24" >navigate_next</span>
                      </Fab>
                      </div>
                  </GridCell>
                }
                <GridCell weight={1}/>
              </GridContainer>
            </div>
          }
        </div>
      }
    </div>
  );
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({})),
  filter: PropTypes.string,
  isMobile: PropTypes.bool,
  handleOpenPreview: PropTypes.func,
  handleClosePreview: PropTypes.func,
}

export default Gallery;
