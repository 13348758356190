import React from 'react';
import PropTypes from 'prop-types';

import ListingForm from '../../../../components/forms/listing';
import EventInformationForm from '../../../../components/forms/eventInformation';

import {paletteData, Typography, Button, ProgressBar} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function EditEventInformationSubPanel({
    me,
    processing,
    dialogOpen,
    dialogData,
    setProcessing,
    setDialogOpen,
    setDialogData,

    focusedEvent,
    handleChangeFocusedEvent,
    handlePublishEvent,

    categories,
    categoryReferences,
  }) {
    return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Listing
        </Typography>
      </div>
      <ListingForm
        preview={focusedEvent}
        processing={processing}
        handleChange={(name, value) => handleChangeFocusedEvent(name, value)}
        dialogOpen={dialogOpen}
        dialogData={dialogData}
        setDialogData={setDialogData}
        setDialogOpen={setDialogOpen}
      />
      <br/>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Categories
            </Typography>
          </GridCell>
          <GridCell>
            <Button
              palette="secondary" style={{padding: 5, fontSize: '.8em'}}
              onClick={() => {
                setDialogData({
                  type: 'addCategory',
                  title: 'Add a category',
                });
                setDialogOpen(true);
              }}
            >
              Add category
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {(!focusedEvent?.categories || focusedEvent?.categories.length === 0) &&
        <div style={{margin: 5}}>
          <Typography>
            No Categories
          </Typography>
        </div>
      }
      {focusedEvent?.categories?.length > 0 &&
        <div style={{margin: 5}}>
          {focusedEvent?.categories.map((c, cIndex) => (
            <Typography
              key={cIndex}
              style={{display: 'inline', cursor: 'pointer'}}
              onClick={() => {
                const tempCategories = focusedEvent?.categories;
                tempCategories.splice(cIndex, 1);
                handleChangeFocusedEvent('categories', tempCategories);
              }}
            >
              {categoryReferences[c]?.name || '...loading'}{cIndex !== (focusedEvent?.categories.length - 1) ? ', ' : ''}
            </Typography>
          ))}
        </div>
      }
      <br/>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Details
        </Typography>
      </div>
      <div style={{padding: 5}}>
        <EventInformationForm
          me={me}
          processing={processing}
          focusedEvent={focusedEvent}
          handleChange={(name, value) => handleChangeFocusedEvent(name, value)}
        />
      </div>
      <GridContainer style={{marginTop: 10, marginBottom: 10}}>
        <GridCell>
          <Button
            disabled={
              !(focusedEvent?.layout?.pages?.length > 0 && focusedEvent?.layout?.pages[0]?.sections?.length > 0) ||
              !(focusedEvent?.categories?.length > 0) ||
              !focusedEvent?.thumbnail?.imageId ||
              !focusedEvent?.name ||
              !(focusedEvent?.locations?.length > 0)
            }
            palette='primary'
            onClick={() => {
              setDialogData({
                type: 'messageWithCallback',
                title: 'Publish Event',
                message: 'Once you publish your event it is recomended you dont make changes.  Please make sure all the event information is correct',
                data: {
                  callback: () => {
                    handlePublishEvent();
                  }
                }
              });
              setDialogOpen(true);
            }}
          >
            {focusedEvent?.state === 'public' ? 'Re-' : ''}Publish
          </Button>
          {processing && <ProgressBar palette='secondary'/>}
        </GridCell>
      </GridContainer>
    </div>
  );
};

EditEventInformationSubPanel.propTypes = {
  focusedEvent: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedEvent: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditEventInformationSubPanel;
