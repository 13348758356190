import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {convertToRaw} from 'draft-js';
import {draftToMarkdown} from 'markdown-draft-js';

import {TextAreaRich, Typography, paletteData, textData} from '../../../styles';

function Input({value, label, onChange, palette, indicatorStyle, highlightStyle, forceShrink, labelSize, labelStyle, labelHeights, labelContainerStyle, style, onFocus, onBlur, disabled, withControls}) {
  const [markdownState, setMarkdownState] = useState(undefined);

  const [focused, setFocused] = useState(false);

  const [highlightPosition, setHighlightPosition] = useState((focused) ? 'translateX(0%)' : 'translateX(-105%)');
  const [labelHeight, setLabelHeight] = useState((forceShrink || value || focused) ? (labelHeights?.focused || 5) : (labelHeights?.unfocused || 22));
  const [labelFontSize, setLabelFontSize] = useState((forceShrink || value || focused) ? '.8em' : (labelSize || '1em'));
  const [labelColor, setLabelColor] = useState((focused) ? paletteData[palette || 'blank'].highlight.foreground: paletteData[palette || 'blank'].standard.foreground);

  return (
    <div style={{position: 'relative', overflow: 'hidden', margin: 5, ...style}}>
      {!withControls && 
        <div style={{position: 'relative', height: 20, ...labelContainerStyle}}>
          <div
            style={{
              fontFamily: textData.text.fontFamily,
              fontSize: labelFontSize,
              color: labelColor,
              transition: '0.2s',
              position: 'absolute',
              top: labelHeight,
              left: 2,
              pointerEvents: 'none',
              ...labelStyle,
            }}
          >
            {label}
          </div>
        </div>
      }
      
      {disabled &&
        <Typography style={{whiteSpace: 'pre-wrap'}}>
          {value}
          <br/><br/>
        </Typography>
      }
      {!disabled &&
        <TextAreaRich
          title={withControls ? label : undefined}
          disabled={disabled}
          palette={palette}
          stripStyles={withControls ? false : true}
          style={withControls ? {} : {margin: 0}}
          editorContainerStyle={withControls ? {} : {padding: 0}}
          editorState={markdownState || undefined}
          markdown={value || ''}
          onChange={(value) => {
            setMarkdownState(value);
            onChange(draftToMarkdown(convertToRaw(value.getCurrentContent())));
          }}
          onFocus={() => {
            // update look when input focused
            setFocused(true);
            setLabelHeight(5);
            setHighlightPosition('translateX(0%)');
            setLabelColor(paletteData[palette || 'blank'].highlight.foreground);
            setLabelFontSize('.8em');
            if(onFocus) {
              onFocus();
            }
          }}
          onBlur={() => {
            // update look when input unfocused
            setFocused(false);
            setLabelHeight((value || forceShrink) ? (labelHeights?.focused || 5) : (labelHeights?.unfocused || 22));
            setLabelFontSize((value || forceShrink)? '.8em' : (labelSize || '1em'));
            setHighlightPosition('translateX(-105%)');
            setLabelColor(paletteData[palette || 'blank'].standard.foreground);
            if(onBlur) {
              onBlur();
            }
          }}
        />
      }


      <label style={{
        position: 'absolute',
        bottom: 11,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        borderBottom: `1px solid ${paletteData[palette || 'blank'].standard.foreground}`,
        ...indicatorStyle,
      }}>
      </label>
      <div
        style={{
          position: 'absolute',
          bottom: 11,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          borderBottom: `2px solid ${paletteData[palette || 'blank'].highlight.foreground}`,
          transition: '0.2s',
          transform: highlightPosition,
          ...highlightStyle,
        }}
      />
    </div>
  );
}

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  palette: PropTypes.string,
  indicatorStyle: PropTypes.shape({}),
  highlightStyle: PropTypes.shape({}),
  labelStyle: PropTypes.shape({}),
  labelSize: PropTypes.string,
  forceShrink: PropTypes.bool,
  labelContainerStyle: PropTypes.shape({}),
  style: PropTypes.shape({}),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  labelHeights: PropTypes.shape({
    focused: PropTypes.number,
    unfocused: PropTypes.number,
  }),
};

export default Input;


/*
const [inputValue, setInputValue] = useState(value || '');
const [focused, setFocused] = useState(false);
const [labelHeight, setLabelHeight] = useState((forceShrink || value || inputValue || focused) ? 5 : 22);
const [labelFontSize, setLabelFontSize] = useState((forceShrink || value || inputValue || focused) ? '.8em' : (labelSize || '1em'));
const [highlightPosition, setHighlightPosition] = useState((focused) ? 'translateX(0%)' : 'translateX(-105%)');
const [labelColor, setLabelColor] = useState((focused) ? paletteData[palette || 'blank'].highlight.foreground: paletteData[palette || 'blank'].standard.foreground);

const inputEl = useRef(null);
const [textAreaHeight, setTextAreaHeight] = useState("auto");
const [parentHeight, setParentHeight] = useState("auto");

useEffect(() => {
  if(value !== inputValue) {
    setInputValue(value);
  }

  setLabelHeight((forceShrink || value || inputValue || focused) ? 5 : 22);
  setHighlightPosition((focused) ? 'translateX(0%)' : 'translateX(-105%)');
  setLabelColor((focused) ? paletteData[palette || 'blank'].highlight.foreground: paletteData[palette || 'blank'].standard.foreground);
  setLabelFontSize((forceShrink || value || inputValue || focused) ? '.8em' : (labelSize || '1em'));

  setTextAreaHeight(`${inputEl.current.scrollHeight}px`);
  setParentHeight(`${inputEl.current.scrollHeight}px`)
}, [value, palette, focused, forceShrink, inputValue]);

return (
  <div style={{padding: 5, position: 'relative', ...style}}>
    <div style={{height: 21, position: 'relative', ...labelContainerStyle}}>
      <span
        style={{
          fontFamily: textData.text.fontFamily,
          fontSize: labelFontSize,
          color: labelColor,
          transition: '0.2s',
          position: 'absolute',
          top: labelHeight,
          left: 2,
          ...labelStyle,
        }}
      >
        {label}
      </span>
    </div>

    <div style={{
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      height: parentHeight,
    }}>
      <textarea
        rows={1}
        ref={inputEl}
        type={type}
        autoComplete={autoComplete}
        name={name}
        id={name}
        value={value || ''}
        style={{
          width: '100%',
          height: textAreaHeight,
          border: 'none',
          outline: 'none',
          fontSize: '1em',
          fontFamily: textData.text.fontFamily,
          color: paletteData[palette || 'blank'].standard.foreground,
          background: 'rgba(0, 0, 0, 0.0)',
          paddingBottom: 5,
          ...inputStyle,
        }}
        onFocus={() => {
          // update look when input focused
          setTextAreaHeight("auto");
          setFocused(true);
          setLabelHeight(25);
          setHighlightPosition('translateX(0%)');
          setLabelColor(paletteData[palette || 'blank'].highlight.foreground);
          setLabelFontSize('.8em');
          if(onFocus) {
            onFocus();
          }
        }}
        onBlur={() => {
          // update look when input unfocused
          setTextAreaHeight("auto");
          setFocused(false);
          setLabelHeight((inputValue || forceShrink) ? 5 : 22);
          setLabelFontSize((inputValue || forceShrink)? '.8em' :'1em');
          setHighlightPosition('translateX(-105%)');
          setLabelColor(paletteData[palette || 'blank'].standard.foreground);
          if(onBlur) {
            onBlur();
          }
        }}
        onChange={(e) => {
          setTextAreaHeight("auto");
          setParentHeight(inputEl.current.scrollHeight);
          setInputValue(e.target.value);
          if(onChange) {
            onChange(e.target.value);
          }
        }}
      >
      </textarea>
      <label htmlFor={name} style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        borderBottom: `1px solid ${paletteData[palette || 'blank'].standard.foreground}`,
        ...indicatorStyle,
      }}>
      </label>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          borderBottom: `2px solid ${paletteData[palette || 'blank'].highlight.foreground}`,
          transition: '0.2s',
          transform: highlightPosition,
          ...highlightStyle,
        }}
      />
    </div>
  </div>
);
*/
