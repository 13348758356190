import React from 'react';
import {Helmet} from "react-helmet";

import {Typography, paletteData} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';
import Hidden from '../../../components/hidden';
import {YoutubeEmbed} from '../../../components/video';

function About() {
  return (
    <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - about</title>
        <meta
          name ='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>
      <GridContainer
        style={{
          background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundBlendMode: 'lighten',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          minHeight: '100vh'
        }}
      >
        <GridCell
          weight={1}
          center={true}
          centerWeights={{top: 2, bottom: 3}}
        >
          <br/>
          <Typography
            size='heading'
            style={{textAlign: 'center'}}
          >
            About Us
          </Typography>
          <br/><br/>
          <div style={{width: '60vw', margin: 'auto'}}>
            <YoutubeEmbed youtubeId='fiYCl-qAWYU'/>
          </div>
          <br/><br/>
        </GridCell>
      </GridContainer>
      <div style={{padding: 10, background: paletteData.primary.standard.background}}>
        <GridContainer>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
          <GridCell weight={10} style={{display: 'flex'}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Our Vision
            </Typography>
          </GridCell>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
        </GridContainer>
      </div>
      <GridContainer>
        <Hidden breakpoint='hiddenlessthan650'>
          <GridCell weight={1}/>
        </Hidden>
        <GridCell weight={10} style={{display: 'flex', flexDirection: 'column', padding: 10}}>
          <Typography>
          Rerengātahi is a digital and community development project to help
            develop connections and strengthen support networks. We encourage
            buying locally, because money spent in your local community will go back
            into local wages, into community activities, into local schools, and benefit
            local whanau.
            <br/><br/>
            The COVID crisis and the lockdown in 2020 raised a number issues for us
            in Te Taitokerau. Particularly during lockdown, a number of our
            communities and whanau became isolated. Also, small businesses and
            Maori and community groups - the heart of many rural communities - were
            put under pressure; some of them found it difficult to use digital tools to
            connect with the people they served or who bought products. Local money
            was being spent to secure supplies from other places. While many roopu
            worked creatively to collaborate to meet whanau and community needs, a
            number of gaps showed up in delivery lines, in people being able to sell
            and buy local products, in micro-businesses, whanau and roopu being able
            to connect with their communities.
            <br/><br/>
            The Rerengātahi project is a collaboration between local community
            development groups, roopu, organisations, businesses, led by 
            <a href="https://heiwi.co.nz/" target="_blank" rel='noreferrer noopener'>He Iwi Kotahi Tatou Trust</a>,
            <a href="https://www.155.org.nz/" target="_blank" rel='noreferrer noopener'>One Double Five Community House</a>, and Northland Urban Rural Mission. We promote local
            connections. We aim to create networks of small businesses, whanau
            economic initiatives, and Maori and community groups, that is supported,
            collaborating, visible, and accessible to our communities. We believe this
            will help our communities flourish and grow, becoming more robust
            economically, socially, and culturally. We work within our communities in
            Te Taitokerau
          </Typography>
          <ul>
            <li><Typography>Inclusiveness: We will work with all small businesses, whanau initiatives, organisations, and community groups who want our support.</Typography></li>
            <li><Typography>Collaboration: We will encourage those we work with to also work together, creating strong support networks.</Typography></li>
            <li><Typography>Accessibility: If you are lacking skills or resources to help your small business, organisation, or community group grow, we can help you find training and resources to support your growth.</Typography></li>
            <li><Typography>Not-For-Profit: While those we work with may be small for-profit businesses, organisations, and community groups, we are not. We do not take a cut from any activity on our platform. Rather, we will secure funding to operate primarily with the generous support of funding organisations.</Typography></li>
          </ul>
        </GridCell>
        <Hidden breakpoint='hiddenlessthan650'>
          <GridCell weight={1}/>
        </Hidden>
      </GridContainer>
      <br/><br/>
      <div style={{padding: 10, background: paletteData.primary.standard.background}}>
        <GridContainer>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
          <GridCell weight={10} style={{display: 'flex'}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              What we do
            </Typography>
          </GridCell>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
        </GridContainer>
      </div>
      <GridContainer>
        <Hidden breakpoint='hiddenlessthan650'>
          <GridCell weight={1}/>
        </Hidden>
        <GridCell weight={10} style={{display: 'flex', flexDirection: 'column', padding: 10}}>
          <Typography>
          The Rerengātahi project works within our community to support small businesses, organisations, and community groups.
          </Typography>
          <ul>
            <li><Typography>We provide a platform to promote your group, sell goods and services locally, and connect with your community</Typography></li>
            <li><Typography>We connect you with services that will help you grow your business, organisation or community group</Typography></li>
            <li><Typography>We work within our community to make it easy to buy local and support local</Typography></li>
          </ul>
        </GridCell>
        <Hidden breakpoint='hiddenlessthan650'>
          <GridCell weight={1}/>
        </Hidden>
      </GridContainer>
    </div>
  );
}

export default About;
