import React from 'react';
import PropTypes from 'prop-types';

import {CheckBox, Input, Select, Typography} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function ProductInformationForm({focusedProduct, focusedOrganisation, handleChange, setDialogData, setDialogOpen}) {
  return (
    <div>
      <Input 
        label='Name'
        value={focusedProduct.name}
        onChange={(value) => handleChange('name', value)}
      />
      <GridContainer>
        <GridCell weight={1}>
          <Select
            palette='secondary'
            label="Product State"
            value={focusedProduct?.state || ''}
            onChange={(value) => handleChange('state', value)}
          >
            <option value="active">Visible</option>
            <option value="inactive">Hidden</option>
          </Select>
        </GridCell>
        <GridCell weight={1}>
          <Input 
            label='Stock'
            type='number'
            value={focusedProduct?.stock}
            onChange={(value) => handleChange('stock', value)}
          />
        </GridCell>
      </GridContainer>
      <GridContainer>
        <GridCell style={{marginTop: 22}}>
          <CheckBox 
            label='Bulk Price Available'
            value={focusedProduct?.payment?.bulkPriceAvailable}
            onChange={(value) => {
              const paymentProcessed = focusedProduct?.payment || {};
              paymentProcessed.bulkPriceAvailable = value;
              handleChange('payment', paymentProcessed);
            }}
          />
        </GridCell>
        {focusedProduct?.payment?.bulkPriceAvailable &&
          <GridCell weight={1}>
            <Input 
              label='Bulk Threshold'
              type='number'
              value={focusedProduct?.payment?.bulkThreshold}
              onChange={(value) => {
                const paymentProcessed = focusedProduct?.payment || {};
                paymentProcessed.bulkThreshold = value;
                handleChange('payment', paymentProcessed);
              }}
            />
          </GridCell>
        }
      </GridContainer>
      <GridContainer>
        <GridCell weight={1}>
          <Input 
            label='Price'
            type='number'
            value={focusedProduct?.payment?.price}
            onChange={(value) => {
              const paymentProcessed = focusedProduct?.payment || {};
              paymentProcessed.price = value;
              handleChange('payment', paymentProcessed);
            }}
          />
          {focusedOrganisation?.gst &&
            <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
              (${((focusedProduct?.payment?.price || 0) * 1.15).toFixed(2)} Including GST)
            </Typography>
          }
        </GridCell>
        {focusedOrganisation?.gst &&
          <GridCell 
            style={{
              marginTop: 25, 
              marginRight: 5,
              cursor: 'pointer',
            }}
            onClick={() => {
              setDialogData({
                type: 'gstInclusivePrice',
                title: 'Set GST Inclusive Price',
                data: {
                  callback: (value) => {
                    const paymentProcessed = focusedProduct?.payment || {};
                    paymentProcessed.price = value;
                    handleChange('payment', paymentProcessed);
                  }
                },
                value: {
                  price: +((focusedProduct?.payment?.price || 0) * 1.15).toFixed(2),
                }
              });
              setDialogOpen(true);
            }}
          >
            <Typography>
              + GST
            </Typography>
          </GridCell>
        }
      </GridContainer>
      {focusedProduct?.payment?.bulkPriceAvailable &&
        <GridContainer>
          <GridCell weight={1}>
            <Input 
              label='Bulk Price'
              type='number'
              value={focusedProduct?.payment?.bulkPrice}
              onChange={(value) => {
                const paymentProcessed = focusedProduct?.payment || {};
                paymentProcessed.bulkPrice = value;
                handleChange('payment', paymentProcessed);
              }}
            />
            {focusedOrganisation?.gst &&
              <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                (${((focusedProduct?.payment?.bulkPrice || 0) * 1.15).toFixed(2)} Including GST)
              </Typography>
            }
          </GridCell>
          {focusedOrganisation?.gst &&
            <GridCell 
              style={{
                marginTop: 26, 
                marginRight: 5,
                cursor: 'pointer',
              }}
              onClick={() => {
                setDialogData({
                  type: 'gstInclusivePrice',
                  title: 'Set Bulk GST Inclusive Price',
                  data: {
                    callback: (value) => {
                      const paymentProcessed = focusedProduct?.payment || {};
                      paymentProcessed.bulkPrice = value;
                      handleChange('payment', paymentProcessed);
                    }
                  },
                  value: {
                    price: +((focusedProduct?.payment?.bulkPrice || 0) * 1.15).toFixed(2),
                  }
                });
                setDialogOpen(true);
              }}
            >
              <Typography>
                + GST
              </Typography>
            </GridCell>
          }
        </GridContainer>
      }
    </div>
  );
}

ProductInformationForm.propTypes = {
  me: PropTypes.shape({}),
  focusedProduct: PropTypes.shape({}),
  handleChange: PropTypes.func,
  processing: PropTypes.bool,
}

export default ProductInformationForm;
