import React from 'react';
import PropTypes from 'prop-types';

import {Typography, Fab} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';
import {YoutubeEmbed, VimeoEmbed} from '../../../video';

function VideoBlockEdit({
  block,
  removeBlock,
  blockIndex,
  maxIndex,
  changeSection,
  changeParent,
  changeBlockIndex,

  dialogData,
  dialogOpen,
  setDialogData,
  setDialogOpen
}) {
  return (
    <div style={{position: 'relative'}}>
      <GridContainer>
        <GridCell weight={1}/>
        <GridCell>
          <GridContainer
            style={{
              padding: 5,
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 5,
              background: 'white',
              margin: 10,
            }}
          >
            <GridCell center={true}>
              <Fab
                size='small'
                onClick={() => {
                  setDialogData({
                    type: 'selectVideo',
                    title: 'Change Video',
                    data: {
                      callback: (value) => {
                        changeSection('update', blockIndex, 'children.1.children.0.options.video', value);
                      }
                    },
                    value: {
                      search: '',
                    },
                  });
                  setDialogOpen(true);
                }}
              >
                <span className="material-icons md-24">tv</span>
              </Fab>
            </GridCell>
            <GridCell center={true}>
              <Fab
                size='small'
                style={{background: '#c6c6c6', cursor: 'pointer'}}
                onClick={() => {
                  setDialogData({
                    type: 'moveSection',
                    title: 'Move Section',
                    data: {
                      maxIndex,
                      currentIndex: blockIndex + 1,
                      callbackMove: (shift) => changeBlockIndex(shift),
                      callbackRemove: () => removeBlock(blockIndex),
                    },
                    value: {
                      index: blockIndex + 1,
                    },
                  });
                  setDialogOpen(true);
                }}
              >
                <Typography style={{textAlign: 'center'}}>
                  {blockIndex + 1}
                </Typography>
              </Fab>
            </GridCell>
          </GridContainer>
        </GridCell>
      </GridContainer>
      <GridContainer style={{...(block.options?.style || {})}}>
        <GridCell weight={block.children[0].options.weight}/>
        <GridCell style={block.children[1].options.style}>
          {block.children[1].children[0].options.video.platform === 'youtube' &&
            <div style={{zIndex: -10}}>
              <YoutubeEmbed youtubeId={block.children[1].children[0].options.video.youtubeId} preview={true}/>
            </div>
          }
          {block.children[1].children[0].options.video.platform === 'vimeo' &&
            <div>
              <VimeoEmbed vimeoId={block.children[1].children[0].options.video.vimeoId} preview={true}/>
            </div>
          }
          {!block.children[1].children[0].options.video.platform &&
            <div style={{paddingBottom: '56%', background: '#c6c6c6', position: 'relative', zIndex: -1}}>
              <GridContainer style={{position: 'absolute', width: '100%', height: '100%'}}>
                <GridCell weight={1} center={true} style={{textAlign: 'center'}}>
                  <span className="material-icons md-24" style={{fontSize: '5em'}}>tv</span>
                  <Typography>
                    Video not selected
                  </Typography>
                </GridCell>
              </GridContainer>
            </div>
          }
          {block.children[1].children[0].options.video.credit && !block.children[1].children[0].options.video.citation &&
            <Typography size='subText' style={{textAlign: 'right'}}>
              Created by {block.children[1].children[0].options.video.credit}
            </Typography>
          }
          {block.children[1].children[0].options.video.credit && block.children[1].children[0].options.video.citation &&
            <a
              href={block.children[1].children[0].options.video.citation}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Typography size='subText' style={{textAlign: 'right'}}>
                Created by {block.children[1].children[0].options.video.credit}
              </Typography>
            </a>
          }
        </GridCell>
        <GridCell weight={block.children[2].options.weight}/>
      </GridContainer>
    </div>
  );
}

VideoBlockEdit.propTypes = {
  block: PropTypes.shape({}),
  changeSection: PropTypes.func,
  changeParent: PropTypes.func,
};

export default VideoBlockEdit;
