import React, {useState} from 'react';
import ReactMarkdown from 'react-markdown';
import {convertToRaw} from 'draft-js';
import {draftToMarkdown} from 'markdown-draft-js';

import Hidden from '../../../../components/hidden';
import {paletteData, Typography, ProgressBar, Fab, Button, Dialog, TextAreaRich, Select, TextArea} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';
import SearchStore from '../../../../components/search/store';
import apiRequest from '../../../../tools/apiRequest';

function Validation({
  communityId,
  communityType,
  queryLimit,
  focusedGroup,
  setFocusedGroup,
  messages,
  search,
  isMobile,
  setShowCodeOfConduct,
  me,
  isModerator,
  replaceMessage,
  communityReference,
  replaceArea,
  replaceCategory,
  setCategoryReference,
  setAreaReference,
}) {
  const [processing, setProcessing] = useState(false)
  const [expandSearch, setExpandSearch] = useState(false);
  const [initialLoadComplete, setInitalLoadComplete] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{padding: 10, background: paletteData.primary.standard.background}}>
        <GridContainer>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
          <GridCell weight={10}>
            <GridContainer>
              <GridCell weight={1} center={true}>
                <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                  {focusedGroup.name}
                </Typography>
              </GridCell>
              {focusedGroup?.postAuthority === 'moderatorValidation' &&
                <GridCell center={true} style={{marginRight: 10}}>
                  <Fab
                    palette='secondary' size='small'
                    onClick={() => {
                      if(me?._id && me.name && me.email && me?.verification?.contact && me.verification.contact[me.email] === true) {
                        let buildMessage = '';
                        if(focusedGroup?.postAuthority === 'moderatorValidation') {
                          buildMessage += 'Messages on this message board are screened by our volinteer moderators,  if you are interested in becoming a moderator for this community please contact info@rerengatahi.nz. ';
                        }
                        if(focusedGroup?.transparency === 'public') {
                          buildMessage += 'This message board is tagged as high visibility by the moderator.  This means that your email address will be attached to all messages you post on this message board. '
                        }

                        setDialogData({
                          type: 'submitCommunityMessage',
                          title: "Submit a message",
                          message: buildMessage,
                          value: {}
                        });
                        setDialogOpen(true);
                      } else if(me?._id) {
                        setDialogData({
                          type: 'message',
                          title: "Submit a community message",
                          message: 'Your email must be verified to submit a community message',
                        });
                        setDialogOpen(true);
                      } else {
                        setDialogData({
                          type: 'message',
                          title: "Submit a community message",
                          message: 'You must be logged in to submit a community message',
                        });
                        setDialogOpen(true);
                      }
                    }}
                  >
                    <span className="material-icons md-24">add</span>
                  </Fab>
                </GridCell>
              }
              <GridCell center={true}>
                <Fab palette='secondary' size='small' onClick={() => setFocusedGroup(undefined)}>
                  <span className="material-icons md-24">arrow_back</span>
                </Fab>
              </GridCell>
            </GridContainer>
          </GridCell>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
        </GridContainer>
      </div>
      <GridContainer>
        <Hidden breakpoint='hiddenlessthan650'>
          <GridCell weight={1}/>
        </Hidden>
        <GridCell weight={10}>
          <div style={{marginLeft: 5, marginRight: 5}}>
            <Typography size='subText' style={{textAlign: 'right', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setShowCodeOfConduct(true)}>
              Code of Conduct
            </Typography>
            {focusedGroup?.postAuthority === 'moderatorValidation' &&
              <GridContainer>
                <GridCell weight={1}/>
                <GridCell>
                  <span className="material-icons md-24" style={{fontSize: '1em', marginRight: 5}}>warning</span>
                </GridCell>
                <GridCell center={true}>
                  <Typography
                    size='subText'
                    style={{textAlign: 'right', textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => {
                      setDialogData({
                        type: 'message',
                        title: 'Requires Moderator Approval',
                        message: 'Posts on this message board require moderator approval before going live.',
                      });
                      setDialogOpen(true);
                    }}
                  >
                    Requires Moderator Approval
                  </Typography>
                </GridCell>
              </GridContainer>
            }
            {focusedGroup?.transparency === 'public' &&
              <GridContainer>
                <GridCell weight={1}/>
                <GridCell>
                  <span className="material-icons md-24" style={{fontSize: '1em', marginRight: 5}}>warning</span>
                </GridCell>
                <GridCell center={true}>
                  <Typography
                    size='subText'
                    style={{textAlign: 'right', textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => {
                      setDialogData({
                        type: 'message',
                        title: 'High Visibility Posting',
                        message: 'Your email address will be attached to each post you make on this message board.',
                      });
                      setDialogOpen(true);
                    }}
                  >
                    High Visibility Posting
                  </Typography>
                </GridCell>
              </GridContainer>
            }
          </div>
          <SearchStore
            isMobile={isMobile}
            databaseArea='public_messages'
            processing={processing}
            setProcessing={setProcessing}
            expandSearch={expandSearch}
            setExpandSearch={setExpandSearch}
            setInitalLoadComplete={setInitalLoadComplete}
            filter={{communityId, communityType, groupName: focusedGroup.name}}
          />
          {initialLoadComplete &&
           search?.messages?.query?.communityId === communityId &&
           search?.messages?.query?.groupName === focusedGroup.name &&
           messages?.length > 0 &&
           messages.map((m, mIndex) => (
            <div key={mIndex}>
              {mIndex === 0 && <hr/>}
              <div
                style={{
                  background: (m.validation === 'invalid' || m.validation === 'unvalidated') ? '#c6c6c6' : undefined,
                  padding: (m.validation === 'invalid' || m.validation === 'unvalidated') ? 10 : undefined,
                }}
              >
                {isModerator &&
                  <div>
                    {m.validation === 'invalid' &&
                      <Typography
                        style={{color: 'red', cursor: 'pointer'}}
                        onClick={() => {
                          setDialogData({
                            type: 'unblockMessage',
                            title: "Unblock Message",
                            message: 'This message is currently Blocked do you want to make it Visible?',
                            data: {mIndex},
                          });
                          setDialogOpen(true);
                        }}
                      >
                        Message Blocked
                      </Typography>
                    }
                    {m.validation === 'valid' &&
                      <Typography
                        style={{color: 'green', cursor: 'pointer'}}
                        onClick={() => {
                          setDialogData({
                            type: 'blockMessage',
                            title: "Block Message",
                            message: 'This message is currently Visible do you want to Block it?',
                            data: {mIndex},
                          });
                          setDialogOpen(true);
                        }}
                      >
                        Message Visible
                      </Typography>
                    }
                    {m.validation === 'unvalidated' &&
                      <Typography
                        style={{color: 'red', cursor: 'pointer'}}
                        onClick={() => {
                          setDialogData({
                            type: 'validateMessage',
                            title: "Validate Message",
                            data: {mIndex},
                            value: {}
                          });
                          setDialogOpen(true);
                        }}
                      >
                        Message Pending
                      </Typography>
                    }
                  </div>
                }
                <Typography>
                  <ReactMarkdown children={m.message} />
                </Typography>
                <Typography
                  size='subText'
                  style={{
                    textAlign: 'right',
                    color:  (focusedGroup?.blackList[m.userId]) ? 'red' :
                            (m.validation === 'invalid' || m.validation === 'unvalidated') ? 'black' : '#c6c6c6',
                    cursor: isModerator ? 'pointer' : undefined,
                  }}
                  onClick={() => {
                    if(isModerator) {
                      if(m.userId === me._id) {
                        setDialogData({
                          type: 'message',
                          title: "Blacklist",
                          message: "You can not blacklist yourself.",
                        });
                        setDialogOpen(true);
                      } else if(!focusedGroup?.blackList[m.userId]) {
                        setDialogData({
                          type: 'blackList',
                          title: "Blacklist",
                          message: "Do you want to blacklist this user?  They will not be able to post messages.",
                          data: {userId: m.userId, communityId, communityType, groupName: focusedGroup.name},
                          value: {location: 'single'},
                        });
                        setDialogOpen(true);
                      } else {
                        setDialogData({
                          type: 'unBlackList',
                          title: "Remove From Blacklist",
                          message: "Do you want to remove this user from the blacklist?  They will be able to post messages again.",
                          data: {userId: m.userId, communityId, communityType, groupName: focusedGroup.name},
                          value: {location: 'single'},
                        });
                        setDialogOpen(true);
                      }
                    }
                  }}
                >
                  {m.userAlius}
                </Typography>
                <Typography size='subText' style={{textAlign: 'right', color: (m.validation === 'invalid' || m.validation === 'unvalidated') ? 'black' : '#c6c6c6'}}>
                  {m.userContact}
                </Typography>
                <Typography size='subText' style={{textAlign: 'right', color: (m.validation === 'invalid' || m.validation === 'unvalidated') ? 'black' : '#c6c6c6'}}>
                  {(new Date(m.createdAt)).toDateString()}
                </Typography>
              </div>
              <hr/>
            </div>
          ))}
          {processing && messages?.length > 5 &&
            <div style={{padding: 5}}>
              <Typography>
                Loading...
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          }
          {!processing && initialLoadComplete && search?.messages?.hasMore &&
            <div style={{padding: 5}}>
              <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                Load More
              </Button>
            </div>
          }
        </GridCell>
        <Hidden breakpoint='hiddenlessthan650'>
          <GridCell weight={1}/>
        </Hidden>
      </GridContainer>
      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message && dialogData.type !== 'reviewCommunityMessage' &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'submitCommunityMessage' &&
            <div style={{padding: 10}}>
              <TextAreaRich
                title='Message'
                palette='primary'
                editorState={dialogData?.value?.markdownState || undefined}
                markdown={dialogData?.value?.markdown || ''}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      markdownState: value,
                      markdown: draftToMarkdown(convertToRaw(value.getCurrentContent())),
                    }
                  });
                }}
              />
            </div>
          }
          {dialogData?.type === 'reviewCommunityMessage' &&
            <div style={{padding: 10}}>
              <hr />
              <div>
                <Typography>
                  <ReactMarkdown children={dialogData?.value?.markdown} />
                </Typography>
                <Typography size='subText' style={{textAlign: 'right', color: '#c6c6c6'}}>
                  {me.name}<br/>
                  {me.email}<br/>
                  {(new Date()).toDateString()}
                </Typography>
              </div>
              <hr />
              <br/>

            </div>
          }
          {dialogData?.type === 'validateMessage' &&
            <div style={{padding: 10}}>
              <Select
                label='Decision'
                value={dialogData?.value?.decision}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      decision: value,
                    }
                  });
                }}
              >
                <option value='valid'>Accept</option>
                <option value='invalid'>Reject</option>
              </Select>
              {dialogData?.value?.decision === 'invalid' &&
                <TextArea
                  label="Reason for rejecting"
                  value={dialogData?.value?.reason}
                  onChange={(value) => {
                    setDialogData({
                      ...dialogData,
                      value: {
                        ...(dialogData?.value || {}),
                        reason: value,
                      }
                    });
                  }}
                />
              }
            </div>
          }
          {(dialogData?.type === 'blackList' || dialogData?.type === 'unBlackList') &&
            <div>
              <Select
                label='Location'
                value={dialogData?.value?.location}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      location: value,
                    }
                  });
                }}
              >
                <option value='all'>All message boards</option>
                <option value='single'>Just this message board</option>
              </Select>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'submitCommunityMessage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setDialogData({
                      ...dialogData,
                      title: 'Review Community Message',
                      type: 'reviewCommunityMessage',
                    })
                  }}
                >
                  Review
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'reviewCommunityMessage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setDialogData({
                      ...dialogData,
                      title: 'Submit a community message',
                      type: 'submitCommunityMessage',
                      value: {
                        ...(dialogData?.value || {}),
                        full: false,
                      }
                    })
                  }}
                >
                  Edit
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'reviewCommunityMessage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const updateMessage = {
                      communityId,
                      groupName: focusedGroup.name,
                      userId: me._id,
                      userAlius: me.name,
                      userContact: me.email,
                      message: dialogData?.value?.markdown,
                    }
                    apiRequest({type: 'post', action: `messages/create`, data: {updateMessage, communityType, communityId, groupName: focusedGroup.name}})
                    .then((result) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Community Message',
                        message: 'Your message has been submitted, a moderator will review it before it goes live.  If you have any questions please contact info@rerengatahi.nz',
                      });
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    });
                  }}
                >
                  Submit
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
            {dialogData?.type === 'validateMessage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.decision || (dialogData?.value?.decision === 'invalid' && !dialogData?.value?.reason)}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const messageProcessed = messages[dialogData?.data?.mIndex];
                    apiRequest({
                      type: 'patch',
                      action: `messages/validate/${messageProcessed._id}`,
                      data: {
                        communityType,
                        communityId,
                        groupName: 'Community Messages',
                        decision: dialogData?.value?.decision,
                        reason: dialogData?.value?.reason,
                      }
                    })
                    .then((result) => {
                      messageProcessed.validation = dialogData?.value?.decision;
                      replaceMessage({message: messageProcessed});
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    });
                  }}
                >
                  Submit
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
            {dialogData?.type === 'blockMessage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const messageProcessed = messages[dialogData?.data?.mIndex];
                    const updateMessage = {validation: 'invalid'};
                    apiRequest({type: 'patch', action: `messages/update/${messageProcessed._id}`, data: {updateMessage}})
                    .then((result) => {
                      replaceMessage({message: {...messageProcessed, ...updateMessage}});
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    });
                  }}
                >
                  Block
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
            {dialogData?.type === 'unblockMessage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const messageProcessed = messages[dialogData?.data?.mIndex];
                    const updateMessage = {validation: 'valid'};
                    apiRequest({type: 'patch', action: `messages/update/${messageProcessed._id}`, data: {updateMessage}})
                    .then((result) => {
                      replaceMessage({message: {...messageProcessed, ...updateMessage}});
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    });
                  }}
                >
                  Unblock
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
            {dialogData?.type === 'blackList' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const blacklistData = {
                      type: 'add',
                      location: dialogData?.value?.location,
                      ...dialogData?.data,
                    };
                    apiRequest({type: 'patch', action: `communities/blacklist/${communityId}`, data: {blacklistData}})
                    .then((result) => {
                      const groupsProcessed = result.data.newGroupData;
                      const communityProcessed = {
                        ...communityReference,
                        community: {
                          ...(communityReference.community || {}),
                          groups: groupsProcessed,
                        }
                      }
                      if(communityType === 'category') {
                        setCategoryReference(communityProcessed);
                        replaceCategory({category: communityProcessed});
                      } else if(communityType === 'area') {
                        setAreaReference(communityProcessed);
                        replaceArea({area: communityProcessed});
                      }
                      const groupProcessed = groupsProcessed.find((g, gIndex) => g.name === focusedGroup.name);
                      setFocusedGroup(groupProcessed);
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    });
                  }}
                >
                  Blacklist
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
            {dialogData?.type === 'unBlackList' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const blacklistData = {
                      type: 'remove',
                      location: dialogData?.value?.location,
                      ...dialogData?.data,
                    };
                    apiRequest({type: 'patch', action: `communities/blacklist/${communityId}`, data: {blacklistData}})
                    .then((result) => {
                      const groupsProcessed = result.data.newGroupData;
                      const communityProcessed = {
                        ...communityReference,
                        community: {
                          ...(communityReference.community || {}),
                          groups: groupsProcessed,
                        }
                      }
                      if(communityType === 'category') {
                        setCategoryReference(communityProcessed);
                        replaceCategory({category: communityProcessed});
                      } else if(communityType === 'area') {
                        setAreaReference(communityProcessed);
                        replaceArea({area: communityProcessed});
                      }
                      const groupProcessed = groupsProcessed.find((g, gIndex) => g.name === focusedGroup.name);
                      setFocusedGroup(groupProcessed);
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    });
                  }}
                >
                  Remove From Blacklist
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

export default Validation;
