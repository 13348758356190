import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import SearchStore from '../../../../components/search/store';
import {paletteData, Button, ProgressBar, Typography} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';
import Hidden from '../../../../components/hidden';

function SearchOrganisationsSubPanel({
    organisations,
    setOrganisations,
    addOrganisations,
    search,
    setSearch,
    queryLimit,
    processing,
    setProcessing,
    setOrganisationReference,
    setRedirect,
    generateMarkers,
    categories,
    isMobile,
}) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [expandSearch, setExpandSearch] = useState(false);
  const [initalLoadComplete, setInitalLoadComplete] = useState(false);

  const urlQuery = useQuery();
  const areaId = urlQuery.get('area');
  const categoryId = urlQuery.get('category');

  return (
    <div>
      <SearchStore
        databaseArea='public_organisations'
        processing={processing}
        setProcessing={setProcessing}
        expandSearch={expandSearch}
        setExpandSearch={setExpandSearch}
        setInitalLoadComplete={setInitalLoadComplete}
        isMobile={isMobile}
        initalFilter={
          areaId ? {'offices.area': areaId} :
          categoryId ? {} :
          undefined
        }
      />
      {organisations?.length > 0 && initalLoadComplete &&
        <div style={{padding: 5}}>
          <Hidden breakpoint='hiddenlessthan1024'>
            <div>
              {organisations.map((o, oIndex) => (
                <GridContainer
                  key={oIndex}
                  style={{
                    margin: 5,
                    cursor: processing ? 'auto' : 'pointer',
                  }}
                  onClick={() => {
                    if(!processing) {
                      setOrganisationReference(o);
                      setRedirect(`/organisation/${o._id}`);
                    }
                  }}
                >
                  <GridCell center={true} style={{height: 200}}>
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${o.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                      alt={o?.thumbnail?.altText}
                      style={{width: 200}}
                    />
                  </GridCell>
                  <GridCell weight={1} center style={{textAlign: 'left', padding: 10, height: 180}}>
                    <Typography size='title'>
                      {o.name}
                    </Typography>
                    <Typography style={{color: '#333333'}}>
                      {o.tagline}
                    </Typography>
                    <Typography style={{marginTop: 5}}>
                      {o.blurb?.length > 350 ? `${o.blurb.substring(0, 350)}...` : o.blurb}
                  </Typography>
                  </GridCell>
                </GridContainer>
              ))}
            </div>
          </Hidden>

          <Hidden breakpoint='hiddengreaterthan1024'>
            <div>
              <GridContainer>
                {organisations.map((o, oIndex) => (
                  <GridCell
                    key={oIndex}
                    style={{
                      width: isMobile ? '100%' : undefined,
                      maxWidth: isMobile ? undefined : 200,
                      textAlign: 'center',
                      borderStyle: 'solid',
                      margin: 5,
                      borderWidth: 2,
                      borderRadius: 5,
                      borderColor: paletteData.primary.standard.background,
                      display: 'flex',
                      flexDirection: 'column',
                      cursor: processing ? 'auto' : 'pointer',
                    }}
                    onClick={() => {
                      if(!processing) {
                        setOrganisationReference(o);
                        setRedirect(`/organisation/${o._id}`);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${o.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                      alt={o?.thumbnail?.altText}
                      style={{width: '100%'}}
                    />
                    <div style={{background: paletteData.primary.standard.background, padding: 10, flex: 1}}>
                      <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                        {o.name}
                      </Typography>
                    </div>
                  </GridCell>
                ))}
              </GridContainer>
            </div>
          </Hidden>
          {processing && organisations?.length > 9 &&
            <div>
              <Typography>
                Loading...
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          }
          {!processing && (organisations.length >= search?.organisations?.queryDepth * queryLimit) &&
            <div style={{padding: 5}}>
              <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                Load More
              </Button>
            </div>
          }
        </div>
      }
    </div>
  );
};

SearchOrganisationsSubPanel.propTypes = {
  area: PropTypes.shape({}),
  organisations: PropTypes.arrayOf(PropTypes.shape({})),
  setOrganisations: PropTypes.func,
  addOrganisations: PropTypes.func,
  setOrganisationReference: PropTypes.func,

  search: PropTypes.shape({}),
  setSearch: PropTypes.func,
  queryLimit: PropTypes.number,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SearchOrganisationsSubPanel;
