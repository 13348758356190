import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Input, Button} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';

function SearchBar({search, onSearch, onClear, paletteButtons, paletteInput, styleButtons, styleInput, processing}) {
  const [searchValue, setSearchValue] = useState(search);

  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            disabled={processing}
            name="search"
            autoComplete="off"
            palette={paletteInput || 'secondary'}
            style={styleInput || {}}
            label="Search"
            type="text"
            value={searchValue || ''}
            onChange={(value) => setSearchValue(value)}
          />
        </GridCell>
        {search !== '' &&
          <GridCell style={{marginTop: 7, marginRight: 5}}>
            <Button
              disabled={processing}
              palette={paletteButtons || 'primary'}
              style={styleButtons || {}}
              onClick={() => {
                setSearchValue('');
                onClear();
              }}
            >
              Clear
            </Button>
          </GridCell>
        }
        <GridCell style={{marginTop: 7}}>
          <Button palette={paletteButtons || 'primary'} style={styleButtons || {}} onClick={() => onSearch(searchValue)} disabled={processing}>
            Search
          </Button>
        </GridCell>
      </GridContainer>
    </div>
  );
}

SearchBar.propTypes = {
  search: PropTypes.string,
  paletteButtons: PropTypes.string,
  paletteInputs: PropTypes.string,
  styleButtons: PropTypes.shape({}),
  styleInput: PropTypes.shape({}),
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  processing: PropTypes.bool,
};

export default SearchBar;
