import React, {useState, useEffect} from 'react';
import {useLocation, Redirect} from 'react-router-dom';
import {Helmet} from "react-helmet";

import {Typography} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';

function Home() {
  const [redirect, setRedirect] = useState(undefined);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  useEffect(() => {
    if(query.get('unsubscribe')) {
      const eventId = query.get('event');
      const organisationId = query.get('organisation');
      if(eventId && organisationId) {
        setRedirect(`/unsubscribe?event=${eventId}&organisation=${organisationId}`);
      } else if (eventId) {
        setRedirect(`/unsubscribe?event=${eventId}`);
      } else if (organisationId) {
        setRedirect(`/unsubscribe?organisation=${organisationId}`);
      } else {
        setRedirect(`/unsubscribe`);
      }
    } else if(query.get('emailverification')) {
      setRedirect(`/emailverification/${query.get('emailverification')}`);
    } else if (query.get('passwordreset')) {
      setRedirect(`/passwordreset/${query.get('passwordreset')}`);
    }
  }, [query]);

  if(redirect) {
    return (<Redirect to={redirect} />);
  }

  return (
    <GridContainer style={{
      flex: 1,
      background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundBlendMode: 'lighten',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      backgroundAttachment: 'fixed',
    }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - home</title>
        <meta
          name ='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>
      <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{textAlign: 'center'}}>
        <img
          src='https://res.cloudinary.com/taitokerau-tatou/image/upload/c_scale,q_auto:good,w_1024/v1617227279/media-assets/Reretahi-_Logo-V3_-_no_words_koehgh.png'
          alt='reretahi logo'
          style={{maxWidth: 300, margin: 'auto'}}
        />
        <Typography size='heading' style={{letterSpacing: 2.3, fontWeight: 700}}>
          RERENGĀTAHI
        </Typography>
        <Typography size='title' palette='secondary' style={{letterSpacing: 5}}>
          NORTHLAND TOGETHER
        </Typography>
      </GridCell>
    </GridContainer>
  );
}

export default Home;
