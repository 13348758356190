import React from 'react';
import PropTypes from 'prop-types';

function LinkStyle({children, contentState, entityKey}) {
  const {url} = contentState.getEntity(entityKey).getData();
  return (
    <a href={url} style={{color: '#ea3141'}}>
      {children}
    </a>
  );
}

LinkStyle.propTypes = {
  children: PropTypes.node,
  contentState: PropTypes.shape({
    getEntity: PropTypes.func,
  }),
  entityKey: PropTypes.string,
}

export default LinkStyle;
