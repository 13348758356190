import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import jwt from 'jsonwebtoken';
import {useParams} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import {Helmet} from "react-helmet";

import {setApiToken} from '../../../store/reducers/auth/actions.js';
import {setProfile} from '../../../store/reducers/user/actions.js';
import {GridContainer, GridCell} from '../../../components/grid';
import {Button, Typography, Dialog, Input} from '../../../components/styles';
import {setUserReference, setProfileReference} from '../../../store/reducers/references/actions.js';
import {checkPasswordStrength} from '../../../tools/validation';

import apiRequest from '../../../tools/apiRequest';
import handleErrorMessage from '../../../tools/handleErrorMessage';

function PasswordReset({me, auth, setApiToken, setProfile, setMe, setUserReference, setProfileReference}) {
  const [processing, setProcessing] = useState(false);
  const [key, setKey] = useState(undefined);
  const [newPassword, setNewPassword] = useState('');
  const [redirect, setRedirect] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const {id} = useParams();

  useEffect(() => {
    if(key === undefined && !processing) {
      setProcessing(true);
      apiRequest({type: 'get', action: `public_keys/${id}`})
      .then((result) => {
        setKey(result.data.key);
        setProcessing(false);
      }).catch((error) => {
        setKey(false);
        setProcessing(false);
      });
    }
  }, [id, key, processing]);

  const handleResetPassword = () => {
    setProcessing(true);
    apiRequest({type: 'post', action: `public_keys/use/${id}`, data: {value: newPassword}})
    .then((result) => {
      apiRequest({
        type: 'post',
        action: 'auth/login',
        data: {
          email: key.value,
          password: newPassword,
        },
      }).then((loginResult) => {
        const user = (jwt.decode(loginResult.data.auth?.access_token)).data;
        setApiToken(loginResult.data.auth);
        apiRequest({type: 'get', action: `users/${user._id}/profile`}).then((profileResult) => {
          setProfile(profileResult.data || undefined);
          setProcessing(false);
          setRedirect(true);
        }).catch((error) => {
          setProcessing(false);
          setDialogData({
            type: 'message',
            title: 'Password reset request denied',
            message: handleErrorMessage(error),
          });
          setDialogOpen(true);
        });
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Password reset request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Password reset request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  if(redirect) {
    return (<Redirect to="/console" />);
  }

  return (
    <div style={{
      background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundBlendMode: 'lighten',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      backgroundAttachment: 'fixed',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - password reset</title>
        <meta
          name ='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>
      <div style={{flex: 1}}/>
      <div style={{textAlign: 'center'}}>
        <Typography size='heading'>
          Rerengatahi
        </Typography>
        <Typography size='subHeading'>
          Password Reset {key?.value ? ` - ${key.value}` : ''}
        </Typography>
      </div>
      <br/><br/>
      <GridContainer>
        <GridCell weight={1} />
        <GridCell style={{width: 400}}>
          <div style={{background: 'white', padding: 10, borderRadius: 4}}>
            {key === undefined &&
              <Typography>
                ...loading
              </Typography>
            }
            {key === false &&
              <Typography>
                Invalid Token
              </Typography>
            }
            {key &&
              <form>
                <GridContainer>
                  <GridCell weight={1}>
                    <Input
                      name="password"
                      label="New Password"
                      palette='secondary'
                      type="password"
                      autoComplete='new-password'
                      value={newPassword}
                      onChange={(value) => setNewPassword(value)}
                    />
                    {!checkPasswordStrength(newPassword) &&
                      <Typography size='subText' style={{paddingLeft: 5, paddingRight: 5, color: '#c6c6c6'}}>
                        Password must contain an uppercase and lowercase letter, one or more numbers, and a special character.
                      </Typography>
                    }
                  </GridCell>
                  <GridCell style={{marginTop: 7}}>
                    <Button palette='primary' onClick={() => handleResetPassword()} disabled={processing || !newPassword || !!checkPasswordStrength(newPassword)}>
                      Reset Password
                    </Button>
                  </GridCell>
                </GridContainer>
                {(!newPassword || newPassword.length < 6) &&
                  <Typography size='subText' style={{paddingLeft: 5}}>
                    minimum password length 6 characters
                  </Typography>
                }
              </form>
            }
          </div>
        </GridCell>
        <GridCell weight={1} />
      </GridContainer>
      <div style={{flex: 2}}/>

      {/*popouts and popups*/}
      <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
        <div style={{padding: 10}}>
          <Typography style={{fontSize: '1.5em'}}>
            {dialogData?.title}
          </Typography>
        </div>
        {dialogData?.type === 'message' &&
          <div style={{padding: 10}}>
            <Typography>
              {dialogData?.message}
            </Typography>
          </div>
        }
        <GridContainer>
          <GridCell weight={1}/>
          <GridCell style={{padding: 10}}>
            <Button
              disabled={processing}
              palette='primary'
              onClick={() => {
                setDialogOpen(false);
                setDialogData(undefined);
              }}
            >
              {dialogData?.type === 'message' ? 'OK' : 'cancel'}
            </Button>
          </GridCell>
        </GridContainer>
      </Dialog>
    </div>
  );
}

PasswordReset.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
}

export default connect(undefined, {setApiToken, setProfile, setUserReference, setProfileReference})(PasswordReset);
