import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {YoutubeEmbed, VimeoEmbed} from '../../../../components/video';
import VideoForm from '../../../../components/forms/video';
import Hidden from '../../../../components/hidden';

import {paletteData, Button, Typography, Dialog, Input, ProgressBar} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';
import apiRequest from '../../../../tools/apiRequest';

function EditOrganisationVideoGallerySubPanel({
    focusedOrganisation,
    handleChangeFocusedOrganisation,
    processing,
    setProcessing,
    replaceOrganisation,
    setOrganisationReference,
  }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [searchGallery, setSearchGallery] = useState('');

  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Video Gallery
        </Typography>
      </div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            name="search"
            autoComplete='off'
            palette='secondary'
            label="Search Video Gallery"
            type="text"
            value={searchGallery || ''}
            onChange={(value) => setSearchGallery(value)}
          />
        </GridCell>
        <GridCell style={{marginTop: 23}}>
          <Button
            palette="primary" size='small'
            onClick={() => {
              setDialogOpen(true);
              setDialogData({
                type: 'addVideo',
                title: 'Add a video to your library',
                preview: undefined,
                value: {
                  platform: 'youtube',
                  link: '',
                  title: '',
                  tags: '',
                  description: '',
                }
              });
            }}
          >
            Add video
          </Button>
        </GridCell>
      </GridContainer>
      {(!focusedOrganisation?.videoLibrary || focusedOrganisation?.videoLibrary.length === 0) &&
        <div style={{margin: 5}}>
          <Typography>
            Library empty
          </Typography>
        </div>
      }
      {focusedOrganisation?.videoLibrary?.length > 0 && focusedOrganisation?.videoLibrary.filter((v, vIndex) => {
        return v.name?.toLowerCase().includes(searchGallery.toLowerCase()) || v.tags?.toLowerCase().includes(searchGallery.toLowerCase());
      }).map((v, vIndex) => (
        <div key={vIndex}>
          <Hidden breakpoint='hiddengreaterthan1024'>
            <div style={{padding: 10}}>
              {v.platform === 'youtube' &&
                <YoutubeEmbed youtubeId={v.youtubeId} />
              }
              {v.platform === 'vimeo' &&
                <VimeoEmbed vimeoId={v.vimeoId}/>
              }
              {v.credit && !v.citation &&
                <Typography size='subText' style={{textAlign: 'right'}}>Created By {v.credit}</Typography>
              }
              {v.credit && v.citation &&
                <a href={v.citation} target='_blank' rel='noopener noreferrer'><Typography size='subText' style={{textAlign: 'right'}}>Created By {v.credit}</Typography></a>
              }
            </div>
          </Hidden>
          <GridContainer>
            <Hidden breakpoint='hiddenlessthan1024'>
              <GridCell weight={1} style={{padding: 10}}>
                {v.platform === 'youtube' &&
                  <YoutubeEmbed youtubeId={v.youtubeId} />
                }
                {v.platform === 'vimeo' &&
                  <VimeoEmbed vimeoId={v.vimeoId}/>
                }
                {v.credit && !v.citation &&
                  <Typography size='subText' style={{textAlign: 'right'}}>Created By {v.credit}</Typography>
                }
                {v.credit && v.citation &&
                  <a href={v.citation} target='_blank' rel='noopener noreferrer'><Typography size='subText' style={{textAlign: 'right'}}>Created By {v.credit}</Typography></a>
                }
              </GridCell>
            </Hidden>
            <GridCell weight={2} style={{padding: 10, display: 'flex', flexDirection: 'column'}}>
              <Typography size='title'>
                {v.title}
              </Typography>
              <Typography style={{color: '#c6c6c6'}}>
                {v.tags}
              </Typography>
              <br/>
              <Typography style={{whiteSpace: 'pre-wrap'}}>
                {v.description}
              </Typography>
              <div style={{flex: 1}}/>
              <GridContainer style={{marginTop: 10}}>
                <GridCell weight={1}/>
                <GridCell>
                  <Button
                    palette='primary'
                    onClick={() => {
                      if(v.platform === 'youtube') {
                        setDialogData({
                          type: 'editVideo',
                          title: 'Edit a video in your library',
                          index: vIndex,
                          value: {
                            ...v,
                            link: `https://www.youtube.com/watch?v=${v.youtubeId}`,
                          }
                        });
                      } else if(v.platform === 'vimeo') {
                        setDialogData({
                          type: 'editVideo',
                          title: 'Edit a video in your library',
                          index: vIndex,
                          value: {
                            ...v,
                            link: `https://vimeo.com/${v.vimeoId}`,
                          }
                        });
                      }
                      setDialogOpen(true);
                    }}
                  >
                    Edit Video
                  </Button>
                </GridCell>
              </GridContainer>
            </GridCell>
          </GridContainer>
        </div>
      ))}

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {(dialogData?.type === 'addVideo' || dialogData?.type === 'editVideo') &&
            <div style={{padding: 10}}>
              <VideoForm
                video={dialogData?.value || {}}
                handleChange={(name, value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      [name]: value,
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              />
            </div>
          }

          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'addVideo' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.link || !dialogData?.value?.title || (dialogData?.value?.platform === 'facebook' && (!dialogData?.value?.credit || !dialogData?.value?.citation))}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const item = {
                      platform: dialogData?.value?.platform,
                      link: dialogData?.value?.link,
                      title: dialogData?.value?.title,
                      credit:  dialogData?.value?.credit,
                      citation: dialogData?.value?.citation,
                      tags: dialogData?.value?.tags,
                      description: dialogData?.value?.description,
                    };
                    if(item.platform === 'youtube') {
                      item.youtubeId = (dialogData?.value?.link || '').replace('https://www.youtube.com/watch?v=', '');
                    }
                    if(item.platform === 'vimeo') {
                      item.vimeoId = (dialogData?.value?.link || '').replace('https://vimeo.com/', '');
                    }
                    const videoLibraryProcessed = focusedOrganisation?.videoLibrary || [];
                    videoLibraryProcessed.push(item);
                    const updateOrganisation = {videoLibrary: videoLibraryProcessed};
                    apiRequest({type: 'patch', action: `organisations/update/${focusedOrganisation._id}`, data: {updateOrganisation}})
                    .then((result) => {
                      replaceOrganisation({organisation: {...focusedOrganisation, ...updateOrganisation}});
                      setOrganisationReference({...focusedOrganisation, ...updateOrganisation});
                      setDialogData(undefined);
                      setDialogOpen(false);
                      setProcessing(false);
                    }).catch((error) => {

                    });
                  }}
                >
                  Add Video
                </Button>
                {processing && <ProgressBar palette='secondary' />}
              </GridCell>
            }
            {dialogData?.type === 'editVideo' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const videoLibraryProcessed = focusedOrganisation?.videoLibrary || [];
                    videoLibraryProcessed.splice(dialogData?.index, 1);
                    const updateOrganisation = {videoLibrary: videoLibraryProcessed};
                    apiRequest({type: 'patch', action: `organisations/update/${focusedOrganisation._id}`, data: {updateOrganisation}})
                    .then((result) => {
                      replaceOrganisation({organisation: {...focusedOrganisation, ...updateOrganisation}});
                      setOrganisationReference({...focusedOrganisation, ...updateOrganisation});
                      setDialogData(undefined);
                      setDialogOpen(false);
                      setProcessing(false);
                    }).catch((error) => {

                    });
                  }}
                >
                  Delete Video
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'editVideo' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const item = {
                      platform: dialogData?.value?.platform,
                      link: dialogData?.value?.link,
                      title: dialogData?.value?.title,
                      credit:  dialogData?.value?.credit,
                      citation: dialogData?.value?.citation,
                      tags: dialogData?.value?.tags,
                      description: dialogData?.value?.description,
                    };
                    if(item.platform === 'youtube') {
                      item.youtubeId = (dialogData?.value?.link || '').replace('https://www.youtube.com/watch?v=', '');
                    }
                    if(item.platform === 'vimeo') {
                      item.vimeoId = (dialogData?.value?.link || '').replace('https://vimeo.com/', '');
                    }
                    const videoLibraryProcessed = focusedOrganisation?.videoLibrary || [];
                    videoLibraryProcessed.splice(dialogData?.index, 1, item);
                    const updateOrganisation = {videoLibrary: videoLibraryProcessed};
                    apiRequest({type: 'patch', action: `organisations/update/${focusedOrganisation._id}`, data: {updateOrganisation}})
                    .then((result) => {
                      replaceOrganisation({organisation: {...focusedOrganisation, ...updateOrganisation}});
                      setOrganisationReference({...focusedOrganisation, ...updateOrganisation});
                      setDialogData(undefined);
                      setDialogOpen(false);
                      setProcessing(false);
                    }).catch((error) => {

                    });
                  }}
                >
                  Edit Video
                </Button>
                {processing && <ProgressBar palette='secondary' />}
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
};

EditOrganisationVideoGallerySubPanel.propTypes = {
  focusedOrganisation: PropTypes.shape({}),
  handleChangeFocusedOrganisation: PropTypes.func,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
};

export default EditOrganisationVideoGallerySubPanel;
