import React from 'react';
import PropTypes from 'prop-types';

import {Fab} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function EditMenuSubPanel({
  processing,
  setTab,
  handleUpdateOrganisation,
}) {
  return (
    <div>
      <GridContainer>
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => setTab('layout')}
          >
            <span className="material-icons md-24">web</span>
          </Fab>
        </GridCell>
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => setTab('listing')}
          >
            <span className="material-icons md-24">list</span>
          </Fab>
        </GridCell>
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => setTab('information')}
          >
            <span className="material-icons md-24">info</span>
          </Fab>
        </GridCell>
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => setTab('location')}
          >
            <span className="material-icons md-24">location_on</span>
          </Fab>
        </GridCell>
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => setTab('image')}
          >
            <span className="material-icons md-24">image</span>
          </Fab>
        </GridCell>
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => setTab('video')}
          >
          <span className="material-icons md-24">play_circle</span>
          </Fab>
        </GridCell>
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => {
              handleUpdateOrganisation();
            }}
          >
            <span className="material-icons md-24">done</span>
          </Fab>
        </GridCell>
      </GridContainer>
    </div>
  );
};

EditMenuSubPanel.propTypes = {
  me: PropTypes.shape({}),
  focusedOrganisation: PropTypes.shape({}),

  processing: PropTypes.bool,
  tab: PropTypes.string,
  setTab: PropTypes.func,
  handleUpdateOrganisation: PropTypes.func,
};

export default EditMenuSubPanel;
