import React, {useState, useEffect} from 'react';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";

import {Button, Fab, Typography, Dialog, ProgressBar} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';
import Hidden from '../../../components/hidden';
import {setOrganisationReference, setProductReference} from '../../../store/reducers/references/actions.js';
import {setProducts, addProducts, setSearch} from '../../../store/reducers/lists/actions.js';
import apiRequest from '../../../tools/apiRequest';

import SearchProductsSubPanel from './SearchProducts';

function Products({
  search,
  setSearch,
  queryLimit,
  isMobile,
  refreshCredentialsLoaded,

  products,
  setProducts,
  addProducts,
  setProductReference,

  organisationReferences,
  setOrganisationReference,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [redirect, setRedirect] = useState(undefined);

  let history = useHistory();
  const urlQuery = useQuery();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const organisationId = urlQuery.get('organisation');

  const [organisationLoaded, setOrganisationLoaded] = useState(false);
  useEffect(() => {
    if(!organisationLoaded) {
      setOrganisationLoaded(true);
      if(organisationId && !organisationReferences[organisationId]) {
        apiRequest({type: 'get', action: `public_organisations/${organisationId}`})
        .then((result) => {
          setOrganisationReference(result.data.organisation);
        }).catch((error) => {
        });
      }
    }
  }, [
    organisationId,
    organisationLoaded,
    organisationReferences,
    setOrganisationReference,
  ]);

  if(redirect) {
    return <Redirect to={redirect} push={true} />
  }

  return (
    <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - {organisationReferences[organisationId]?.name ? `${organisationReferences[organisationId]?.name} ` : ''}products</title>
        <meta
          name ='description'
          content={
            organisationReferences[organisationId]?.blurb ||
            "Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
          }
        />
        <meta
          name='keywords'
          content={
            organisationReferences[organisationId]?.keywords ||
            'social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
          }
        />
      </Helmet>
      <div style={{
        position: 'absolute',
        top: 0,
        right: 20,
        zIndex: 21,
        margin: 10,
      }}>
        <Fab
          palette='primary'
          disabled={processing}
          onClick={() => {
            history.goBack();
          }}
        >
          <span className="material-icons md-24">arrow_back</span>
        </Fab>
      </div>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20,
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        background: 'rgba(255, 255, 255, 0.85)',
        overflowX: 'hidden',
      }}>
        <GridContainer
          style={{
            background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundBlendMode: 'lighten',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            height: 200,
          }}
        >
          <GridCell
            weight={1}
            center={true}
            centerWeights={{top: 2, bottom: 3}}
          >
            <Typography size='heading' style={{
              color: 'black',
              textAlign: 'center',
            }}>
              Products
            </Typography>
            {organisationId && organisationReferences[organisationId] &&
              <Typography size='subHeading' style={{
                color: 'black',
                textAlign: 'center',
              }}>
                {organisationReferences[organisationId].name}
              </Typography>
            }
          </GridCell>
        </GridContainer>
        {!refreshCredentialsLoaded && <ProgressBar palette='secondary' />}
        <GridContainer>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
          <GridCell weight={10} style={{padding: 10}}>
            {(!organisationReferences[organisationId]?.bank || !organisationReferences[organisationId]?.stripe) &&
              <div style={{padding: 10, background: '#a20000'}}>
                <GridContainer>
                  <GridCell weight={1}>
                    <span className="material-icons md-24" style={{color: 'white'}}>warning</span>
                  </GridCell>
                  <GridCell>
                    <Typography style={{color: 'white'}}>
                      This shop is disabled because the organisation has not registered a bank account and/or stripe Id.
                    </Typography>
                  </GridCell>
                  <GridCell weight={1} style={{textAlign: 'right'}}>
                    <span className="material-icons md-24" style={{color: 'white'}}>warning</span>
                  </GridCell>
                </GridContainer>
              </div>
            }
            {refreshCredentialsLoaded &&
              <SearchProductsSubPanel
                isMobile={isMobile}
                search={search}
                setSearch={setSearch}
                queryLimit={queryLimit}
                processing={processing}
                setProcessing={setProcessing}

                products={products}
                setProducts={setProducts}
                addProducts={addProducts}
                setProductReference={setProductReference}

                setRedirect={setRedirect}
                organisationId={organisationId}
              />
            }
          </GridCell>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
        </GridContainer>
      </div>

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10}}>
            <Typography size='title'>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }

          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
    products: state.lists.products || [],
    productGroups: state.lists.productGroups || [],

    organisationReferences: state.references.organisations || {},
    areaReferences: state.references.areas || {},
    productReferences: state.references.products || {},
    productGroupReferences: state.references.productGroups || {},
  };
};

export default connect(mapStateToProps, {setOrganisationReference, setProductReference, setSearch, setProducts, addProducts})(Products);
