import React, {useState, useRef, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {
  GoogleMap,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';

import {Typography, paletteData} from '../styles';

const enabled = true; // (process.env.NODE_ENV === 'development' ? false : true);

function Map({style, center, zoom, options, markers, linkToOrganisation}) {
  const [selectedMarker, setSelectedMarker] = useState(undefined);
  const [redirect, setRedirect] = useState(false);

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    if(markers?.length > 1) {
      const markerBounds = {
        north: undefined,
        south: undefined,
        east: undefined,
        west: undefined,
      }
      for(let i = 0; i < markers.length; i += 1) {
        if(!markerBounds.north || markers[i].position.lat > markerBounds.north) {
          markerBounds.north = markers[i].position.lat;
        }
        if(!markerBounds.south || markers[i].position.lat < markerBounds.south) {
          markerBounds.south = markers[i].position.lat;
        }
        if(!markerBounds.east || markers[i].position.lng > markerBounds.east) {
          markerBounds.east = markers[i].position.lng;
        }
        if(!markerBounds.west || markers[i].position.lng < markerBounds.west) {
          markerBounds.west = markers[i].position.lng;
        }
       }
       if(markerBounds.north && markerBounds.south && markerBounds.east && markerBounds.west) {
         const bounds = new window.google.maps.LatLngBounds(
           {lat: markerBounds.south, lng: markerBounds.west},
           {lat: markerBounds.north, lng: markerBounds.east}
         );
         map.fitBounds(bounds);
       }
    }
    mapRef.current = map;
  }, [markers]);

  useEffect(() => {
    if(mapRef.current && (!markers?.length || markers?.length === 1)) {
      mapRef.current.panTo(center);
    }
  }, [center, markers]);


  if(redirect) {
    return <Redirect to={redirect} push={true} />
  }

  if(enabled) {
    return (
      <GoogleMap
        mapContainerStyle={style}
        zoom={zoom}
        center={center}
        options={options}
        onLoad={onMapLoad}
      >
        {markers && markers?.length !== 0 && markers.map((marker, mIndex) => (
          <Marker
            key={mIndex}
            style={{cursor: 'pointer'}}
            position={marker.position}
            onClick={() => {
              setSelectedMarker(marker);
            }}
          />
        ))}

        {selectedMarker &&
          <InfoWindow
            position={selectedMarker.position}
            onCloseClick={() => setSelectedMarker(undefined)}
          >
            <div>
              <Typography size='title'>
                {selectedMarker.infoWindow.title}
              </Typography>
              <Typography style={{whiteSpace: 'pre-wrap'}}>
                {selectedMarker.infoWindow.text}
              </Typography>
              <br/>
              <a
                style={{textDecoration: 'none'}}
                href={`http://www.google.com/maps/place/${selectedMarker.position.lat},${selectedMarker.position.lng}`}
                target="_blank"
                rel='noreferrer noopener'
              >
                <Typography style={{color: paletteData.blank.standard.foreground, textDecoration: 'underline'}}>
                  Get Directions
                </Typography>
              </a>

              {linkToOrganisation && selectedMarker.organisationId &&
                <Typography
                  style={{cursor: 'pointer', textDecoration: 'underline'}}
                  onClick={() => {
                    if(linkToOrganisation && selectedMarker.organisationId) {
                      setRedirect(`/organisation/${selectedMarker.organisationId}`);
                    }
                  }}
                >
                  Go to Page
                </Typography>
              }
            </div>
          </InfoWindow>
        }
      </GoogleMap>
    );
  } else {
    return (<div/>);
  }
}

Map.propTypes = {
  linkToOrganisation: PropTypes.bool,
  style: PropTypes.shape({}),
  center: PropTypes.shape({}),
  zoom: PropTypes.number,
}

export default Map;
