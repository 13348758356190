import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Editor, RichUtils, EditorState, CompositeDecorator, Entity, convertFromRaw} from 'draft-js';
import {markdownToDraft} from 'markdown-draft-js';

import {Typography, Input, Button, paletteData, textData} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';

import LinkStyle from './LinkStyle';

function TextAreaRich({editorState, markdown, onChange, onBlur, onFocus, style, palette, title, stripStyles, editorContainerStyle, floatImage}) {
  const [addLink, setAddLink] = useState(undefined);
  const editor = React.useRef(null);

  function findLinkEntities(contentBlock, callback) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          Entity.get(entityKey).getType() === 'LINK'
        )
      },
      callback
    )
  }

  const [decorators] = useState([{strategy: findLinkEntities, component: LinkStyle}]);

  useEffect(() => {
    if(!editorState) {
      const newState = markdown ?
        EditorState.createWithContent(convertFromRaw(markdownToDraft(markdown)), new CompositeDecorator(decorators)) :
        EditorState.createEmpty(new CompositeDecorator(decorators));
      onChange(newState);
    }
  }, [editorState, markdown, onChange, decorators]);

  function focusEditor() {
    if(onFocus){
      onFocus();
    }
    editor.current.focus();
  }

  function handleReturn(e) {
    if(e.shiftKey) {
      onChange(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }

    return handleKeyCommand('split-block', editorState);
  }

  function handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  function promtForLink() {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      setAddLink(url);
    }
  }

  function confirmLink() {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {url: addLink}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Apply entity
    let newState = EditorState.set(editorState, {currentContent: contentStateWithEntity});

    // Apply selection
    newState = RichUtils.toggleLink(newState, newState.getSelection(), entityKey);
    if (newState) {
      onChange(newState);
    }
    setAddLink(undefined);
  }

  function removeLink() {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const newState = RichUtils.toggleLink(editorState, selection, null);
      if (newState) {
        onChange(newState);
      }
    }
  }

  return (
    <div style={{
      ...style
    }}>
      {!stripStyles &&
        <div style={{
          padding: 5,
          background: paletteData[palette || 'blank'].standard.background,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: paletteData[palette || 'blank'].standard.background,
          borderRadius: '5px 5px 0px 0px',
        }}>
          {title &&
            <Typography size='title' style={{marginLeft: 10, fontWeight: 500, color: paletteData[palette || 'blank'].standard.foreground}}>
              {title}
            </Typography>
          }
          <GridContainer>
            <GridCell
              style={{padding: 5, cursor: 'pointer'}}
              onClick={() => {
                const newState = RichUtils.toggleBlockType(editorState, 'header-two');
                onChange(newState);
              }}
            >
              <span className="material-icons md-24" style={{color: paletteData[palette || 'blank'].standard.foreground}}>title</span>
            </GridCell>
            <GridCell
              style={{padding: 5, cursor: 'pointer'}}
              onClick={() => {
                const newState = RichUtils.toggleBlockType(editorState, 'header-three');
                onChange(newState);
              }}
            >
              <span className="material-icons md-24" style={{color: paletteData[palette || 'blank'].standard.foreground}}>format_size</span>
            </GridCell>
            <GridCell
              style={{padding: 5, cursor: 'pointer'}}
              onClick={() => {
                const newState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
                onChange(newState);
              }}
            >
              <span className="material-icons md-24" style={{color: paletteData[palette || 'blank'].standard.foreground}}>format_bold</span>
            </GridCell>
            <GridCell
              style={{padding: 5, cursor: 'pointer'}}
              onClick={() => {
                const newState = RichUtils.toggleInlineStyle(editorState, 'ITALIC');
                onChange(newState);
              }}
            >
              <span className="material-icons md-24" style={{color: paletteData[palette || 'blank'].standard.foreground}}>format_italic</span>
            </GridCell>
            <GridCell
              style={{padding: 5, cursor: 'pointer'}}
              onClick={() => {
                const newState = RichUtils.toggleBlockType(editorState, 'unordered-list-item');
                onChange(newState);
              }}
            >
              <span className="material-icons md-24" style={{color: paletteData[palette || 'blank'].standard.foreground}}>format_list_bulleted</span>
            </GridCell>
            <GridCell
              style={{padding: 5, cursor: 'pointer'}}
              onClick={() => {
                const newState = RichUtils.toggleBlockType(editorState, 'ordered-list-item');
                onChange(newState);
              }}
            >
              <span className="material-icons md-24" style={{color: paletteData[palette || 'blank'].standard.foreground}}>format_list_numbered</span>
            </GridCell>
            <GridCell
              style={{padding: 5, cursor: 'pointer'}}
              onClick={() => promtForLink()}
            >
              <span className="material-icons md-24" style={{color: paletteData[palette || 'blank'].standard.foreground}}>add_link</span>
            </GridCell>
            <GridCell
              style={{padding: 5, cursor: 'pointer'}}
              onClick={() => removeLink()}
            >
              <span className="material-icons md-24" style={{color: paletteData[palette || 'blank'].standard.foreground}}>link_off</span>
            </GridCell>
          </GridContainer>
          {addLink !== undefined &&
            <GridContainer>
              <GridCell weight={1}>
                <Input
                  palette={palette || 'blank'}
                  label="Link"
                  type='text'
                  value={addLink}
                  onChange={(value) => setAddLink(value)}
                />
              </GridCell>
              <GridCell style={{marginTop: 16}}>
                <Button palette={palette || 'blank'} onClick={() => setAddLink(undefined)}>Cancel</Button>
              </GridCell>
              <GridCell style={{marginTop: 16}}>
                <Button palette={palette || 'blank'} onClick={() => confirmLink()} disabled={!addLink}>Confirm</Button>
              </GridCell>
            </GridContainer>
          }
        </div>
      }

      <div
        onClick={() => {
          focusEditor();
          if(onFocus) {
            onFocus();
          }
        }}
        onBlur={() => {
          if(onBlur) {
            onBlur()
          }
        }}
        style={{
          position: 'relative',
          padding: 5,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: '0px 0px 5px 5px',
          borderColor: paletteData[palette || 'blank'].standard.background,
          whiteSpace: 'pre-wrap',
          fontFamily: textData.text.fontFamily,
          ...editorContainerStyle,
        }}
      >
        {floatImage}
        <div>
          {editorState &&
            <Typography style={{whiteSpace: 'pre-wrap'}}>
              <Editor
                ref={editor}
                decorators={decorators}
                editorState={editorState}
                onChange={editorState => {
                  onChange(editorState);
                }}
                onFocus={() => {
                  if(onFocus) {
                    onFocus();
                  }
                }}
                onBlur={() => {
                  if(onBlur) {
                    onBlur();
                  }
                }}
                handleReturn={handleReturn}
                handleKeyCommand={handleKeyCommand}
              />
            </Typography>

          }
          {!editorState &&
            <div>
              <br/>
            </div>
          }
        </div>
        <div style={{clear: 'both'}}/>
      </div>
    </div>
  );
}

TextAreaRich.propTypes = {
  stripStyles: PropTypes.bool,
  editorState: PropTypes.shape({
    getSelection: PropTypes.func,
    getCurrentContent: PropTypes.func,
  }),
  markdown: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  style: PropTypes.shape({}),
  editorContainerStyle: PropTypes.shape({}),
  palette: PropTypes.string,
  title: PropTypes.string,
};

export default TextAreaRich;
