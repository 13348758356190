import React from 'react';
import PropTypes from 'prop-types';

import EventLocationForm from '../../../../components/forms/eventLocation';
import ContactAddressForm from '../../../../components/forms/contactAddress';
import PositionForm from '../../../../components/forms/position';

import {paletteData, Button, Typography, DatePicker, Select} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function EditEventLocationsSubPanel({
    me,
    processing,
    setProcessing,
    handleVerifyEmail,
    dialogData,
    setDialogData,
    dialogOpen,
    setDialogOpen,

    focusedEvent,
    handleChangeFocusedEvent,

    areas,
    areaReferences,
    setAreaReference,
  }) {

  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Locations
            </Typography>
          </GridCell>
          <GridCell>
            <Button
              palette="secondary"
              size='small'
              onClick={() => {
                const locations = focusedEvent.locations;
                locations.push({
                  area: '',
                  address: {},
                  times: [],
                });
                handleChangeFocusedEvent('locations', locations);
                setDialogData({
                  type: 'message',
                  title: 'Location Created',
                });
                setDialogOpen(true);
              }}
            >
              Add location
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {focusedEvent?.locations?.length > 0 && focusedEvent.locations.map((l, lIndex) => (
        <div key={lIndex}>
          <div style={{marginTop: 10, padding: 10, background: paletteData.primary.standard.background}}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Location {lIndex + 1}
            </Typography>
          </div>
          <div style={{marginTop: 10, padding: 10, background: '#c6c6c6'}}>
            <Typography>
              Address
            </Typography>
          </div>
          <div style={{background: 'white'}}>
            <EventLocationForm
              location={l}
              areaReferences={areaReferences}
              handleChange={(name, value) => {
                const locations = focusedEvent.locations;
                locations[lIndex][name] = value;
                handleChangeFocusedEvent('locations', locations);
              }}
              dialogData={dialogData}
              setDialogData={setDialogData}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
            />
            <ContactAddressForm
              address={l.address}
              handleChange={(name, value) => {
                const locations = focusedEvent.locations;
                if(!locations[lIndex].address) {
                  locations[lIndex].address = {};
                }
                locations[lIndex].address[name] = value;
                handleChangeFocusedEvent('locations', locations);
              }}
            />
            <PositionForm
              position={l.position}
              address={`${l.address?.extra ? `${l.address?.extra} ` : ''}${l.address?.number ? `${l.address?.number} ` : ''}${l.address?.street ? `${l.address?.street} ` : ''}${l.address?.suburb ? `${l.address?.suburb} ` : ''}${l.address?.city ? `${l.address?.city} ` : ''}${l.address?.country ? `${l.address?.country} ` : ''}`}
              handleChange={(name, value) => {
                if(name === 'position') {
                  const locations = focusedEvent.locations;
                  locations[lIndex].position = value;
                  handleChangeFocusedEvent('locations', locations);
                } else {
                  const locations = focusedEvent.locations;
                  if(!locations[lIndex].position) {
                    locations[lIndex].position = {};
                  }
                  locations[lIndex].position[name] = value;
                  handleChangeFocusedEvent('locations', locations);
                }
              }}
            />
            <Typography style={{marginLeft: 5}} size='subText'>
              Having your position entered (latitude and longitude) will place a pin on the maps at your event location.
            </Typography>
            <br/>
            <div style={{marginTop: 10, padding: 10, background: '#c6c6c6'}}>
              <GridContainer>
                <GridCell weight={1} center={true}>
                  <Typography>
                    Times
                  </Typography>
                </GridCell>
                <GridCell>
                  <Button
                    size='small'
                    palette='secondary'
                    onClick={() => {
                      const locationsProcessed = focusedEvent.locations || [];
                      const timesProcessed = l.times || [];
                      timesProcessed.push({date: undefined, start: '09:00:00', end: '17:00:00'});
                      locationsProcessed[lIndex].times = timesProcessed;
                      handleChangeFocusedEvent('locations', locationsProcessed);
                    }}
                  >
                    Add Time
                  </Button>
                </GridCell>
              </GridContainer>
            </div>
            {(!l.times || l.times?.length === 0) &&
              <div style={{padding: 5}}>
                <Typography>
                  Event has no times
                </Typography>
              </div>
            }
            {l.times?.length > 0 && l.times.map((t, tIndex) => (
              <GridContainer key={tIndex}>
                <GridCell weight={1}>
                  <DatePicker
                    label='date'
                    value={t.date || ''}
                    onChange={(value) => {
                      const locationsProcessed = focusedEvent.locations || [];
                      const timesProcessed = l.times || [];
                      const timeProcessed = t;
                      timeProcessed.date = value;
                      timesProcessed.splice(tIndex, 1, timeProcessed);
                      locationsProcessed[lIndex].times = timesProcessed;
                      handleChangeFocusedEvent('locations', locationsProcessed);
                    }}
                  />
                </GridCell>
                <GridCell>
                  <Select
                    label='Start Time'
                    value={t.start}
                    onChange={(value) => {
                      const locationsProcessed = focusedEvent.locations || [];
                      const timesProcessed = l.times || [];
                      const timeProcessed = t;
                      timeProcessed.start = value;
                      timesProcessed.splice(tIndex, 1, timeProcessed);
                      locationsProcessed[lIndex].times = timesProcessed;
                      handleChangeFocusedEvent('locations', locationsProcessed);
                    }}
                  >
                    <option value='00:00:00'>Mid night</option>
                    <option value='00:30:00'>12:30 am</option>
                    <option value='01:00:00'>1:00 am</option>
                    <option value='01:30:00'>1:30 am</option>
                    <option value='02:00:00'>2:00 am</option>
                    <option value='02:30:00'>2:30 am</option>
                    <option value='03:00:00'>3:00 am</option>
                    <option value='03:30:00'>3:30 am</option>
                    <option value='04:00:00'>4:00 am</option>
                    <option value='04:30:00'>4:30 am</option>
                    <option value='05:00:00'>5:00 am</option>
                    <option value='05:30:00'>5:30 am</option>
                    <option value='06:00:00'>6:00 am</option>
                    <option value='06:30:00'>6:30 am</option>
                    <option value='07:00:00'>7:00 am</option>
                    <option value='07:30:00'>7:30 am</option>
                    <option value='08:00:00'>8:00 am</option>
                    <option value='08:30:00'>8:30 am</option>
                    <option value='09:00:00'>9:00 am</option>
                    <option value='09:30:00'>9:30 am</option>
                    <option value='10:00:00'>10:00 am</option>
                    <option value='10:30:00'>10:30 am</option>
                    <option value='11:00:00'>11:00 am</option>
                    <option value='11:30:00'>11:30 am</option>
                    <option value='12:00:00'>Mid day</option>
                    <option value='12:30:00'>12:30 pm</option>
                    <option value='13:00:00'>1:00 pm</option>
                    <option value='13:30:00'>1:30 pm</option>
                    <option value='14:00:00'>2:00 pm</option>
                    <option value='14:30:00'>2:30 pm</option>
                    <option value='15:00:00'>3:00 pm</option>
                    <option value='15:30:00'>3:30 pm</option>
                    <option value='16:00:00'>4:00 pm</option>
                    <option value='16:30:00'>4:30 pm</option>
                    <option value='17:00:00'>5:00 pm</option>
                    <option value='17:30:00'>5:30 pm</option>
                    <option value='18:00:00'>6:00 pm</option>
                    <option value='18:30:00'>6:30 pm</option>
                    <option value='19:00:00'>7:00 pm</option>
                    <option value='19:30:00'>7:30 pm</option>
                    <option value='20:00:00'>8:00 pm</option>
                    <option value='20:30:00'>8:30 pm</option>
                    <option value='21:00:00'>9:00 pm</option>
                    <option value='21:30:00'>9:30 pm</option>
                    <option value='22:00:00'>10:00 pm</option>
                    <option value='22:30:00'>10:30 pm</option>
                    <option value='23:00:00'>11:00 pm</option>
                    <option value='23:30:00'>11:30 pm</option>
                  </Select>
                </GridCell>
                <GridCell>
                  <Select
                    label='End Time'
                    value={t.end}
                    onChange={(value) => {
                      const locationsProcessed = focusedEvent.locations || [];
                      const timesProcessed = l.times || [];
                      const timeProcessed = t;
                      timeProcessed.end = value;
                      timesProcessed.splice(tIndex, 1, timeProcessed);
                      locationsProcessed[lIndex].times = timesProcessed;
                      handleChangeFocusedEvent('locations', locationsProcessed);
                    }}
                  >
                  <option value='00:00:00'>Mid night</option>
                  <option value='00:30:00'>12:30 am</option>
                  <option value='01:00:00'>1:00 am</option>
                  <option value='01:30:00'>1:30 am</option>
                  <option value='02:00:00'>2:00 am</option>
                  <option value='02:30:00'>2:30 am</option>
                  <option value='03:00:00'>3:00 am</option>
                  <option value='03:30:00'>3:30 am</option>
                  <option value='04:00:00'>4:00 am</option>
                  <option value='04:30:00'>4:30 am</option>
                  <option value='05:00:00'>5:00 am</option>
                  <option value='05:30:00'>5:30 am</option>
                  <option value='06:00:00'>6:00 am</option>
                  <option value='06:30:00'>6:30 am</option>
                  <option value='07:00:00'>7:00 am</option>
                  <option value='07:30:00'>7:30 am</option>
                  <option value='08:00:00'>8:00 am</option>
                  <option value='08:30:00'>8:30 am</option>
                  <option value='09:00:00'>9:00 am</option>
                  <option value='09:30:00'>9:30 am</option>
                  <option value='10:00:00'>10:00 am</option>
                  <option value='10:30:00'>10:30 am</option>
                  <option value='11:00:00'>11:00 am</option>
                  <option value='11:30:00'>11:30 am</option>
                  <option value='12:00:00'>Mid day</option>
                  <option value='12:30:00'>12:30 pm</option>
                  <option value='13:00:00'>1:00 pm</option>
                  <option value='13:30:00'>1:30 pm</option>
                  <option value='14:00:00'>2:00 pm</option>
                  <option value='14:30:00'>2:30 pm</option>
                  <option value='15:00:00'>3:00 pm</option>
                  <option value='15:30:00'>3:30 pm</option>
                  <option value='16:00:00'>4:00 pm</option>
                  <option value='16:30:00'>4:30 pm</option>
                  <option value='17:00:00'>5:00 pm</option>
                  <option value='17:30:00'>5:30 pm</option>
                  <option value='18:00:00'>6:00 pm</option>
                  <option value='18:30:00'>6:30 pm</option>
                  <option value='19:00:00'>7:00 pm</option>
                  <option value='19:30:00'>7:30 pm</option>
                  <option value='20:00:00'>8:00 pm</option>
                  <option value='20:30:00'>8:30 pm</option>
                  <option value='21:00:00'>9:00 pm</option>
                  <option value='21:30:00'>9:30 pm</option>
                  <option value='22:00:00'>10:00 pm</option>
                  <option value='22:30:00'>10:30 pm</option>
                  <option value='23:00:00'>11:00 pm</option>
                  <option value='23:30:00'>11:30 pm</option>
                  </Select>
                </GridCell>
                <GridCell center={true}>
                  <Button size='small' onClick={() => {
                    const locationsProcessed = focusedEvent.locations || [];
                    const timesProcessed = l.times || [];
                    timesProcessed.splice(tIndex, 1);
                    locationsProcessed[lIndex].times = timesProcessed;
                    handleChangeFocusedEvent('locations', locationsProcessed);
                  }}>
                    Remove
                  </Button>
                </GridCell>
              </GridContainer>
            ))}
            <br/>
            <GridContainer>
              <GridCell weight={1}/>
              <GridCell>
                <Button
                  palette='primary'
                onClick={() => {
                  setDialogOpen(true);
                  setDialogData({
                    type: 'messageWithCallback',
                    title: 'Remove Location',
                    message: 'Do you wish to remove this location?',
                    data: {
                      callback: () => {
                        const locationsProcessed = focusedEvent.locations || [];
                        locationsProcessed.splice(lIndex, 1);
                        handleChangeFocusedEvent('locations', locationsProcessed);
                      }
                    },
                  });
                }}
              >
                Remove Location
              </Button>
            </GridCell>
          </GridContainer>
        </div>
        </div>
      ))}
    </div>
  );
};

EditEventLocationsSubPanel.propTypes = {
  me: PropTypes.shape({}),
  focusedEvent: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedEvent: PropTypes.func,
  handleVerifyEmail: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleUploadImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditEventLocationsSubPanel;
