import React, {useState} from 'react';
import {Helmet} from "react-helmet";

import {Typography, MenuSide, Fab, LinkButton, ProgressBar} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';
import Hidden from '../../../components/hidden';
import ConsoleMenuPanel from './consoleMenu';

import MyProfilePanel from './myProfile';
import OrdersCurrentPanel from './ordersCurrent';
import OrdersHistoryPanel from './ordersHistory';

function Console({
  me,
  setMe,
  resetCredentials,
  refreshCredentialsLoaded,
}) {
  const [consoleNav, setConsoleNav] = useState('myProfile');
  const [consoleNavOpen, setConsoleNavOpen] = useState(false);
  const [processing, setProcessing] = useState(undefined);

  if(!me._id) {
    return (
      <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rerengatahi - console</title>
          <meta
            name ='description'
            content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
          />
          <meta
            name='keywords'
            content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
          />
        </Helmet>
        <GridContainer
          style={{
            background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundBlendMode: 'lighten',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            height: 200,
          }}
        >
          <GridCell
            weight={1}
            center={true}
            centerWeights={{top: 2, bottom: 3}}
          >
            <Typography size='heading' style={{
              color: 'white',
              textShadow: '2px 2px black',
              textAlign: 'center',
            }}>
              My Console
            </Typography>
          </GridCell>
        </GridContainer>
        {!refreshCredentialsLoaded &&
          <GridContainer style={{flex: 1}}>
            <GridCell weight={1}/>
            <GridCell  centerWeights={{top: 1, bottom: 2}} center style={{textAlign: 'center'}}>
              <Typography size='title'>
                Credentials Loading
              </Typography>
              <div>
                <br/><br/>
                <ProgressBar palette='secondary'/>
              </div>
            </GridCell>
            <GridCell weight={1}/>
          </GridContainer>
        }
        {refreshCredentialsLoaded &&
          <GridContainer style={{flex: 1}}>
            <GridCell weight={1}/>
            <GridCell  centerWeights={{top: 1, bottom: 2}} center style={{textAlign: 'center'}}>
              <Typography size='title'>
                Please Log in to use the console
              </Typography>
              <div>
                <br/><br/>
                <LinkButton palette='primary' to="/login">Go to Login</LinkButton>
              </div>
            </GridCell>
            <GridCell weight={1}/>
          </GridContainer>
        }
      </div>
    )
  }

  return (
    <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
      <GridContainer
        style={{
          background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundBlendMode: 'lighten',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          height: 200,
        }}
      >
        <Hidden breakpoint='hiddengreaterthan800' forceShowOnPhone={true}>
          <GridCell center={true} centerWeights={{top: 2, bottom: 3}} style={{marginLeft: 10}}>
            <Fab
              palette='secondary'
              onClick={() => setConsoleNavOpen(true)}
            >
              <span className="material-icons md-24">menu</span>
            </Fab>
          </GridCell>
        </Hidden>
        <GridCell
          weight={1}
          center={true}
          centerWeights={{top: 2, bottom: 3}}
        >
          <Typography size='heading' style={{
            color: 'white',
            textShadow: '2px 2px black',
            textAlign: 'center',
          }}>
            My Console
          </Typography>
        </GridCell>
      </GridContainer>

      <GridContainer style={{flex: 1}}>
        <Hidden breakpoint='hiddenlessthan800' forceHideOnPhone={true}>
          <GridCell
            style={{
              background: '#c6c6c6',
              padding: 10,
            }}
          >
            <ConsoleMenuPanel selected={consoleNav} handleChange={(value) => setConsoleNav(value)} me={me}/>
          </GridCell>
        </Hidden>
        <GridCell
          weight={4}
          style={{padding: 10, display: 'flex', flexDirection: 'column'}}
        >
          {consoleNav === 'myProfile' &&
            <div>
              <MyProfilePanel processing={processing} setProcessing={setProcessing} me={me} setMe={setMe} resetCredentials={resetCredentials} />
            </div>
          }
          {consoleNav === 'ordersCurrent' &&
            <div>
              <OrdersCurrentPanel processing={processing} setProcessing={setProcessing} me={me}/>
            </div>
          }
          {consoleNav === 'ordersHistory' &&
            <div>
              <OrdersHistoryPanel processing={processing} setProcessing={setProcessing} me={me}/>
            </div>
          }
        </GridCell>
      </GridContainer>

      {/*popouts and popups*/}
      <MenuSide style={{background: '#c6c6c6'}} open={consoleNavOpen} handleClose={() => setConsoleNavOpen(false)}>
        <div style={{margin: 5}}>
          <ConsoleMenuPanel selected={consoleNav} handleChange={(value) => {setConsoleNav(value); setConsoleNavOpen(false)}} me={me}/>
        </div>
      </MenuSide>
    </div>
  );
}

export default Console;
