import React from 'react';
import PropTypes from 'prop-types';

import {Input, Button, Typography} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function OrganisationForm({me, focusedOrganisation, handleChange}) {
  return (
    <div>
      <Input
        name="name"
        palette='secondary'
        label="Name"
        type="text"
        value={focusedOrganisation?.name || ''}
        onChange={(value) => handleChange('name', value)}
      />
      <Input
        name="website"
        palette='secondary'
        label="Website"
        type="text"
        value={focusedOrganisation?.website || ''}
        onChange={(value) => handleChange('website', value)}
      />
      <Input
        name="email"
        palette='secondary'
        label="Primary Email"
        type="text"
        value={focusedOrganisation?.email || ''}
        onChange={(value) => handleChange('email', value)}
      />
      <Input
        name="phone"
        palette='secondary'
        label="Primary Phone"
        type="text"
        value={focusedOrganisation?.phone || ''}
        onChange={(value) => handleChange('phone', value)}
      />
      <GridContainer>
        <GridCell weight={1}>
          <Input
            disabled={(typeof focusedOrganisation?.gst === "boolean")}
            name="gst"
            palette='secondary'
            label="GST Number"
            type="text"
            value={
              typeof focusedOrganisation?.gst === "boolean" ?
              (focusedOrganisation?.gst ? '*** Hidden For Security ****' : 'No GST registered') :
              (focusedOrganisation?.gst)
            }
            onChange={(value) => handleChange('gst', value)}
          />
        </GridCell>
        {(typeof focusedOrganisation?.gst === "boolean") &&
          <GridCell style={{marginTop: 7}}>
            <Button palette='primary' onClick={() =>  handleChange('gst', '')}>
              Update
            </Button>
          </GridCell>
        }
      </GridContainer>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            disabled={(typeof focusedOrganisation?.stripe === "boolean")}
            name="bank"
            palette='secondary'
            label="Bank Account"
            type="text"
            value={
              typeof focusedOrganisation?.bank === "boolean" ?
              (focusedOrganisation?.bank ? '*** Hidden For Security ****' : 'No bank registered') :
              (focusedOrganisation?.bank)
            }
            onChange={(value) => handleChange('bank', value)}
          />
        </GridCell>
        {(typeof focusedOrganisation?.bank === "boolean") &&
          <GridCell style={{marginTop: 7}}>
            <Button palette='primary' onClick={() =>  handleChange('bank', '')}>
              Update
            </Button>
          </GridCell>
        }
      </GridContainer>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            disabled={(typeof focusedOrganisation?.stripe === "boolean") || !me?.roles?.global || !(me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])}
            name="stripe"
            palette='secondary'
            label="Stripe ID"
            type="text"
            value={
              typeof focusedOrganisation?.stripe === "boolean" ?
              (focusedOrganisation?.stripe ? '*** Hidden For Security ****' : 'No stripe registered') :
              (focusedOrganisation?.stripe)
            }
            onChange={(value) => handleChange('stripe', value)}
          />
        </GridCell>
        {(typeof focusedOrganisation?.stripe === "boolean") &&
          <GridCell style={{marginTop: 7}}>
            <Button
              disabled={!me?.roles?.global || !(me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])}
              palette='primary'
              onClick={() =>  handleChange('stripe', '')}
            >
              Update
            </Button>
          </GridCell>
        }
      </GridContainer>
      {(!me?.roles?.global || !(me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) &&
      <Typography size='subText' style={{marginLeft: 5, marginRight: 5, marginBottom: 5, color: 'red'}}>
        To register or update your stripe account with rerengatahi please contact an administrator
      </Typography>
      }
    </div>
  );
}

OrganisationForm.propTypes = {
  me: PropTypes.shape({}),
  focusedOrganisation: PropTypes.shape({}),
  handleChange: PropTypes.func,
  processing: PropTypes.bool,
}

export default OrganisationForm;
