import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import SearchStore from '../../../../components/search/store';
import {paletteData, Button, ProgressBar, Typography, Fab} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';
import Hidden from '../../../../components/hidden';

import apiRequest from '../../../../tools/apiRequest';

function SearchEventsSubPanel({
    me,
    events,
    setEvents,
    addEvents,
    replaceEvent,
    search,
    setSearch,
    queryLimit,
    processing,
    setProcessing,
    setEventReference,
    setRedirect,
    generateMarkers,
    categories,
    isMobile,
    organisationReferences,
    setOrganisationReference,
    dialogData,
    setDialogData,
    dialogOpen,
    setDialogOpen,
    areaReferences,
    setAreaReference,
    categoryReferences,
}) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [expandSearch, setExpandSearch] = useState(false);
  const [initalLoadComplete, setInitalLoadComplete] = useState(false);
  const [moderator, setModerator] = useState(undefined);
  const urlQuery = useQuery();
  const organisationId = urlQuery.get('organisation');
  const categoryId = urlQuery.get('category');
  const areaId = urlQuery.get('area');

  const [organisationLoaded, setOrganisationLoaded] = useState(false);
  const [areaLoaded, setAreaLoaded] = useState(false);

  useEffect(() => {
    if(!organisationLoaded && organisationId && organisationReferences[organisationId]) {
      setOrganisationLoaded(true);
    } else if(!organisationLoaded && organisationId && !organisationReferences[organisationId]) {
      apiRequest({type: 'get', action: `public_organisations/${organisationId}`})
      .then((result) => {
        setOrganisationReference(result.data.organisation);

      }).catch((error) => {

      });
    }

    if(!areaLoaded && areaId && areaReferences[areaId]) {
      setAreaLoaded(true);
    } else if(!areaLoaded && areaId && !areaReferences[areaId]) {
      apiRequest({type: 'get', action: `public_areas/${areaId}`})
      .then((result) => {
        setAreaReference(result.data.area);
      }).catch((error) => {

      });
    }

    if(moderator === undefined && me?._id && areaId && areaReferences[areaId]) {
      if(areaReferences[areaId]?.moderators?.findIndex((m, mIndex) => m === me._id) !== -1) {
        setModerator(true);
      } else {
        setModerator(false);
      }
    }

  }, [
    organisationId,
    organisationLoaded,
    organisationReferences,
    setOrganisationReference,
    moderator,
    me,
    categoryId,
    categoryReferences,
    areaId,
    areaReferences,
    setAreaReference,
    areaLoaded,
  ]);

  const authCheck = (e) => {
    const communityId = categoryId || areaId;
    if(!communityId) return undefined;

    if((e.authorised || []).findIndex((v, vIndex) => v === communityId) !== -1) return 'Authorised';
    if((e.unauthorised || []).findIndex((v, vIndex) => v === communityId) !== -1) return 'Unauthorised';
    return 'Pending Authorisation';
  }

  const handleAuthorisation = (e, value) => {
    setProcessing(true);
    const communityId = categoryId || areaId;
    apiRequest({type: 'patch', action: `events/authorisation/${e._id}/${communityId}/${value}`})
    .then((result) => {
      setProcessing(false);
      setEventReference({...e, ...result.data.newAuthorisation});
      replaceEvent({event: {...e, ...result.data.newAuthorisation}});
    }).catch((error) => {
      setProcessing(false);
    });
  }

  if(moderator === undefined && me?._id && categoryId && categoryReferences[categoryId]) {
    if(categoryReferences[categoryId]?.moderators?.findIndex((m, mIndex) => m === me._id) !== -1) {
      setModerator(true);
    } else {
      setModerator(false);
    }
  }

  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <SearchStore
            databaseArea='public_events'
            processing={processing}
            setProcessing={setProcessing}
            expandSearch={expandSearch}
            setExpandSearch={setExpandSearch}
            setInitalLoadComplete={setInitalLoadComplete}
            isMobile={isMobile}
            filter={
              organisationId ? {createdByOrg: organisationId} :
              categoryId ? {categories: categoryId} :
              areaId ? {'locations.area': areaId} :
              undefined
            }
          />
        </GridCell>
        {organisationId && (organisationReferences[organisationId]?.owner === me?._id || (me?.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker']))) &&
          <GridCell style={{marginTop: 11}}>
            <Fab
              palette='primary'
              size='small'
              onClick={() => {
                setDialogData({
                  type: 'addEvent',
                  title: 'Create Event',
                  message: 'create an event?',
                  data: {
                    callback: (event) => {
                      setRedirect(`/event/${event._id}`);
                    }
                  },
                  value: {
                    state: 'pending',
                    createdAt: new Date(),
                    createdByOrg: organisationId,
                    createdByUser: me._id,
                    name: '',
                    tagline: '',
                    blurb: '',
                    thumbnail: undefined,
                    layout: undefined,
                    categories: [],
                    locations: [],
                    authorised: [],
                    unauthorised: [],
                  }
                });
                setDialogOpen(true);
              }}
            >
              <span className="material-icons md-24">add</span>
            </Fab>
          </GridCell>
        }
      </GridContainer>

      {events?.length > 0 && initalLoadComplete &&
        <div style={{padding: 5}}>
          <Hidden breakpoint='hiddenlessthan1024'>
            <div>
              {events.map((o, oIndex) => (
                <GridContainer
                  key={oIndex}
                  style={{
                    margin: 5,
                    cursor: processing ? 'auto' : 'pointer',
                  }}
                >
                  <GridCell
                    center={true}
                    style={{height: 200}}
                    onClick={() => {
                      if(!processing) {
                        setEventReference(o);
                        setRedirect(`/event/${o._id}`);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${o.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                      alt={o?.thumbnail?.alt}
                      style={{width: 200}}
                    />
                  </GridCell>
                  <GridCell
                    weight={1}
                    center
                    style={{textAlign: 'left', padding: 10, height: 180}}
                    onClick={() => {
                      if(!processing) {
                        setEventReference(o);
                        setRedirect(`/event/${o._id}`);
                      }
                    }}
                  >
                    <Typography size='title'>
                      {o.name || 'Unnamed Event'}
                    </Typography>
                    <Typography style={{color: '#333333'}}>
                      {o.tagline}
                    </Typography>
                    <Typography style={{marginTop: 5}}>
                      {o.blurb?.length > 350 ? `${o.blurb.substring(0, 350)}...` : o.blurb}
                    </Typography>
                  </GridCell>
                  {(moderator || (me?.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker'])))&&
                    <GridCell>
                      <Typography style={{marginBottom: 10}}>
                        {authCheck(o)}
                        {processing && <ProgressBar palette='secondary'/>}
                      </Typography>
                      {authCheck(o) !== 'Authorised' &&
                        <Button
                          disabled={processing}
                          palette='primary'
                          style={{marginBottom: 10}}
                          onClick={() => handleAuthorisation(o, 'accept')}
                        >
                          Approve
                        </Button>
                      }
                      {authCheck(o) !== 'Unauthorised' &&
                        <Button
                          disabled={processing}
                          palette='primary'
                          onClick={() => handleAuthorisation(o, 'reject')}
                        >
                          Reject
                        </Button>
                      }

                    </GridCell>
                  }
                </GridContainer>
              ))}
            </div>
          </Hidden>

          <Hidden breakpoint='hiddengreaterthan1024'>
            <div>
              <GridContainer>
                {events.map((o, oIndex) => (
                  <GridCell
                    key={oIndex}
                    style={{
                      width: isMobile ? '100%' : undefined,
                      maxWidth: isMobile ? undefined : 200,
                      textAlign: 'center',
                      borderStyle: 'solid',
                      margin: 5,
                      borderWidth: 2,
                      borderRadius: 5,
                      borderColor: paletteData.primary.standard.background,
                      display: 'flex',
                      flexDirection: 'column',
                      cursor: processing ? 'auto' : 'pointer',
                    }}
                    onClick={() => {
                      if(!processing) {
                        setEventReference(o);
                        setRedirect(`/event/${o._id}`);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${o.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                      alt={o?.thumbnail?.alt}
                      style={{width: '100%'}}
                    />
                    <div style={{background: paletteData.primary.standard.background, padding: 10, flex: 1}}>
                      <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                        {o.name || 'Unnamed Event'}
                      </Typography>
                    </div>
                    {moderator &&
                      <div>
                        <Typography style={{margin: 5}}>
                          {authCheck(o)}
                          {processing && <ProgressBar palette='secondary'/>}
                        </Typography>
                        {authCheck(o) !== 'Authorised' &&
                          <Button
                            disabled={processing}
                            palette='primary'
                            style={{margin: 5}}
                            onClick={() => handleAuthorisation(o, 'accept')}
                          >
                            Approve
                          </Button>
                        }
                        {authCheck(o) !== 'Unauthorised' &&
                          <Button
                            disabled={processing}
                            palette='primary'
                            style={{margin: 5}}
                            onClick={() => handleAuthorisation(o, 'reject')}
                          >
                            Reject
                          </Button>
                        }
                      </div>
                    }
                  </GridCell>
                ))}
              </GridContainer>
            </div>
          </Hidden>

          {processing && events?.length > 9 &&
            <div>
              <Typography>
                Loading...
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          }
          {!processing && (events.length >= search?.events?.queryDepth * queryLimit) &&
            <div style={{padding: 5}}>
              <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                Load More
              </Button>
            </div>
          }
        </div>
      }
    </div>
  );
};

SearchEventsSubPanel.propTypes = {
  area: PropTypes.shape({}),
  events: PropTypes.arrayOf(PropTypes.shape({})),
  setEvents: PropTypes.func,
  addEvents: PropTypes.func,
  setEventReference: PropTypes.func,

  search: PropTypes.shape({}),
  setSearch: PropTypes.func,
  queryLimit: PropTypes.number,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SearchEventsSubPanel;
