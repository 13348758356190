import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {useParams, useHistory} from 'react-router-dom';

import Hidden from '../../../components/hidden';
import {paletteData, Typography, ProgressBar, Input, Fab, Button, Dialog, Select} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';
import apiRequest from '../../../tools/apiRequest';
import {setSearch, setCategories, setAreas, setMessages, replaceMessage, addMessages, replaceArea, replaceCategory} from '../../../store/reducers/lists/actions.js';
import {setCategoryReference, setAreaReference, setMessageReferences, setMessageReference, setUserReferences} from '../../../store/reducers/references/actions.js';

import Validation from './validation';
import Group from './group';
import CodeOfConduct from './codeOfConduct';

function Messages({
  me,
  categoryReferences,
  setCategoryReferences,
  areaReferences,
  setAreaRefrences,
  refreshCredentialsLoaded,
  search,
  queryLimit,
  messages,
  setMessages,
  replaceMessage,
  addMessages,
  setMessageReferences,
  isMobile,
  replaceArea,
  replaceCategory,
  setAreaReference,
  setCategoryReference,
  userReferences,
  setUserReferences,
}) {
  const {id, type} = useParams(); // categoryId
  let history = useHistory();

  const [communityLoaded, setCommunityLoaded] = useState(false);
  const [communityLoadComplete, setCommunityLoadComplete] = useState(false);
  const [validationCheckLoaded, setValidationCheckLoaded] = useState(false);
  const [isModerator, setIsModerator] = useState(undefined);
  const [messageValidationCount, setMessageValidationCount] = useState(undefined);
  const [searchGroups, setSearchGroups] = useState('');
  const [focusedGroup, setFocusedGroup] = useState(undefined);
  const [showCodeOfConduct, setShowCodeOfConduct] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if(!communityLoaded && type === 'category' && categoryReferences[id]) {
      setCommunityLoaded(true);
      setCommunityLoadComplete(true);
    } else if(!communityLoaded && type === 'area' && areaReferences[id]) {
      setCommunityLoaded(true);
      setCommunityLoadComplete(true);
    } else if(!communityLoaded && type === 'category') {
      setCommunityLoaded(true);
      apiRequest({type: 'get', action: `public_categories/${id}`})
      .then((result) => {
        setCategoryReference(result.data.category);
        setCommunityLoadComplete(true);
      }).catch((error) => {
        setCommunityLoadComplete(true);
      });
    } else if(!communityLoaded && type === 'area') {
      setCommunityLoaded(true);
      apiRequest({type: 'get', action: `public_areas/${id}`})
      .then((result) => {
        setAreaReference(result.data.area);
        setCommunityLoadComplete(true);
      }).catch((error) => {
        setCommunityLoadComplete(true);
      });
    }

    if(refreshCredentialsLoaded && categoryReferences[id] && me._id && communityLoadComplete && type === 'category' && isModerator === undefined) {
      if(categoryReferences[id] && categoryReferences[id]?.moderators?.findIndex((m, mIndex) => m === me._id) !== -1) {
        setIsModerator(true);
      } else if(me?.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker'])) {
        setIsModerator(true);
      } else {
        setIsModerator(false);
      }
    } else if(refreshCredentialsLoaded && areaReferences[id] && me._id && communityLoadComplete && type === 'area' && isModerator === undefined) {
      if(areaReferences[id] && areaReferences[id]?.moderators?.findIndex((m, mIndex) => m === me._id) !== -1) {
        setIsModerator(true);
      } else if(me?.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker'])) {
        setIsModerator(true);
      } else {
        setIsModerator(false);
      }
    }
    if(refreshCredentialsLoaded && isModerator && !validationCheckLoaded) {
      setValidationCheckLoaded(true);
      apiRequest({
        type: 'get',
        action: `messages/validationCount/${id}`,
        data: {}
      })
      .then((result) => {
        setMessageValidationCount(result.data.validationCount);
      }).catch((error) => {
        setMessageValidationCount(0);
      });
    }
  }, [
    setAreaReference,
    setCategoryReference,
    areaReferences,
    categoryReferences,
    communityLoaded,
    id,
    type,
    communityLoadComplete,
    isModerator,
    me,
    refreshCredentialsLoaded,
    validationCheckLoaded,
  ]);

  return (
    <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - messages</title>
        <meta
          name ='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>
      <div style={{
        position: 'absolute',
        top: 0,
        right: 20,
        zIndex: 21,
        margin: 10,
      }}>
        <GridContainer style={{background: paletteData.primary.standard.background, borderRadius: 25}}>
          <GridCell>
            <Fab
              palette='primary'
              onClick={() => {
                if(focusedGroup) {
                  setFocusedGroup(undefined);
                } else {
                  history.goBack();
                }
              }}
            >
              <span className="material-icons md-24">arrow_back</span>
            </Fab>
          </GridCell>
        </GridContainer>
      </div>
      <GridContainer
        style={{
          background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundBlendMode: 'lighten',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          height: 200,
        }}
      >
        <GridCell
          weight={1}
          center={true}
          centerWeights={{top: 2, bottom: 3}}
        >
          <Typography size='heading' style={{textAlign: 'center'}}>
            {type === 'category' ? categoryReferences[id]?.name : areaReferences[id]?.name} Community Message Board
          </Typography>
        </GridCell>
      </GridContainer>
      {!showCodeOfConduct &&
        <div>
          {focusedGroup?.name === 'Validation Requests' &&
            <Validation
              setShowCodeOfConduct={setShowCodeOfConduct}
              queryLimit={queryLimit}
              communityId={id}
              communityType={type}
              setFocusedGroup={setFocusedGroup}
              messageValidationCount={messageValidationCount}
              setMessageValidationCount={setMessageValidationCount}
            />
          }
          {focusedGroup && focusedGroup?.name !== 'Validation Requests' &&
            <Group
              me={me}
              isModerator={isModerator}
              setShowCodeOfConduct={setShowCodeOfConduct}
              communityId={id}
              communityType={type}
              focusedGroup={focusedGroup}
              setFocusedGroup={setFocusedGroup}
              messages={messages}
              search={search}
              queryLimit={queryLimit}
              isMobile={isMobile}
              replaceMessage={replaceMessage}
              communityReference={type === 'category' ? categoryReferences[id] : areaReferences[id]}
              setAreaReference={setAreaReference}
              setCategoryReference={setCategoryReference}
              replaceArea={replaceArea}
              replaceCategory={replaceCategory}
            />
          }
          {!focusedGroup &&
            <div style={{padding: 10, background: paletteData.primary.standard.background}}>
              <GridContainer style={{height: 35}}>
                <Hidden breakpoint='hiddenlessthan650'>
                  <GridCell weight={1}/>
                </Hidden>
                <GridCell weight={10} center={true}>
                  <GridContainer>
                    <GridCell weight={1} center={true}>
                      <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                        Message Boards
                      </Typography>
                    </GridCell>
                    <GridCell>
                      <Fab
                        size='small'
                        palette='secondary'
                        onClick={() => {
                          setDialogData({
                            type: 'addGroup',
                            title: "Add Message Board",
                            value: {
                              name: '',
                              postAuthority: 'public',
                              visibility: 'public',
                              transparency: 'private',
                              blackList: {},
                              whiteList: {},
                            }
                          });
                          setDialogOpen(true);
                        }}
                      >
                        <span className="material-icons md-24">add</span>
                      </Fab>
                    </GridCell>
                  </GridContainer>
                </GridCell>
                <Hidden breakpoint='hiddenlessthan650'>
                  <GridCell weight={1}/>
                </Hidden>
              </GridContainer>
            </div>
          }
          {!focusedGroup &&
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10}>
                <div style={{marginLeft: 5, marginRight: 5}}>
                  <Typography size='subText' style={{textAlign: 'right', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setShowCodeOfConduct(true)}>
                    Code of Conduct
                  </Typography>
                </div>
                <div>
                  <Input
                    label='Search'
                    value={searchGroups}
                    onChange={(value) => setSearchGroups(value)}
                  />
                  {!communityLoadComplete &&
                    <div style={{marginLeft: 5, marginRight: 5}}>
                      <ProgressBar palette='secondary'/>
                    </div>
                  }

                  {communityLoadComplete && isModerator &&
                    <div
                      style={{
                          padding: 10,
                          margin: 5,
                          borderStyle: 'solid',
                          borderRadius: 5,
                          borderWidth: 1,
                          borderColor: 'black',
                          cursor: messageValidationCount > 0 ? 'pointer' : undefined,
                        }}
                      onClick={() => {
                        if(messageValidationCount > 0) {
                          setFocusedGroup({name: 'Validation Requests'});
                        }
                      }}
                    >
                      <GridContainer>
                        <GridCell weight={1} center={true}>
                          <Typography size='title'>
                            Validation Requests
                          </Typography>
                        </GridCell>
                        <GridCell center={true}>
                          {messageValidationCount === undefined &&
                            <div>
                              <Typography>
                                ...loading
                              </Typography>
                              <ProgressBar palette='secondary'/>
                            </div>
                          }
                          {messageValidationCount !== undefined &&
                            <div style={{background: paletteData.primary.standard.background, width: 30, height: 30, borderRadius: 15}}>
                              <Typography style={{color: paletteData.primary.standard.foreground, textAlign: 'center', marginTop: 5}}>
                                {messageValidationCount}
                              </Typography>
                            </div>
                          }
                        </GridCell>
                      </GridContainer>
                    </div>
                  }
                  {communityLoadComplete && type === 'category' && categoryReferences[id]?.community?.groups?.length > 0 && categoryReferences[id].community.groups.filter((g, gIndex) => {
                    return (g.name || '').toLowerCase().includes((searchGroups || '').toLowerCase());
                  }).map((g, gIndex) => (
                    <GridContainer key={gIndex}>
                      <GridCell
                        weight={1}
                        style={{margin: 5, padding: 10, borderStyle: 'solid', borderRadius: 5, borderWidth: 1, borderColor: 'black', cursor: 'pointer'}}
                        onClick={() => setFocusedGroup(g)}
                      >
                        <Typography size='title'>
                          {g.name}
                        </Typography>
                      </GridCell>
                      {isModerator &&
                        <GridCell
                          style={{
                            margin: 5,
                            padding: 10,
                            borderStyle: 'solid',
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: 'black',
                            cursor: 'pointer',
                            background: g.name === 'Community Messages' ? '#c6c6c6' : paletteData.primary.standard.background,
                            color: g.name === 'Community Messages' ? 'black' : paletteData.primary.standard.foreground,
                          }}
                          onClick={() => {
                            if(g.name !== 'Community Messages') {
                              setDialogData({
                                type: 'editGroup',
                                title: "Group Settings",
                                value: g,
                                data: {gIndex}
                              });
                              setDialogOpen(true);
                            }
                          }}
                        >
                          <span className="material-icons md-24">settings</span>
                        </GridCell>
                      }
                      {isModerator &&
                        <GridCell
                          style={{
                            margin: 5,
                            padding: 10,
                            borderStyle: 'solid',
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: 'black',
                            cursor: 'pointer',
                            background: paletteData.primary.standard.background,
                            color: paletteData.primary.standard.foreground,
                          }}
                          onClick={() => {
                            const blackList = Object.keys(g.blackList || {});
                            const fetchUsers = [];
                            for(let i = 0; i < blackList.length; i += 1) {
                              if(!userReferences[blackList[i]]) {
                                fetchUsers.push(blackList[i]);
                              }
                            }
                            if(fetchUsers.length > 0) {
                              apiRequest({type: 'get', action: 'public_users/', data: {query: {_id: {$in: fetchUsers}}, sort: {name: 1}}})
                              .then((result) => {
                                setUserReferences(result.data.users);
                              }).catch((error) => {});
                            }
                            setDialogData({
                              type: 'blackList',
                              title: "Blacklist",
                              data: {
                                groupName: g.name,
                                blackList,
                              }
                            });
                            setDialogOpen(true);
                          }}
                        >
                          <span className="material-icons md-24">list_alt</span>
                        </GridCell>
                      }
                      {isModerator &&
                        <GridCell
                          style={{
                            margin: 5,
                            padding: 10,
                            borderStyle: 'solid',
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: 'black',
                            cursor: 'pointer',
                            background: g.name === 'Community Messages' ? '#c6c6c6' : paletteData.primary.standard.background,
                            color: g.name === 'Community Messages' ? 'black' : paletteData.primary.standard.foreground,
                          }}
                          onClick={() => {
                            if(g.name !== 'Community Messages') {
                              setDialogData({
                                type: 'moveGroup',
                                title: "Move Group",
                                data: {
                                  gIndex,
                                },
                                value: {
                                  newIndex: gIndex,
                                }
                              });
                              setDialogOpen(true);
                            }
                          }}
                        >
                          <span className="material-icons md-24">move_down</span>
                        </GridCell>
                      }
                      {isModerator &&
                        <GridCell
                          style={{
                            margin: 5,
                            padding: 10,
                            borderStyle: 'solid',
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: 'black',
                            cursor: 'pointer',
                            background: g.name === 'Community Messages' ? '#c6c6c6' : paletteData.primary.standard.background,
                            color: g.name === 'Community Messages' ? 'black' : paletteData.primary.standard.foreground,
                          }}
                          onClick={() => {
                            if(g.name !== 'Community Messages') {
                              setDialogData({
                                type: 'removeGroup',
                                title: "Remove Message Board",
                                message: 'Are you sure you want to remove this group?  this action is permanent',
                                data: {
                                  gIndex,
                                }
                              });
                              setDialogOpen(true);
                            }
                          }}
                        >
                          <span className="material-icons md-24">close</span>
                        </GridCell>
                      }
                    </GridContainer>
                  ))}

                  {communityLoadComplete && type === 'area' && areaReferences[id]?.community?.groups?.length > 0 && areaReferences[id].community.groups.filter((g, gIndex) => {
                    return (g.name || '').toLowerCase().includes((searchGroups || '').toLowerCase());
                  }).map((g, gIndex) => (
                    <GridContainer key={gIndex}>
                      <GridCell
                        weight={1}
                        style={{margin: 5, padding: 10, borderStyle: 'solid', borderRadius: 5, borderWidth: 1, borderColor: 'black', cursor: 'pointer'}}
                        onClick={() => setFocusedGroup(g)}
                      >
                        <Typography size='title'>
                          {g.name}
                        </Typography>
                      </GridCell>
                      {isModerator &&
                        <GridCell
                          style={{
                            margin: 5,
                            padding: 10,
                            borderStyle: 'solid',
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: 'black',
                            cursor: 'pointer',
                            background: g.name === 'Community Messages' ? '#c6c6c6' : paletteData.primary.standard.background,
                            color: g.name === 'Community Messages' ? 'black' : paletteData.primary.standard.foreground,
                          }}
                          onClick={() => {
                            if(g.name !== 'Community Messages') {
                              setDialogData({
                                type: 'editGroup',
                                title: "Group Settings",
                                value: g,
                                data: {gIndex}
                              });
                              setDialogOpen(true);
                            }
                          }}
                        >
                          <span className="material-icons md-24">settings</span>
                        </GridCell>
                      }
                      {isModerator &&
                        <GridCell
                          style={{
                            margin: 5,
                            padding: 10,
                            borderStyle: 'solid',
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: 'black',
                            cursor: 'pointer',
                            background: paletteData.primary.standard.background,
                            color: paletteData.primary.standard.foreground,
                          }}
                          onClick={() => {
                            const blackList = Object.keys(g.blackList || {});
                            const fetchUsers = [];
                            for(let i = 0; i < blackList.length; i += 1) {
                              if(!userReferences[blackList[i]]) {
                                fetchUsers.push(blackList[i]);
                              }
                            }
                            if(fetchUsers.length > 0) {
                              apiRequest({type: 'get', action: 'public_users/', data: {query: {_id: {$in: fetchUsers}}, sort: {name: 1}}})
                              .then((result) => {
                                setUserReferences(result.data.users);
                              }).catch((error) => {});
                            }
                            setDialogData({
                              type: 'blackList',
                              title: "Blacklist",
                              data: {
                                groupName: g.name,
                                blackList,
                              }
                            });
                            setDialogOpen(true);
                          }}
                        >
                          <span className="material-icons md-24">list_alt</span>
                        </GridCell>
                      }
                      {isModerator &&
                        <GridCell
                          style={{
                            margin: 5,
                            padding: 10,
                            borderStyle: 'solid',
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: 'black',
                            cursor: 'pointer',
                            background: g.name === 'Community Messages' ? '#c6c6c6' : paletteData.primary.standard.background,
                            color: g.name === 'Community Messages' ? 'black' : paletteData.primary.standard.foreground,
                          }}
                          onClick={() => {
                            if(g.name !== 'Community Messages') {
                              setDialogData({
                                type: 'moveGroup',
                                title: "Move Group",
                                data: {
                                  gIndex,
                                },
                                value: {
                                  newIndex: gIndex,
                                }
                              });
                              setDialogOpen(true);
                            }
                          }}
                        >
                          <span className="material-icons md-24">move_down</span>
                        </GridCell>
                      }
                      {isModerator &&
                        <GridCell
                          style={{
                            margin: 5,
                            padding: 10,
                            borderStyle: 'solid',
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: 'black',
                            cursor: 'pointer',
                            background: g.name === 'Community Messages' ? '#c6c6c6' : paletteData.primary.standard.background,
                            color: g.name === 'Community Messages' ? 'black' : paletteData.primary.standard.foreground,
                          }}
                          onClick={() => {
                            if(g.name !== 'Community Messages') {
                              setDialogData({
                                type: 'removeGroup',
                                title: "Remove Message Board",
                                message: 'Are you sure you want to remove this group?  this action is permanent',
                                data: {
                                  gIndex,
                                }
                              });
                              setDialogOpen(true);
                            }
                          }}
                        >
                          <span className="material-icons md-24">close</span>
                        </GridCell>
                      }
                    </GridContainer>
                  ))}
                </div>
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
          }
        </div>
      }
      {showCodeOfConduct &&
        <CodeOfConduct setShowCodeOfConduct={setShowCodeOfConduct}/>
      }

      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'blackList' &&
            <div style={{padding: 10}}>
              {dialogData?.data?.blackList?.length > 0 && dialogData.data.blackList.map((u, uIndex) => (
                <GridContainer key={uIndex}>
                  <GridCell weight={1}>
                    <Typography>
                      {userReferences[u]?.name || '...loading'}
                    </Typography>
                  </GridCell>
                  <GridCell>
                    <Typography
                      style={{textDecoration: 'underline', cursor: 'pointer'}}
                      onClick={() => {
                        if(!processing) {
                          setProcessing(true);
                          const blacklistData = {
                            type: 'remove',
                            location: 'single',
                            userId: u,
                            communityId: id,
                            communityType: type,
                            groupName: dialogData?.data?.groupName,
                          };
                          apiRequest({type: 'patch', action: `communities/blacklist/${id}`, data: {blacklistData}})
                          .then((result) => {
                            const blackListProcessed = dialogData?.data?.blackList;
                            blackListProcessed.splice(uIndex, 1);
                            setDialogData({
                              ...dialogData,
                              data: {
                                ...(dialogData?.data || {}),
                                blackList: blackListProcessed,
                              }
                            });
                            const groupsProcessed = result.data.newGroupData;
                            if(type === 'category') {
                              const communityProcessed = {
                                ...categoryReferences[id],
                                community: {
                                  ...(categoryReferences[id].community || {}),
                                  groups: groupsProcessed,
                                }
                              }
                              setCategoryReference(communityProcessed);
                              replaceCategory({category: communityProcessed});
                            } else if(type === 'area') {
                              const communityProcessed = {
                                ...areaReferences[id],
                                community: {
                                  ...(areaReferences[id].community || {}),
                                  groups: groupsProcessed,
                                }
                              }
                              setAreaReference(communityProcessed);
                              replaceArea({area: communityProcessed});
                            }
                            setProcessing(false);
                          }).catch((error) => {
                            setProcessing(false);
                          });
                        }
                      }}
                    >
                      remove
                    </Typography>
                    {processing && <ProgressBar palette='secondary'/>}
                  </GridCell>
                </GridContainer>
              ))}
            </div>
          }
          {(dialogData?.type === 'addGroup' || dialogData?.type === 'editGroup') &&
            <div style={{padding: 10}}>
              <form>
                <Input
                  disabled={dialogData?.type === 'editGroup'}
                  autoComplete='off'
                  name='name'
                  label='Name'
                  value={dialogData?.value?.name}
                  onChange={(value) => {
                    setDialogData({
                      ...dialogData,
                      value: {
                        ...(dialogData.value || {}),
                        name: value,
                      }
                    })
                  }}
                />
                <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                  Name can not be changed after creation of group.
                </Typography>
                <Select
                  name='postAuthority'
                  label='Post Authority'
                  value={dialogData?.value?.postAuthority}
                  onChange={(value) => {
                    setDialogData({
                      ...dialogData,
                      value: {
                        ...(dialogData.value || {}),
                        postAuthority: value,
                      }
                    })
                  }}
                >
                  <option value='public'>Public</option>
                  <option value='moderatorValidation'>Moderator Validated</option>
                </Select>
                {dialogData?.value?.visibility === 'hidden' &&
                  <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                    Posts on this message board are not shown until validated by a moderator
                  </Typography>
                }
                <Select
                  name='visibility'
                  label='Visibility'
                  value={dialogData?.value?.visibility}
                  onChange={(value) => {
                    setDialogData({
                      ...dialogData,
                      value: {
                        ...(dialogData.value || {}),
                        visibility: value,
                      }
                    })
                  }}
                >
                  <option value='public'>Public</option>
                  <option value='hidden'>Hidden</option>
                </Select>
                {dialogData?.value?.visibility === 'hidden' &&
                  <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                    This message board is hidden from the public
                  </Typography>
                }
                <Select
                  name='transparency'
                  label='Transparency'
                  value={dialogData?.value?.transparency}
                  onChange={(value) => {
                    setDialogData({
                      ...dialogData,
                      value: {
                        ...(dialogData.value || {}),
                        transparency: value,
                      }
                    })
                  }}
                >
                  <option value='public'>Public</option>
                  <option value='private'>Private</option>
                </Select>
                {dialogData?.value?.transparency === 'public' &&
                  <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                    Emails of users posting on this message board are made public
                  </Typography>
                }
              </form>
            </div>
          }
          {dialogData?.type === 'moveGroup' &&
            <div style={{padding: 10}}>
              <Input
                name='newIndex'
                label='Move to index'
                value={dialogData?.value?.newIndex}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      newIndex: value,
                    }
                  })
                }}
              />
              <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                Index range 1 - {(categoryReferences[id]?.community?.groups?.length || 2) - 1}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                 disabled={processing}
                 palette='primary'
                 onClick={() => {
                   setDialogData(undefined);
                   setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'addGroup' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.name}
                  palette='primary'
                  onClick={() => {
                     setProcessing(true);
                     let communityProcessed = undefined;
                     if(type === 'category') {
                       communityProcessed = categoryReferences[id].community || {};
                     } else if(type === 'area') {
                       communityProcessed = areaReferences[id].community || {};
                     }
                     if(!communityProcessed.groups) {
                       communityProcessed.groups = [{
                         name: 'Community Messages',
                         postAuthority: 'moderatorValidation',
                         visibility: 'public',
                         transparency: 'public',
                         blackList: {},
                         whiteList: {},
                       }];
                     }
                     communityProcessed.groups.push(dialogData?.value);
                     const updateCommunity = {
                       community: communityProcessed,
                     };
                     apiRequest({type: 'patch', action: `communities/updateCommunitySettings/${id}`, data: {updateCommunity, type}})
                     .then((result) => {
                       if(type === 'category') {
                         setCategoryReference({_id: id, ...categoryReferences[id], ...updateCommunity})
                         replaceCategory({category: {_id: id, ...categoryReferences[id], ...updateCommunity}});
                       } else if(type === 'area') {
                         setAreaReference({_id: id, ...areaReferences[id], ...updateCommunity})
                         replaceArea({area: {_id: id, ...areaReferences[id], ...updateCommunity}});
                       }
                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     }).catch((error) => {
                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     });
                  }}
                >
                  Create Group
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
            {dialogData?.type === 'editGroup' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.name}
                  palette='primary'
                  onClick={() => {
                     setProcessing(true);
                     let communityProcessed = undefined;
                     if(type === 'category') {
                       communityProcessed = categoryReferences[id].community || {};
                     } else if(type === 'area') {
                       communityProcessed = areaReferences[id].community || {};
                     }
                     if(!communityProcessed.groups) {
                       communityProcessed.groups = [{
                         name: 'Community Messages',
                         postAuthority: 'moderatorValidation',
                         visibility: 'public',
                         transparency: 'public',
                         blackList: {},
                         whiteList: {},
                       }];
                     }
                     communityProcessed.groups.splice(dialogData?.data?.gIndex, 1, dialogData?.value);
                     const updateCommunity = {
                       community: communityProcessed,
                     };
                     apiRequest({type: 'patch', action: `communities/updateCommunitySettings/${id}`, data: {updateCommunity, type}})
                     .then((result) => {
                       if(type === 'category') {
                         setCategoryReference({_id: id, ...categoryReferences[id], ...updateCommunity})
                         replaceCategory({category: {_id: id, ...categoryReferences[id], ...updateCommunity}});
                       } else if(type === 'area') {
                         setAreaReference({_id: id, ...areaReferences[id], ...updateCommunity})
                         replaceArea({area: {_id: id, ...areaReferences[id], ...updateCommunity}});
                       }
                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     }).catch((error) => {
                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     });
                  }}
                >
                  Update Group
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
            {dialogData?.type === 'removeGroup' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                     setProcessing(true);
                     let communityProcessed = undefined;
                     if(type === 'category') {
                       communityProcessed = categoryReferences[id].community || {};
                     } else if(type === 'area') {
                       communityProcessed = areaReferences[id].community || {};
                     }
                     if(!communityProcessed.groups) {
                       communityProcessed.groups = [{
                         name: 'Community Messages',
                         postAuthority: 'moderatorValidation',
                         visibility: 'public',
                         transparency: 'public',
                         blackList: {},
                         whiteList: {},
                       }];
                     }
                     communityProcessed.groups.splice(dialogData?.data?.gIndex, 1);
                     const updateCommunity = {
                       community: communityProcessed,
                     };
                     apiRequest({type: 'patch', action: `communities/updateCommunitySettings/${id}`, data: {updateCommunity, type: 'category'}})
                     .then((result) => {
                       if(type === 'category') {
                         setCategoryReference({_id: id, ...categoryReferences[id], ...updateCommunity})
                         replaceCategory({category: {_id: id, ...categoryReferences[id], ...updateCommunity}});
                       } else if(type === 'area') {
                         setAreaReference({_id: id, ...areaReferences[id], ...updateCommunity})
                         replaceArea({area: {_id: id, ...areaReferences[id], ...updateCommunity}});
                       }
                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     }).catch((error) => {
                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     });
                  }}
                >
                  Remove Group
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
            {dialogData?.type === 'moveGroup' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || dialogData?.value?.newIndex < 1 || dialogData?.value?.newIndex > ((categoryReferences[id]?.community?.groups?.length || 2) - 1)}
                  palette='primary'
                  onClick={() => {
                     setProcessing(true);
                     let communityProcessed = undefined;
                     if(type === 'category') {
                       communityProcessed = categoryReferences[id].community || {};
                     } else if(type === 'area') {
                       communityProcessed = areaReferences[id].community || {};
                     }
                     if(!communityProcessed.groups) {
                       communityProcessed.groups = [{
                         name: 'Community Messages',
                         postAuthority: 'moderatorValidation',
                         visibility: 'public',
                         transparency: 'public',
                         blackList: {},
                         whiteList: {},
                       }];
                     }
                     const groupProcessed = communityProcessed.groups[dialogData?.data?.gIndex];
                     communityProcessed.groups.splice(dialogData?.data?.gIndex, 1);
                     communityProcessed.groups.splice(dialogData?.value?.newIndex, 1, groupProcessed);
                     const updateCommunity = {
                       community: communityProcessed,
                     };
                     apiRequest({type: 'patch', action: `communities/updateCommunitySettings/${id}`, data: {updateCommunity, type}})
                     .then((result) => {
                       if(type === 'category') {
                         setCategoryReference({_id: id, ...categoryReferences[id], ...updateCommunity})
                         replaceCategory({category: {_id: id, ...categoryReferences[id], ...updateCommunity}});
                       } else if(type === 'area') {
                         setAreaReference({_id: id, ...areaReferences[id], ...updateCommunity})
                         replaceArea({area: {_id: id, ...areaReferences[id], ...updateCommunity}});
                       }
                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     }).catch((error) => {
                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     });
                  }}
                >
                  Move Group
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    categoryReferences: state.references.categories || {},
    areaReferences: state.references.areas || {},
    userReferences: state.references.users || {},
    search: state.lists.search,
    messages: state.lists.messages,
    queryLimit: state.lists.queryLimit,
  };
};

export default connect(mapStateToProps, {
  setSearch, setCategories, setAreas, setMessages, addMessages, replaceMessage, replaceArea, replaceCategory,
  setCategoryReference, setAreaReference, setMessageReferences, setMessageReference, setUserReferences,
})(Messages);
