import {
  SET_USERS,
  ADD_USERS,
  ADD_USER,
  REPLACE_USER,
  REMOVE_USER,

  SET_ORGANISATIONS,
  ADD_ORGANISATIONS,
  ADD_ORGANISATION,
  REPLACE_ORGANISATION,
  REMOVE_ORGANISATION,

  SET_AREAS,
  ADD_AREAS,
  ADD_AREA,
  REPLACE_AREA,
  REMOVE_AREA,

  SET_CATEGORIES,
  ADD_CATEGORIES,
  ADD_CATEGORY,
  REPLACE_CATEGORY,
  REMOVE_CATEGORY,

  SET_ARTICLES,
  ADD_ARTICLES,
  ADD_ARTICLE,
  REPLACE_ARTICLE,
  REMOVE_ARTICLE,

  SET_EVENTS,
  ADD_EVENTS,
  ADD_EVENT,
  REPLACE_EVENT,
  REMOVE_EVENT,

  SET_PRODUCTS,
  ADD_PRODUCTS,
  ADD_PRODUCT,
  REPLACE_PRODUCT,
  REMOVE_PRODUCT,

  SET_ORDERS,
  ADD_ORDERS,
  ADD_ORDER,
  REPLACE_ORDER,
  REMOVE_ORDER,

  SET_CURRENT_ORDERS,
  ADD_CURRENT_ORDERS,
  ADD_CURRENT_ORDER,
  REPLACE_CURRENT_ORDER,
  REMOVE_CURRENT_ORDER,

  SET_REVIEWS,
  ADD_REVIEWS,
  ADD_REVIEW,
  REPLACE_REVIEW,
  REMOVE_REVIEW,

  SET_COMMUNITIES,
  ADD_COMMUNITY,
  ADD_COMMUNITIES,
  REPLACE_COMMUNITY,
  REMOVE_COMMUNITY,

  SET_MESSAGES,
  ADD_MESSAGE,
  ADD_MESSAGES,
  REPLACE_MESSAGE,
  REMOVE_MESSAGE,

  SET_SEARCH,
  RESET_LISTS,
} from "./actionTypes";

//--------------------- users --------------------

export const setUsers = (content) => ({
  type: SET_USERS,
  payload: content
});

export const addUser = (content) => ({
  type: ADD_USER,
  payload: content
});

export const addUsers = (content) => ({
  type: ADD_USERS,
  payload: content
});

export const removeUser = (content) => ({
  type: REMOVE_USER,
  payload: content
});

export const replaceUser = (content) => ({
  type: REPLACE_USER,
  payload: content
});

//--------------------- areas --------------------


export const setAreas = (content) => ({
  type: SET_AREAS,
  payload: content
});

export const addArea = (content) => ({
  type: ADD_AREA,
  payload: content
});

export const addAreas = (content) => ({
  type: ADD_AREAS,
  payload: content
});

export const removeArea = (content) => ({
  type: REMOVE_AREA,
  payload: content
});

export const replaceArea = (content) => ({
  type: REPLACE_AREA,
  payload: content
});

//--------------------- organisations --------------------

export const setOrganisations = (content) => ({
  type: SET_ORGANISATIONS,
  payload: content
});

export const addOrganisation = (content) => ({
  type: ADD_ORGANISATION,
  payload: content
});

export const addOrganisations = (content) => ({
  type: ADD_ORGANISATIONS,
  payload: content
});

export const removeOrganisation = (content) => ({
  type: REMOVE_ORGANISATION,
  payload: content
});

export const replaceOrganisation = (content) => ({
  type: REPLACE_ORGANISATION,
  payload: content
});


//--------------------- categories --------------------

export const setCategories = (content) => ({
  type: SET_CATEGORIES,
  payload: content
});

export const addCategory = (content) => ({
  type: ADD_CATEGORY,
  payload: content
});

export const addCategories = (content) => ({
  type: ADD_CATEGORIES,
  payload: content
});

export const removeCategory = (content) => ({
  type: REMOVE_CATEGORY,
  payload: content
});

export const replaceCategory = (content) => ({
  type: REPLACE_CATEGORY,
  payload: content
});

//--------------------- articles --------------------

export const setArticles = (content) => ({
  type: SET_ARTICLES,
  payload: content
});

export const addArticle = (content) => ({
  type: ADD_ARTICLE,
  payload: content
});

export const addArticles = (content) => ({
  type: ADD_ARTICLES,
  payload: content
});

export const removeArticle = (content) => ({
  type: REMOVE_ARTICLE,
  payload: content
});

export const replaceArticle = (content) => ({
  type: REPLACE_ARTICLE,
  payload: content
});

//--------------------- events --------------------

export const setEvents = (content) => ({
  type: SET_EVENTS,
  payload: content
});

export const addEvent = (content) => ({
  type: ADD_EVENT,
  payload: content
});

export const addEvents = (content) => ({
  type: ADD_EVENTS,
  payload: content
});

export const removeEvent = (content) => ({
  type: REMOVE_EVENT,
  payload: content
});

export const replaceEvent = (content) => ({
  type: REPLACE_EVENT,
  payload: content
});


//--------------------- products --------------------

export const setProducts = (content) => ({
  type: SET_PRODUCTS,
  payload: content
});

export const addProduct = (content) => ({
  type: ADD_PRODUCT,
  payload: content
});

export const addProducts = (content) => ({
  type: ADD_PRODUCTS,
  payload: content
});

export const removeProduct = (content) => ({
  type: REMOVE_PRODUCT,
  payload: content
});

export const replaceProduct = (content) => ({
  type: REPLACE_PRODUCT,
  payload: content
});


//--------------------- orders --------------------

export const setOrders = (content) => ({
  type: SET_ORDERS,
  payload: content
});

export const addOrder = (content) => ({
  type: ADD_ORDER,
  payload: content
});

export const addOrders = (content) => ({
  type: ADD_ORDERS,
  payload: content
});

export const removeOrder = (content) => ({
  type: REMOVE_ORDER,
  payload: content
});

export const replaceOrder = (content) => ({
  type: REPLACE_ORDER,
  payload: content
});

//--------------------- reviews --------------------

export const setReviews = (content) => ({
  type: SET_REVIEWS,
  payload: content
});

export const addReview = (content) => ({
  type: ADD_REVIEW,
  payload: content
});

export const addReviews = (content) => ({
  type: ADD_REVIEWS,
  payload: content
});

export const removeReview = (content) => ({
  type: REMOVE_REVIEW,
  payload: content
});

export const replaceReview = (content) => ({
  type: REPLACE_REVIEW,
  payload: content
});

// -------------------- current orders -------------

export const setCurrentOrders = (content) => ({
  type: SET_CURRENT_ORDERS,
  payload: content
});

export const addCurrentOrder = (content) => ({
  type: ADD_CURRENT_ORDER,
  payload: content
});

export const addCurrentOrders = (content) => ({
  type: ADD_CURRENT_ORDERS,
  payload: content
});

export const removeCurrentOrder = (content) => ({
  type: REMOVE_CURRENT_ORDER,
  payload: content
});

export const replaceCurrentOrder = (content) => ({
  type: REPLACE_CURRENT_ORDER,
  payload: content
});

//--------------------- communities --------------------

export const setCommunities = (content) => ({
  type: SET_COMMUNITIES,
  payload: content
});

export const addCommunity = (content) => ({
  type: ADD_COMMUNITY,
  payload: content
});

export const addCommunities = (content) => ({
  type: ADD_COMMUNITIES,
  payload: content
});

export const removeCommunity = (content) => ({
  type: REMOVE_COMMUNITY,
  payload: content
});

export const replaceCommunity = (content) => ({
  type: REPLACE_COMMUNITY,
  payload: content
});

//--------------------- messages --------------------

export const setMessages = (content) => ({
  type: SET_MESSAGES,
  payload: content
});

export const addMessage = (content) => ({
  type: ADD_MESSAGE,
  payload: content
});

export const addMessages = (content) => ({
  type: ADD_MESSAGES,
  payload: content
});

export const removeMessage = (content) => ({
  type: REMOVE_MESSAGE,
  payload: content
});

export const replaceMessage = (content) => ({
  type: REPLACE_MESSAGE,
  payload: content
});

// -------------------- general --------------------

export const setSearch = (content) => ({
  type: SET_SEARCH,
  payload: content,
});
export const resetLists = () => ({
  type: RESET_LISTS,
});
