import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {Helmet} from "react-helmet";

import {Button, Typography, Dialog, paletteData} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';
import Hidden from '../../../components/hidden';

import SearchOrganisationsSubPanel from './searchOrganisations';

import {setOrganisations, addOrganisations, setSearch, setCategories} from '../../../store/reducers/lists/actions.js';
import {setOrganisationReference} from '../../../store/reducers/references/actions.js';

function Organisations({
  myPosition,
  search,
  setSearch,
  queryLimit,
  isMobile,

  categories,
  setCategories,

  areaReferences,
  setOrganisationReference,

  organisations,
  setOrganisations,
  addOrganisations,
}) {
  //page states
  const [redirect, setRedirect] = useState(undefined);

  // user feedback
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [processing, setProcessing] = useState(false);

  if(redirect) {
    return <Redirect to={redirect} push={true} />
  }

  return (
    <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - organisations</title>
        <meta
          name ='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>
      {/*------------ main body -----------------*/}
      <div style={{
        transition: 'opacity .5s ease-out',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20,
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        background: 'rgba(255, 255, 255, 0.85)',
        overflowX: 'hidden',
      }}>
        {/*------------ banner --------------*/}
        <GridContainer
          style={{
            background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundBlendMode: 'lighten',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            height: 200,
          }}
        >
          <GridCell
            weight={1}
            center={true}
            centerWeights={{top: 2, bottom: 3}}
          >
            <Typography size='heading' style={{textAlign: 'center'}}>
              Organisations
            </Typography>
          </GridCell>
        </GridContainer>
        {/*------------- layout -------------*/}
        <GridContainer>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
          <GridCell weight={10} style={{padding: 10}}>
            {/*------------ sub panels --------------*/}
            <SearchOrganisationsSubPanel
              isMobile={isMobile}
              search={search}
              setSearch={setSearch}
              processing={processing}
              setProcessing={setProcessing}
              setRedirect={setRedirect}
              queryLimit={queryLimit}

              organisations={organisations}
              setOrganisations={setOrganisations}
              addOrganisations={addOrganisations}
              setOrganisationReference={setOrganisationReference}

              categories={categories}
            />
          </GridCell>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
        </GridContainer>
      </div>

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    areaReferences: state.references.areas || {},
    search: state.lists.search,
    organisations: state.lists.organisations,
    categories: state.lists.categories,
    queryLimit: state.lists.queryLimit,
  };
};

export default connect(mapStateToProps, {setOrganisationReference, setOrganisations, addOrganisations, setSearch, setCategories})(Organisations);
