import React from 'react';
import PropTypes from 'prop-types';

import {Input, Button, ProgressBar} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function InvoiceHeaderForm({header, handleChange, processing, dialogData, dialogOpen, setDialogData, setDialogOpen}) {
  return (
    <div>
      <GridContainer>
        <GridCell style={{width: 180}}>
          <div>
            {!header?.logo?.imageId &&
              <GridContainer style={{height: 180, width: 180}}>
                <GridCell center={true} weight={1} style={{background: '#c6c6c6', textAlign: 'center', borderRadius: 5}}>
                  No Image
                </GridCell>
              </GridContainer>
            }
            {header?.logo?.imageId &&
              <GridContainer>
                <GridCell center={true} weight={1} style={{minHeight: 180}}>
                  <img
                    src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${header.logo?.imageId}`}
                    alt={header?.logo?.altText}
                    style={{width: 180}}
                  />
                </GridCell>
              </GridContainer>
            }
            <div style={{marginTop: 10, textAlign: 'center'}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setDialogData({
                    type: 'selectImage',
                    title: 'Select Logo',
                    data: {
                      callback: (value) => {
                        if(value.imageId) {
                          handleChange('logo', value)
                        }
                      }
                    },
                    value: {
                      search: '',
                    },
                  });
                  setDialogOpen(true);
                }}
              >
                Select Logo
              </Button>
            </div>
          </div>
          {(processing) &&
            <ProgressBar palette='secondary'/>
          }
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="name"
            palette='secondary'
            label="Name"
            type="text"
            value={header?.name || ''}
            onChange={(value) => handleChange('name', value)}
          />
          <Input
            name="tagline"
            palette='secondary'
            label="Tagline"
            type="text"
            value={header?.tagline || ''}
            onChange={(value) => handleChange('tagline', value)}
          />
        </GridCell>
      </GridContainer>
    </div>
  );
}

InvoiceHeaderForm.propTypes = {
  header: PropTypes.shape({}),
  handleChange: PropTypes.func,
  processing: PropTypes.bool,
}

export default InvoiceHeaderForm;
