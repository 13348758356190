import React from 'react';
import PropTypes from 'prop-types';

import {Fab} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function MenuEventSubPanel({
  me,
  focusedEvent,
  focusedOrganisation,
  convertTime,
  convertDate,

  products,
  productsLoaded,
  events,
  eventsLoaded,

  editing,
  menuOpen,
  setEditing,
  setMenuOpen,
  setPage,

  setDialogData,
  setDialogOpen,
  setRedirect,
  urlHistory,
}) {
  return (
    <GridContainer style={{flex: 1}}>
      <GridCell weight={1}/>
      {me?._id && (me?._id === focusedEvent?.createdByUser || me?._id === focusedOrganisation?.owner || (me?.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker']))) &&
        <GridCell>
          <Fab
            palette='primary'
            onClick={() => {
              if(focusedEvent?.state === 'public' ) {
                setDialogData({
                  type: 'messageWithCallback',
                  title: 'Edit Event',
                  message: 'Your event is currently public.  If you make any changes community moderators will have to re-authorise your event to apear on community pages.',
                  data: {
                    callback: () => {
                      setEditing(true);
                    }
                  }
                });
                setDialogOpen(true);
              } else {
                setEditing(true);
              }
            }}
          >
            <span className="material-icons md-24">edit_square</span>
          </Fab>
        </GridCell>
      }
      {focusedEvent?.locations?.length > 0 &&
        <GridCell>
          <Fab
            palette='primary'
            onClick={() => {
              setDialogData({
                type: 'schedule',
                title: 'Event Schedule',
                data: {focusedEvent},
              });
              setDialogOpen(true);
            }}
          >
            <span className="material-icons md-24" >schedule</span>
          </Fab>
        </GridCell>
      }
      <GridCell>
        <Fab
          palette='primary'
          onClick={() => {
            urlHistory.goBack();
          }}
        >
          <span className="material-icons md-24" >arrow_back</span>
        </Fab>
      </GridCell>
      <GridCell weight={1}/>
    </GridContainer>
  );
};

MenuEventSubPanel.propTypes = {
  area: PropTypes.shape({}),
};

export default MenuEventSubPanel;
