import React from 'react';
import PropTypes from 'prop-types';

import {Typography, TextArea} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function EditProductInformationSubPanel({
    processing,
    dialogOpen,
    dialogData,
    setDialogOpen,
    setDialogData,

    focusedProduct,
    handleChangeFocusedProduct,
    focusedOrganisation,
  }) {
    return (
    <div>
        <TextArea 
            label='Description'
            withControls={true}
            palette='primary'
            value={focusedProduct?.description}
            onChange={(value) => handleChangeFocusedProduct('description', value)}
        />
        <GridContainer style={{margin: 5}}>
            {focusedProduct?.gallery?.length > 0 && focusedProduct.gallery.map((g, gIndex) => (
                <GridCell
                    key={gIndex}
                    style={{width: 180, display: 'flex', flexDirection: 'column', marginRight: 10, marginBottom: 10, cursor: 'pointer'}}
                    onClick={() => {
                    setDialogData({
                        type: 'messageWithCallback',
                        title: 'Remove Image',
                        message: 'Do you want to remove this image from the gallery?',
                        data: {
                            callback: () => {
                                const galleryProcessed = focusedProduct.gallery;
                                galleryProcessed.splice(gIndex, 1);
                                handleChangeFocusedProduct('gallery', galleryProcessed);
                            }
                        },
                    });
                    setDialogOpen(true);
                    }}
                >
                    <img src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_300,h_300/${g.imageId}`} alt={g.alt} style={{width: '100%'}} />
                    <Typography style={{padding: 10, textAlign: 'center'}}>
                    {g.alt}
                    </Typography>
                </GridCell>
            ))}
            <GridCell
                style={{width: 180, display: 'flex', flexDirection: 'column', marginRight: 10, marginBottom: 10}}
                onClick={() => {
                    setDialogData({
                        type: 'selectImage',
                        title: 'Add an image to gallery',
                        data: {
                            callback: (image) => {
                                const galleryProcessed = focusedProduct.gallery;
                                galleryProcessed.push(image);
                                handleChangeFocusedProduct('gallery', galleryProcessed);
                            }
                        },
                        value: {
                            search: '',
                        }
                    });
                    setDialogOpen(true);
                }}
                >
                <GridContainer style={{height: 180, width: 180}}>
                    <GridCell center={true} weight={1} style={{background: '#c6c6c6', textAlign: 'center', borderRadius: 5, cursor: 'pointer'}}>
                    <span className="material-icons md-24" style={{fontSize: 50}}>add</span>
                    </GridCell>
                </GridContainer>
                <Typography style={{padding: 10, textAlign: 'center'}}>
                    New
                </Typography>
            </GridCell>
        </GridContainer>
        <br/><br/><br/>
    </div>
  );
};

EditProductInformationSubPanel.propTypes = {
  focusedProduct: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedProduct: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditProductInformationSubPanel;