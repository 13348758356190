import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {paletteData, Input, Button, Fab, Typography, Dialog, ProgressBar, Select} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

// import publicApiRequest from '../../../tools/publicApiRequest';
import apiRequest from '../../../tools/apiRequest';
import handleErrorMessage from '../../../tools/handleErrorMessage';

import {
  setSearch,

  setUsers,
  addUsers,
  addUser,
  replaceUser,
  removeUser,

  setAreas,
  addAreas,
  addArea,
  replaceArea,
  removeArea,

  setOrganisations,
  addOrganisations,
  addOrganisation,
  replaceOrganisation,
  removeOrganisation,

  setCategories,
  addCategories,
  addCategory,
  replaceCategory,
  removeCategory,

  setEvents,
  addEvents,
  addEvent,
  replaceEvent,
  removeEvent,

  setProducts,
  addProducts,
  addProduct,
  replaceProduct,
  removeProduct,

  setReviews,
  addReviews,
  addReview,
  replaceReview,
  removeReview,

  setOrders,
  addOrders,
  addOrder,
  replaceOrder,
  removeOrder,

  setArticles,
  addArticles,
  addArticle,
  replaceArticle,
  removeArticle,

  setCommunities,
  addCommunities,
  addCommunity,
  replaceCommunity,
  removeCommunity,

  setMessages,
  addMessages,
  addMessage,
  replaceMessage,
  removeMessage,
} from '../../../store/reducers/lists/actions.js';
import {
  setUserReferences,
  setUserReference,
  clearUserReference,

  setAreaReference,
  setAreaReferences,
  clearAreaReference,

  setOrganisationReference,
  setOrganisationReferences,
  clearOrganisationReference,

  setCategoryReference,
  setCategoryReferences,
  clearCategoryReference,

  setEventReference,
  setEventReferences,
  clearEventReference,

  setProductReference,
  setProductReferences,
  clearProductReference,

  setReviewReference,
  setReviewReferences,
  clearReviewReference,

  setOrderReference,
  setOrderReferences,
  clearOrderReference,

  setArticleReference,
  setArticleReferences,
  clearArticleReference,

  setCommunityReference,
  setCommunityReferences,
  clearCommunityReference,

  setMessageReference,
  setMessageReferences,
  clearMessageReference,
} from '../../../store/reducers/references/actions.js';

function SearchStore({
  databaseArea,
  processing,
  setProcessing,
  expandSearch,
  setExpandSearch,
  setInitalLoadComplete,
  overRideSearch,
  setOverRideSearch,
  queryLimit,
  isMobile,

  search,
  setSearch,
  lists,
  references,
  filter,
  initalFilter,

  setUsers,
  addUsers,
  addUser,
  replaceUser,
  removeUser,
  setUserReferences,
  setUserReference,
  clearUserReference,

  setAreas,
  addAreas,
  addArea,
  replaceArea,
  removeArea,
  setAreaReference,
  setAreaReferences,
  clearAreaReference,

  setOrganisations,
  addOrganisations,
  addOrganisation,
  replaceOrganisation,
  removeOrganisation,
  setOrganisationReference,
  setOrganisationReferences,
  clearOrganisationReference,

  setCategories,
  addCategories,
  addCategory,
  replaceCategory,
  removeCategory,
  setCategoryReference,
  setCategoryReferences,
  clearCategoryReference,

  setEvents,
  addEvents,
  addEvent,
  replaceEvent,
  removeEvent,
  setEventReference,
  setEventReferences,
  clearEventReference,

  setProducts,
  addProducts,
  addProduct,
  replaceProduct,
  removeProduct,
  setProductReference,
  setProductReferences,
  clearProductReference,

  setReviews,
  addReviews,
  addReview,
  replaceReview,
  removeReview,
  setReviewReference,
  setReviewReferences,
  clearReviewReference,

  setOrders,
  addOrders,
  addOrder,
  replaceOrder,
  removeOrder,
  setOrderReference,
  setOrderReferences,
  clearOrderReference,

  setArticles,
  addArticles,
  addArticle,
  replaceArticle,
  removeArticle,
  setArticleReference,
  setArticleReferences,
  clearArticleReference,

  setCommunities,
  addCommunities,
  addCommunity,
  replaceCommunity,
  removeCommunity,
  setCommunityReference,
  setCommunityReferences,
  clearCommunityReference,

  setMessages,
  addMessages,
  addMessage,
  replaceMessage,
  removeMessage,
  setMessageReference,
  setMessageReferences,
  clearMessageReference,
}) {
  const [databaseAreaProcessed] = useState(databaseArea.replace('public_',''));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [searchValue, setSearchValue] = useState(search[databaseAreaProcessed]?.text || '');
  const [communityFilter, setCommunityFilter] = useState(filter?.type || search?.communities?.query?.type || 'areas');

  const buildQuery = useCallback(
    (s, f, da) => {
      const dap = da.replace('public_','');
      if(dap === 'users') {
        return {
          ...(search[dap].query),
          ...(f || {}),
          $or: [
            {name: {$regex: s, $options: 'i'}},
            {email: {$regex: s, $options: 'i'}},
          ],
        };
      } else if (dap === 'orders') {
        return  {
          ...(search[dap].query),
          ...(f || {}),
        };
      } else if(dap === 'organisations') {
        return {
          ...(f || {}),
          ...(search[dap]?.query || {}),
          name: {$regex: s, $options: 'i'},
        };
      } else if(dap === 'messages') {
        return {
          ...(f || {}),
          ...(search[dap]?.query || {}),
        };
      } else {
        return {
          ...(search[dap].query),
          ...(f || {}),
          name: {$regex: s, $options: 'i'},
        };
      }
    },
    [search],
  );

  const buildSort = useCallback(
    (da) => {
      const dap = da.replace('public_','');
      if(dap === 'orders' || dap === 'messages') {
        return {createdAt: -1};
      } else if(dap === 'events') {
        return {'locations.times.date': -1};
      } else {
        return {name: 1};
      }
    },
    [],
  );

  const addToStore = useCallback(
    (list, da) => {
      const dap = da.replace('public_','');
      if (dap === 'areas') {
        addAreas(list);
        setAreaReferences(list);
      } else if(dap === 'organisations') {
        addOrganisations(list);
        setOrganisationReferences(list);
      } else if(dap === 'categories') {
        addCategories(list);
        setCategoryReferences(list);
      } else if(dap === 'events') {
        addEvents(list);
        setEventReferences(list);
      } else if(dap === 'products') {
        addProducts(list);
        setProductReferences(list);
      } else if(dap === 'articles') {
        addArticles(list);
        setArticleReferences();
      } else if(dap === 'communities') {
        addCommunities(list);
        setCommunityReferences(list);
      } else if(dap === 'messages') {
        addMessages(list);
        setMessageReferences(list);
      }
    },
    [
      addAreas, setAreaReferences,
      addOrganisations, setOrganisationReferences,
      addCategories, setCategoryReferences,
      addEvents, setEventReferences,
      addProducts, setProductReferences,
      addArticles, setArticleReferences,
      addCommunities, setCommunityReferences,
      addMessages, setMessageReferences,
    ],
  )

  const replaceStore = useCallback(
    (list, da) => {
      const dap = da.replace('public_','');
      if(dap === 'areas') {
        setAreas(list);
        setAreaReferences(list);
      } else if(dap === 'organisations') {
        setOrganisations(list);
        setOrganisationReferences(list);
      } else if(dap === 'categories') {
        setCategories(list);
        setCategoryReferences(list);
      } else if(dap === 'events') {
        setEvents(list);
        setEventReferences(list);
      } else if(dap === 'products') {
        setProducts(list);
        setProductReferences(list);
      } else if(dap === 'articles') {
        setArticles(list);
        setArticleReferences(list);
      } else if(dap === 'communities') {
        setCommunities(list);
        setCommunityReferences(list);
      } else if(dap === 'messages') {
        setMessages(list);
        setMessageReferences(list);
      }
    },
    [
      setAreas, setAreaReferences,
      setOrganisations, setOrganisationReferences,
      setCategories, setCategoryReferences,
      setEvents, setEventReferences,
      setProducts, setProductReferences,
      setArticles, setArticleReferences,
      setCommunities, setCommunityReferences,
      setMessages, setMessageReferences,
    ],
  )

  const handleSearch = useCallback(
    (s, f, da, callback) => {
      setProcessing(true);
      const dap = da.replace('public_','');
      let query = undefined;
      if(dap === 'communities') {
        query = buildQuery(s, {
          ...f,
          type: communityFilter,
        }, da);
      } else {
        query = buildQuery(s, f, da);
      }
      const sort = buildSort(da);
      const skip = 0;

      apiRequest({type: 'get', action: da, data: {query, sort, skip, limit: queryLimit}})
      .then((result) => {
        setSearch({name: dap, value: {text: s, query, data: {usesQueryLimit: true},  queryDepth: 1, hasMore: result.data.hasMore}});
        replaceStore(result.data[dap], da);
        setProcessing(false);
        if(callback) {
          callback();
        }
      }).catch((error) => {
        setSearch({name: dap, value: {text: s, query, data: {usesQueryLimit: true},  queryDepth: 1, hasMore: false}});
        replaceStore([], da);
        setProcessing(false);
        if(callback) {
          callback();
        }
        setDialogData({
          type: 'message',
          title: `Fetch ${da} request denied`,
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    },
    [buildQuery, buildSort, replaceStore, setSearch, queryLimit, setProcessing, communityFilter],
  );

  const handleExpandSearch = useCallback(
    (f, da, callback) => {
      setProcessing(true);
      const dap = da.replace('public_','');
      const s = search[dap].text;

      const query = buildQuery(s, f, da);
      const sort = buildSort(da);
      const skip = (search[dap]?.queryDepth || 0) * queryLimit;

      apiRequest({type: 'get', action: da, data: {query, sort, skip, limit: queryLimit}})
      .then((result) => {
        setSearch({name: dap, value: {text: s, query, data: {usesQueryLimit: true},  queryDepth: search[dap].queryDepth + 1, hasMore: result.data.hasMore}});
        addToStore(result.data[dap], da);
        setProcessing(false);
        if(callback) {
          callback();
        }
      }).catch((error) => {
        setSearch({name: dap, value: {text: s, query, data: {usesQueryLimit: true},  queryDepth: search[dap].queryDepth + 1, hasMore: false}});
        setProcessing(false);
        if(callback) {
          callback();
        }
        setDialogData({
          type: 'message',
          title: `Fetch ${da} request denied`,
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    },
    [buildQuery, buildSort, addToStore, search, setSearch, queryLimit, setProcessing],
  );

  // first load
  const [searchLoaded, setSearchLoaded] = useState(false);
  useEffect(() => {
    if(search[databaseArea]?.text) {
      setSearchValue(search[databaseArea]?.text);
    }

    if(databaseArea === 'public_communities' && filter?.type && filter?.type !== communityFilter) {
      setCommunityFilter(filter?.type);
      setSearchLoaded(false);
      if(setInitalLoadComplete !== undefined) {
        setInitalLoadComplete(false);
      }
    }

    // inital load of search
    if(!searchLoaded) {
      setSearchLoaded(true);
      let searchValid = true;
      let searchMatch = true;

      // check if search is valid and if the current search is already loaded
      if(databaseArea === 'public_events') {
        if(filter && search?.events?.query) {
          if(!filter.createdByOrg && !filter.categories && !filter['locations.area']) searchValid = false;

          if(filter.createdByOrg && search?.events?.query?.createdByOrg !== filter.createdByOrg) {
            searchMatch = false;
          } else if(filter.categories && search?.events?.query?.categories !== filter.categories) {
            searchMatch = false
          } else if(filter['locations.area'] && search?.events?.query['locations.area'] !== filter['locations.area']) {
            searchMatch = false
          };
        } else {
          searchMatch = false;
        }
      } else if(databaseArea === 'public_products') {
        if(!filter?.organisationId) searchValid = false;
        if(search?.products?.query?.organisationId !== filter?.organisationId) {
          searchMatch = false;
        } 
      } else if (databaseArea === 'public_organisations') {
        if(filter && search?.organisations?.query && search?.organisations?.query['offices.area'] !== filter['offices.area']) searchMatch = false;
        if(initalFilter) {
          if(initalFilter['offices.area'] && search?.organisations?.query['offices.area'] !== initalFilter['offices.area']) searchMatch = false;
        }
        if(!filter && !initalFilter && search?.organisations?.query && search?.organisations?.query['offices.area']) searchMatch = false;
      } else if(databaseArea === 'public_communities') {
        if(filter?.type && filter?.type !== search?.communities?.query?.type) {
          searchMatch = false;
        }
      } else if(databaseArea === 'public_messages') {
        if(filter?.communityId && search?.messages?.query?.communityId !== filter.communityId) searchMatch = false;
        if(filter?.groupName && search?.messages?.query?.groupName !== filter.groupName) searchMatch = false;
      }

      if(searchValid && (!lists[databaseArea.replace('public_','')] || !searchMatch)) {
        const dap = databaseArea.replace('public_','');
        setSearch({name: dap, value: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true}});
        let f = undefined;
        if(filter && initalFilter) {
          f = {...filter, ...initalFilter};
        } else if(filter) {
          f = filter;
        } else if (initalFilter) {
          f = initalFilter;
        }
        handleSearch('', f, databaseArea, () => {
          if(setInitalLoadComplete !== undefined) {
            setInitalLoadComplete(true);
          }
        });
      } else {
        if(setInitalLoadComplete !== undefined) {
          setInitalLoadComplete(true);
        }
      }
    }
    // expand search
    if(expandSearch) {
      setExpandSearch(false);
      handleExpandSearch(filter, databaseArea)
    }
    // overide Search
    if(overRideSearch) {
      setOverRideSearch(false);
      setSearchValue('');
    }
  }, [
    lists,
    databaseArea,
    overRideSearch,
    setOverRideSearch,
    references,
    handleSearch,
    handleExpandSearch,
    communityFilter,

    filter,
    initalFilter,
    search,
    queryLimit,
    setSearch,
    searchLoaded,
    expandSearch,
    setInitalLoadComplete,
    setExpandSearch,
    setProcessing,

    setUsers,
    addUsers,
    setUserReferences,

    setAreas,
    addAreas,
    setAreaReferences,

    setOrganisations,
    addOrganisations,
    setOrganisationReferences,

    setCategories,
    addCategories,
    setCategoryReferences,

    setEvents,
    addEvents,
    setEventReferences,

    setProducts,
    addProducts,
    setProductReferences,

    setReviews,
    addReviews,
    setReviewReferences,

    setOrders,
    addOrders,
    setOrderReferences,

    setArticles,
    addArticles,
    setArticleReferences,
  ]);

  return (
    <div>
      {/*-------------------- search bar ----------------------*/}
      {databaseArea !== 'public_messages' &&
        <GridContainer style={{marginLeft: 5, marginRight: 5}}>
          <GridCell weight={1}>
            <form onSubmit={(e) => {
              e.preventDefault();
              handleSearch(searchValue, filter, databaseArea);
            }}>
              <Input
                name="searchValue"
                palette='secondary'
                label="Search"
                type="text"
                autoComplete="off"
                value={searchValue || ''}
                onChange={(value) => setSearchValue(value)}
              />
            </form>
          </GridCell>
          {databaseAreaProcessed === 'communities' && !filter.type &&
            <GridCell>
              <Select
                name="filter"
                palette='secondary'
                label="Filter"
                value={communityFilter}
                onChange={(value) => setCommunityFilter(value)}
              >
                <option value='areas'>Areas</option>
                <option value='categories'>Groups</option>
              </Select>
            </GridCell>
          }
          {search[databaseAreaProcessed]?.text !== '' &&
            <GridCell style={{marginTop: 11,marginRight: 5}}>
              <Fab
                size='small'
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setSearchValue('');
                  handleSearch('', filter, databaseArea);
                }}
              >
                <span className="material-icons md-24" style={{fontSize: 20}}>close</span>
              </Fab>
            </GridCell>
          }
          <GridCell style={{marginTop: 11}}>
            <Fab
              size='small'
              disabled={processing}
              palette='primary'
              onClick={() => handleSearch(searchValue, filter, databaseArea)}
            >
              <span className="material-icons md-24" style={{fontSize: 20}}>search</span>
            </Fab>
          </GridCell>
        </GridContainer>
      }

      {processing &&
        <div style={{marginLeft: 10, marginRight: 10, marginBottom: 5}}>
          <ProgressBar palette='secondary'/>
        </div>
      }
      {/*-----------------------taggs and categories -----------------------*/}
      {databaseAreaProcessed === 'organisations' &&
        <GridContainer style={{marginLeft: 10, marginRight: 10}}>
          <GridCell>
            <Fab
              size="small"
              disabled={processing || (filter && filter['offices.area'] !== undefined)}
              palette='primary'
              onClick={() => {
                if(search.areas?.text !== '' || !lists.areas?.length) {
                  setProcessing(true);
                  handleSearch('', {}, 'public_areas');
                }
                setDialogData({
                  type: 'changeArea',
                  title: 'Change Area',
                  value: {
                    search: search.areas?.text || '',
                  }
                });
                setDialogOpen(true);
              }}
            >
              <span className="material-icons md-24" style={{fontSize: 20}}>location_on</span>
            </Fab>
          </GridCell>
          <GridCell style={{marginLeft: 5}}>
            <Fab
              size="small"
              disabled={processing}
              palette='primary'
              onClick={() => {
                setDialogData({
                  type: 'addCategory',
                  title: 'Add Group',
                  value: {
                    search: '',
                  }
                });
                setDialogOpen(true);
              }}
            >
              <span className="material-icons md-24" style={{fontSize: 20}}>group</span>
            </Fab>
          </GridCell>
          {search.organisations?.query && search?.organisations?.query['offices.area'] &&
            <GridCell
              center={true}
              style={{margin: 5, padding: 5, background: '#c6c6c6', borderRadius: 5, cursor: 'pointer'}}
              onClick={() => {
                setProcessing(true);
                const queryProcessed = search.organisations.query;
                delete queryProcessed['offices.area'];
                setSearch({name: 'organisations', value: {text: '',  query: queryProcessed, data: {usesQueryLimit: true}, queryDepth: 1}});
                handleSearch(searchValue, filter, databaseArea);
              }}
            >
              <Typography size='subText'>
                {references?.areas && references.areas[search?.organisations?.query['offices.area']] ? references.areas[search?.organisations?.query['offices.area']].name : 'unnamed area'} X
              </Typography>
            </GridCell>
          }
          {search.organisations?.query?.categories?.$in?.length > 0 && search.organisations.query.categories.$in.map((c, cIndex) => (
            <GridCell key={cIndex} style={{margin: 5, padding: 5, background: '#c6c6c6', borderRadius: 5, cursor: 'pointer'}} onClick={() => {
              setProcessing(true);
              const queryProcessed = search.organisations.query;
              queryProcessed.categories.$in.splice(cIndex, 1);
              if(queryProcessed.categories.$in.length === 0) {
                delete queryProcessed.categories;
              }
              setSearch({name: 'organisations', value: {text: '',  query: queryProcessed, data: {usesQueryLimit: true}, queryDepth: 1}});
              if(queryProcessed.categories) {
                handleSearch(search.organisations.text || '', {...filter, categories: queryProcessed.categories}, 'public_organisations');
              } else {
                handleSearch(search.organisations.text || '', filter, 'public_organisations');
              }

            }}>
              <Typography size='subText'>
                {c} X
              </Typography>
            </GridCell>
          ))}
        </GridContainer>
      }
      {databaseAreaProcessed === 'products' &&
        <GridContainer style={{marginLeft: 10, marginRight: 10}}>

        </GridContainer>
      }
      {databaseAreaProcessed === 'events' &&
        <GridContainer style={{marginLeft: 5, marginRight: 5}}>
          {search?.events?.query?.createdByOrg &&
            <GridCell
              center={true}
              style={{margin: 5, padding: 5, background: '#c6c6c6', borderRadius: 5}}
            >
              <Typography size='subText'>
                {references.organisations[search?.events?.query?.createdByOrg]?.name || '...loading'}
              </Typography>
            </GridCell>
          }
          {search?.events?.query?.categories &&
            <GridCell
              center={true}
              style={{margin: 5, padding: 5, background: '#c6c6c6', borderRadius: 5}}
            >
              <Typography size='subText'>
                {references.categories[search?.events?.query?.categories]?.name || '...loading'}
              </Typography>
            </GridCell>
          }
          {search?.events?.query && search?.events?.query['locations.area'] &&
            <GridCell
              center={true}
              style={{margin: 5, padding: 5, background: '#c6c6c6', borderRadius: 5}}
            >
              <Typography size='subText'>
                {references.areas[search?.events?.query['locations.area']]?.name || '...loading'}
              </Typography>
            </GridCell>
          }
        </GridContainer>
      }

      {/*----------------------- popouts and popups -----------------------*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData?.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'addCategory' &&
            <div style={{padding: 10}}>
              <Input
                name="searchCategory"
                palette='secondary'
                label="Category"
                type="text"
                autoComplete="off"
                value={dialogData.value.search || ''}
                onChange={(value) => setDialogData({
                  ...dialogData,
                  value: {
                    ...(dialogData.value),
                    search: value,
                  }
                })}
              />
              {processing &&
                <div style={{marginLeft: 5, marginRight: 5}}>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {lists?.categories?.length > 0 && lists?.categories.filter((c, cIndex) => {
                return c.name.toLowerCase().includes(dialogData.value.search.toLowerCase());
              }).map((c, cIndex) => (
                <div key={cIndex} style={{padding: 10, margin: 5, borderStyle: 'solid', borderRadius: 5, borderWidth: 1, cursor: 'pointer'}} onClick={() => {
                  const queryProcessed = search.organisations.query;
                  if(queryProcessed?.categories?.$in?.length > 0) {
                    const duplicateTest = queryProcessed.categories.$in.findIndex((t, tIndex) => {
                      return c.name === t;
                    })
                    if(duplicateTest === -1) {
                      queryProcessed.categories.$in.push(c.name);
                    }
                  } else {
                    queryProcessed.categories = {$in: [c.name]}
                  }
                  setSearch({name: 'organisations', value: {text: '',  query: queryProcessed, data: {usesQueryLimit: true}, queryDepth: 1}});
                  handleSearch(searchValue || '', {...filter, categories: queryProcessed.categories}, databaseArea);
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}>
                  <Typography>{c.nameProper || c.name}</Typography>
                </div>
              ))}
            </div>
          }
          {dialogData?.type === 'changeArea' &&
            <div style={{padding: 10}}>
              <GridContainer>
                <GridCell weight={1}>
                  <Input
                    name="searchArea"
                    palette='secondary'
                    label="Area"
                    type="text"
                    autoComplete="off"
                    value={dialogData.value.search || ''}
                    onChange={(value) => setDialogData({
                      ...dialogData,
                      value: {
                        ...(dialogData.value),
                        search: value,
                      }
                    })}
                  />
                </GridCell>
                <GridCell style={{marginTop: 11}}>
                  <Fab disabled={processing} size='small' palette='primary' onClick={() => {
                    setProcessing(true);
                    handleSearch(dialogData.value.search, {}, 'public_areas');
                  }}>
                    <span className="material-icons md-24" style={{fontSize: 20}}>search</span>
                  </Fab>
                </GridCell>
              </GridContainer>
              {processing &&
                <div style={{marginLeft: 5, marginRight: 5}}>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {lists?.areas?.length > 0 && lists.areas.map((a, aIndex) => (
                <div key={aIndex} style={{padding: 10, margin: 5, borderStyle: 'solid', borderRadius: 5, borderWidth: 1, cursor: 'pointer'}} onClick={() => {
                  if(!processing) {
                    const queryProcessed = search.organisations.query;
                    queryProcessed['offices.area'] = a._id;
                    setSearch({name: 'organisations', value: {text: '',  query: queryProcessed, data: {usesQueryLimit: true}, queryDepth: 1}});
                    handleSearch(searchValue || '', {...filter, 'offices.area': a._id}, databaseArea);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }
                }}>
                  {a.nameProper || a.name}
                </div>
              ))}
              {lists?.areas?.length >= search?.areas?.queryDepth * queryLimit &&
                <Button palette='primary' onClick={() => {
                  handleExpandSearch({}, 'public_areas');
                }}>
                  Load More
                </Button>
              }
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

SearchStore.propTypes = {
  databaseArea: PropTypes.string,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  expandSearch: PropTypes.bool,
  setExpandSearch: PropTypes.func,
  setInitalLoadComplete: PropTypes.func,
  filter: PropTypes.shape({}),
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
    lists: state.lists,
    references: state.references,
  };
};

export default connect(mapStateToProps, {
  setSearch,

  setUsers,
  addUsers,
  addUser,
  replaceUser,
  removeUser,
  setUserReferences,
  setUserReference,
  clearUserReference,

  setAreas,
  addAreas,
  addArea,
  replaceArea,
  removeArea,
  setAreaReference,
  setAreaReferences,
  clearAreaReference,

  setOrganisations,
  addOrganisations,
  addOrganisation,
  replaceOrganisation,
  removeOrganisation,
  setOrganisationReference,
  setOrganisationReferences,
  clearOrganisationReference,

  setCategories,
  addCategories,
  addCategory,
  replaceCategory,
  removeCategory,
  setCategoryReference,
  setCategoryReferences,
  clearCategoryReference,

  setEvents,
  addEvents,
  addEvent,
  replaceEvent,
  removeEvent,
  setEventReference,
  setEventReferences,
  clearEventReference,

  setProducts,
  addProducts,
  addProduct,
  replaceProduct,
  removeProduct,
  setProductReference,
  setProductReferences,
  clearProductReference,

  setReviews,
  addReviews,
  addReview,
  replaceReview,
  removeReview,
  setReviewReference,
  setReviewReferences,
  clearReviewReference,

  setOrders,
  addOrders,
  addOrder,
  replaceOrder,
  removeOrder,
  setOrderReference,
  setOrderReferences,
  clearOrderReference,

  setArticles,
  addArticles,
  addArticle,
  replaceArticle,
  removeArticle,
  setArticleReference,
  setArticleReferences,
  clearArticleReference,

  setCommunities,
  addCommunities,
  addCommunity,
  replaceCommunity,
  removeCommunity,
  setCommunityReference,
  setCommunityReferences,
  clearCommunityReference,

  setMessages,
  addMessages,
  addMessage,
  replaceMessage,
  removeMessage,
  setMessageReference,
  setMessageReferences,
  clearMessageReference,
})(SearchStore);
