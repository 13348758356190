import React, {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown';

import Hidden from '../../../../components/hidden';
import {paletteData, Typography, ProgressBar, Select, TextArea, Fab, Button} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';
import apiRequest from '../../../../tools/apiRequest';

function Validation({communityId, communityType, queryLimit, setFocusedGroup, messageValidationCount, setMessageValidationCount}) {
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [messagesLoadComplete, setMessagesLoadComplete] = useState(false);
  const [messagesToValidate, setMessagesToValidate] = useState([]);
  const [moreMessagesToValidate, setMoreMessagesToValidate] = useState(true);
  const [queryDepth, setQueryDepth] = useState(0);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if(!messagesLoaded) {
      setMessagesLoaded(true);
      apiRequest({
        type: 'get',
        action: `messages/`,
        data: {
          query: {communityId, communityType, groupName: 'Community Messages', validation: 'unvalidated'},
          sort: {createdAt: 1},
          skip: 0,
          limit: queryLimit,
        }
      })
      .then((result) => {
        setMessagesToValidate(result.data.messages);
        setMoreMessagesToValidate(result.data.hasMore);
        setQueryDepth(1);
        setMessagesLoadComplete(true);
      }).catch((error) => {
        setMoreMessagesToValidate(false);
        setQueryDepth(1);
        setMessagesLoadComplete(true);
      });
    }
  }, [
    messagesLoaded,
    communityId,
    communityType,
    queryLimit,
  ]);

  const fetchMoreMessages = () => {
    setProcessing(true);
    const currentMessageIds = [];
    for(let i = 0; i < messagesToValidate.length; i += 1) {
      currentMessageIds.push(messagesToValidate[i]._id);
    }
    const query = {
      communityId,
      communityType,
      groupName: 'Community Messages',
      $or: [
        {_id: {$in: currentMessageIds}},
        {validation: 'unvalidated'},
      ]
    };
    const sort = {createdAt: 1};
    const skip = (queryDepth || 0) * queryLimit;
    apiRequest({
      type: 'get',
      action: `messages/`,
      data: {
        query,
        sort,
        skip,
        limit: queryLimit,
      }
    })
    .then((result) => {
      setMessagesToValidate([...messagesToValidate, ...result.data.messages]);
      setMoreMessagesToValidate(result.data.hasMore);
      setQueryDepth(queryDepth + 1);
      setProcessing(false);
    }).catch((error) => {
      setMoreMessagesToValidate(false);
      setQueryDepth(queryDepth + 1);
      setMessagesLoadComplete(false);
    });
  }

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{padding: 10, background: paletteData.primary.standard.background}}>
        <GridContainer>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
          <GridCell weight={10}>
            <GridContainer>
              <GridCell weight={1} center={true}>
                <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                  Validation Requests
                </Typography>
              </GridCell>
              <GridCell center={true}>
                <Fab palette='secondary' size='small' onClick={() => setFocusedGroup(undefined)}>
                  <span className="material-icons md-24">arrow_back</span>
                </Fab>
              </GridCell>
            </GridContainer>
          </GridCell>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
        </GridContainer>
      </div>
      <GridContainer>
        <Hidden breakpoint='hiddenlessthan650'>
          <GridCell weight={1}/>
        </Hidden>
        <GridCell weight={10}>
          {!messagesLoadComplete &&
            <ProgressBar palette='secondary'/>
          }
          {messagesToValidate?.length > 0 && messagesToValidate.map((m, mIndex) => (
            <div key={mIndex}>
              {mIndex === 0 && <hr/>}
              <Typography size="title" style={{color: paletteData.soft.standard.foreground}}>
                {m.groupName}
              </Typography>
              <Typography>
                <ReactMarkdown children={m.message} />
              </Typography>
              <Typography size='subText' style={{textAlign: 'right', color: '#c6c6c6'}}>
                {m.userAlius}
              </Typography>
              <Typography size='subText' style={{textAlign: 'right', color: '#c6c6c6'}}>
                {m.userContact}
              </Typography>
              <Typography size='subText' style={{textAlign: 'right', color: '#c6c6c6'}}>
                {(new Date(m.createdAt)).toDateString()}
              </Typography>
              <Select
                disabled={m.processed}
                label='Decision'
                value={m.decision}
                onChange={(value) => {
                  const messagesProcessed = messagesToValidate || [];
                  messagesProcessed[mIndex].decision = value;
                  setMessagesToValidate([...messagesProcessed]);
                }}
              >
                <option value='valid'>Accept</option>
                <option value='invalid'>Reject</option>
              </Select>
              {m.decision === 'invalid' &&
                <TextArea
                  disabled={m.processed}
                  label="Reason for rejecting"
                  value={m.reason}
                  onChange={(value) => {
                    const messagesProcessed = messagesToValidate || [];
                    messagesProcessed[mIndex].reason = value;
                    setMessagesToValidate([...messagesProcessed]);
                  }}
                />
              }
              <GridContainer>
                <GridCell weight={1}/>
                <GridCell>
                  <Button
                    palette='primary'
                    disabled={processing || m.processed || !m.decision || (m.decision === 'invalid' && !m.reason)}
                    onClick={() => {
                      setProcessing(true);
                      apiRequest({
                        type: 'patch',
                        action: `messages/validate/${m._id}`,
                        data: {
                          communityType,
                          communityId,
                          groupName: 'Community Messages',
                          decision: m.decision,
                          reason: m.reason,
                        }
                      })
                      .then((result) => {
                        setProcessing(false);
                        const messagesProcessed = messagesToValidate || [];
                        messagesProcessed[mIndex].processed = true;
                        setMessagesToValidate([...messagesProcessed]);
                        setMessageValidationCount(messageValidationCount - 1);
                      }).catch((error) => {
                        setProcessing(false);
                        console.log(error);
                      });
                    }}
                  >
                    Submit
                  </Button>
                  {processing && <ProgressBar palette='secondary'/>}
                </GridCell>
                {m.processed === true &&
                  <GridCell center={true} style={{marginLeft: 10}}>
                    <div style={{padding: 5, borderRadius: 30, background: 'green'}}>
                      <span className="material-icons md-24" style={{color: 'white', fontSize: '.8em'}}>done</span>
                    </div>
                  </GridCell>
                }
              </GridContainer>
              <hr/>
            </div>
          ))}
          {messagesToValidate?.length > 0 && moreMessagesToValidate &&
            <div>
              <Button disabled={processing} palette='primary' onClick={() => fetchMoreMessages()}>
                Load More Messages
              </Button>
              {processing && <ProgressBar palette='secondary'/>}
            </div>
          }
        </GridCell>
        <Hidden breakpoint='hiddenlessthan650'>
          <GridCell weight={1}/>
        </Hidden>
      </GridContainer>

      <br/><br/>
    </div>
  );
}

export default Validation;
