import React from 'react';
import PropTypes from 'prop-types';

import {Typography, paletteData} from '../../../../components/styles';

function MenuInformationSubPanel({
  focusedOrganisation,
}) {
  return (
    <div style={{padding: 10}}>
      <Typography style={{color: paletteData.primary.standard.foreground, textAlign: 'center'}}>
        {focusedOrganisation?.email}
      </Typography>
      <Typography style={{color: paletteData.primary.standard.foreground, textAlign: 'center'}}>
        {focusedOrganisation?.phone}
      </Typography>
    </div>
  );
};

MenuInformationSubPanel.propTypes = {
  focusedOrganisation: PropTypes.shape({}),
};

export default MenuInformationSubPanel;
