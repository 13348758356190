import React from 'react';

import Hidden from '../../../../components/hidden';
import {paletteData, Typography, Fab} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function Validation({setShowCodeOfConduct}) {
  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{padding: 10, background: paletteData.primary.standard.background}}>
        <GridContainer>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
          <GridCell weight={10}>
            <GridContainer>
              <GridCell weight={1} center={true}>
                <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                  Code of conduct
                </Typography>
              </GridCell>
              <GridCell center={true}>
                <Fab palette='secondary' size='small' onClick={() => setShowCodeOfConduct(false)}>
                  <span className="material-icons md-24">arrow_back</span>
                </Fab>
              </GridCell>
            </GridContainer>
          </GridCell>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
        </GridContainer>
      </div>
      <GridContainer>
        <Hidden breakpoint='hiddenlessthan650'>
          <GridCell weight={1}/>
        </Hidden>
        <GridCell weight={10}>
          <div style={{padding: 10}}>
            <Typography>
              Rerengatahi reserves the right to blacklist or ban any person from the community message boards for any
              reason but will generaly will impose a blacklist of ban for breaking our code of conduct as follows.
              <br/>
              <br/>
              <span style={{fontWeight: 600}}>When posting on rerengatahi message boards we expect you to</span><br/>
               - Be kind and considerate to others in the community<br/>
               - Keep on topic for the message board<br/>
               - Try to be concise and to the point<br/>
               - Avoid profanity<br/>
               - Avoid posting spam or scams
            </Typography>
          </div>
        </GridCell>
        <Hidden breakpoint='hiddenlessthan650'>
          <GridCell weight={1}/>
        </Hidden>
      </GridContainer>

    </div>
  );
}

export default Validation;
