import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {Typography, ProgressBar, Fab, Dialog, Button, Input, Select, paletteData} from '../../styles';
import {GridContainer, GridCell} from '../../grid';
import Hidden from '../../hidden';
import {setEventReference} from '../../../store/reducers/references/actions.js';

import apiRequest from '../../../tools/apiRequest';

function CalendarBlock({
  category,
  area,
  isMobile,

  setEventReference,
  categoryReferences,
  areaReferences,
  calendarReferences,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [month, setMonth] = useState(undefined);
  const [year, setYear] = useState(undefined);

  const [days, setDays] = useState(undefined);
  const [weeks, setWeeks] = useState(undefined);
  const [focusedDay, setFocusedDay] = useState(undefined);

  const [eventsProcessed, setEventsProcessed] = useState(undefined);
  useEffect(() => {
    if(!month || !year || !eventsProcessed) {
      setProcessing(true);
      setEventsProcessed(true);
      if(!month) setMonth((new Date().getMonth() + 1));
      if(!year) setYear((new Date()).getFullYear());

      const startTimestamp = `${
        (year || (new Date()).getFullYear())                        // year
      }-${
        (month || ((new Date()).getMonth() + 1)) < 10 ? '0' : ''    // leading 0 to month
      }${
        (month || ((new Date()).getMonth() + 1))                    // month
      }-${
        '0'                                                       // leading 0 to day
      }${
        '1'                                                       // day
      }T00:00:00.000Z`;

      const start = new Date(startTimestamp);
      const numberOfDays = new Date(start.getFullYear(), start.getMonth() + 1, 0).getDate();
      const endTimestamp = `${
        (year || (new Date()).getFullYear())                        // year
      }-${
        (month || ((new Date()).getMonth() + 1)) < 10 ? '0' : ''    // leading 0 to month
      }${
        (month || ((new Date()).getMonth() + 1))                   // month
      }-${
        (numberOfDays) < 10 ? '0' : ''                            // leading 0 to day
      }${
        numberOfDays                                              // day
      }T00:00:00.000Z`;
      const daysProcessed = [];

      //preload
      const startDay = start.getDay();
      if(startDay !== 0) {
        for(let i = 0; i < startDay; i += 1) {
          daysProcessed.push({
            date: undefined,
            events: [],
          });
        }
      }

      for(let i = 0; i < numberOfDays; i += 1) {
        daysProcessed.push({
          date: `${start.getFullYear()}-${(start.getMonth() + 1) < 10 ? '0' : ''}${start.getMonth() + 1}-${(i+1) < 10 ? '0' : ''}${i+1}T00:00:00.000Z`,
          events: [],
        });
      }

      //postLoad
      const weeks = Math.ceil(daysProcessed.length / 7);
      const leftover = (weeks * 7) - daysProcessed.length;

      if(leftover !== 0) {
        for(let i = 0; i < leftover; i += 1) {
          daysProcessed.push({
            date: undefined,
            events: [],
          });
        }
      }

      setDays(daysProcessed);
      setWeeks(weeks);

      apiRequest({type: 'get', action: 'public_events/dateRange', data: {start: startTimestamp, end: endTimestamp, category, area}})
      .then((result) => {
        const eventsToSplit = result.data.events;
        if(eventsToSplit?.length > 0) {
          for(let i = 0; i < eventsToSplit.length; i += 1) {
            const event = eventsToSplit[i];
            if(event.locations?.length > 0) {
              for(let j = 0; j < event.locations.length; j += 1) {
                const location = event.locations[j];
                if(location.times?.length > 0) {
                  for(let k = 0; k < location.times.length; k += 1) {
                    const time = location.times[k];
                    if(time.date) {
                      const dayIndex = daysProcessed.findIndex((d, dIndex) => {
                        if(d.date) {
                          return (d.date.split('T'))[0] === time.date.split('T')[0];
                        }
                        return false;
                      });
                      if(dayIndex !== -1) {
                        if(daysProcessed[dayIndex].events.findIndex((e, eIndex) => e._id === event._id) === -1) {
                          daysProcessed[dayIndex].events.push(event);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        setDays(daysProcessed);
        setWeeks(weeks);
        setProcessing(false)
      }).catch((error) => {
        setDays(daysProcessed);
        setWeeks(weeks);
        setProcessing(false)
      });
    }
  }, [
    month,
    year,
    eventsProcessed,
    area,
    category,
    calendarReferences,
  ]);

  const incrementMonth = () => {
    let monthProcessed = month + 1;
    let yearProcessed = year;
    if(monthProcessed > 12) {
      monthProcessed = 1;
      yearProcessed += 1;
    }
    setMonth(monthProcessed);
    setYear(yearProcessed);
    setFocusedDay(undefined);
    setEventsProcessed(false);
  }

  const decrementMonth = () => {
    let monthProcessed = month - 1;
    let yearProcessed = year;
    if(monthProcessed < 1) {
      monthProcessed = 12;
      yearProcessed -= 1;
    }
    setMonth(monthProcessed);
    setYear(yearProcessed);
    setFocusedDay(undefined);
    setEventsProcessed(false);
  }

  const setDate = (m, y) => {
    setMonth(m);
    setYear(y);
    setFocusedDay(undefined);
    setEventsProcessed(false);
  }

  if(redirect) {
    return <Redirect to={redirect} push={true} />
  }

  return (
    <div>
      <div style={{padding: 30, background: paletteData.primary.standard.background}}>
        <GridContainer>
          <GridCell>
            <Fab
              disabled={processing}
              palette='primary'
              onClick={() => decrementMonth()}
            >
              <span className="material-icons md-24">navigate_before</span>
            </Fab>
          </GridCell>
          <GridCell weight={1}/>
          <GridCell
            style={{cursor: processing ? 'auto' : 'pointer'}}
            onClick={() => {
              setDialogData({
                type: 'chooseDate',
                title: "Choose Date",
                value: {
                  month,
                  year,
                }
              });
              setDialogOpen(true);
            }}
          >
            <Typography
              size='title'
              style={{textAlign: 'center', color: paletteData.primary.standard.foreground}}
            >
              {
                month === 1 ? 'January' :
                month === 2 ? 'February' :
                month === 3 ? 'March' :
                month === 4 ? 'April' :
                month === 5 ? 'May' :
                month === 6 ? 'June' :
                month === 7 ? 'July' :
                month === 8 ? 'August' :
                month === 9 ? 'September' :
                month === 10 ? 'October' :
                month === 11 ? 'November' :
                month === 12 ? 'December' : ''
              }
            </Typography>
            <Typography size='title' style={{textAlign: 'center', color: paletteData.primary.standard.foreground}}>
              {year}
            </Typography>
          </GridCell>
          <GridCell weight={1}/>
          <GridCell>
            <Fab
              disabled={processing}
              palette='primary'
              onClick={() => incrementMonth()}
            >
              <span className="material-icons md-24">navigate_next</span>
            </Fab>
          </GridCell>
        </GridContainer>
      </div>
      {processing && <ProgressBar palette='secondary' />}
      <GridContainer>
        <GridCell weight={1} style={{padding: 10, background: '#c6c6c6'}}>
          <Typography style={{textAlign: 'center'}}>
            Su
          </Typography>
        </GridCell>
        <GridCell weight={1} style={{padding: 10, background: '#c6c6c6'}}>
          <Typography style={{textAlign: 'center'}}>
            Mo
          </Typography>
        </GridCell>
        <GridCell weight={1} style={{padding: 10, background: '#c6c6c6'}}>
          <Typography style={{textAlign: 'center'}}>
            Tu
          </Typography>
        </GridCell>
        <GridCell weight={1} style={{padding: 10, background: '#c6c6c6'}}>
          <Typography style={{textAlign: 'center'}}>
            We
          </Typography>
        </GridCell>
        <GridCell weight={1} style={{padding: 10, background: '#c6c6c6'}}>
          <Typography style={{textAlign: 'center'}}>
            Th
          </Typography>
        </GridCell>
        <GridCell weight={1} style={{padding: 10, background: '#c6c6c6'}}>
          <Typography style={{textAlign: 'center'}}>
            Fr
          </Typography>
        </GridCell>
        <GridCell weight={1} style={{padding: 10, background: '#c6c6c6'}}>
          <Typography style={{textAlign: 'center'}}>
            Sa
          </Typography>
        </GridCell>
      </GridContainer>
      {weeks > 0 && [...Array(weeks)].map((w, wIndex) => (
        <GridContainer key={wIndex}>
          {days?.length > 0 && days.filter((d, dIndex) => {
            return (dIndex >= wIndex * 7) && dIndex < (wIndex + 1) *7;
          }).map((d, dIndex) => (
            <GridCell
              key={dIndex}
              weight={1}
              style={{
                textAlign: 'center',
                padding: 10,
                background: d.events?.length > 0 ? '#515151' : '#dedede',
                cursor: d.date ? 'pointer' : undefined,
                borderStyle: 'solid',
                borderWidth: 2,
                borderColor: (focusedDay?.dIndex === dIndex && focusedDay?.wIndex === wIndex) ? paletteData.primary.standard.background : '#dedede',
              }}
              onClick={() => {
                if(d.date) {
                  setFocusedDay({dIndex, wIndex, events: d.events});
                }
              }}
            >
              <Typography style={{color: d.events?.length > 0 ? 'white' : 'black',}}>
                {d.date ? (new Date(d.date)).getDate() : ''}
              </Typography>
            </GridCell>
          ))}
        </GridContainer>
      ))}

      {focusedDay?.events?.length > 0 &&
        <div style={{padding: 5}}>
          <Hidden breakpoint='hiddenlessthan1024'>
            <div>
              {focusedDay.events.map((e, oIndex) => (
                <GridContainer
                  key={oIndex}
                  style={{
                    margin: 5,
                    cursor: processing ? 'auto' : 'pointer',
                  }}
                >
                  <GridCell
                    center={true}
                    style={{height: 200}}
                    onClick={() => {
                      if(!processing) {
                        setEventReference(e);
                        setRedirect(`/event/${e._id}`);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${e.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                      alt={e?.thumbnail?.alt}
                      style={{width: 200}}
                    />
                  </GridCell>
                  <GridCell
                    weight={1}
                    center
                    style={{textAlign: 'left', padding: 10, height: 180}}
                    onClick={() => {
                      if(!processing) {
                        setEventReference(e);
                        setRedirect(`/event/${e._id}`);
                      }
                    }}
                  >
                    <Typography size='title'>
                      {e.name || 'Unnamed Event'}
                    </Typography>
                    <Typography style={{color: '#333333'}}>
                      {e.tagline}
                    </Typography>
                    <Typography style={{marginTop: 5}}>
                      {e.blurb?.length > 350 ? `${e.blurb.substring(0, 350)}...` : e.blurb}
                    </Typography>
                  </GridCell>
                </GridContainer>
              ))}
            </div>
          </Hidden>

          <Hidden breakpoint='hiddengreaterthan1024'>
            <div>
              <GridContainer>
                {focusedDay.events.map((e, oIndex) => (
                  <GridCell
                    key={oIndex}
                    style={{
                      width: isMobile ? '100%' : undefined,
                      maxWidth: isMobile ? undefined : 200,
                      textAlign: 'center',
                      borderStyle: 'solid',
                      margin: 5,
                      borderWidth: 2,
                      borderRadius: 5,
                      borderColor: paletteData.primary.standard.background,
                      display: 'flex',
                      flexDirection: 'column',
                      cursor: processing ? 'auto' : 'pointer',
                    }}
                    onClick={() => {
                      if(!processing) {
                        setEventReference(e);
                        setRedirect(`/event/${e._id}`);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${e.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                      alt={e?.thumbnail?.alt}
                      style={{width: '100%'}}
                    />
                    <div style={{background: paletteData.primary.standard.background, padding: 10, flex: 1}}>
                      <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                        {e.name || 'Unnamed Event'}
                      </Typography>
                    </div>
                  </GridCell>
                ))}
              </GridContainer>
            </div>
          </Hidden>
        </div>
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'chooseDate' &&
            <div style={{padding: 10}}>
              <GridContainer>
                <GridCell weight={1}>
                  <Select
                    label='Month'
                    value={dialogData?.value?.month}
                    onChange={(value) => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          month: value,
                        }
                      })
                    }}
                  >
                    <option value={1}>January</option>
                    <option value={2}>February</option>
                    <option value={3}>March</option>
                    <option value={4}>April</option>
                    <option value={5}>May</option>
                    <option value={6}>June</option>
                    <option value={7}>July</option>
                    <option value={8}>August</option>
                    <option value={9}>September</option>
                    <option value={10}>October</option>
                    <option value={11}>November</option>
                    <option value={12}>December</option>
                  </Select>
                </GridCell>
                <GridCell weight={1}>
                  <Input
                    type='number'
                    label='Year'
                    value={dialogData?.value?.year}
                    onChange={(value) => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          year: value,
                        }
                      })
                    }}
                  />
                </GridCell>
              </GridContainer>

            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                   setDialogData(undefined);
                   setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'chooseDate' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={!dialogData?.value?.month || !dialogData?.value?.year || processing}
                  palette='primary'
                  onClick={() => {
                     setDate(parseInt(dialogData?.value?.month), parseInt(dialogData?.value?.year));
                     setDialogData(undefined);
                     setDialogOpen(false);
                  }}
                >
                  Choose
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

CalendarBlock.propTypes = {
  address: PropTypes.shape({}),
  handleChange: PropTypes.func,
  required: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    categoryReferences: state.references.categories || {},
    areaReferences: state.references.area || {},
    calendarReferences: state.references.calendar || {},
  };
};

export default connect(mapStateToProps, {setEventReference})(CalendarBlock);
