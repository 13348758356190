import React from 'react';
import PropTypes from 'prop-types';

import {Typography, Fab, MenuDropdown} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function MenuOrganisationSubPanel({
  me,
  focusedOrganisation,
  eventsAvailable,

  menuOpen,
  setEditing,
  setMenuOpen,
  setPage,

  setDialogData,
  setDialogOpen,
  setRedirect,
  urlHistory,
}) {
  return (
    <GridContainer style={{flex: 1}}>
      <GridCell weight={1}/>
      {focusedOrganisation?.layout?.multiPageEnabled && focusedOrganisation?.layout?.pages?.length > 0 &&
        <GridCell>
          <Fab
            palette='primary'
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span className="material-icons md-24">menu</span>
          </Fab>

          {/*Menu Dropdown*/}
          {menuOpen &&
            <MenuDropdown
              open={menuOpen}
              handleClose={() => setMenuOpen(false)}
              position={{}}
              style={{padding: 10, marginTop: 5, borderRadius: 5, zIndex: 100}}
            >
              <div style={{minWidth: 100}}>
                {focusedOrganisation?.layout?.multiPageEnabled &&  focusedOrganisation?.layout?.pages.map((p, pIndex) => (
                  <div
                    key={pIndex}
                    style={{padding: 5, textAlign: 'left', cursor: 'pointer'}}
                    onClick={() => {
                      setMenuOpen(false);
                      setPage(pIndex);
                    }}
                  >
                    <Typography>
                      {p.title}
                    </Typography>
                  </div>
                ))}
              </div>
            </MenuDropdown>
          }
        </GridCell>
      }
      {me?._id && (me?._id === focusedOrganisation?.owner || (me?.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker']))) &&
        <GridCell>
          <Fab
            palette='primary'
            onClick={() => setEditing(true)}
          >
            <span className="material-icons md-24">edit_square</span>
          </Fab>
        </GridCell>
      }
      {me?._id && (me?._id === focusedOrganisation?.owner || (me?.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker']))) &&
        <GridCell>
          <Fab
            palette='primary'
            onClick={() => setRedirect(`/products?organisation=${focusedOrganisation._id}`)}
          >
            <span className="material-icons md-24">storefront</span>
          </Fab>
        </GridCell>
      }
      {(eventsAvailable || (me?._id && (me?._id === focusedOrganisation?.owner || (me?.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker']))))) &&
        <GridCell>
          <Fab
            palette='primary'
            onClick={() => setRedirect(`/events?organisation=${focusedOrganisation._id}`)}
          >
            <span className="material-icons md-24">event</span>
          </Fab>
        </GridCell>
      }
      <GridCell>
        <Fab
          palette='primary'
          onClick={() => {
            setDialogData({
              type: 'review',
              title: `Review ${focusedOrganisation?.name}`,
              value: {}
            });
            setDialogOpen(true);
          }}
        >
          <span className="material-icons md-24" >star</span>
        </Fab>
      </GridCell>
      {focusedOrganisation?.social?.facebook &&
        <GridCell>
          <Fab
            palette='primary'
            href={focusedOrganisation?.social?.facebook}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src='https://res.cloudinary.com/taitokerau-tatou/image/upload/c_scale,q_auto:good,w_50/media-assets/Facebook_icon_aev1k6.png' alt='facebook logo' style={{width: 24, height: 24, marginLeft: 13, marginRight: 13}} />
          </Fab>
        </GridCell>
      }
      {focusedOrganisation?.social?.instagram &&
        <GridCell>
          <Fab
            palette='primary'
            href={focusedOrganisation?.social?.instagram}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src='https://res.cloudinary.com/taitokerau-tatou/image/upload/c_scale,q_auto:good,w_50/media-assets/2048px-Instagram_icon_havqzk.png' alt='instagram logo' style={{width: 24, height: 24, marginLeft: 13, marginRight: 13}} />
          </Fab>
        </GridCell>
      }
      {focusedOrganisation?.website &&
        <GridCell>
          <Fab
            palette='primary'
            href={focusedOrganisation?.website}
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className="material-icons md-24" >public</span>
          </Fab>
        </GridCell>
      }
      <GridCell>
        <Fab
          palette='primary'
          onClick={() => {
            urlHistory.goBack();
          }}
        >
          <span className="material-icons md-24" >arrow_back</span>
        </Fab>
      </GridCell>
      <GridCell weight={1}/>
    </GridContainer>
  );
};

MenuOrganisationSubPanel.propTypes = {
  area: PropTypes.shape({}),
};

export default MenuOrganisationSubPanel;
