const generateNavigationElement = ({id, text}) => {
  const section = {
    section: 'navigationElement',
    type: 'div',
    options: {
      id
    },
    text,
  };
  return section;
};

export default generateNavigationElement;
