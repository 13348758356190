import {combineReducers} from 'redux'

import authReducer from './auth';
import userReducer from './user';
import listReducer from './lists';
import cartReducer from './cart';
import referenceReducer from './references';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  lists: listReducer,
  cart: cartReducer,
  references: referenceReducer,
})

export default rootReducer
