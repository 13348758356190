import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {paletteData, textData} from '../../../styles';
import {GridContainer, GridCell} from '../../../grid';

function Input({onChange, onFocus, onBlur, palette, label, name, value, style, containerStyle, inputStyle, labelStyle, highlightStyle, indicatorStyle, autoComplete, disabled, forceShrink, postIcon, preIcon}) {
  const convertDate = (d) => {
    if(d && d instanceof Date) {
      let processYear = `${d.getFullYear()}`;
      const checkLength = 4 - processYear.length;
      if(checkLength > 0) {
        for(let i = 0; i < checkLength; i += 1) {
          processYear = `0${processYear}`;
        }
      }
      return `${processYear}-${(d.getMonth() + 1) < 10 ? '0' : ''}${d.getMonth() + 1}-${d.getDate() < 10 ? '0' : ''}${d.getDate()}`;
    } else if(d) {
      const dProcessed = new Date(d);
      if(dProcessed?.getTime() > 0) {
        return `${dProcessed.getFullYear()}-${(dProcessed.getMonth() + 1) < 10 ? '0' : ''}${dProcessed.getMonth() + 1}-${dProcessed.getDate() < 10 ? '0' : ''}${dProcessed.getDate()}`;
      }
    }
    return undefined;
  }

  const [inputValue, setInputValue] = useState(value ? convertDate(value) : '');
  const [focused, setFocused] = useState(false);
  const [highlightPosition, setHighlightPosition] = useState((focused) ? 'translateX(0%)' : 'translateX(-105%)');
  const [labelColor, setLabelColor] = useState((focused) ? paletteData[palette || 'blank'].highlight.foreground: paletteData[palette || 'blank'].standard.foreground);

  const [labelHeightInternal] = useState(25);
  const [labelSizeInternal] = useState('.8em');

  useEffect(() => {
    setHighlightPosition((focused) ? 'translateX(0%)' : 'translateX(-105%)');
    setLabelColor((focused) ? paletteData[palette || 'blank'].highlight.foreground: paletteData[palette || 'blank'].standard.foreground);

    if(!inputValue && value instanceof Date && !isNaN(value)) {
      setInputValue(convertDate(value));
    }
    
    const test = convertDate(value);
    if(test !== inputValue) {
      setInputValue(test);
    }
	}, [value, palette, focused, forceShrink, inputValue]);

  return (
    <div style={{padding: 5, marginTop: 1, ...style}}>
      <div style={{position: 'relative', width: '100%', overflow: 'hidden', minHeight: 45, ...containerStyle}}>
        <GridContainer>
          {preIcon !== undefined &&
            <GridCell>
              <div
                style={{cursor: (preIcon.callback !== undefined) ? 'pointer' : undefined}}
                onClick={() => {
                  if(preIcon.callback !== undefined) {
                    preIcon.callback();
                  }
                }}
              >
                {preIcon.display}
              </div>
            </GridCell>
          }
          <GridCell weight={1}>
            <input
              disabled={disabled || false}
              type='date'
              autoComplete={autoComplete}
              name={name}
              value={inputValue || ''}
              style={{
                width: '100%',
                height: '100%',
                paddingTop: 13,
                border: 'none',
                outline: 'none',
                fontSize: '1em',
                fontFamily: textData.text.fontFamily,
                color: paletteData[palette || 'blank'].standard.foreground,
                background: 'rgba(0, 0, 0, 0.0)',
                ...inputStyle,
              }}
              onFocus={() => {
                // update look when input focused
                setFocused(true);
                setHighlightPosition('translateX(0%)');
                setLabelColor(paletteData[palette || 'blank'].highlight.foreground);
                if(onFocus) {
                  onFocus();
                }
              }}
              onBlur={() => {
                // update look when input unfocused
                setFocused(false);
                setHighlightPosition('translateX(-105%)');
                setLabelColor(paletteData[palette || 'blank'].standard.foreground);
                if(onBlur) {
                  onBlur();
                }
              }}
              onChange={(e) => {
                setInputValue(e.target.value);
                if(onChange) {
                  onChange(new Date(e.target.value));
                }
              }}
            />
          </GridCell>
          {postIcon !== undefined &&
            <GridCell>
              <div
                style={{cursor: (postIcon.callback !== undefined) ? 'pointer' : undefined}}
                onClick={() => {
                  if(postIcon.callback !== undefined) {
                    postIcon.callback();
                  }
                }}
              >
                {postIcon.display}
              </div>
            </GridCell>
          }
        </GridContainer>

        <label htmlFor={name} style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          borderBottom: `1px solid ${paletteData[palette || 'blank'].standard.foreground}`,
          ...indicatorStyle,
        }}>
          <span
            style={{
              position: 'absolute',
              fontSize: labelSizeInternal,
              fontFamily: textData.text.fontFamily,
              color: labelColor,
              bottom: labelHeightInternal,
              left: 2,
              transition: '0.2s',
              ...labelStyle,
            }}
          >
            {label}
          </span>
        </label>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            borderBottom: `2px solid ${paletteData[palette || 'blank'].highlight.foreground}`,
            transition: '0.2s',
            transform: highlightPosition,
            ...highlightStyle,
          }}
        />
      </div>
    </div>

  );
}

Input.propTypes = {
  value: PropTypes.any,
  forceShrink: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  palette: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.shape({}),
  inputStyle: PropTypes.shape({}),
  containerStyle: PropTypes.shape({}),
  labelStyle:  PropTypes.shape({}),
  labelSize: PropTypes.string,
  labelHeight: PropTypes.string,
  highlightStyle: PropTypes.shape({}),
  indicatorStyle: PropTypes.shape({}),
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  forceText:  PropTypes.bool,
  preIcon: PropTypes.shape({
    display: PropTypes.node,
    callback: PropTypes.func,
  }),
  postIcon: PropTypes.shape({
    display: PropTypes.node,
    callback: PropTypes.func,
  }),
};

export default Input;
