import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Redirect, useParams, useHistory} from 'react-router-dom';
import {Helmet} from "react-helmet";
import {convertToRaw} from 'draft-js';
import {draftToMarkdown} from 'markdown-draft-js';
import ReactMarkdown from 'react-markdown';
import markdownToTxt from 'markdown-to-text';

import {Button, Fab, Typography, Dialog, ProgressBar, CheckBox, TextAreaRich, paletteData} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';
import {LayoutDisplay} from '../../..//components/layout';
import Map from '../../../components/map';
import Hidden from '../../../components/hidden';
import CalendarBlock from '../../../components/blocks/calendar';

import {mapStyle1, mapStyle2} from './mapStyles';
import {setOrganisations, addOrganisations, setSearch, setCategories, setMessages, addMessages, replaceArea} from '../../../store/reducers/lists/actions.js';
import {setAreaReference, setOrganisationReference, setOrganisationReferences, setMessageReferences, setMessageReference} from '../../../store/reducers/references/actions.js';
import apiRequest from '../../../tools/apiRequest';

function Area({
  me,
  myPosition,
  search,
  setSearch,
  isMobile,
  queryLimit,
  refreshCredentialsLoaded,

  categories,
  setCategories,

  areaReferences,
  setAreaReference,
  setOrganisationReference,
  setOrganisationReferences,
  replaceArea,

  organisations,
  setOrganisations,
  addOrganisations,

  messages,
  setMessages,
  addMessages,
  setMessageReferences,
  setMessageReference,
}) {
  // gloabal variables
  const {id} = useParams(); // areaId
  let history = useHistory();
  const [center] = useState({lat: -35.7274938, lng: 174.3165604}); // fallback for map
  const [page] = useState(0);
  const [isModerator, setIsModerator] = useState(undefined);

  // page state
  const [pointerEventsInfo, setPointerEventsInfo] = useState('auto');
  const [opacityInfo, setOpacityInfo] = useState(1);
  const [redirect, setRedirect] = useState(undefined);
  const [menuHidden, setMenuHidden] = useState(false);
  const [communityMessagesBlackList, setCommunityMessagesBlackList] = useState(undefined);

  // user feedback
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [processing, setProcessing] = useState(false);

  const [expandedMessages, setExpandedMessages] = useState({});
  const [messagesToValidate, setMessagesToValidate] = useState(0);
  const [eventsToValidate, setEventsToValidate] = useState(0);

  const [areaLoaded, setAreaLoaded] = useState(false);
  const [organisationsLoaded, setOrganisationsLoaded] = useState(false);
  const [markersLoaded, setMarkersLoaded] = useState(false);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [messagesLoadComplete, setMessagesLoadComplete] = useState(false);
  const [messageValidationLoaded, setMessageValidationLoaded] = useState(false);
  const [messageValidationLoadComplete, setMessageValidationLoadComplete] = useState(false);
  const [eventValidationLoaded, setEventValidationLoaded] = useState(false);
  const [eventValidationLoadComplete, setEventValidationLoadComplete] = useState(false)

  useEffect(() => {
    // fetch area
    if(!areaLoaded && areaReferences[id]) {
      setAreaLoaded(true);
    } else if(!areaLoaded) {
      setAreaLoaded(true);
      apiRequest({type: 'get', action: `public_areas/${id}`})
      .then((result) => {
        setAreaReference(result.data.area);
      }).catch((error) => {});
    }

    // fetch area organisations
    if(!organisationsLoaded && organisations?.length > 0 && search?.organisations?.query['offices.area'] === id) {
      setOrganisationsLoaded(true);
    } else if(!organisationsLoaded) {
      setOrganisationsLoaded(true);
      setProcessing(true);
      apiRequest({type: 'get', action: `public_organisations/`, data: {query: {'offices.area': id}, sort: {name: 1}, skip: 0, limit: queryLimit}})
      .then((result) => {
        setProcessing(false);
        setSearch({name: 'organisations', value: {text: '', query: {'offices.area': id}, data: {usesQueryLimit: true},  queryDepth: 1, hasMore: result.data.hasMore}});
        setOrganisations(result.data.organisations);
        setOrganisationReferences(result.data.organisations)
      }).catch((error) => {
        setProcessing(false);
        setSearch({name: 'organisations', value: {text: '', query: {'offices.area': id}, data: {usesQueryLimit: true},  queryDepth: 1, hasMore: false}});
      });
    }

    // fetch area markers
    if(areaReferences[id] && areaReferences[id].markers && !markersLoaded) {
      setMarkersLoaded(true);
    } else if(areaReferences[id] && !areaReferences[id].markers && !markersLoaded) {
      setMarkersLoaded(true)
      apiRequest({type: 'get', action: `public_organisations/markersForArea/${id}`})
      .then((result) => {
        const o = result.data.organisations;
        const m = [];
        for(let i = 0; i < o.length; i += 1) {
          const org = o[i];
          const offices = o[i].offices || [];
          for(let j = 0; j < offices.length; j+= 1) {
            if(offices[j].position?.lat && offices[j].position?.lng) {
              const marker = {
                position: offices[j].position,
                organisationId: o[i]._id,
                infoWindow: {
                  title: org.name,
                  text: org.blurb,
                }
              };
              m.push(marker);
            }
          }
        }
        const areaTemp = areaReferences[id];
        areaTemp.markers = m;
        setAreaReference(areaTemp);
      }).catch((error) => {
        const areaTemp = areaReferences[id];
        areaTemp.markers = [];
        setAreaReference(areaTemp);
      });
    }

    // fetch area community messages
    if(refreshCredentialsLoaded && areaLoaded && areaReferences[id] && !messagesLoaded && messages?.length > 0 && search?.messages?.query?.communityId === id && search?.messages?.query?.groupName === "Community Messages") {
      setMessagesLoaded(true);
      setMessagesLoadComplete(true);
    } else if(refreshCredentialsLoaded && areaLoaded && areaReferences[id] && !messagesLoaded) {
      setMessagesLoaded(true);
      apiRequest({
        type: 'get',
        action: `public_messages/`,
        data: {
          query: {communityId: id, communityType: 'area', groupName: 'Community Messages'},
          sort: {createdAt: -1},
          skip: 0,
          limit: queryLimit,
        }
      })
      .then((result) => {
        setSearch({name: 'messages', value: {text: '', query: {communityId: id, groupName: 'Community Messages', validation: 'valid'}, data: {usesQueryLimit: true},  queryDepth: 1, hasMore: result.data.hasMore}});
        setMessages(result.data.messages);
        setMessageReferences(result.data.messages);
        setMessagesLoadComplete(true);
      }).catch((error) => {
        setSearch({name: 'messages', value: {text: '', query: {communityId: id, groupName: 'Community Messages', validation: 'valid'}, data: {usesQueryLimit: true},  queryDepth: 1, hasMore: false}});
        setMessagesLoadComplete(true);
      });
    }

    // check if user is moderator
    if(refreshCredentialsLoaded && areaReferences[id] && me._id && isModerator === undefined) {
      if(areaReferences[id] && areaReferences[id]?.moderators?.findIndex((m, mIndex) => m === me._id) !== -1) {
        setIsModerator(true);
      } else if(me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) {
        setIsModerator(true);
      } else {
        setIsModerator(false);
      }
    }

    // if user is moderator check if message validation is required
    if(isModerator !== undefined && refreshCredentialsLoaded && me?._id && areaLoaded && areaReferences[id] && !messageValidationLoaded) {
      setMessageValidationLoaded(true);
      if(isModerator) {
        apiRequest({
          type: 'get',
          action: `messages/validationCount/${id}`,
          data: {}
        })
        .then((result) => {
          setMessageValidationLoadComplete(true);
          setMessagesToValidate(result.data.validationCount);
        }).catch((error) => {
          setMessageValidationLoadComplete(true);
        });
      }
    }

    // if user is moderator check if event validation is required
    if(isModerator !== undefined && refreshCredentialsLoaded && me?._id && areaLoaded && areaReferences[id] && !eventValidationLoaded) {
      setEventValidationLoaded(true);
      if(isModerator) {
        apiRequest({
          type: 'get',
          action: `events/validationCount/area/${id}`,
          data: {}
        })
        .then((result) => {
          setEventValidationLoadComplete(true);
          setEventsToValidate(result.data.validationCount);
        }).catch((error) => {
          setEventValidationLoadComplete(true);
        });
      }
    }

    // set up blacklist for community messages
    if(areaReferences[id] && !communityMessagesBlackList) {
      const cm = (areaReferences[id].community?.groups || []).find((g) => g.name === 'Community Messages');
      if(cm) {
        setCommunityMessagesBlackList(cm.blackList);
      } else {
        setCommunityMessagesBlackList({});
      }
    }
  }, [
    areaLoaded,
    areaReferences,
    id,
    setAreaReference,
    organisations,
    organisationsLoaded,
    setOrganisations,
    setOrganisationReferences,
    queryLimit,
    search,
    setSearch,
    markersLoaded,
    communityMessagesBlackList,
    eventValidationLoaded,
    isModerator,
    me,
    messageValidationLoaded,
    messages,
    messagesLoaded,
    refreshCredentialsLoaded,
    setMessageReferences,
    setMessages,
  ]);

  const handleInfoFadeOut = () => {
    //setTransitioning(true);
    setOpacityInfo(0);
    setPointerEventsInfo('none');
    //setTimeout(function(){
    //  setTransitioning(false);
    //}, 500);
  }

  const handleInfoFadeIn = () => {
    //setTransitioning(true);
    setPointerEventsInfo('auto');
    setOpacityInfo(1);
    //setTimeout(function(){
    //  setTransitioning(false);
    //}, 500);
  }

  if(redirect) {
    return <Redirect to={redirect} push={true} />
  }

  return (
    <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
      {areaReferences[id] &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rerengatahi - {areaReferences[id]?.name}</title>
          <meta
            name ='description'
            content={
              areaReferences[id]?.blurb ||
              "Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
            }
          />
          <meta
            name='keywords'
            content={
              areaReferences[id]?.keywords ||
              'social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
            }
          />
        </Helmet>
      }

      {/*--------- Buttons ------------*/}
      {!menuHidden && areaReferences[id] &&
        <div style={{
          position: 'absolute',
          top: 0,
          left: 20,
          zIndex: 21,
          margin: 10,
        }}>
          <Fab
            palette='primary'
            onClick={() => {
              if(opacityInfo) {
                handleInfoFadeOut();
              } else {
                handleInfoFadeIn();
              }
            }}
          >
            {opacityInfo ? <span className="material-icons md-24">place</span> : <span className="material-icons md-24">wrong_location</span>}
          </Fab>
        </div>
      }
      {!menuHidden && opacityInfo !== 0 &&
        <div style={{
          position: 'absolute',
          top: 0,
          right: 20,
          zIndex: 21,
          margin: 10,
        }}>
          <GridContainer style={{background: paletteData.primary.standard.background, borderRadius: 25}}>
            {isModerator && areaReferences[id] &&
              <GridCell>
                <Fab
                  palette='primary'
                  onClick={() => {
                    setDialogData({
                      type: 'updateCommunitySettings',
                      title: "Update Community Settings",
                      value: {
                        ...(areaReferences[id]?.community || {})
                      }
                    });
                    setDialogOpen(true);
                  }}
                >
                  <span className="material-icons md-24">edit</span>
                </Fab>
              </GridCell>
            }
            {isModerator && areaReferences[id]?.community?.messagesEnabled && messageValidationLoadComplete &&
              <GridCell>
                <Fab
                  count={messagesToValidate}
                  palette='primary'
                  onClick={() => setRedirect(`/messages/area/${id}`)}
                >
                  <span className="material-icons md-24">checklist</span>
                </Fab>
              </GridCell>
            }
            {isModerator && areaReferences[id]?.community?.eventsEnabled && eventValidationLoadComplete &&
              <GridCell>
                <Fab
                  count={eventsToValidate}
                  palette='primary'
                  onClick={() => setRedirect(`/events?area=${id}`)}
                >
                  <span className="material-icons md-24">event</span>
                </Fab>
              </GridCell>
            }
            <GridCell>
              <Fab
                palette='primary'
                onClick={() => history.goBack()}
              >
                <span className="material-icons md-24">arrow_back</span>
              </Fab>
            </GridCell>
          </GridContainer>
        </div>
      }

      {/*---------- Main Body ---------*/}
      <div style={{
        pointerEvents: pointerEventsInfo,
        display: opacityInfo === 0 ? 'none' : 'initial',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20,
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        background: 'rgba(255, 255, 255, 0.85)',
        overflowX: 'hidden',
      }}>
        {/*---------- fallback if area not loaded -------------*/}
        {!areaReferences[id] &&
          <GridContainer style={{height: '100vh'}}>
            <GridCell weight={1}/>
            <GridCell center={true} centerWeights={{top: 2, bottom: 3}}>
              <Typography size='title' style={{textAlign: 'center'}}>
                ...Loading
              </Typography>
              <ProgressBar palette='secondary'/>
            </GridCell>
            <GridCell weight={1}/>
          </GridContainer>
        }
        {/*----------  area loaded but no layout -------------------*/}
        {(!areaReferences[id]?.layout?.pages || !areaReferences[id]?.layout?.pages[page]?.sections || areaReferences[id]?.layout?.pages[page]?.sections?.length === 0) &&
          <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100%'}}>
            <div style={{
              background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundBlendMode: 'lighten',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              backgroundAttachment: 'fixed',
              display: 'flex', flexDirection: 'column', flex: 1,
            }}>
              <GridContainer style={{flex: 1}}>
                <GridCell weight={1}/>
                <GridCell weight={1} center={true} centerWeights={{top: 2, bottom: 3}}>
                  <Typography size='heading' style={{letterSpacing: 2.3, fontWeight: 700, textAlign: 'center'}}>
                    {areaReferences[id]?.name}
                  </Typography>
                  <Typography style={{textAlign: 'center'}}>
                    {areaReferences[id]?.blurb}
                  </Typography>
                </GridCell>
                <GridCell weight={1}/>
              </GridContainer>
            </div>
          </div>
        }
        {/*----------  area loaded with layout -------------------*/}
        {areaReferences[id]?.layout?.pages?.length > 0 &&
         areaReferences[id]?.layout?.pages[page]?.sections?.length > 0 &&
         areaReferences[id]?.layout?.pages[page]?.sections.map((b, bIndex) => (
           <LayoutDisplay
            key={bIndex}
            block={b}
            onClick={() => {}}
            onCallback={(name, value) => {
              if(name === 'galeryOpenEvent') {
                setMenuHidden(value);
              }
            }}
          />
         ))}
        {areaReferences[id] &&
          <div>
            <div style={{background: paletteData.primary.standard.background, padding: 10}}>
              <GridContainer>
                <Hidden breakpoint='hiddenlessthan650'>
                  <GridCell weight={1}/>
                </Hidden>
                <GridCell weight={10}>
                  <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                    ORGANISATIONS IN OUR AREA
                  </Typography>
                </GridCell>
                <Hidden breakpoint='hiddenlessthan650'>
                  <GridCell weight={1}/>
                </Hidden>
              </GridContainer>
            </div>
            {processing &&
              <GridContainer>
                <Hidden breakpoint='hiddenlessthan650'>
                  <GridCell weight={1}/>
                </Hidden>
                <GridCell weight={10}>
                  <div style={{padding: 5}}>
                    <Typography size='title' style={{textAlign: 'center', marginBottom: 5}}>
                      ...loading
                    </Typography>
                    <ProgressBar palette='secondary'/>
                  </div>
                </GridCell>
                <Hidden breakpoint='hiddenlessthan650'>
                  <GridCell weight={1}/>
                </Hidden>
              </GridContainer>
            }
          </div>
        }
        {!processing && areaReferences[id] && (!organisations || organisations.length === 0) &&
          <GridContainer>
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell weight={1}/>
            </Hidden>
            <GridCell weight={10}>
              <div style={{padding: 5}}>
                <Typography size='title' style={{textAlign: 'center'}}>
                  No organisations in this area
                </Typography>
                <br/><br/>
              </div>
            </GridCell>
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell weight={1}/>
            </Hidden>
          </GridContainer>
        }
        {areaReferences[id] && organisations?.length > 0 &&
          <div>
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10} style={{padding: 10}}>
                {isMobile && !processing &&
                  <div>
                    {organisations.slice(0, 2).map((o, oIndex) => (
                      <div
                        key={oIndex}
                        style={{padding: 5, cursor: 'pointer'}}
                        onClick={() => setRedirect(`/organisation/${o._id}`)}
                      >
                        <img
                          style={{textAlign: 'center', width: '100%'}}
                          src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${o.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                          alt='thumbnail'
                        />
                        <Typography size='title'>
                          {o.name}
                        </Typography>
                        <Typography>
                          {o.blurb}
                        </Typography>
                        <br/>
                      </div>
                    ))}
                  </div>
                }
                {!isMobile && !processing &&
                  <GridContainer>
                    {organisations.slice(0, 4).map((o, oIndex) => (
                      <GridCell
                        key={oIndex}
                        weight={1}
                        style={{cursor: 'pointer', minWidth: 200}}
                        onClick={() => setRedirect(`/organisation/${o._id}`)}
                      >
                        <div style={{padding: 10}}>
                          <img
                            style={{textAlign: 'center', width: '100%'}}
                            src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${o.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                            alt='thumbnail'
                          />
                          <Typography size='title' style={{textAlign: 'center'}}>
                            {o.name}
                          </Typography>
                        </div>

                      </GridCell>
                    ))}
                    {organisations.length < 2 && <GridCell weight={1}/>}
                    {organisations.length < 3 && <GridCell weight={1}/>}
                    {organisations.length < 4 && <GridCell weight={1}/>}
                  </GridContainer>
                }
                <br/>
                <GridContainer>
                  <GridCell weight={1}/>
                  <GridCell>
                    <Button palette='primary' onClick={() => setRedirect(`/organisations?area=${id}`)}>
                      More Organisations in our area
                    </Button>
                  </GridCell>
                </GridContainer>
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
            <br/><br/>
          </div>
        }

        {areaReferences[id]?.community?.messagesEnabled &&
          <div>
            <div style={{background: paletteData.primary.standard.background, padding: 10}}>
              <GridContainer>
                <Hidden breakpoint='hiddenlessthan650'>
                  <GridCell weight={1}/>
                </Hidden>
                <GridCell weight={10}>
                  <GridContainer>
                    <GridCell weight={1} center={true}>
                      <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                        COMMUNITY MESSAGES
                      </Typography>
                    </GridCell>
                    {communityMessagesBlackList && !communityMessagesBlackList[me?._id] &&
                      <GridCell>
                        <Fab
                          size='small' palette='secondary'
                          onClick={() => {
                            if(me?._id && me.name && me.email && me?.verification?.contact && me.verification.contact[me.email] === true && communityMessagesBlackList && !communityMessagesBlackList[me._id]) {
                              setDialogData({
                                type: 'submitCommunityMessage',
                                title: "Submit a community message",
                                message: 'Messages on this message board are screened by our volinteer moderators,  if you are interested in becoming a moderator for this community please contact info@rerengatahi.nz. This message board is tagged as high visibility by the moderator.  This means that your email address will be attached to all messages you post on this message board.',
                                value: {}
                              });
                              setDialogOpen(true);
                            } else if(communityMessagesBlackList && communityMessagesBlackList[me._id]) {
                              setDialogData({
                                type: 'message',
                                title: "Submit a community message",
                                message: 'You have been blocked from submitting community messages by a moderator. If you beleive this has been done in error please contact info@rerengatahi.nz.',
                              });
                              setDialogOpen(true);
                            } else if(me?._id) {
                              setDialogData({
                                type: 'message',
                                title: "Submit a community message",
                                message: 'Your email must be verified to submit a community message',
                              });
                              setDialogOpen(true);
                            } else {
                              setDialogData({
                                type: 'message',
                                title: "Submit a community message",
                                message: 'You must be logged in with a verified email address to submit a community message',
                              });
                              setDialogOpen(true);
                            }
                          }}
                        >
                          <span className="material-icons md-24" >add</span>
                        </Fab>
                      </GridCell>
                    }
                  </GridContainer>

                </GridCell>
                <Hidden breakpoint='hiddenlessthan650'>
                  <GridCell weight={1}/>
                </Hidden>
              </GridContainer>
            </div>
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10}>
                {!messagesLoadComplete &&
                  <div>
                    <Typography size='title' style={{textAlign: 'center', marginBottom: 5}}>
                      ...loading
                    </Typography>
                    <ProgressBar palette='secondary'/>
                  </div>
                }
                {messagesLoadComplete && areaReferences[id] && (!messages || messages?.length === 0) &&
                  <div style={{padding: 5}}>
                    <Typography>
                      Have an important message for the {areaReferences[id]?.name} community?  Submit a community message by clicking the button below.
                    </Typography>
                    <br/>
                  </div>
                }
                {messagesLoadComplete && messages?.length > 0 && messages.slice(0, 5).map((m, mIndex) => (
                  <div key={mIndex}>
                    {mIndex === 0 && <hr/>}
                    <div
                      style={{
                        background: (m.validation === 'invalid' || m.validation === 'unvalidated') ? '#c6c6c6' : undefined,
                        padding: (m.validation === 'invalid' || m.validation === 'unvalidated') ? 10 : undefined,
                      }}
                    >
                      {isModerator &&
                        <div>
                          {m.validation === 'invalid' &&
                            <Typography style={{color: 'red'}}>
                              Message Blocked
                            </Typography>
                          }
                          {m.validation === 'valid' &&
                            <Typography style={{color: 'green'}}>
                              Message Visible
                            </Typography>
                          }
                          {m.validation === 'unvalidated' &&
                            <Typography style={{color: 'red'}}>
                              Message Pending
                            </Typography>
                          }
                        </div>
                      }
                      {!expandedMessages[m._id] &&
                        <Typography style={{whiteSpace: 'pre-wrap'}}>
                          {markdownToTxt(m.message).substring(0, 500)}... <span
                            onClick={() => {
                              setExpandedMessages({
                                ...expandedMessages,
                                [m._id]: true,
                              });
                            }}
                            style={{
                              color: 'red',
                              textDecoration: 'underline',
                              cursor: 'pointer'
                            }}
                          >
                            READ MORE
                          </span>
                        </Typography>
                      }
                      {expandedMessages[m._id] &&
                        <Typography>
                          <ReactMarkdown children={m.message} />
                          <span
                            onClick={() => {
                              setExpandedMessages({
                                ...expandedMessages,
                                [m._id]: false,
                              });
                            }}
                            style={{
                              color: 'red',
                              textDecoration: 'underline',
                              cursor: 'pointer'
                            }}
                          >
                            SHOW LESS
                          </span>
                        </Typography>
                      }
                      <Typography size='subText' style={{textAlign: 'right', color: (m.validation === 'invalid' || m.validation === 'unvalidated') ? 'black' : '#c6c6c6'}}>
                        {m.userAlius}
                      </Typography>
                      <Typography size='subText' style={{textAlign: 'right', color: (m.validation === 'invalid' || m.validation === 'unvalidated') ? 'black' : '#c6c6c6'}}>
                        {m.userContact}
                      </Typography>
                      <Typography size='subText' style={{textAlign: 'right', color: (m.validation === 'invalid' || m.validation === 'unvalidated') ? 'black' : '#c6c6c6'}}>
                        {(new Date(m.createdAt)).toDateString()}
                      </Typography>
                    </div>
                    <hr/>
                  </div>
                ))}
                <br/>
                <GridContainer>
                  <Hidden breakpoint='hiddenlessthan650'>
                    <GridCell weight={1}/>
                  </Hidden>
                  <GridCell style={{padding: 5}}>
                    <Button  palette='primary' onClick={() => setRedirect(`/messages/area/${id}`)}>
                      {areaReferences[id].name} Community Area
                    </Button>
                  </GridCell>
                </GridContainer>
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
            <br/><br/>
          </div>
        }
        {areaReferences[id]?.community?.eventsEnabled &&
          <div>
            <div style={{background: paletteData.primary.standard.background, padding: 10}}>
              <GridContainer>
                <Hidden breakpoint='hiddenlessthan650'>
                  <GridCell weight={1}/>
                </Hidden>
                <GridCell weight={10}>
                  <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                    UPCOMING EVENTS
                  </Typography>
                </GridCell>
                <Hidden breakpoint='hiddenlessthan650'>
                  <GridCell weight={1}/>
                </Hidden>
              </GridContainer>
            </div>
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10}>
                <br/>
                <CalendarBlock
                  area={areaReferences[id]?._id}
                />
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
            <br/><br/>
          </div>
        }
        <br/><br/><br/>
      </div>

      {/*----------- map background -------------*/}
      {areaReferences[id]?.markers?.length > 0 &&
        <Map
          linkToOrganisation={true}
          style={{width: '100%', flex: 1}}
          center={areaReferences[id]?.position || myPosition || center}
          zoom={15}
          markers={areaReferences[id]?.markers || []}
          options={{
            styles: opacityInfo ? mapStyle1 : mapStyle2,
            disableDefaultUI: true,
          }}
        />
      }

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message && dialogData.type !== 'reviewCommunityMessage' &&
            <div style={{padding: 10}}>
              <Typography style={{whiteSpace: 'pre-wrap'}}>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'updateCommunitySettings' &&
            <div style={{padding: 10}}>
              <CheckBox
                label="Community Messages"
                value={dialogData?.value?.messagesEnabled}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      messagesEnabled: value,
                    }
                  });
                }}
              />
              <CheckBox
                label="Events"
                value={dialogData?.value?.eventsEnabled}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      eventsEnabled: value,
                    }
                  });
                }}
              />
            </div>
          }
          {dialogData?.type === 'submitCommunityMessage' &&
            <div style={{padding: 10}}>
              <TextAreaRich
                title='Message'
                palette='primary'
                editorState={dialogData?.value?.markdownState || undefined}
                markdown={dialogData?.value?.markdown || ''}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      markdownState: value,
                      markdown: draftToMarkdown(convertToRaw(value.getCurrentContent())),
                    }
                  });
                }}
              />
            </div>
          }
          {dialogData?.type === 'reviewCommunityMessage' &&
            <div style={{padding: 10}}>
              <hr />
              <div>
                {dialogData?.value?.full &&
                  <Typography>
                    <ReactMarkdown children={dialogData?.value?.markdown} />
                    <span
                      onClick={() => {
                        setDialogData({
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            full: false,
                          }
                        });
                      }}
                      style={{color: 'red', textDecoration: 'underline', cursor: 'pointer'}}
                    >
                      SHOW LESS
                    </span>
                  </Typography>
                }
                {!dialogData?.value?.full &&
                  <Typography style={{whiteSpace: 'pre-wrap'}}>
                    {markdownToTxt(dialogData?.value?.markdown).substring(0, 500)}... <span onClick={() => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          full: true,
                        }
                      });
                    }} style={{color: 'red', textDecoration: 'underline', cursor: 'pointer'}}>READ MORE</span>
                  </Typography>
                }
                <Typography size='subText' style={{textAlign: 'right', color: '#c6c6c6'}}>
                  {me.name}<br/>
                  {me.email}<br/>
                  {(new Date()).toDateString()}
                </Typography>
              </div>
              <hr/>
              <br/>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'updateCommunitySettings' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                     setProcessing(true);
                     const updateCommunity = {
                       community: {
                         messagesEnabled: dialogData?.value?.messagesEnabled,
                         eventsEnabled: dialogData?.value?.eventsEnabled,
                         groupsEnabled: dialogData?.value?.groupsEnabled,
                         groups: dialogData?.value?.groups || [{
                           name: 'Community Messages',
                           postAuthority: 'moderatorValidation',
                           visibility: 'public',
                           transparency: 'transparent',
                           blackList: {},
                           whiteList: {},
                         }],
                       }
                     };
                     apiRequest({type: 'patch', action: `communities/updateCommunitySettings/${id}`, data: {updateCommunity, type: 'area'}})
                     .then((result) => {
                       setAreaReference({_id: id, ...areaReferences[id], ...updateCommunity})
                       replaceArea({area: {_id: id, ...areaReferences[id], ...updateCommunity}});
                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     }).catch((error) => {
                       setDialogData(undefined);
                       setDialogOpen(false);
                       setProcessing(false);
                     });
                  }}
                >
                  Submit
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
            {dialogData?.type === 'submitCommunityMessage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setDialogData({
                      ...dialogData,
                      title: 'Review Community Message',
                      type: 'reviewCommunityMessage',
                    })
                  }}
                >
                  Review
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'reviewCommunityMessage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setDialogData({
                      ...dialogData,
                      title: 'Submit a community message',
                      type: 'submitCommunityMessage',
                      value: {
                        ...(dialogData?.value || {}),
                        full: false,
                      }
                    })
                  }}
                >
                  Edit
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'reviewCommunityMessage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    const updateMessage = {
                      communityId: id,
                      groupName: 'Community Messages',
                      userId: me._id,
                      userAlius: me.name,
                      userContact: me.email,
                      message: dialogData?.value?.markdown,
                    }
                    apiRequest({type: 'post', action: `messages/create`, data: {updateMessage, communityType: 'area', communityId: id, groupName: 'Community Messages'}})
                    .then((result) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Community Message',
                        message: 'Your message has been submitted, a moderator will review it before it goes live.  If you have any questions please contact info@rerengatahi.nz',
                      });
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    });
                  }}
                >
                  Submit
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    areaReferences: state.references.areas || {},
    search: state.lists.search,
    organisations: state.lists.organisations,
    categories: state.lists.categories,
    queryLimit: state.lists.queryLimit,
    messages: state.lists.messages,
  };
};

export default connect(mapStateToProps, {
  setAreaReference,
  setOrganisationReference,
  setOrganisationReferences,
  setOrganisations,
  addOrganisations,
  setSearch,
  setCategories,
  setMessages,
  addMessages,
  setMessageReferences,
  setMessageReference,
  replaceArea,
})(Area);
