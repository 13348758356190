import React, {useEffect, useState, createRef} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import ReactMarkdown from 'react-markdown';

import apiRequest from '../../../tools/apiRequest';
import {setOrganisationReference, setProductReference} from '../../../store/reducers/references/actions.js';
import {replaceOrganisation, replaceProduct} from '../../../store/reducers/lists/actions.js';
import {addProductCart} from '../../../store/reducers/cart/actions.js';

import {paletteData, Button, Typography, Dialog, ProgressBar, Input, TextArea} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';
import handleErrorMessage from '../../../tools/handleErrorMessage';
import Hidden from '../../../components/hidden';
import Gallery from '../../../components/gallery';

import MenuProductSubPanel from './menuProduct';
import MenuEditSubPanel from './menuEdit';
import EditProductInformation from './EditProductInformation';
import EditProductLayout from './EditProductLayout';
import EditProductListing from './EditProductListing';

function Product({
  me,
  productReferences,
  organisationReferences,
  
  addProductCart,
  cart,
  isMobile,

  setProductReference,
  replaceProduct,
  setOrganisationReference,
  replaceOrganisation,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [tab, setTab] = useState('layout')
  const [focusedProduct, setFocusedProduct] = useState(undefined);
  const [imageUploadRef] = useState(createRef());

  const [storeLocations, setStoreLocations] = useState(undefined);
  const [onlineOrderValid, setOnlineOrderValid] = useState(undefined);

  const {id} = useParams();
  const urlHistory = useHistory();

  useEffect(() => {
    if(!productReferences[id]) {
      apiRequest({type: 'get', action: `public_products/${id}`})
      .then((result) => {
        const product = result.data.product;
        setProductReference(product);

        if(!organisationReferences[product.organisationId]) {
          apiRequest({type: 'get', action: `public_organisations/${product.organisationId}`})
          .then((result) => {
            const org = result.data.organisation;
            setOrganisationReference(org);
            const storeLocationsProcessed = [];
            if(org.offices.length > 0) {
              for(let i = 0; i < org.offices.length; i += 1) {
                if(org.offices[i]?.position?.lat && org.offices[i]?.position?.lng) {
                  storeLocationsProcessed.push(org.offices[i]);
                }
              }
            }
            setStoreLocations(storeLocationsProcessed);
          }).catch((error) => {
          });
        } else if(!storeLocations) {
          const org = organisationReferences[product.organisationId];
          const storeLocationsProcessed = [];
          if(org.offices.length > 0) {
            for(let i = 0; i < org.offices.length; i += 1) {
              if(org.offices[i]?.position?.lat && org.offices[i]?.position?.lng) {
                storeLocationsProcessed.push(org.offices[i]);
              }
            }
          }
          setStoreLocations(storeLocationsProcessed);
        }
      }).catch((error) => {
      });
    } else {
      if(!organisationReferences[productReferences[id]?.organisationId]) {
        apiRequest({type: 'get', action: `public_organisations/${productReferences[id]?.organisationId}`})
        .then((result) => {
          const org = result.data.organisation;
          setOrganisationReference(org);
          const storeLocationsProcessed = [];
          if(org.offices.length > 0) {
            for(let i = 0; i < org.offices.length; i += 1) {
              if(org.offices[i]?.position?.lat && org.offices[i]?.position?.lng) {
                storeLocationsProcessed.push(org.offices[i]);
              }
            }
          }
          setStoreLocations(storeLocationsProcessed);
        }).catch((error) => {
        });
      } else if(!storeLocations) {
        const org = organisationReferences[productReferences[id]?.organisationId];
        const storeLocationsProcessed = [];
        if(org.offices.length > 0) {
          for(let i = 0; i < org.offices.length; i += 1) {
            if(org.offices[i]?.position?.lat && org.offices[i]?.position?.lng) {
              storeLocationsProcessed.push(org.offices[i]);
            }
          }
        }
        setStoreLocations(storeLocationsProcessed);
      }

      if(productReferences[id] && organisationReferences[productReferences[id]?.organisationId] && onlineOrderValid === undefined) {
        if(!organisationReferences[productReferences[id]?.organisationId]?.bank || 
           !organisationReferences[productReferences[id]?.organisationId]?.stripe || 
           !productReferences[id]?.payment?.price
          ) {
            setOnlineOrderValid(false);
        } else {
          setOnlineOrderValid(true);
        }
      }
    }
  }, [id, productReferences, setProductReference, organisationReferences, setOrganisationReference, storeLocations, onlineOrderValid]);

  const checkCart = (item) => {
    if(cart[item.organisationId] && cart[item.organisationId]?.length > 0) {
      return cart[item.organisationId].findIndex((i) => i._id === item._id) !== -1;
    }
    return false;
  }

  const handleChangeFocusedProduct = (name, value) => {
    setFocusedProduct({
      ...focusedProduct,
      [name]: value,
    });
  };

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({type: 'post', action: 'upload/image', data: {file: reader.result}}).then((result) => {
        setProcessing(false);
        const alt = (file.name.split('.') || ['thumbnail'])[0];
        callback({imageId: result.data.public_id, alt}, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  const handleUpdateProduct = (callback) => {
    setProcessing(true);
    const productId = id;
    const updateProduct = {
      	name: focusedProduct?.name,
        tagline: focusedProduct?.tagline,
        blurb: focusedProduct?.blurb,
        thumbnail: focusedProduct?.thumbnail,
        description: focusedProduct?.description,
        gallery: focusedProduct?.gallery,

        organisationId: focusedProduct?.organisationId,
        state: focusedProduct?.state,
        stock: focusedProduct?.stock,
        payment: focusedProduct?.payment,
        delivery: focusedProduct?.delivery,
    }

    apiRequest({type: 'patch', action: `products/update/${productId}`, data: {updateProduct}})
    .then((result) => {
      replaceProduct({product: {_id: productId, ...focusedProduct, ...updateProduct}});
      setProductReference({_id: productId, ...focusedProduct, ...updateProduct});
      setFocusedProduct(undefined);
      setEditing(false);
      setProcessing(false);
      if(callback) {
        callback();
      }
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Update organisation request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleUpdateOrganisation = (update, callback) => {
    setProcessing(true);
    const organisationId = productReferences[id].organisationId;
    const updateOrganisation = {
      gallery: organisationReferences[productReferences[id].organisationId]?.gallery,
      videoLibrary: organisationReferences[productReferences[id].organisationId]?.videoLibrary,
      layout: organisationReferences[productReferences[id].organisationId]?.layout,

      name: organisationReferences[productReferences[id].organisationId]?.name,
      tagline: organisationReferences[productReferences[id].organisationId]?.tagline,
      blurb: organisationReferences[productReferences[id].organisationId]?.blurb,
      thumbnail: organisationReferences[productReferences[id].organisationId]?.thumbnail,
      logo: organisationReferences[productReferences[id].organisationId]?.logo,
      website: organisationReferences[productReferences[id].organisationId]?.website,
      email: organisationReferences[productReferences[id].organisationId]?.email,
      phone: organisationReferences[productReferences[id].organisationId]?.phone,
      social: organisationReferences[productReferences[id].organisationId]?.social,
      categories: organisationReferences[productReferences[id].organisationId]?.categories,
      offices: organisationReferences[productReferences[id].organisationId]?.offices,
      postGroups: organisationReferences[productReferences[id].organisationId]?.postGroups,

      ...update,
    }

    apiRequest({type: 'patch', action: `organisations/update/${organisationId}`, data: {updateOrganisation}})
    .then((result) => {
      replaceOrganisation({organisation: {_id: organisationId, ...organisationReferences[productReferences[id].organisationId], ...updateOrganisation}});
      setOrganisationReference({_id: organisationId, ...organisationReferences[productReferences[id].organisationId], ...updateOrganisation});
      setProcessing(false);
      if(callback) {
        callback();
      }
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Update organisation request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  return (
    <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
      {productReferences[id] &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rerengatahi - {productReferences[id]?.name}</title>
          <meta
            name ='description'
            content={
              productReferences[id]?.blurb ||
              "Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
            }
          />
          <meta
            name='keywords'
            content={
              productReferences[id]?.keywords ||
              'social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
            }
          />
        </Helmet>
      }
      {!galleryOpen && editing &&
        <div
        style={{
          position: 'absolute',
          top: 0,
          left: 20,
          zIndex: 21,
          margin: 10,
        }}>
          <div style={{
            borderRadius: 25,
            background: paletteData.primary.standard.background,
          }}>
            <MenuEditSubPanel 
              me={me}
              handleUpdateProduct={handleUpdateProduct}
              processing={processing}
              setTab={setTab}
              setEditing={(value) => {
                setEditing(value);
                if(value === true) {
                  setFocusedProduct(productReferences[id]);
                } else {
                  setFocusedProduct(undefined);
                }
              }}
            />
          </div>
          {processing && 
              <div style={{margin: 10}}>
                <ProgressBar palette='secondary'/>
              </div>
            } 
        </div>
      }
      {!galleryOpen && !editing &&
        <div 
          style={{
          position: 'absolute',
          top: 0,
          right: 20,
          zIndex: 21,
          margin: 10,
        }}>
          <div style={{
            borderRadius: 25,
            background: paletteData.primary.standard.background,
          }}>
            <MenuProductSubPanel 
              me={me}
              focusedOrganisation={organisationReferences[productReferences[id]?.organisationId]}
              processing={processing}
              urlHistory={urlHistory}
              setEditing={(value) => {
                setEditing(value);
                if(value === true) {
                  setFocusedProduct(productReferences[id]);
                } else {
                  setFocusedProduct(undefined);
                }
              }}
            />
          </div> 
        </div>
      }
      
      <div style={{
        top: 0,
        left: 0,
        position: 'absolute',
        zIndex: 20,
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        background: 'rgba(255, 255, 255, 0.85)',
        overflowX: 'hidden',
        flex: 1,
      }}>
        {!productReferences[id] &&
          <GridContainer style={{height: '100vh'}}>
            <GridCell weight={1}/>
            <GridCell center={true} centerWeights={{top: 2, bottom: 3}}>
              <Typography size='title' style={{textAlign: 'center'}}>
                ...Loading
              </Typography>
              <ProgressBar palette='primary'/>
            </GridCell>
            <GridCell weight={1}/>
          </GridContainer>
        }
        {focusedProduct && editing &&
          <div>
            <GridContainer
              style={{
                background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundBlendMode: 'lighten',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                height: 200,
              }}
            >
              <GridCell
                weight={1}
                center={true}
                centerWeights={{top: 2, bottom: 3}}
              >
                <Typography size='heading' style={{textAlign: 'center'}}>
                  {focusedProduct.name}
                </Typography>
                {focusedProduct.organisationId && organisationReferences[focusedProduct.organisationId] &&
                  <Typography size='subHeading' style={{textAlign: 'center'}}>
                    {organisationReferences[focusedProduct.organisationId].name}
                  </Typography>
                }
              </GridCell>
            </GridContainer>
          </div>
        }
        {focusedProduct && editing && tab === 'information' &&
          <GridContainer>
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell weight={1}/>
            </Hidden>
            <GridCell weight={10} style={{padding: 10}}>
              <EditProductInformation
                me={me}
                processing={processing}
                dialogOpen={dialogOpen}
                dialogData={dialogData}
                setProcessing={setProcessing}
                setDialogOpen={setDialogOpen}
                setDialogData={setDialogData}

                focusedProduct={focusedProduct}
                handleChangeFocusedProduct={handleChangeFocusedProduct}
                focusedOrganisation={organisationReferences[focusedProduct.organisationId]}
                focusedPostGroup={
                  (organisationReferences[focusedProduct.organisationId]?.postGroups?.findIndex((p, Index) => {return p.id === focusedProduct?.delivery?.postGroup}) !== -1) ? 
                    organisationReferences[focusedProduct.organisationId].postGroups[organisationReferences[focusedProduct.organisationId]?.postGroups?.findIndex((p, Index) => {return p.id === focusedProduct?.delivery?.postGroup})] :
                    undefined
                }
              />
            </GridCell>
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell weight={1}/>
            </Hidden>
          </GridContainer>
        }
        {focusedProduct && editing && tab === 'listing' &&
          <GridContainer>
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell weight={1}/>
            </Hidden>
            <GridCell weight={10} style={{padding: 10}}>
              <EditProductListing
                me={me}
                processing={processing}
                dialogOpen={dialogOpen}
                dialogData={dialogData}
                setProcessing={setProcessing}
                setDialogOpen={setDialogOpen}
                setDialogData={setDialogData}

                focusedProduct={focusedProduct}
                handleChangeFocusedProduct={handleChangeFocusedProduct}
                focusedOrganisation={organisationReferences[focusedProduct.organisationId]}
              />
            </GridCell>
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell weight={1}/>
            </Hidden>
          </GridContainer>
        }
        {focusedProduct && editing && tab === 'layout' &&
          <GridContainer>
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell weight={1}/>
            </Hidden>
            <GridCell weight={10} style={{padding: 10}}>
              <EditProductLayout
                me={me}
                processing={processing}
                dialogOpen={dialogOpen}
                dialogData={dialogData}
                setProcessing={setProcessing}
                setDialogOpen={setDialogOpen}
                setDialogData={setDialogData}

                focusedProduct={focusedProduct}
                handleChangeFocusedProduct={handleChangeFocusedProduct}
                focusedOrganisation={organisationReferences[focusedProduct.organisationId]}
              />
            </GridCell>
            <Hidden breakpoint='hiddenlessthan650'>
              <GridCell weight={1}/>
            </Hidden>
          </GridContainer>
        }
        {productReferences[id] && !editing &&
          <div>
            <GridContainer
              style={{
                background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundBlendMode: 'lighten',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                height: 200,
              }}
            >
              <GridCell
                weight={1}
                center={true}
                centerWeights={{top: 2, bottom: 3}}
              >
                <Typography size='heading' style={{textAlign: 'center'}}>
                  {productReferences[id].name}
                </Typography>
                {productReferences[id].organisationId && organisationReferences[productReferences[id].organisationId] &&
                  <Typography size='subHeading' style={{textAlign: 'center'}}>
                    {organisationReferences[productReferences[id].organisationId].name}
                  </Typography>
                }
              </GridCell>
            </GridContainer>
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10}>
                <GridContainer>
                  <GridCell weight={1} style={{minWidth: isMobile ? '100%' : undefined}}>
                    <div style={{padding: 10, margin: 10, borderStyle: 'solid', borderWidth: 1, borderRadius: 5}}>
                      <Typography size='title'>
                        Purchace Information
                      </Typography>
                      <Typography>
                        {productReferences[id]?.name}
                      </Typography>
                      {productReferences[id]?.payment?.price &&
                        <Typography>
                          Price: ${organisationReferences[productReferences[id].organisationId]?.gst ? (productReferences[id]?.payment?.price + (productReferences[id]?.payment?.price*0.15)).toFixed(2) : (productReferences[id]?.payment?.price).toFixed(2)} {organisationReferences[productReferences[id].organisationId]?.gst ? '(Including GST)' : ''}
                        </Typography>
                      }
                      <Typography>
                        Stock: <span style={{color: productReferences[id]?.stock > 0 ? '' : 'red'}}>{productReferences[id]?.stock > 0 ? `${productReferences[id].stock} Units` : 'Out of stock'}</span>
                      </Typography>

                      <br/>
                      <Typography style={{fontWeight: 500}}>
                        {organisationReferences[productReferences[id].organisationId]?.name}
                      </Typography>
                      <Typography>
                        {organisationReferences[productReferences[id].organisationId]?.email}
                      </Typography>
                      <Typography>
                        {organisationReferences[productReferences[id].organisationId]?.phone}
                      </Typography>
                      {storeLocations?.length > 0 && storeLocations.map((o, oIndex) => (
                        <div key={oIndex} style={{marginTop: 5}}>
                          <a
                            href={`https://www.google.co.nz/maps/place/${`${o.address?.extra ? `${o.address?.extra} ` : ''}${o.address?.number ? `${o.address?.number} ` : ''}${o.address?.street ? `${o.address?.street} ` : ''}${o.address?.suburb ? `${o.address?.suburb} ` : ''}${o.address?.city ? `${o.address?.city} ` : ''}${o.address?.country ? `${o.address?.country} ` : ''}`}`}
                            target='_blank' rel='noopener noreferrer'
                            style={{textDecoration: 'none'}}
                          >
                            <div style={{marginBottom: 5}}>
                              <Typography>
                                {o.name}
                              </Typography>
                              <Typography>
                                {o.address?.extra} {o.address?.number} {o.address?.street}
                              </Typography>
                              <Typography>
                                {o.address?.suburb}
                              </Typography>
                              <Typography>
                                {o.address?.city}
                              </Typography>
                              <Typography>
                                {o.address?.country}
                              </Typography>
                            </div>
                          </a>
                        </div>
                      ))}
                      <br/>

                      <GridContainer>
                        <GridCell weight={1} style={{marginRight: 5}}>
                          <Button palette='primary' onClick={() => {
                            setDialogData({
                              type: 'contact',
                              title: `Enquire to ${organisationReferences[productReferences[id].organisationId]?.name} about ${productReferences[id]?.name}`,
                              value: {
                                organisationId: productReferences[id].organisationId,
                                name: `${`${me?.profile?.nameFirst} ` || ''}${me?.profile?.nameLast || ''}`,
                                email: me?.email || '',
                                subject: `Product enquiry - ${productReferences[id].name} - ${(new Date()).toDateString()}`,
                                body: `I would like to know the following information about '${productReferences[id]?.name}':`,
                              }
                            });
                            setDialogOpen(true);
                          }}>
                            Make Enquiry
                          </Button>
                        </GridCell>
                        {onlineOrderValid &&
                          <GridCell weight={1} style={{marginLeft: 5}}>
                            <Button disabled={checkCart(productReferences[id]) || ((productReferences[id]?.stock === 0 || !productReferences[id]?.stock))} palette='primary' onClick={() => {
                              addProductCart(productReferences[id]);
                              setDialogData({
                                type: 'message',
                                title: 'Item Added to cart',
                                message: 'Your item has been added to cart.  go to the cart page to view your current order'
                              });
                              setDialogOpen(true);
                            }}>
                              Add To Cart
                            </Button>
                          </GridCell>
                        }
                      </GridContainer>
                    </div>
                  </GridCell>
                  <GridCell weight={5}>
                    {productReferences[id].description &&
                      <div style={{padding: 10, margin: 10, minWidth: 250}}>
                        <Typography size='title'>
                          Product Information
                        </Typography>
                        <ReactMarkdown>
                          {productReferences[id].description}
                        </ReactMarkdown>
                      </div>
                    }
                    {productReferences[id]?.gallery?.length > 0 &&
                      <Gallery
                        isMobile={isMobile}
                        images={productReferences[id].gallery}
                        handleOpenPreview={() => setGalleryOpen(true)}
                        handleClosePreview={() => setGalleryOpen(false)}
                      />
                    }
                  </GridCell>
                </GridContainer>
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
          </div>
        }
      </div>

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'contact' &&
            <div style={{padding: 10}}>
              <Input
                name="name"
                palette='secondary'
                label="Name"
                type="text"
                value={dialogData?.value?.name || ''}
                onChange={(value) => {
                  setDialogData({
                    ...(dialogData || {}),
                    value: {
                      ...(dialogData?.value || {}),
                      name: value,
                    }
                  })
                }}
              />
              <Input
                name="name"
                palette='secondary'
                label="Email"
                type="text"
                value={dialogData?.value?.email || ''}
                onChange={(value) => {
                  setDialogData({
                    ...(dialogData || {}),
                    value: {
                      ...(dialogData?.value || {}),
                      email: value,
                    }
                  })
                }}
              />
              <TextArea
                name="body"
                palette='secondary'
                label="Message"
                type="text"
                value={dialogData?.value?.body || ''}
                onChange={(value) => {
                  setDialogData({
                    ...(dialogData || {}),
                    value: {
                      ...(dialogData?.value || {}),
                      body: value,
                    }
                  })
                }}
              />
            </div>
          }
          {(dialogData?.type === 'gstInclusivePrice' || dialogData?.type === 'gstInclusivePriceFromDialog') &&
            <div style={{padding: 10}}>
              <Input 
                label='Price'
                value={dialogData?.value?.price}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      price: value,
                    }
                  })
                }}
              />
            </div>
          }
          {(dialogData?.type === 'createPostGroup' || dialogData?.type === 'editPostGroup') &&
            <div style={{padding: 10}}>
              {dialogData?.type === 'editPostGroup' &&
                <div style={{padding: 5}}>
                  <Typography style={{color: 'red'}}>
                    Warning, editing this post group will change it for all products using this post group.
                  </Typography>
                </div>
                
              }
              <Input 
                label='Post Group Name'
                value={dialogData?.value?.name}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      name: value,
                    }
                  });
                }}
              />
              <GridContainer>
                <GridCell weight={1}>
                  <Input
                    label='National Post Price'
                    type='number'
                    value={dialogData?.value?.national}
                    onChange={(value) => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          national: value,
                        }
                      });
                    }}
                  />
                  {organisationReferences[focusedProduct.organisationId]?.gst &&
                    <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                      (${((dialogData?.value?.national || 0) * 1.15).toFixed(2)} Including GST)
                    </Typography>
                  }
                </GridCell>
                {organisationReferences[focusedProduct.organisationId]?.gst &&
                  <GridCell 
                    style={{marginTop: 25, marginRight: 5, cursor: 'pointer'}}
                    onClick={() => {
                      setDialogData({
                        type: 'gstInclusivePriceFromDialog',
                        title: 'Set GST Inclusive Price',
                        data: {
                          ...(dialogData?.data || {}),
                          previousType: dialogData?.type,
                          previousTitle: dialogData?.title,
                          valueToUpdate: 'national',
                        }, 
                        value: {
                          ...(dialogData?.value || {}),
                          price: +((dialogData?.value?.national || 0) * 1.15).toFixed(2),
                        }
                      })
                      setDialogOpen(true);
                    }}
                  >
                    <Typography>
                      + GST
                    </Typography>
                  </GridCell>
                }
                <GridCell weight={1}>
                  <Input
                    label='Maximum Items Per Parcel'
                    type='number'
                    value={dialogData?.value?.nationalLoad}
                    onChange={(value) => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          nationalLoad: value,
                        }
                      });
                    }}
                  />
                  {!dialogData?.value?.nationalLoad &&
                    <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                      Postage will be applied once per order.
                    </Typography>
                  }
                  {dialogData?.value?.nationalLoad &&
                    <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                      Postage will be applied once per {dialogData?.value?.nationalLoad} items.
                    </Typography>
                  }
                </GridCell>
              </GridContainer>
              <GridContainer>
                <GridCell weight={1}>
                  <Input
                    label='International Post Price'
                    type='number'
                    value={dialogData?.value?.international}
                    onChange={(value) => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          international: value,
                        }
                      });
                    }}
                  />
                  {organisationReferences[focusedProduct.organisationId]?.gst &&
                    <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                      (${((dialogData?.value?.international || 0) * 1.15).toFixed(2)} Including GST)
                    </Typography>
                  }
                </GridCell>
                {organisationReferences[focusedProduct.organisationId]?.gst &&
                  <GridCell 
                    style={{marginTop: 25, marginRight: 5, cursor: 'pointer'}}
                    onClick={() => {
                      setDialogData({
                        type: 'gstInclusivePriceFromDialog',
                        title: 'Set GST Inclusive Price',
                        data: {
                          ...(dialogData?.data || {}),
                          previousType: dialogData?.type,
                          previousTitle: dialogData?.title,
                          valueToUpdate: 'international',
                        }, 
                        value: {
                          ...(dialogData?.value || {}),
                          price: +((dialogData?.value?.international || 0) * 1.15).toFixed(2),
                        }
                      })
                      setDialogOpen(true);
                    }}
                  >
                    <Typography>
                      + GST
                    </Typography>
                  </GridCell>
                }
                <GridCell weight={1}>
                  <Input
                    label='Maximum Items Per Parcel'
                    type='number'
                    value={dialogData?.value?.internationalLoad}
                    onChange={(value) => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          internationalLoad: value,
                        }
                      });
                    }}
                  />
                  {!dialogData?.value?.internationalLoad &&
                    <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                      Postage will be applied once per order.
                    </Typography>
                  }
                  {dialogData?.value?.internationalLoad &&
                    <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
                      Postage will be applied once per {dialogData?.value?.internationalLoad} items.
                    </Typography>
                  }
                </GridCell>
              </GridContainer>
            </div>
          }
          {dialogData?.type === 'selectPostGroup' &&
            <div style={{padding: 10}}>
              <Input 
                label='Search'
                value={dialogData?.value?.search}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      search: value,
                    }
                  });
                }}
              />
              {organisationReferences[productReferences[id]?.organisationId]?.postGroups?.length > 0 && organisationReferences[productReferences[id].organisationId].postGroups.map((p, pIndex) => (
                <div 
                  key={pIndex} 
                  style={{padding: 10, margin: 5, borderRadius: 5, borderStyle: 'solid', borderWidth: 1, cursor: 'pointer'}}
                  onClick={() => {
                    if(dialogData?.data?.callback) {
                      dialogData.data.callback(p.id);
                    }
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  <Typography size='title'>
                    {p.name}
                  </Typography>
                  <GridContainer>
                    <GridCell style={{marginRight: 10}}>
                      <Typography>
                        National<br/>
                        International
                      </Typography>
                    </GridCell>
                    <GridCell>
                      ${p.national} {organisationReferences[productReferences[id]?.organisationId]?.gst ? '+ GST' : ''} {p.nationalLoad > 0 ? `per ${p.nationalLoad} items` : ''}<br/>
                      ${p.international} {organisationReferences[productReferences[id]?.organisationId]?.gst ? '+ GST' : ''} {p.internationalLoad > 0 ? `per ${p.internationalLoad} items` : ''}
                    </GridCell>
                  </GridContainer>
                </div>
              ))}
            </div>
          }
          {dialogData?.type === 'selectImage' &&
            <div style={{padding: 10}}>
              <Input
                label='Search Images'
                value={dialogData?.value?.search}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      search: value,
                    }
                  })
                }}
              />
              {processing && <ProgressBar palette='secondary'/>}
              <GridContainer>
                <GridCell
                  style={{width: 100, margin: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!processing) {
                      imageUploadRef.current.click();
                    }
                  }}
                >
                  <input
                    ref={imageUploadRef}
                    type="file"
                    style={{display: 'none'}}
                    onChange={(event) => {
                      const file = event?.target?.files?.length > 0 ? event.target.files[0] : undefined;
                      handleUploadImage(file, (response, error) => {
                        if(!error) {
                          if(dialogData?.data?.callback) {
                            dialogData.data.callback({
                              imageId: response.imageId,
                              imageOptions: dialogData?.value?.imageOptions,
                              alt: response.alt,
                            });
                          }
                          const image = {
                            imageId: response.imageId,
                            tags: '',
                            alt: response.alt,
                            description: '',
                          };
                          const galleryProcessed =  organisationReferences[productReferences[id].organisationId]?.gallery || [];
                          galleryProcessed.push(image);
                          handleUpdateOrganisation({gallery: galleryProcessed});
                          setDialogData(undefined);
                          setDialogOpen(false);
                        } else {
                          console.log(error);
                        }
                      });
                    }}
                  />
                  <div style={{width: '100%', height: 100, textAlign: 'center', background: '#c6c6c6'}}>
                    <span className="material-icons md-24" style={{marginTop: 35}}>image</span>
                    <span className="material-icons md-24" style={{marginTop: 35}}>add</span>
                  </div>
                  <Typography style={{textAlign: 'center'}}>
                    New
                  </Typography>
                </GridCell>
                {organisationReferences[productReferences[id].organisationId]?.gallery?.length > 0 && organisationReferences[productReferences[id].organisationId]?.gallery.filter((i, iIndex) => {
                  return (i.alt || '').toLowerCase().includes(dialogData?.value?.search?.toLowerCase());
                }).map((i, iIndex) => (
                  <GridCell
                    key={iIndex}
                    style={{width: 100, margin: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!processing) {
                        if(dialogData?.data?.callback) {
                          dialogData?.data?.callback({
                            imageId: i.imageId,
                            imageOptions: dialogData?.value?.imageOptions,
                            alt: i.alt,
                          });
                        }
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_100,h_100/${i.imageId}`}
                      alt={i.alt}
                      style={{width: '100%'}}
                    />
                    <Typography style={{textAlign: 'center'}}>
                      {i.alt}
                    </Typography>
                  </GridCell>
                ))}
              </GridContainer>

            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            {dialogData?.type !== 'gstInclusivePriceFromDialog' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'contact' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.email || !dialogData?.value.name}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    apiRequest({type: 'post', action: 'public_email/sendEnquiry', data: {
                      organisationId: dialogData?.value?.organisationId,
                      replyTo: dialogData?.value?.email,
                      name: dialogData?.value?.name,
                      subject: dialogData?.value?.subject,
                      body: dialogData?.value?.body,
                    }}).then((result) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Email Sent',
                        message: 'Email has been sent successfuly'
                      });
                      setDialogOpen(true);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Error Sending Email',
                        message: handleErrorMessage(error),
                      });
                      setDialogOpen(true);
                    });
                  }}
                >
                  Send Enquiry
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'gstInclusivePrice' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    if(dialogData?.data?.callback) {
                      dialogData.data.callback((dialogData?.value?.price || 0) - +(((dialogData?.value?.price || 0) * 3) / 23).toFixed(2))
                    }
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Submit
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'gstInclusivePriceFromDialog' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    const dialogDataProcessed = dialogData;
                    dialogDataProcessed.type = dialogDataProcessed?.data?.previousType;
                    dialogDataProcessed.title = dialogDataProcessed?.data?.previousTitle;
                    dialogDataProcessed.value[dialogDataProcessed?.data?.valueToUpdate] = (dialogData?.value?.price || 0) - +(((dialogData?.value?.price || 0) * 3) / 23).toFixed(2);
                    delete dialogDataProcessed.data.previousType;
                    delete dialogDataProcessed.data.previousTitle;
                    delete dialogDataProcessed.data.valueToUpdate;
                    delete dialogDataProcessed.value.price;
                    setDialogData({...dialogDataProcessed});
                  }}
                >
                  Submit
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'messageWithCallback' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    if(dialogData?.data?.callback) {
                      dialogData.data.callback();
                    }
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Submit
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'createPostGroup' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={
                    processing || 
                    !dialogData?.value?.id || 
                    !dialogData?.value?.name ||
                    !dialogData?.value?.national ||
                    !dialogData?.value?.international
                  }
                  palette='primary'
                  onClick={() => {
                    const postGroupsProcessed = organisationReferences[focusedProduct.organisationId]?.postGroups || [];
                    const postGroupProcessed = dialogData?.value || {};
                    postGroupsProcessed.push(postGroupProcessed);
                    postGroupsProcessed.sort((a, b) => {
                      if(a.name < b.name) {
                        return 1;
                      }
                      if(a.name > b.name) {
                        return -1;
                      }
                      return 0;
                    });

                    if(dialogData?.data?.callback) {
                      dialogData.data.callback(postGroupProcessed.id);
                    }
                    handleUpdateOrganisation({postGroups: postGroupsProcessed}, () => {
                      setDialogData(undefined);
                      setDialogOpen(false);
                    });
                  }}
                >
                  Submit
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
            {dialogData?.type === 'editPostGroup' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={
                    processing || 
                    !dialogData?.value?.id || 
                    !dialogData?.value?.name ||
                    !dialogData?.value?.national ||
                    !dialogData?.value?.international
                  }
                  palette='primary'
                  onClick={() => {
                    const postGroupsProcessed = organisationReferences[focusedProduct.organisationId]?.postGroups || [];
                    const postGroupProcessed = dialogData?.value || {};
                    const duplicate = postGroupsProcessed.findIndex((p, pIndex) => {
                      return p.id === postGroupProcessed.id;
                    });
                    if(duplicate !== -1) {
                      postGroupsProcessed.splice(duplicate, 1);
                    }
                    postGroupsProcessed.push(postGroupProcessed);
                    postGroupsProcessed.sort((a, b) => {
                      if(a.name < b.name) {
                        return 1;
                      }
                      if(a.name > b.name) {
                        return -1;
                      }
                      return 0;
                    });

                    if(dialogData?.data?.callback) {
                      dialogData.data.callback(postGroupProcessed.id);
                    }
                    handleUpdateOrganisation({postGroups: postGroupsProcessed}, () => {
                      setDialogData(undefined);
                      setDialogOpen(false);
                    });
                  }}
                >
                  Submit
                </Button>
                {processing && <ProgressBar palette='secondary'/>}
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart || {},
    productReferences: state.references.products || {},
    organisationReferences: state.references.organisations || {},
  };
};

export default connect(mapStateToProps, {setProductReference, setOrganisationReference, replaceOrganisation, replaceProduct, addProductCart})(Product);
