import React, {useEffect, useState, createRef, useCallback} from 'react';
import {connect} from 'react-redux';
import {Redirect, useParams, useHistory} from 'react-router-dom';
import {Helmet} from "react-helmet";
import objectPath from 'object-path';

import {paletteData, Button, Fab, Typography, Dialog, ProgressBar, Input} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';
import {LayoutDisplay, LayoutEdit} from '../../../components/layout';
import Map from '../../../components/map';
import Hidden from '../../../components/hidden';
import SearchStore from '../../../components/search/store';

import EditEventImageGallery from './EditEventImageGallery';
import EditEventVideoGallery from './EditEventVideoGallery';
import EditEventInformation from './EditEventInformation';
import EditEventLocations from './EditEventLocations';

import MenuEvent from './MenuEvent';
import MenuEdit from './MenuEdit';

import apiRequest from '../../../tools/apiRequest';
import {mapStyle1, mapStyle2} from './mapStyles';
import {setEventReference, setProductReference, setReviewReference, setAreaReference, setAreaReferences, setOrganisationReference, clearEventReference} from '../../../store/reducers/references/actions.js';
import {setEvents, setProducts, setSearch, replaceEvent, replaceOrganisation, removeEvent} from '../../../store/reducers/lists/actions.js';
import handleErrorMessage from '../../../tools/handleErrorMessage';

function Event({
  me,
  myPosition,
  isMobile,
  search,
  setSearch,
  queryLimit,
  refreshCredentialsLoaded,

  events,
  eventReferences,
  setEventReference,
  replaceEvent,
  removeEvent,
  clearEventReference,

  categories,
  categoryReferences,

  areas,
  areaReferences,
  setAreaReferences,

  organisationReferences,
  setOrganisationReference,
  replaceOrganisation,
}) {
  // global variables
  const [page, setPage] = useState(0);
  const [center] = useState({lat: -35.7274938, lng: 174.3165604});
  const [markers, setMarkers] = useState(undefined);

  const {id} = useParams();
  const urlHistory = useHistory();

  // page state
  const [pointerEventsInfo, setPointerEventsInfo] = useState('auto');
  const [opacityInfo, setOpacityInfo] = useState(1);
  const [redirect, setRedirect] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(false);

  const [focusedEvent, setFocusedEvent] = useState (undefined);
  const [editing, setEditing] = useState(false);
  const [addSection, setAddSection] = useState(false);
  const [advancedEdit, setAdvancedEdit] = useState(false);
  const [tab, setTab] = useState('layout');
  const [imageUploadRef] = useState(createRef());

  const convertDate = useCallback((d) => {
    if(d && d instanceof Date) {
      return d.toDateString();
    } else if(d) {
      const dProcessed = new Date(d);
      if(dProcessed?.getTime() > 0) {
        return dProcessed.toDateString();
      }
    }
    return undefined;
  }, []);

  const convertTime = useCallback((t) => {
    if(t) {
      const timeArray = t.split(':');
      if(timeArray?.length === 3) {
        const hours = parseInt(timeArray[0]);
        const minutes = parseInt(timeArray[1]);
        const seconds = parseInt(timeArray[2]);
        if(
          !isNaN(hours) && !isNaN(minutes) && !isNaN(seconds) &&
          hours >= 0 && minutes >= 0 && seconds >= 0 &&
          hours <= 24 && minutes <= 60 && seconds <= 60
         ) {
           const hoursProcessed = hours > 12 ? hours - 12 : hours;
           return `${hoursProcessed}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'pm' : 'am'}`;
        }
      }
    }
    return undefined;
  }, []);

  // first load
  const [initialLoad, setInitialLoad] = useState({
    eventReference: false,
    areaReferences: false,
    organisationReferences: false,
    markers: false,
  });
  const [allLoaded, setAllLoaded] = useState(false)
  const [eventReferenceLoaded, setEventReferenceLoaded] = useState(false);
  const [organisationReferencesLoaded, setOrganisationReferencesLoaded] = useState(false);
  const [areaReferencesLoaded, setAreaReferencesLoaded] = useState(false);
  const [markersLoaded, setMarkersLoaded] = useState(false);
  useEffect(() => {
    // finish page load
    if(!allLoaded && eventReferenceLoaded && organisationReferencesLoaded && areaReferencesLoaded && markersLoaded) {
      setAllLoaded(true);
    }
    // check if event is loaded and fetch if not
    if(refreshCredentialsLoaded && !initialLoad.eventReference && eventReferences[id]) {
      setInitialLoad({
        ...initialLoad,
        eventReference: true,
      });
      setEventReferenceLoaded(true);
    } else if (refreshCredentialsLoaded && !initialLoad.eventReference){
      setInitialLoad({
        ...initialLoad,
        eventReference: true,
      });
      apiRequest({type: 'get', action: `public_events/${id}`})
      .then((result) => {
        setEventReference(result.data.event);
        setEventReferenceLoaded(true);
      }).catch((error) => {
        setMarkers([]);
      });
    }

    // check if host org is loaded and fetch if not
    if(!initialLoad.organisationReferences && eventReferences[id]?.createdByOrg && organisationReferences[eventReferences[id]?.createdByOrg]) {
      setInitialLoad({
        ...initialLoad,
        organisationReferences: true,
      });
      setOrganisationReferencesLoaded(true);
    } else if(!initialLoad.organisationReferences && eventReferences[id]?.createdByOrg && !organisationReferences[eventReferences[id]?.createdByOrg]) {
      setInitialLoad({
        ...initialLoad,
        organisationReferences: true,
      });
      apiRequest({type: 'get', action: `public_organisations/${eventReferences[id]?.createdByOrg}`})
      .then((result) => {
        setOrganisationReference(result.data.organisation);
        setOrganisationReferencesLoaded(true);
      }).catch((error) => {
        setMarkers([]);
      });
    }

    // check if area references are loaded and fetch if not
    if(!initialLoad.areaReferences && eventReferences[id]?.locations?.length > 0) {
      setInitialLoad({
        ...initialLoad,
        areaReferences: true,
      });
      const areasToFetch = [];
      for(let i = 0; i < eventReferences[id].locations.length; i += 1) {
        if(eventReferences[id].locations[i].area && !areaReferences[eventReferences[id].locations[i].area]) {
          areasToFetch.push(eventReferences[id].locations[i].area);
        }
      }
      if(areasToFetch.length > 0) {
        const query = {_id: {$in: areasToFetch}};
        apiRequest({type: 'get', action: 'public_areas', data: {query, sort: {name: 1}}})
        .then((result) => {
          setAreaReferences(result.data.areas);
          setAreaReferencesLoaded(true);
        }).catch((error) => {
          setAreaReferencesLoaded(true);
        });
      } else {
        setAreaReferencesLoaded(true);
      }
    }

    // process markers
    if(!initialLoad.markers && eventReferences[id]?.locations?.length > 0) {
      setInitialLoad({
        ...initialLoad,
        markers: true,
      });
      const markersProcessed = [];
      for(let i = 0; i < eventReferences[id].locations.length; i += 1) {
        if(eventReferences[id].locations[i].position?.lat && eventReferences[id].locations[i].position?.lng) {
          const marker = {
            position: eventReferences[id].locations[i].position,
            infoWindow: {
              title: eventReferences[id].name || 'unnamed event',
              text: `${(eventReferences[id]?.blurb?.length > 200) ? `${eventReferences[id]?.blurb.substring(0, 200)}...` : eventReferences[id]?.blurb}\n`,
            }
          };
          if (eventReferences[id].locations[i]?.times?.length > 0) {
            for(let j = 0; j < eventReferences[id].locations[i].times.length; j += 1) {
              const date = convertDate(eventReferences[id].locations[i].times[j].date);
              if(date) {
                const start = convertTime(eventReferences[id].locations[i].times[j].start);
                const end = convertTime(eventReferences[id].locations[i].times[j].end);
                if(start && end) {
                  marker.infoWindow.text += '\n';
                  marker.infoWindow.text += `${date} ${start} - ${end}`;
                }
              }
            }
          }
          markersProcessed.push(marker);
        }
      }
      setMarkers(markersProcessed);
      setMarkersLoaded(true);
    } else if(!initialLoad.markers && eventReferences[id]?.locations?.length === 0) {
      setInitialLoad({
        ...initialLoad,
        markers: true,
      });
      setMarkers([]);
      setMarkersLoaded(true);
    }
  }, [
    id,
    markers,
    initialLoad,
    allLoaded,
    convertDate,
    convertTime,

    areaReferences,
    setAreaReferences,
    areaReferencesLoaded,

    eventReferences,
    setEventReference,
    eventReferenceLoaded,
    markersLoaded,

    organisationReferences,
    setOrganisationReference,
    organisationReferencesLoaded,

    refreshCredentialsLoaded,
  ]);

  const handleInfoFadeOut = () => {
    //setTransitioning(true);
    setOpacityInfo(0);
    setPointerEventsInfo('none');
    //setTimeout(function(){
    //  setTransitioning(false);
    //}, 500);
  }

  const handleInfoFadeIn = () => {
    //setTransitioning(true);
    setPointerEventsInfo('auto');
    setOpacityInfo(1);
    //setTimeout(function(){
    //  setTransitioning(false);
    //}, 500);
  }

  const handleChangeFocusedEvent = (name, value) => {
    setFocusedEvent({
      ...focusedEvent,
      [name]: value,
    });
  };

  const changePage = (page, type, index, path, value) => {
    if(type === 'insert') {
      const tempLayout = focusedEvent.layout || {
        header: {},
        multiPageEnabled: false,
        pages: [{
          title: 'home',
          sections: [],
        }],
      };
      if(!tempLayout.pages) {
        tempLayout.pages = [{
          title: 'home',
          sections: [],
        }];
      };
      tempLayout.pages[page].sections.splice(index, 0, value);
      handleChangeFocusedEvent('layout', tempLayout);
    } else if (type === 'update') {
      const tempLayout = focusedEvent.layout;
      if(path === undefined) {
        tempLayout.pages[page].sections = value;
      } else {
        if(path === '') {
          objectPath.set(tempLayout.pages[page].sections, `${index}`, value);
        } else {
          objectPath.set(tempLayout.pages[page].sections, `${index}.${path}`, value);
        }
      }
      handleChangeFocusedEvent('layout', tempLayout);
    }
  }

  const changeParent = (type, path, value) => {
    if(path === 'gallery' && type === 'insert') {
      // use organisation gallery
      const focusedOrganisation = organisationReferences[eventReferences[id]?.createdByOrg];
      const galleryProcessed = focusedOrganisation.gallery;
      galleryProcessed.push(value);
      const updateOrganisation = {gallery: galleryProcessed};
      apiRequest({type: 'patch', action: `organisations/update/${focusedOrganisation._id}`, data: {updateOrganisation}})
      .then((result) => {
        replaceOrganisation({organisation: {...focusedOrganisation, ...updateOrganisation}});
        setOrganisationReference({...focusedOrganisation, ...updateOrganisation});
      }).catch((error) => {

      });
    } else if(type === 'insert') {
      const tempArray = objectPath.get(focusedEvent, path) || [];
      tempArray.push(value);
      objectPath.set(focusedEvent, path, tempArray);
    }
  }

  const handlePublishEvent = () => {
    setProcessing(true);
    const eventId = focusedEvent._id;
    const updateEvent = {
      state: focusedEvent?.state,
      name: focusedEvent?.name,
      tagline: focusedEvent?.tagline,
      blurb: focusedEvent?.blurb,
      thumbnail: focusedEvent?.thumbnail,
      layout: focusedEvent?.layout,
      categories: focusedEvent?.categories,
      locations: focusedEvent?.locations,
    }

    apiRequest({type: 'patch', action: `events/update/${eventId}`, data: {updateEvent}})
    .then((result) => {
      apiRequest({type: 'patch', action: `events/publish/${eventId}`})
      .then((result2) => {
        replaceEvent({event: {_id: eventId, ...focusedEvent, ...updateEvent, state: 'public'}});
        setEventReference({_id: eventId, ...focusedEvent, ...updateEvent, state: 'public'});
        setInitialLoad({
          ...initialLoad,
          markers: false,
        });
        setMarkersLoaded(false);
        setProcessing(false);
        setFocusedEvent(undefined);
        setEditing(false);
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'Update event request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Update event request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleUpdateEvent = () => {
    setProcessing(true);
    const eventId = focusedEvent._id;
    const updateEvent = {
      state: focusedEvent?.state,
      name: focusedEvent?.name,
      tagline: focusedEvent?.tagline,
      blurb: focusedEvent?.blurb,
      thumbnail: focusedEvent?.thumbnail,
      layout: focusedEvent?.layout,
      categories: focusedEvent?.categories,
      locations: focusedEvent?.locations,
    }

    apiRequest({type: 'patch', action: `events/update/${eventId}`, data: {updateEvent}})
    .then((result) => {
      replaceEvent({event: {_id: eventId, ...focusedEvent, ...updateEvent, state: 'pending'}});
      setEventReference({_id: eventId, ...focusedEvent, ...updateEvent, state: 'pending'});
      setInitialLoad({
        ...initialLoad,
        markers: false,
      });
      setMarkersLoaded(false);
      setProcessing(false);
      setFocusedEvent(undefined);
      setEditing(false);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Update event request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({type: 'post', action: 'upload/image', data: {file: reader.result}}).then((result) => {
        setProcessing(false);
        const alt = (file.name.split('.') || ['thumbnail'])[0];
        callback({imageId: result.data.public_id, alt}, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  const handleRemoveEvent = () => {
    setProcessing(true);
    apiRequest({type: 'delete', action: `events/delete/${id}`})
    .then((result) => {
      removeEvent({event: {_id: id}});
      clearEventReference(id);
      urlHistory.goBack();
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Delete event request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleRemoveEventNotification = () => {
    setDialogData({
      type: 'messageWithCallback',
      title: 'Remove Event',
      message: 'Are you sure you wish to remove this event?  this action is permanent.',
      data: {
        callback: () => handleRemoveEvent(),
      }
    });
    setDialogOpen(true);
  }

  if(redirect) {
    return <Redirect to={redirect} push={true} />
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
      {eventReferences[id] &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rerengatahi - {eventReferences[id]?.name}</title>
          <meta
            name ='description'
            content={
              eventReferences[id]?.blurb ||
              "Rerengātahi is a community economic development project promoting local Māori and community events and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community events small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
            }
          />
          <meta
            name='keywords'
            content={
              eventReferences[id]?.keywords ||
              'social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
            }
          />
        </Helmet>
      }

      {/*---------------- small sceen menu -------------*/}
      <Hidden breakpoint='hiddengreaterthan1024'>
        <div style={{position: 'relative', background: paletteData.primary.standard.background}}>
          <GridContainer>
            {!editing && markers?.length > 0 &&
              <GridCell>
                <Fab
                  palette='primary'
                  onClick={() => {
                    if(opacityInfo) {
                      handleInfoFadeOut();
                    } else {
                      handleInfoFadeIn();
                    }
                  }}
                >
                  {opacityInfo ? <span className="material-icons md-24">place</span> : <span className="material-icons md-24">wrong_location</span>}
                </Fab>
              </GridCell>
            }
            {editing &&
              <div>
                <MenuEdit
                  me={me}
                  focusedEvent={focusedEvent}
                  focusedOrganisation={organisationReferences[eventReferences[id]?.createdByOrg]}

                  processing={processing}
                  tab={tab}
                  setTab={setTab}
                  handleUpdateEvent={handleUpdateEvent}
                  handleRemoveEvent={handleRemoveEventNotification}
                />
                {processing &&
                  <div style={{marginLeft: 15, marginRight: 15, marginTop: 5}}>
                    <ProgressBar palette='primary'/>
                  </div>
                }
                {tab === 'layout' &&
                  <GridContainer>
                    <GridCell>
                      <div style={{
                        marginTop: 10,
                        borderRadius: 25,
                        background: paletteData.primary.standard.background
                      }}>
                        <GridContainer>
                          <GridCell>
                            <Fab
                              disabled={processing}
                              palette='primary'
                              onClick={() => setAddSection(true)}
                            >
                            <span className="material-icons md-24">add</span>
                            </Fab>
                          </GridCell>
                          <GridCell>
                            <Fab
                              disabled={processing}
                              palette='primary'
                              onClick={() => setAdvancedEdit(!advancedEdit)}
                            >
                            {!advancedEdit && <span className="material-icons md-24">tune</span>}
                            {advancedEdit && <span className="material-icons md-24">web_asset</span>}
                            </Fab>
                          </GridCell>
                          <GridCell weight={1}/>
                        </GridContainer>
                      </div>
                    </GridCell>
                  </GridContainer>
                }
              </div>
            }
            <GridCell weight={1}/>
            {!editing && opacityInfo !== 0 &&
              <GridCell>
                <MenuEvent
                  me={me}
                  focusedEvent={eventReferences[id]}
                  focusedOrganisation={organisationReferences[eventReferences[id]?.createdByOrg]}
                  convertTime={convertTime}
                  convertDate={convertDate}

                  editing={editing}
                  menuOpen={menuOpen}
                  setEditing={(value) => {
                    if(value) {
                      setFocusedEvent(eventReferences[id]);
                    } else {
                      setFocusedEvent(undefined);
                    }
                    setEditing(value);
                  }}
                  setMenuOpen={setMenuOpen}
                  setPage={setPage}

                  setDialogData={setDialogData}
                  setDialogOpen={setDialogOpen}
                  setRedirect={setRedirect}
                  urlHistory={urlHistory}
                />
              </GridCell>
            }
          </GridContainer>
        </div>
      </Hidden>

      <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
        {/*---------------- large sceen menu -------------*/}
        {!menuHidden &&
          <Hidden breakpoint='hiddenlessthan1024'>
            <div>
              {editing &&
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 20,
                  zIndex: 21,
                  margin: 10,

                }}>
                  <div style={{
                    borderRadius: 25,
                    background: paletteData.primary.standard.background
                  }}>
                    <MenuEdit
                      me={me}
                      focusedEvent={focusedEvent}
                      focusedOrganisation={organisationReferences[eventReferences[id]?.createdByOrg]}

                      processing={processing}
                      tab={tab}
                      setTab={setTab}
                      handleUpdateEvent={handleUpdateEvent}
                      handleRemoveEvent={handleRemoveEventNotification}
                    />
                  </div>
                  {processing &&
                    <div style={{marginLeft: 15, marginRight: 15, marginTop: 5}}>
                      <ProgressBar palette='primary'/>
                    </div>
                  }
                  {tab === 'layout' &&
                    <GridContainer>
                      <GridCell>
                        <div style={{
                          marginTop: 10,
                          borderRadius: 25,
                          background: paletteData.primary.standard.background
                        }}>
                          <GridContainer>
                            <GridCell>
                              <Fab
                                disabled={processing}
                                palette='primary'
                                onClick={() => setAddSection(true)}
                              >
                              <span className="material-icons md-24">add</span>
                              </Fab>
                            </GridCell>
                            <GridCell>
                              <Fab
                                disabled={processing}
                                palette='primary'
                                onClick={() => setAdvancedEdit(!advancedEdit)}
                              >
                              {!advancedEdit && <span className="material-icons md-24">tune</span>}
                              {advancedEdit && <span className="material-icons md-24">web_asset</span>}
                              </Fab>
                            </GridCell>
                            <GridCell weight={1}/>
                          </GridContainer>
                        </div>
                      </GridCell>
                    </GridContainer>
                  }
                </div>
              }
              {!editing && markers?.length > 0 &&
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 20,
                  zIndex: 21,
                  margin: 10,
                }}>
                  <Fab
                    palette='primary'
                    onClick={() => {
                      if(opacityInfo) {handleInfoFadeOut()}
                      else {handleInfoFadeIn()}
                    }}
                  >
                    {opacityInfo ? <span className="material-icons md-24">place</span> : <span className="material-icons md-24">wrong_location</span>}
                  </Fab>
                </div>
              }
              {!editing && opacityInfo !== 0 &&
                <div style={{
                  position: 'absolute',
                  top: 0,
                  right: 20,
                  zIndex: 21,
                  margin: 10,
                }}>
                  <div style={{
                    borderRadius: 25,
                    background: paletteData.primary.standard.background
                  }}>
                    <MenuEvent
                      me={me}
                      focusedEvent={eventReferences[id]}
                      focusedOrganisation={organisationReferences[eventReferences[id]?.createdByOrg]}
                      convertTime={convertTime}
                      convertDate={convertDate}

                      editing={editing}
                      menuOpen={menuOpen}
                      setEditing={(value) => {
                        if(value) {
                          setFocusedEvent(eventReferences[id]);
                        } else {
                          setFocusedEvent(undefined);
                        }
                        setEditing(value);
                      }}
                      setMenuOpen={setMenuOpen}
                      setPage={setPage}

                      setDialogData={setDialogData}
                      setDialogOpen={setDialogOpen}
                      setRedirect={setRedirect}
                      urlHistory={urlHistory}
                    />
                  </div>
                </div>
              }
            </div>
          </Hidden>
        }

        {/*----------------- display page ----------------*/}
        {(!eventReferences[id]) &&
          <GridContainer style={{height: '100vh'}}>
            <GridCell weight={1}/>
            <GridCell center={true} centerWeights={{top: 2, bottom: 3}}>
              <Typography size='title' style={{textAlign: 'center'}}>
                ...Loading
              </Typography>
              <ProgressBar palette='secondary'/>
            </GridCell>
            <GridCell weight={1}/>
          </GridContainer>
        }
        <div style={{
          pointerEvents: pointerEventsInfo,
          display: opacityInfo === 0 ? 'none' : 'initial',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 20,
          width: '100%',
          height: '100%',
          overflow: 'scroll',
          background: 'rgba(255, 255, 255, 0.85)',
          overflowX: 'hidden',
        }}>
          {/*---------- event not loaded -------------*/}
          {!eventReferences[id] &&
            <GridContainer style={{height: '100vh'}}>
              <GridCell weight={1}/>
              <GridCell center={true} centerWeights={{top: 2, bottom: 3}}>
                <Typography size='title' style={{textAlign: 'center'}}>
                  ...Loading
                </Typography>
                <ProgressBar palette='secondary'/>
              </GridCell>
              <GridCell weight={1}/>
            </GridContainer>
          }
          {/*---------- fallback if event has no layout -------------*/}
          {(!eventReferences[id]?.layout?.pages || eventReferences[id]?.layout?.pages?.length === 0) &&
            <GridContainer style={{
              background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundBlendMode: 'lighten',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              backgroundAttachment: 'fixed',
              flex: 1,
            }}>
              <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{textAlign: 'center'}}>
                <Typography size='heading' style={{letterSpacing: 2.3, fontWeight: 700}}>
                  {eventReferences[id]?.name}
                </Typography>
                <Typography size='title' palette='secondary' style={{letterSpacing: 5}}>
                  {eventReferences[id]?.tagline}
                </Typography>
              </GridCell>
            </GridContainer>
          }
          {/*---------- event layout -------------*/}
          {!editing && eventReferences[id]?.layout?.pages?.length > 0 &&
           eventReferences[id]?.layout?.pages[page]?.sections?.length > 0 &&
           eventReferences[id]?.layout?.pages[page]?.sections.map((b, bIndex) => (
             <LayoutDisplay
              key={bIndex}
              block={b}
              onClick={() => {}}
              onCallback={(name, value) => {
                if(name === 'galeryOpenEvent') {
                  setMenuHidden(value);
                }
              }}
            />
          ))}
          {/*---------- edit event layout -------------*/}
          {editing && tab === 'layout' &&
            <LayoutEdit
              imageLibrary={organisationReferences[eventReferences[id]?.createdByOrg]?.gallery}
              videoLibrary={organisationReferences[eventReferences[id]?.createdByOrg]?.videoLibrary || []}
              sections={(focusedEvent?.layout?.pages && focusedEvent?.layout?.pages[page]?.sections) || []}
              changeSection={(type, index, path, value) => changePage(page, type, index, path, value)}
              changeParent={(type, path, value) => changeParent(type, path, value)}
              addSection={addSection}
              setAddSection={setAddSection}
              advancedEdit={advancedEdit}
              setAdvancedEdit={setAdvancedEdit}
            />
          }
          {editing && tab === 'image' &&
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10} style={{padding: 10}}>
                <div style={{height: 60}}/>
                <EditEventImageGallery
                  focusedEvent={focusedEvent}
                  focusedOrganisation={organisationReferences[eventReferences[id]?.createdByOrg]}
                  processing={processing}
                  setProcessing={setProcessing}
                  handleChangeFocusedEvent={handleChangeFocusedEvent}
                  handleUploadImage={handleUploadImage}
                  replaceOrganisation={replaceOrganisation}
                  setOrganisationReference={setOrganisationReference}
                />
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
          }
          {editing && tab === 'video' &&
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10} style={{padding: 10}}>
                <div style={{height: 60}}/>
                <EditEventVideoGallery
                  focusedEvent={focusedEvent}
                  focusedOrganisation={organisationReferences[eventReferences[id]?.createdByOrg]}
                  processing={processing}
                  setProcessing={setProcessing}
                  handleChangeFocusedEvent={handleChangeFocusedEvent}
                  handleUploadImage={handleUploadImage}
                  replaceOrganisation={replaceOrganisation}
                  setOrganisationReference={setOrganisationReference}
                />
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
          }
          {editing && tab === 'information' &&
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10} style={{padding: 10}}>
                <div style={{height: 60}}/>
                  <EditEventInformation
                    me={me}
                    processing={processing}
                    dialogOpen={dialogOpen}
                    dialogData={dialogData}
                    setProcessing={setProcessing}
                    setDialogOpen={setDialogOpen}
                    setDialogData={setDialogData}

                    focusedEvent={focusedEvent}
                    focusedOrganisation={organisationReferences[eventReferences[id]?.createdByOrg]}
                    handleChangeFocusedEvent={handleChangeFocusedEvent}
                    handlePublishEvent={handlePublishEvent}

                    categories={categories}
                    categoryReferences={categoryReferences}
                  />
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
          }
          {editing && tab === 'location' &&
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10} style={{padding: 10}}>
                <div style={{height: 60}}/>
                  <EditEventLocations
                    me={me}
                    processing={processing}
                    dialogOpen={dialogOpen}
                    dialogData={dialogData}
                    setProcessing={setProcessing}
                    setDialogOpen={setDialogOpen}
                    setDialogData={setDialogData}

                    focusedEvent={focusedEvent}
                    handleChangeFocusedEvent={handleChangeFocusedEvent}

                    areas={areas}
                    areaReferences={areaReferences}
                  />
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
          }
        </div>

        {/*------------- map ---------------*/}
        {markers?.length > 0 &&
          <Map
            style={{width: '100%', flex: 1}}
            center={(markers?.length > 0 && markers[0]?.position) || myPosition || center}
            zoom={15}
            markers={markers}
            options={{
              styles: opacityInfo ? mapStyle1 : mapStyle2,
              disableDefaultUI: true,
            }}
          />
        }
      </div>


      {/*---------------- popouts and popups ------------------*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography style={{whiteSpace: 'pre-wrap'}}>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'addCategory' &&
            <div style={{padding: 10}}>
              <Input
                label="search"
                value={dialogData?.value?.search || ''}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      search: value,
                    }
                  })
                }}
              />
              {!processing && categories && categories.filter((c, cIndex) => {
                return c.name.toLowerCase().includes((dialogData?.value?.search || '').toLowerCase());
              }).map((c, cIndex) => (
                <div
                  key={cIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    const tempCategories = focusedEvent?.categories || [];
                    let testCategories = true;
                    for(let i = 0; i < tempCategories.length; i += 1) {
                      if(tempCategories[i] === c.name) {
                        testCategories = false;
                      }
                    }
                    if(testCategories) {
                      tempCategories.push(c._id);
                      tempCategories.sort();
                      handleChangeFocusedEvent('categories', tempCategories);
                    }
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {c.name}
                </div>
              ))}
            </div>
          }
          {dialogData?.type === 'selectImage' &&
            <div style={{padding: 10}}>
              <Input
                label='Search Images'
                value={dialogData?.value?.search}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      search: value,
                    }
                  })
                }}
              />
              {processing && <ProgressBar palette='secondary'/>}
              <GridContainer>
                {dialogData?.value?.imageId &&
                  <GridCell
                    style={{width: 100, margin: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!processing) {
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_100,h_100/${dialogData?.value?.imageId || 'media-assets/hudson-hintze-vpxeE7s-my4-unsplash_axrfv6_oemuhc.jpg'}`}
                      alt={dialogData?.value?.alt}
                      style={{width: '100%'}}
                    />
                    <Typography style={{textAlign: 'center'}}>
                      Current
                    </Typography>
                  </GridCell>
                }
                <GridCell
                  style={{width: 100, margin: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!processing) {
                      imageUploadRef.current.click();
                    }
                  }}
                >
                  <input
                    ref={imageUploadRef}
                    type="file"
                    style={{display: 'none'}}
                    onChange={(event) => {
                      const file = event?.target?.files?.length > 0 ? event.target.files[0] : undefined;
                      handleUploadImage(file, (response, error) => {
                        if(!error) {
                          dialogData?.data.callback({
                            imageId: response.imageId,
                            imageOptions: dialogData?.value?.imageOptions,
                            alt: response.alt,
                          });

                          const image = {
                            imageId: response.imageId,
                            tags: '',
                            alt: response.alt,
                            description: '',
                          };
                          const focusedOrganisation = organisationReferences[eventReferences[id]?.createdByOrg];
                          const galleryProcessed = focusedOrganisation.gallery;
                          galleryProcessed.push(image);
                          const updateOrganisation = {gallery: galleryProcessed};
                          apiRequest({type: 'patch', action: `organisations/update/${focusedOrganisation._id}`, data: {updateOrganisation}})
                          .then((result) => {
                            replaceOrganisation({organisation: {...focusedOrganisation, ...updateOrganisation}});
                            setOrganisationReference({...focusedOrganisation, ...updateOrganisation});
                          }).catch((error) => {

                          });
                          setDialogData(undefined);
                          setDialogOpen(false);
                        } else {
                          console.log(error);
                        }
                      });
                    }}
                  />
                  <div style={{width: '100%', height: 100, textAlign: 'center', background: '#c6c6c6'}}>
                    <span className="material-icons md-24" style={{marginTop: 35}}>image</span>
                    <span className="material-icons md-24" style={{marginTop: 35}}>add</span>
                  </div>
                  <Typography style={{textAlign: 'center'}}>
                    New
                  </Typography>
                </GridCell>
                {dialogData?.value?.imageId &&
                  <GridCell
                    style={{width: 100, margin: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!processing) {
                        dialogData?.data.callback({imageId: undefined, imageOptions: undefined, alt: undefined});
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <div style={{width: '100%', height: 100, textAlign: 'center', background: '#c6c6c6'}}>
                      <span className="material-icons md-24" style={{marginTop: 35}}>image</span>
                      <span className="material-icons md-24" style={{marginTop: 35}}>remove</span>
                    </div>
                    <Typography style={{textAlign: 'center'}}>
                      Remove
                    </Typography>
                  </GridCell>
                }
                {organisationReferences[eventReferences[id]?.createdByOrg]?.gallery?.length > 0 && organisationReferences[eventReferences[id]?.createdByOrg]?.gallery.filter((i, iIndex) => {
                  return (i.alt || '').toLowerCase().includes(dialogData?.value?.search?.toLowerCase());
                }).map((i, iIndex) => (
                  <GridCell
                    key={iIndex}
                    style={{width: 100, margin: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!processing) {
                        dialogData?.data.callback({
                          imageId: i.imageId,
                          imageOptions: dialogData?.value?.imageOptions,
                          alt: i.alt,
                        });
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_100,h_100/${i.imageId}`}
                      alt={i.alt}
                      style={{width: '100%'}}
                    />
                    <Typography style={{textAlign: 'center'}}>
                      {i.alt}
                    </Typography>
                  </GridCell>
                ))}
              </GridContainer>

            </div>
          }
          {dialogData?.type === 'changeArea' &&
            <div style={{padding: 10}}>
              <SearchStore
                databaseArea='areas'
                processing={processing}
                setProcessing={setProcessing}
                expandSearch={dialogData?.value?.expandSearch}
                setExpandSearch={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      expandSearch: value,
                    }
                  });
                }}
              />
              {areas?.length > 0 && areas.map((a, aIndex) => (
                <div
                  key={aIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!areaReferences[a._id]) {
                      setAreaReference(a);
                    }
                    dialogData?.data?.callback(a._id);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {a.name}
                </div>
              ))}
              {processing && areas?.length > 9 &&
                <div style={{padding: 10}}>
                  <Typography>
                    Loading...
                  </Typography>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {!processing && search?.areas?.hasMore &&
                <div style={{padding: 5}}>
                  <Button
                    disabled={processing}
                    palette='primary'
                    onClick={() => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          expandSearch: true,
                        }
                      });
                    }}
                  >
                    Load More
                  </Button>
                </div>
              }
            </div>
          }
          {dialogData?.type === 'schedule' &&
            <div style={{padding: 10}}>
              {dialogData?.data?.focusedEvent?.locations?.length > 0 && dialogData?.data?.focusedEvent?.locations?.map((l, lIndex) => (
                <div key={lIndex}>
                  {(l.position?.lat && l.position?.lng) ?
                    <a
                      style={{textDecoration: 'none', cursor: 'pointer'}}
                      href={`http://www.google.com/maps/place/${l.position.lat},${l.position.lng}`}
                      target="_blank"
                      rel='noreferrer noopener'
                    >
                      <GridContainer>
                        <GridCell>
                          <Typography size='title'>
                            {l.address?.extra ? `${l.address?.extra} ` : ''}{l.address?.number ? `${l.address?.number} ` : ''}{l.address?.street ? `${l.address?.street} ` : ''}{l.address?.suburb ? `${l.address?.suburb} ` : ''}{l.address?.city ? `${l.address?.city} ` : ''}{l.address?.country ? `${l.address?.country} ` : ''}
                          </Typography>
                        </GridCell>
                        <GridCell>
                          <span className="material-icons md-24">place</span>
                        </GridCell>
                      </GridContainer>
                    </a> :
                    <Typography>
                      {l.address?.extra ? `${l.address?.extra} ` : ''}{l.address?.number ? `${l.address?.number} ` : ''}{l.address?.street ? `${l.address?.street} ` : ''}{l.address?.suburb ? `${l.address?.suburb} ` : ''}{l.address?.city ? `${l.address?.city} ` : ''}{l.address?.country ? `${l.address?.country} ` : ''}
                    </Typography>
                  }

                  {l?.times?.length > 0 && l.times.map((t, tIndex) => (
                    <div key={tIndex}>
                      {t.date && t.start && t.end &&
                        <div>
                          {convertDate(t.date)} {convertTime(t.start)} - {convertTime(t.end)}
                        </div>
                      }
                    </div>
                  ))}
                  <br/>
                </div>
              ))}
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'messageWithCallback' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                   onClick={() => {
                     if(dialogData?.data?.callback) {
                       dialogData?.data?.callback();
                     }
                     setDialogData(undefined);
                     setDialogOpen(false);
                  }}
                >
                  OK
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
    events: state.lists.events,
    categories: state.lists.categories,
    areas: state.lists.areas,

    eventReferences: state.references.events || {},
    areaReferences: state.references.areas || {},
    categoryReferences: state.references.categories || {},
    organisationReferences: state.references.organisations || {},
  };
};

export default connect(mapStateToProps, {setEventReference, setSearch, setEvents, setProducts, setProductReference, setReviewReference, replaceEvent, replaceOrganisation, setAreaReferences, setOrganisationReference, removeEvent, clearEventReference})(Event);
