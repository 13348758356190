import PropTypes from 'prop-types';

function Hidden({children, breakpoint, forceHideOnPhone, forceShowOnPhone}) {
  let mobile = false;
  if( navigator.userAgent.match(/Android/i)
   || navigator.userAgent.match(/webOS/i)
   || navigator.userAgent.match(/iPhone/i)
   || navigator.userAgent.match(/iPad/i)
   || navigator.userAgent.match(/iPod/i)
   || navigator.userAgent.match(/BlackBerry/i)
   || navigator.userAgent.match(/Windows Phone/i)) {
     mobile = true;
  }

  if(forceHideOnPhone && mobile) {
    return (<div style={{display: 'none'}}></div>);
  }

  if(forceShowOnPhone && mobile) {
    return ({...children});
  }

  return (
      {...children,
        props: {
          ...children.props,
          className: breakpoint,
        }
      }
  );
}

Hidden.propTypes = {
  children: PropTypes.node,
  breakpoint: PropTypes.string,
  forceHideOnPhone: PropTypes.bool,
  forceShowOnPhone: PropTypes.bool,
};

export default Hidden;
