import React, {useState, useEffect} from 'react';
import {useLocation, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";

import {paletteData, Typography, CheckBox, Input, Button, Dialog} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';

import apiRequest from '../../../tools/apiRequest';
import {setEventReference, setOrganisationReference} from '../../../store/reducers/references/actions.js';

function Unsubscibe({eventReferences, setEventReference, organisationReferences, setOrganisationReference}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [processingUnsubsribe, setProcessingUnsubsribe] = useState(false);
  const [eventLoaded, setEventLoaded] = useState(false);
  const [eventsProcessed, setEventsProcessed] = useState(false);
  const [organisationLoaded, setOrganisationLoaded] = useState(false);
  const [organisationsProcessed, setOrganisationsProcessed] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [unsubscribe, setUnsubscribe] = useState({
    organisations: {
      all: false,
    },
    events: {
      all: false,
    },
    email: '',
  });
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const eventId = query.get('event');
  const organisationId = query.get('organisation');

  useEffect(() => {
    if(!eventLoaded && eventId && !eventReferences[eventId]) {
      apiRequest({type: 'get', action: `public_events/${eventId}`})
      .then((result) => {
        setEventLoaded(true);
        const event = result.data.event;
        setEventReference(event);
      }).catch((error) => {
        setEventLoaded(true);
      });
    } else {
      setEventLoaded(true);
    }
    if(!organisationLoaded && organisationId && !organisationReferences[organisationId]) {
      apiRequest({type: 'get', action: `public_organisations/${organisationId}`})
      .then((result) => {
        setOrganisationLoaded(true);
        const org = result.data.organisation;
        setOrganisationReference(org);
      }).catch((error) => {
        setOrganisationLoaded(true);
      });
    } else {
      setOrganisationLoaded(true);
    }

    if(processingUnsubsribe && organisationsProcessed && eventsProcessed) {
      setProcessingUnsubsribe(false);
      setDialogData({
        type: 'message',
        title: `Unsubscibe request processed`,
        message: 'The unsubscribe process is now underway.  Please allow for 1 hour for this process to complete, you do not need to take any further actions. Once this process is complete you will now no longer receive notifications from the events and organisations you have unsubscribed from.',
      });
      setDialogOpen(true);
    }
  }, [eventId, eventReferences, eventLoaded, setEventReference, organisationId, organisationReferences, organisationLoaded, setOrganisationReference, eventsProcessed, organisationsProcessed, processingUnsubsribe]);

  const checkList = () => {
    if(unsubscribe.events.all) return true;
    if(unsubscribe.organisations.all) return true;
    if(organisationId && unsubscribe.organisations[organisationId]) return true;
    if(eventId && unsubscribe.events[eventId]) return true;
    return false;
  }

  if(redirect) {
    return (<Redirect to={redirect} />);
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - unsubscribe</title>
        <meta
          name ='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>
      <br/><br/>
      <Typography size='heading' style={{textAlign: 'center'}}>
        Rerengatahi
      </Typography>
      <Typography size='subHeading' style={{textAlign: 'center'}}>
        Unsubscibe from mailing lists
      </Typography>
      <br/>
      {(!eventLoaded || !organisationLoaded) &&
        <div style={{textAlign: 'center'}}>
          ... loading
        </div>
      }
      {eventLoaded && organisationLoaded &&
        <div>
          <Typography style={{textAlign: 'center'}}>
            We are sorry to see you go.  please choose from the following unsubscription options.
          </Typography>
          <br/>
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell>
              <div style={{padding: 10, background: paletteData.primary.standard.background}}>
                <Typography style={{color: paletteData.primary.standard.foreground}}>
                  Events
                </Typography>
              </div>
              {eventReferences[eventId] && !unsubscribe.events.all &&
                <CheckBox
                  name={eventId}
                  label={eventReferences[eventId].name}
                  value={unsubscribe.events[eventId]}
                  onChange={(value) => setUnsubscribe({
                    ...(unsubscribe || {}),
                    events: {
                      ...(unsubscribe.organisations || {}),
                      [eventId]: value,
                    }
                  })}
                />
              }
              <CheckBox
                name='eventsAll'
                label='All events'
                value={unsubscribe.events.all}
                onChange={(value) => setUnsubscribe({
                  ...(unsubscribe || {}),
                  events: {
                    ...(unsubscribe.organisations || {}),
                    all: value,
                  }
                })}
              />
              <br/>
              <div style={{padding: 10, background: paletteData.primary.standard.background}}>
                <Typography style={{color: paletteData.primary.standard.foreground}}>
                  Organisations
                </Typography>
              </div>
              {organisationReferences[organisationId] && !unsubscribe.organisations.all &&
                <CheckBox
                  name={organisationId}
                  label={organisationReferences[organisationId].name}
                  value={unsubscribe.organisations[organisationId]}
                  onChange={(value) => setUnsubscribe({
                    ...(unsubscribe || {}),
                    organisations: {
                      ...(unsubscribe.organisations || {}),
                      [organisationId]: value,
                    }
                  })}
                />
              }
              <CheckBox
                name='orgAll'
                label='All organisations'
                value={unsubscribe.organisations.all}
                onChange={(value) => setUnsubscribe({
                  ...(unsubscribe || {}),
                  organisations: {
                    ...(unsubscribe.organisations || {}),
                    all: value,
                  }
                })}
              />

              <br/>
              <div style={{padding: 10, background: paletteData.primary.standard.background}}>
                <Typography style={{color: paletteData.primary.standard.foreground}}>
                  Your Details
                </Typography>
              </div>
              <Input
                style={{minWidth: 400}}
                name="email"
                palette='secondary'
                label="Email"
                type="text"
                value={unsubscribe.email || ''}
                onChange={(value) => setUnsubscribe({
                  ...(unsubscribe || {}),
                  email: value,
                })}
              />
              <br/>
              <Button
                disabled={!unsubscribe.email || !checkList()}
                palette='primary'
                onClick={() => {
                  setProcessingUnsubsribe(true);
                  if(unsubscribe.organisations.all) {
                    apiRequest({type: 'patch', action: `public_organisations/bulk_deregister`, data: {email: unsubscribe.email}})
                    .then((result) => {
                      setOrganisationsProcessed(true);
                    }).catch((error) => {
                      setOrganisationsProcessed(true);
                    });
                  } else if(unsubscribe.organisations[organisationId]) {
                    apiRequest({type: 'patch', action: `public_organisations/deregister/${organisationId}`, data: {email: unsubscribe.email}})
                    .then((result) => {
                      setOrganisationsProcessed(true);
                    }).catch((error) => {
                      setOrganisationsProcessed(true);
                    });
                  } else {
                    setOrganisationsProcessed(true);
                  }
                  if(unsubscribe.events.all) {
                    apiRequest({type: 'patch', action: `public_events/bulk_deregister`, data: {email: unsubscribe.email}})
                    .then((result) => {
                      setEventsProcessed(true);
                    }).catch((error) => {
                      setEventsProcessed(true);
                    });
                  } else if(unsubscribe.events[eventId]) {
                    apiRequest({type: 'patch', action: `public_events/deregister/${eventId}`, data: {email: unsubscribe.email}})
                    .then((result) => {
                      setEventsProcessed(true);
                    }).catch((error) => {
                      setEventsProcessed(true);
                    });
                  } else {
                    setEventsProcessed(true);
                  }
                }}
              >
                Unsubscibe
              </Button>
            </GridCell>
            <GridCell weight={1}/>
          </GridContainer>
        </div>
      }


      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10}}>
            <Typography size='title'>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                  setRedirect('/');
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    organisationReferences: state.references.organisations || {},
    eventReferences: state.references.events || {},
  };
};

export default connect(mapStateToProps, {setOrganisationReference, setEventReference})(Unsubscibe);
