import React, {useState, useEffect, createRef} from 'react';
import PropTypes from 'prop-types';
import {ChromePicker} from 'react-color';
import objectPath from 'object-path';

import {paletteData, Fab, Dialog, Button, Typography, Input, ProgressBar} from '../../styles';
import {GridContainer, GridCell} from '../../grid';
import apiRequest from '../../../tools/apiRequest';

import {
  generateSplashBlock,
  generateRichTextBlock,
  generateTitleBlock,
  generateBannerBlock,
  generateGalleryBlock,
  generateImageBlock,
  generateParallaxImageBlock,
  generateNavigationElement,
  generateVideoBlock,
  generateGridContainer,
} from '../';
import AdvancedEdit from './AdvancedEdit';
import SplashBlockEdit from './SplashBlockEdit';
import RichTextBlockEdit from './RichTextBlockEdit';
import TitleBlockEdit from './TitleBlockEdit';
import ImageBlockEdit from './ImageBlockEdit';
import BannerBlockEdit from './BannerBlockEdit';
import GalleryBlockEdit from './GalleryBlockEdit';
import ParallaxImageBlockEdit from './ParallaxImageBlockEdit';
import NavigationElementEdit from './NavigationElementEdit';
import VideoBlockEdit from './VideoBlockEdit';
import GridBlockEdit from './GridBlockEdit';

function LayoutEdit({
  sections,
  imageLibrary,
  videoLibrary,
  staffList,
  changeSection,
  changeParent,
  advancedEdit, setAdvancedEdit,
  addSection, setAddSection,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [processing, setProcessing] = useState(false);

  const [imageUploadRef] = useState(createRef());

  useEffect(() => {
    if(addSection) {
      setAddSection(false);
      setDialogData({
        type: 'addSection',
        title: 'Add Section',
        data: {
          maxIndex: sections.length + 1,
          callback: (index, section) => {
            changeSection('insert', index, undefined, section);
          },
        },
        value: {
          index: sections.length + 1,
        }
      });
      setDialogOpen(true);
    }
  }, [addSection, changeSection, sections, setAddSection]);

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({type: 'post', action: 'upload/image', data: {file: reader.result}}).then((result) => {
        setProcessing(false);
        callback({imageId: result.data.public_id, alt: file.name}, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  const stripImageURL = (url) => {
    let imageId = undefined;
    let imageOptions = undefined;
    if(url) {
      let imageProcessed = url.replace('https://res.cloudinary.com/taitokerau-tatou/image/upload/', '');
      imageProcessed = imageProcessed.replace('url("', '');
      imageProcessed = imageProcessed.replace('")', '');
      const imageSplit = imageProcessed.split("/");

      imageId = '';
      imageOptions = '';
      for(let i = 0; i < imageSplit.length; i += 1) {
        if(i === 0) {
          imageOptions = imageSplit[i];
        }else if(i === 1) {
          imageId = imageId + imageSplit[i];
        } else {
          imageId = imageId + '/' + imageSplit[i];
        }
      }
    }
    return {imageId, imageOptions};
  }

  const stripImageOptions = (imageOptions) => {
    const optionsSplit = imageOptions.split(',');
    let width = undefined;
    let height = undefined;

    for(let i = 0; i < optionsSplit.length; i += 1) {
      if(optionsSplit[i].startsWith('w_')) {
        width = optionsSplit[i].replace('w_', '');
      }
      if(optionsSplit[i].startsWith('h_')) {
        height = optionsSplit[i].replace('h_', '');
      }
    }
    return {width, height};
  }

  return (
    <div style={{height: '100%'}}>
      {sections?.length > 0 && sections.map((b, bIndex) => (
        <div
          key={bIndex}
          style={{cursor: advancedEdit ? 'pointer' : 'auto', height: (!advancedEdit && b.section === 'splashBlock') ? '100%' : 'auto'}}
        >
          {advancedEdit &&
            <AdvancedEdit
              block={b}
              onClick={(path) => {
                const pathProcessed = `${bIndex}${path ? `.${path}` : ''}`;
                const block = objectPath.get(sections, pathProcessed);
                const style = block?.options?.style || {};
                const styleKeys = Object.keys(style);
                const styleArray = [];
                for(let i = 0; i < styleKeys.length; i += 1) {
                  styleArray.push({name: styleKeys[i], value: style[styleKeys[i]]});
                }
                setDialogData({
                  type: 'advancedEdit',
                  title: `Fine Tune ${block.type}`,
                  data: {
                    path,
                    block,
                    blockIndex: bIndex,
                  },
                  value: {
                    styleArray,
                    src: block?.options?.src ? block.options.src : undefined,
                  }
                });
                setDialogOpen(true);
              }}
            />
          }
          {!advancedEdit && b.section === 'splashBlock' &&
            <SplashBlockEdit
              block={b}
              removeBlock={(index) => {
                const sectionsProcessed = sections;
                sectionsProcessed.splice(index, 1);
                changeSection('update', '', sectionsProcessed);
              }}
              blockIndex={bIndex}
              maxIndex={sections.length}
              changeBlockIndex={(shift) => {
                if((bIndex + shift) >= 0 && (bIndex + shift) <= (sections?.length - 1)) {
                  const currentIndex = bIndex;
                  const newIndex = bIndex + shift;
                  const tempSection = sections[currentIndex];
                  const sectionsProcessed = sections;
                  sectionsProcessed.splice(currentIndex, 1);
                  sectionsProcessed.splice(newIndex, 0, tempSection);
                  changeSection('update', '', sectionsProcessed);
                }
              }}
              changeSection={changeSection}
              changeParent={changeParent}
              stripImageURL={stripImageURL}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              dialogData={dialogData}
              setDialogData={setDialogData}
            />
          }
          {!advancedEdit && b.section === 'richTextBlock' &&
            <RichTextBlockEdit
              block={b}
              removeBlock={(index) => {
                const sectionsProcessed = sections;
                sectionsProcessed.splice(index, 1);
                changeSection('update', '', sectionsProcessed);
              }}
              blockIndex={bIndex}
              maxIndex={sections.length}
              changeBlockIndex={(shift) => {
                if((bIndex + shift) >= 0 && (bIndex + shift) <= (sections?.length - 1)) {
                  const currentIndex = bIndex;
                  const newIndex = bIndex + shift;
                  const tempSection = sections[currentIndex];
                  const sectionsProcessed = sections;
                  sectionsProcessed.splice(currentIndex, 1);
                  sectionsProcessed.splice(newIndex, 0, tempSection);
                  changeSection('update', '', sectionsProcessed);
                }
              }}
              changeSection={changeSection}
              changeParent={changeParent}
              stripImageURL={stripImageURL}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              dialogData={dialogData}
              setDialogData={setDialogData}
            />
          }
          {!advancedEdit && b.section === 'titleBlock' &&
            <TitleBlockEdit
              block={b}
              removeBlock={(index) => {
                const sectionsProcessed = sections;
                sectionsProcessed.splice(index, 1);
                changeSection('update', '', sectionsProcessed);
              }}
              blockIndex={bIndex}
              maxIndex={sections.length}
              changeBlockIndex={(shift) => {
                if((bIndex + shift) >= 0 && (bIndex + shift) <= (sections?.length - 1)) {
                  const currentIndex = bIndex;
                  const newIndex = bIndex + shift;
                  const tempSection = sections[currentIndex];
                  const sectionsProcessed = sections;
                  sectionsProcessed.splice(currentIndex, 1);
                  sectionsProcessed.splice(newIndex, 0, tempSection);
                  changeSection('update', '', sectionsProcessed);
                }
              }}
              changeSection={changeSection}
              changeParent={changeParent}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              dialogData={dialogData}
              setDialogData={setDialogData}
            />
          }
          {!advancedEdit && b.section === 'bannerBlock' &&
            <BannerBlockEdit
              block={b}
              removeBlock={(index) => {
                const sectionsProcessed = sections;
                sectionsProcessed.splice(index, 1);
                changeSection('update', '', sectionsProcessed);
              }}
              blockIndex={bIndex}
              maxIndex={sections.length}
              changeBlockIndex={(shift) => {
                if((bIndex + shift) >= 0 && (bIndex + shift) <= (sections?.length - 1)) {
                  const currentIndex = bIndex;
                  const newIndex = bIndex + shift;
                  const tempSection = sections[currentIndex];
                  const sectionsProcessed = sections;
                  sectionsProcessed.splice(currentIndex, 1);
                  sectionsProcessed.splice(newIndex, 0, tempSection);
                  changeSection('update', '', sectionsProcessed);
                }
              }}
              changeSection={changeSection}
              changeParent={changeParent}
              stripImageURL={stripImageURL}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              dialogData={dialogData}
              setDialogData={setDialogData}
            />
          }
          {!advancedEdit && b.section === 'galleryBlock' &&
            <GalleryBlockEdit
              block={b}
              removeBlock={(index) => {
                const sectionsProcessed = sections;
                sectionsProcessed.splice(index, 1);
                changeSection('update', '', sectionsProcessed);
              }}
              blockIndex={bIndex}
              maxIndex={sections.length}
              changeBlockIndex={(shift) => {
                if((bIndex + shift) >= 0 && (bIndex + shift) <= (sections?.length - 1)) {
                  const currentIndex = bIndex;
                  const newIndex = bIndex + shift;
                  const tempSection = sections[currentIndex];
                  const sectionsProcessed = sections;
                  sectionsProcessed.splice(currentIndex, 1);
                  sectionsProcessed.splice(newIndex, 0, tempSection);
                  changeSection('update', '', sectionsProcessed);
                }
              }}
              changeSection={changeSection}
              changeParent={changeParent}
              stripImageURL={stripImageURL}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              dialogData={dialogData}
              setDialogData={setDialogData}
            />
          }
          {!advancedEdit && b.section === 'imageBlock' &&
            <ImageBlockEdit
              block={b}
              removeBlock={(index) => {
                const sectionsProcessed = sections;
                sectionsProcessed.splice(index, 1);
                changeSection('update', '', sectionsProcessed);
              }}
              blockIndex={bIndex}
              maxIndex={sections.length}
              changeBlockIndex={(shift) => {
                if((bIndex + shift) >= 0 && (bIndex + shift) <= (sections?.length - 1)) {
                  const currentIndex = bIndex;
                  const newIndex = bIndex + shift;
                  const tempSection = sections[currentIndex];
                  const sectionsProcessed = sections;
                  sectionsProcessed.splice(currentIndex, 1);
                  sectionsProcessed.splice(newIndex, 0, tempSection);
                  changeSection('update', '', sectionsProcessed);
                }
              }}
              changeSection={changeSection}
              changeParent={changeParent}
              stripImageURL={stripImageURL}
              stripImageOptions={stripImageOptions}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              dialogData={dialogData}
              setDialogData={setDialogData}
            />
          }
          {!advancedEdit && b.section === 'parallaxImageBlock' &&
            <ParallaxImageBlockEdit
              block={b}
              removeBlock={(index) => {
                const sectionsProcessed = sections;
                sectionsProcessed.splice(index, 1);
                changeSection('update', '', sectionsProcessed);
              }}
              blockIndex={bIndex}
              maxIndex={sections.length}
              changeBlockIndex={(shift) => {
                if((bIndex + shift) >= 0 && (bIndex + shift) <= (sections?.length - 1)) {
                  const currentIndex = bIndex;
                  const newIndex = bIndex + shift;
                  const tempSection = sections[currentIndex];
                  const sectionsProcessed = sections;
                  sectionsProcessed.splice(currentIndex, 1);
                  sectionsProcessed.splice(newIndex, 0, tempSection);
                  changeSection('update', '', sectionsProcessed);
                }
              }}
              changeSection={changeSection}
              changeParent={changeParent}
              stripImageURL={stripImageURL}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              dialogData={dialogData}
              setDialogData={setDialogData}
            />
          }
          {!advancedEdit && b.section === 'navigationElement' &&
            <NavigationElementEdit
              block={b}
              removeBlock={(index) => {
                const sectionsProcessed = sections;
                sectionsProcessed.splice(index, 1);
                changeSection('update', '', sectionsProcessed);
              }}
              blockIndex={bIndex}
              maxIndex={sections.length}
              changeBlockIndex={(shift) => {
                if((bIndex + shift) >= 0 && (bIndex + shift) <= (sections?.length - 1)) {
                  const currentIndex = bIndex;
                  const newIndex = bIndex + shift;
                  const tempSection = sections[currentIndex];
                  const sectionsProcessed = sections;
                  sectionsProcessed.splice(currentIndex, 1);
                  sectionsProcessed.splice(newIndex, 0, tempSection);
                  changeSection('update', '', sectionsProcessed);
                }
              }}
              changeSection={changeSection}
              changeParent={changeParent}
              stripImageURL={stripImageURL}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              dialogData={dialogData}
              setDialogData={setDialogData}
            />
          }
          {!advancedEdit && b.section === 'videoBlock' &&
            <VideoBlockEdit
              block={b}
              removeBlock={(index) => {
                const sectionsProcessed = sections;
                sectionsProcessed.splice(index, 1);
                changeSection('update', '', sectionsProcessed);
              }}
              blockIndex={bIndex}
              maxIndex={sections.length}
              changeBlockIndex={(shift) => {
                if((bIndex + shift) >= 0 && (bIndex + shift) <= (sections?.length - 1)) {
                  const currentIndex = bIndex;
                  const newIndex = bIndex + shift;
                  const tempSection = sections[currentIndex];
                  const sectionsProcessed = sections;
                  sectionsProcessed.splice(currentIndex, 1);
                  sectionsProcessed.splice(newIndex, 0, tempSection);
                  changeSection('update', '', sectionsProcessed);
                }
              }}
              changeSection={changeSection}
              changeParent={changeParent}
              stripImageURL={stripImageURL}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              dialogData={dialogData}
              setDialogData={setDialogData}
            />
          }
          {!advancedEdit && b.section === 'gridBlock' &&
            <GridBlockEdit
              block={b}
              removeBlock={(index) => {
                const sectionsProcessed = sections;
                sectionsProcessed.splice(index, 1);
                changeSection('update', '', sectionsProcessed);
              }}
              blockIndex={bIndex}
              maxIndex={sections.length}
              changeBlockIndex={(shift) => {
                if((bIndex + shift) >= 0 && (bIndex + shift) <= (sections?.length - 1)) {
                  const currentIndex = bIndex;
                  const newIndex = bIndex + shift;
                  const tempSection = sections[currentIndex];
                  const sectionsProcessed = sections;
                  sectionsProcessed.splice(currentIndex, 1);
                  sectionsProcessed.splice(newIndex, 0, tempSection);
                  changeSection('update', '', sectionsProcessed);
                }
              }}
              changeSection={changeSection}
              changeParent={changeParent}
              stripImageURL={stripImageURL}
              stripImageOptions={stripImageOptions}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              dialogData={dialogData}
              setDialogData={setDialogData}
            />
          }
          {!advancedEdit &&
            b.section !== 'splashBlock' &&
            b.section !== 'richTextBlock' &&
            b.section !== 'titleBlock' &&
            b.section !== 'bannerBlock' &&
            b.section !== 'galleryBlock' &&
            b.section !== 'imageBlock' &&
            b.section !== 'parallaxImageBlock' &&
            b.section !== 'navigationElement' &&
            b.section !== 'videoBlock' &&
            b.section !== 'gridBlock' &&
            <div style={{padding: 20, background: 'red'}}>
              <Typography size='heading'>
                MISSING BLOCK
              </Typography>
            </div>
          }
        </div>
      ))}

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'addSection' &&
            <div style={{padding: 10}}>
              <Input
                label="Insert at index"
                value={dialogData?.value?.index}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      index: value
                    }
                  })
                }}
              />
              {(!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) &&
                <div style={{marginLeft: 5, marginRight: 5}}>
                  <Typography size='subText' style={{color: 'red'}}>
                    Index can be from 1 to {dialogData.data.maxIndex}
                  </Typography>
                </div>
              }
              {dialogData?.data?.limitSections !== 'grid' &&
                <div
                  style={{
                    margin: 5,
                    padding: 10,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 5,
                    cursor: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? undefined : 'pointer',
                    background: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? '#c6c6c6' : undefined,
                  }}
                  onClick={() => {
                    if (dialogData?.value?.index && dialogData?.value?.index >= 1 && dialogData?.value?.index <= dialogData.data.maxIndex) {
                      const section = generateSplashBlock({
                        backgroundId: "media-assets/default_e2qiho.jpg",
                        title: 'Title',
                        tagline: 'Tagline',
                        backgroundStyle: {
                          backgroundBlendMode: 'lighten',
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        }
                      });
                      dialogData.data.callback((dialogData.value.index - 1), section);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }
                  }}
                >
                  <Typography size='title'>
                    Full Page Splash
                  </Typography>
                  <Typography>
                    Will create a title with full page splash and logo
                  </Typography>
                </div>
              }
              {dialogData?.data?.limitSections !== 'grid' &&
                <div
                  style={{
                    margin: 5,
                    padding: 10,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 5,
                    cursor: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? undefined : 'pointer',
                    background: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? '#c6c6c6' : undefined,
                  }}
                  onClick={() => {
                    if (dialogData?.value?.index && dialogData?.value?.index >= 1 && dialogData?.value?.index <= dialogData.data.maxIndex) {
                      const section = generateBannerBlock({
                        backgroundId: "media-assets/default_e2qiho.jpg",
                        title: 'Title',
                        tagline: 'Tagline'
                      });
                      dialogData.data.callback((dialogData.value.index - 1), section);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }
                  }}
                >
                  <Typography size='title'>
                    Banner Block
                  </Typography>
                  <Typography>
                    Will create a title with background image
                  </Typography>
                </div>
              }
              <div
                style={{
                  margin: 5,
                  padding: 10,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderRadius: 5,
                  cursor: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? undefined : 'pointer',
                  background: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? '#c6c6c6' : undefined,
                }}
                onClick={() => {
                  if (dialogData?.value?.index && dialogData?.value?.index >= 1 && dialogData?.value?.index <= dialogData.data.maxIndex) {
                    const section = generateTitleBlock({
                      text: 'Title',
                      styleContainer: {background: paletteData.primary.standard.background},
                      styleText: {color: paletteData.primary.standard.foreground}
                    });
                    dialogData.data.callback((dialogData.value.index - 1), section);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }
                }}
              >
                <Typography size='title'>
                  Title Block
                </Typography>
                <Typography>
                  Will create a title
                </Typography>
              </div>
              <div
                style={{
                  margin: 5,
                  padding: 10,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderRadius: 5,
                  cursor: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? undefined : 'pointer',
                  background: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? '#c6c6c6' : undefined,
                }}
                onClick={() => {
                  if (dialogData?.value?.index && dialogData?.value?.index >= 1 && dialogData?.value?.index <= dialogData.data.maxIndex) {
                    const section = generateRichTextBlock({
                      markdown: '',
                      styleContainer: {},
                      styleText: {},
                      image: undefined
                    });
                    dialogData.data.callback((dialogData.value.index - 1), section);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }
                }}
              >
                <Typography size='title'>
                  Text Block
                </Typography>
                <Typography>
                  Will create a block of text with an optional image
                </Typography>
              </div>
              {dialogData?.data?.limitSections !== 'grid' &&
                <div
                  style={{
                    margin: 5,
                    padding: 10,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 5,
                    cursor: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? undefined : 'pointer',
                    background: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? '#c6c6c6' : undefined,
                  }}
                  onClick={() => {
                    if (dialogData?.value?.index && dialogData?.value?.index >= 1 && dialogData?.value?.index <= dialogData.data.maxIndex) {
                      const section = generateGalleryBlock({
                        styleContainer: {},
                        images: [],
                      });
                      dialogData.data.callback((dialogData.value.index - 1), section);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }
                  }}
                >
                  <Typography size='title'>
                    Gallery Block
                  </Typography>
                  <Typography>
                    Will create a block with a selection of images that can be clicked on to see larger.
                  </Typography>
                </div>
              }
              <div
                style={{
                  margin: 5,
                  padding: 10,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderRadius: 5,
                  cursor: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? undefined : 'pointer',
                  background: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? '#c6c6c6' : undefined,
                }}
                onClick={() => {
                  if (dialogData?.value?.index && dialogData?.value?.index >= 1 && dialogData?.value?.index <= dialogData.data.maxIndex) {
                    const section = generateImageBlock({
                      src: 'https://res.cloudinary.com/taitokerau-tatou/image/upload/q_auto:good,c_fill,w_2048,h_600/media-assets/default_e2qiho.jpg',
                      alt: 'temp image',
                      description: '',
                      styleContainer: {},
                      styleImage: {},
                    });
                    dialogData.data.callback((dialogData.value.index - 1), section);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }
                }}
              >
                <Typography size='title'>
                  Image Block
                </Typography>
                <Typography>
                  Will create an image
                </Typography>
              </div>
              {dialogData?.data?.limitSections !== 'grid' &&
                <div
                  style={{
                    margin: 5,
                    padding: 10,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 5,
                    cursor: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? undefined : 'pointer',
                    background: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? '#c6c6c6' : undefined,
                  }}
                  onClick={() => {
                    if (dialogData?.value?.index && dialogData?.value?.index >= 1 && dialogData?.value?.index <= dialogData.data.maxIndex) {
                      const section = generateParallaxImageBlock({
                        imageId: 'media-assets/default_e2qiho.jpg',
                        styleContainer: {},
                        styleImage: {
                          width: '100%',
                          height: 600,
                        },
                      });
                      dialogData.data.callback((dialogData.value.index - 1), section);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }
                  }}
                >
                  <Typography size='title'>
                    Parallax Image Block
                  </Typography>
                  <Typography>
                    Will create a parallax image
                  </Typography>
                </div>
              }
              {dialogData?.data?.limitSections !== 'grid' &&
                <div
                  style={{
                    margin: 5,
                    padding: 10,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 5,
                    cursor: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? undefined : 'pointer',
                    background: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? '#c6c6c6' : undefined,
                  }}
                  onClick={() => {
                    if (dialogData?.value?.index && dialogData?.value?.index >= 1 && dialogData?.value?.index <= dialogData.data.maxIndex) {
                      const section = generateVideoBlock({
                        video: undefined,
                      });
                      dialogData.data.callback((dialogData.value.index - 1), section);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }
                  }}
                >
                  <Typography size='title'>
                    Video Block
                  </Typography>
                  <Typography>
                    Will create a video.
                  </Typography>
                </div>
              }
              {dialogData?.data?.limitSections !== 'grid' &&
                <div
                  style={{
                    margin: 5,
                    padding: 10,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 5,
                    cursor: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? undefined : 'pointer',
                    background: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? '#c6c6c6' : undefined,
                  }}
                  onClick={() => {
                    if (dialogData?.value?.index && dialogData?.value?.index >= 1 && dialogData?.value?.index <= dialogData.data.maxIndex) {
                      const section = generateGridContainer({
                        options: {},
                        children: [],
                      });
                      dialogData.data.callback((dialogData.value.index - 1), section);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }
                  }}
                >
                  <Typography size='title'>
                    Grid Block
                  </Typography>
                  <Typography>
                    Will create grid to layout other elements.
                  </Typography>
                </div>
              }
              {dialogData?.data?.limitSections !== 'grid' &&
                <div
                  style={{
                    margin: 5,
                    padding: 10,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 5,
                    cursor: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? undefined : 'pointer',
                    background: (!dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData.data.maxIndex) ? '#c6c6c6' : undefined,
                  }}
                  onClick={() => {
                    if (dialogData?.value?.index && dialogData?.value?.index >= 1 && dialogData?.value?.index <= dialogData.data.maxIndex) {
                      const section = generateNavigationElement({
                        id: '',
                        text: '',
                      });
                      dialogData.data.callback((dialogData.value.index - 1), section);
                      setDialogData(undefined);
                      setDialogOpen(false);
                    }
                  }}
                >
                  <Typography size='title'>
                    Navigation Element
                  </Typography>
                  <Typography>
                    Will create a navigation element which builds a menu for the organisation.
                  </Typography>
                </div>
              }

            </div>
          }
          {dialogData?.type === 'moveSection' &&
            <div style={{padding: 10}}>
              <Typography style={{padding: 5}}>
                This sections index is currently {dialogData?.data?.currentIndex}.<br/>
                Indexes go from 1 to {dialogData?.data?.maxIndex}.
              </Typography>
              <Input
                label='Section Index'
                type='number'
                value={dialogData?.value?.index}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      index: value,
                    }
                  })
                }}
              />
            </div>
          }
          {dialogData?.type === 'selectImage' &&
            <div style={{padding: 10}}>
              <Input
                label='Search Images'
                value={dialogData?.value?.search}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      search: value,
                    }
                  })
                }}
              />
              {processing && <ProgressBar palette='secondary'/>}
              <GridContainer>
                {dialogData?.value?.imageId &&
                  <GridCell
                    style={{width: 100, margin: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!processing) {
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_100,h_100/${dialogData?.value?.imageId || 'media-assets/default_e2qiho.jpg'}`}
                      alt={dialogData?.value?.alt}
                      style={{width: '100%'}}
                    />
                    <Typography style={{textAlign: 'center'}}>
                      Current
                    </Typography>
                  </GridCell>
                }
                <GridCell
                  style={{width: 100, margin: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!processing) {
                      imageUploadRef.current.click();
                    }
                  }}
                >
                  <input
                    ref={imageUploadRef}
                    type="file"
                    style={{display: 'none'}}
                    onChange={(event) => {
                      const file = event?.target?.files?.length > 0 ? event.target.files[0] : undefined;
                      handleUploadImage(file, (response, error) => {
                        if(!error) {
                          dialogData?.data.callback({
                            imageId: response.imageId,
                            imageOptions: dialogData?.value?.imageOptions,
                            alt: response.alt,
                          });
                          const image = {
                            imageId: response.imageId,
                            tags: '',
                            alt: response.alt,
                            description: '',
                          };
                          changeParent('insert', 'gallery', image);
                          setDialogData(undefined);
                          setDialogOpen(false);
                        } else {
                          console.log(error);
                        }
                      });
                    }}
                  />
                  <div style={{width: '100%', height: 100, textAlign: 'center', background: '#c6c6c6'}}>
                    <span className="material-icons md-24" style={{marginTop: 35}}>image</span>
                    <span className="material-icons md-24" style={{marginTop: 35}}>add</span>
                  </div>
                  <Typography style={{textAlign: 'center'}}>
                    New
                  </Typography>
                </GridCell>
                {dialogData?.value?.imageId &&
                  <GridCell
                    style={{width: 100, margin: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!processing) {
                        dialogData?.data.callback({imageId: undefined, imageOptions: undefined, alt: undefined});
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <div style={{width: '100%', height: 100, textAlign: 'center', background: '#c6c6c6'}}>
                      <span className="material-icons md-24" style={{marginTop: 35}}>image</span>
                      <span className="material-icons md-24" style={{marginTop: 35}}>remove</span>
                    </div>
                    <Typography style={{textAlign: 'center'}}>
                      Remove
                    </Typography>
                  </GridCell>
                }
                {imageLibrary?.length > 0 && imageLibrary.filter((i, iIndex) => {
                  return (i.alt || '').toLowerCase().includes(dialogData?.value?.search?.toLowerCase());
                }).map((i, iIndex) => (
                  <GridCell
                    key={iIndex}
                    style={{width: 100, margin: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!processing) {
                        dialogData?.data.callback({
                          imageId: i.imageId,
                          imageOptions: dialogData?.value?.imageOptions,
                          alt: i.alt,
                        });
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_100,h_100/${i.imageId}`}
                      alt={i.alt}
                      style={{width: '100%'}}
                    />
                    <Typography style={{textAlign: 'center'}}>
                      {i.alt}
                    </Typography>
                  </GridCell>
                ))}
              </GridContainer>

            </div>
          }
          {dialogData?.type === 'resizeImage' &&
            <div style={{padding: 10}}>
              <GridContainer>
                <GridCell weight={1}>
                  <Input
                    label='Width'
                    type='number'
                    value={dialogData?.value?.width}
                    onChange={(value) => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          width: value,
                        }
                      })
                    }}
                  />
                </GridCell>
                <GridCell weight={1}>
                  <Input
                    label='Height'
                    type='number'
                    value={dialogData?.value?.height}
                    onChange={(value) => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          height: value,
                        }
                      })
                    }}
                  />
                </GridCell>
              </GridContainer>
            </div>
          }
          {dialogData?.type === 'selectVideo' &&
            <div style={{padding: 10}}>
              <Input
                label='Search Videos'
                value={dialogData?.value?.search}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      search: value,
                    }
                  })
                }}
              />
              {videoLibrary?.length > 0 && videoLibrary.filter((v, vIndex) => {
                return ((v.title || '').toLowerCase()).includes(dialogData?.value?.search) ||
                       ((v.description || '').toLowerCase()).includes(dialogData?.value?.search);
              }).map((v, vIndex) => (
                <div
                  key={vIndex}
                  style={{
                    padding: 10,
                    margin: 5,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 5,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    dialogData?.data?.callback(v);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  <Typography size='title'>
                    {v.title}
                  </Typography>
                  <Typography>
                    {v.description}
                  </Typography>
                </div>
              ))}
            </div>
          }
          {dialogData?.type === 'colorPicker' &&
            <div style={{padding: 10}}>
              <ChromePicker
                color={dialogData?.value?.color}
                onChange={(color) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData.value || {}),
                      color: color.hex,
                    }
                  });
                }}
              />
            </div>
          }
          {dialogData?.type === 'advancedEdit' &&
            <div>
              {dialogData?.value?.src !== undefined &&
                <div>
                  <div style={{background: '#c6c6c6', padding: 10}}>
                    <Typography size='title'>
                      Image
                    </Typography>
                  </div>
                  <div style={{padding: 10}}>
                    <Input
                      label='Image Options'
                      value={(stripImageURL(dialogData?.value?.src))?.imageOptions || ''}
                      onChange={(value) => {
                        const {imageId} = stripImageURL(dialogData?.value?.src);
                        setDialogData({
                          ...dialogData,
                          value: {
                            ...(dialogData.value || {}),
                            src: `https://res.cloudinary.com/taitokerau-tatou/image/upload/${value}/${imageId}`,
                          }
                        });
                      }}
                    />
                  </div>
                </div>
              }
              <div style={{background: '#c6c6c6', padding: 10}}>
                <GridContainer>
                  <GridCell weight={1} center={true}>
                    <Typography size='title'>
                      CSS
                    </Typography>
                  </GridCell>
                  <GridCell>
                    <Fab
                      palette='primary'
                      size='small'
                      onClick={() => {
                        setDialogData({
                          ...dialogData,
                          data: {
                            ...(dialogData.data || {}),
                            tempAddStyle: '',
                          }
                        });
                      }}
                    >
                      <span className="material-icons md-24">add</span>
                    </Fab>
                  </GridCell>
                </GridContainer>
              </div>
              <div style={{padding: 10}}>
                {dialogData?.data?.tempAddStyle !== undefined &&
                  <GridContainer  style={{borderStyle: 'solid', borderWidth: 1, borderColor: '#c6c6c6', padding: 10, borderRadius: 5}}>
                    <GridCell weight={1}>
                      <Input
                        label='CSS style name'
                        value={dialogData?.data?.tempAddStyle}
                        onChange={(value) => {
                          setDialogData({
                            ...dialogData,
                            data: {
                              ...(dialogData.data || {}),
                              tempAddStyle: value,
                            }
                          });
                        }}
                      />
                      <div style={{marginLeft: 5, marginRight: 5}}>
                        <Typography size='subText'>
                          all css should be writen in <a href="https://en.wikipedia.org/wiki/Camel_case" target='_blank' rel='noopener noreferrer'>camelCase</a> starting with a lower case letter
                        </Typography>
                      </div>
                    </GridCell>
                    <GridCell style={{marginTop: 23}}>
                      <Button
                        palette='primary'
                        size='small'
                        onClick={() => {
                          const dialogDataProcessed = dialogData;
                          delete dialogDataProcessed.data.tempAddStyle;
                          setDialogData({...dialogDataProcessed});
                        }}
                      >
                        Cancel
                      </Button>
                    </GridCell>
                    <GridCell style={{marginTop: 23, marginLeft: 5}}>
                      <Button
                        palette='primary'
                        size='small'
                        onClick={() => {
                          const dialogDataProcessed = dialogData;
                          dialogDataProcessed.value.styleArray.unshift({name: dialogDataProcessed.data.tempAddStyle, value: ''});
                          delete dialogDataProcessed.data.tempAddStyle;
                          setDialogData({...dialogDataProcessed});
                        }}
                      >
                        Add
                      </Button>
                    </GridCell>
                  </GridContainer>
                }
                {dialogData?.value?.styleArray?.length > 0 && dialogData.value.styleArray.map((s, sIndex) => (
                  <GridContainer key={sIndex}>
                    {s.name !== 'backgroundImage' &&
                      <GridCell weight={1}>
                        <Input
                          label={s.name}
                          value={s.value}
                          onChange={(value) => {
                            const styleArrayProcessed = dialogData?.value?.styleArray || [];
                            styleArrayProcessed[sIndex].value = value;
                            setDialogData({
                              ...dialogData,
                              value: {
                                ...(dialogData.value || {}),
                                styleArray: styleArrayProcessed,
                              }
                            })
                          }}
                        />
                      </GridCell>
                    }
                    {s.name === 'backgroundImage' &&
                      <GridCell weight={1}>
                        <Input
                          label='Image Options'
                          value={(stripImageURL(s.value))?.imageOptions || ''}
                          onChange={(value) => {
                            const {imageId} = stripImageURL(s.value);
                            const styleArrayProcessed = dialogData?.value?.styleArray || [];
                            styleArrayProcessed[sIndex].value = `https://res.cloudinary.com/taitokerau-tatou/image/upload/${value}/${imageId}`;
                            setDialogData({
                              ...dialogData,
                              value: {
                                ...(dialogData.value || {}),
                                styleArray: styleArrayProcessed,
                              }
                            });
                          }}
                        />
                      </GridCell>
                    }
                    <GridCell style={{marginTop: 23}}>
                      <Button
                        size='small'
                        palette='primary'
                        onClick={() => {
                          const styleArrayProcessed = dialogData?.value?.styleArray || [];
                          styleArrayProcessed.splice(sIndex, 1);
                          setDialogData({
                            ...dialogData,
                            value: {
                              ...(dialogData.value || {}),
                              styleArray: styleArrayProcessed,
                            }
                          });
                        }}
                      >
                        {s.name === 'backgroundImage' ? 'Remove Background Image' : 'Remove Style'}
                      </Button>
                    </GridCell>
                  </GridContainer>
                ))}
              </div>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
              {processing && <ProgressBar palette='secondary'/>}
            </GridCell>
            {dialogData?.type === 'moveSection' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    dialogData.data.callbackRemove();
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Delete Section
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'moveSection' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.index || dialogData?.value?.index < 1 || dialogData?.value?.index > dialogData?.data?.maxIndex}
                  palette='primary'
                  onClick={() => {
                    const shift = dialogData?.value?.index - dialogData?.data?.currentIndex;
                    dialogData.data.callbackMove(shift);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Move Section
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'colorPicker' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    dialogData.data.callback(dialogData.value.color);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Choose Color
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'advancedEdit' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || dialogData?.data?.tempAddStyle !== undefined}
                  palette='primary'
                  onClick={() => {
                    const styleProcessed = {};
                    for(let i = 0; i < (dialogData?.value?.styleArray?.length || 0); i += 1) {
                      const test = Number.parseFloat(dialogData.value.styleArray[i].value);
                      if(isNaN(test) || `${dialogData.value.styleArray[i].value}`.includes('%')) {
                        styleProcessed[dialogData.value.styleArray[i].name] = dialogData.value.styleArray[i].value;
                      } else {
                        styleProcessed[dialogData.value.styleArray[i].name] = Number.parseFloat(dialogData.value.styleArray[i].value);
                      }
                    }
                    const blockProcessed = dialogData?.data?.block || {options: {}};
                    blockProcessed.options.style = styleProcessed;
                    if(dialogData?.value?.src !== undefined) {
                      blockProcessed.options.src = dialogData?.value?.src;
                    }

                    changeSection('update', dialogData.data.blockIndex, `${dialogData.data.path}`, blockProcessed);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Modify
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'messageWithCallback' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing}
                  palette='primary'
                  onClick={() => {
                    dialogData.data.callback(dialogData.value);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  OK
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'resizeImage' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.width || !dialogData?.value?.height}
                  palette='primary'
                  onClick={() => {
                    dialogData.data.callback(dialogData.value);
                    setDialogData(undefined);
                    setDialogOpen(false);
                  }}
                >
                  Submit
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

LayoutEdit.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  imageLibrary: PropTypes.arrayOf(PropTypes.shape({})),
  changeSection: PropTypes.func,
  changeParent: PropTypes.func,
};

export default LayoutEdit;
