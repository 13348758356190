import React from 'react';
import PropTypes from 'prop-types';

import OrganisationOfficeForm from '../../../../components/forms/organisationOffice';
import ContactAddressForm from '../../../../components/forms/contactAddress';
import PositionForm from '../../../../components/forms/position';

import {paletteData, Button, Typography, CheckBox} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function EditOrganisationLocationsSubPanel({
    me,
    processing,
    setProcessing,
    handleVerifyEmail,
    dialogData,
    setDialogData,
    dialogOpen,
    setDialogOpen,

    focusedOrganisation,
    handleChangeFocusedOrganisation,

    areas,
    areaReferences,
    setAreaReference,
  }) {

  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Offices
            </Typography>
          </GridCell>
          <GridCell>
            <Button
              palette="secondary"
              size='small'
              onClick={() => {
                const offices = focusedOrganisation.offices;
                offices.push({staff: [], address: {}});
                handleChangeFocusedOrganisation('offices', offices);
                setDialogData({
                  type: 'message',
                  title: 'Office Created',
                });
                setDialogOpen(true);
              }}
            >
              Add office
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {focusedOrganisation?.offices?.length > 0 && focusedOrganisation.offices.map((o, oIndex) => (
        <div key={oIndex}>
          <div style={{marginTop: 10, padding: 10, background: paletteData.primary.standard.background}}>
            <Typography style={{color: paletteData.primary.standard.foreground}}>
              Office {oIndex + 1}
            </Typography>
          </div>
          <div style={{marginTop: 10, padding: 10, background: '#c6c6c6'}}>
            <Typography>
              Office Details
            </Typography>
          </div>
          <div style={{background: 'white'}}>
            <OrganisationOfficeForm
              office={o}
              areaReferences={areaReferences}
              handleChange={(name, value) => {
                const offices = focusedOrganisation.offices;
                offices[oIndex][name] = value;
                handleChangeFocusedOrganisation('offices', offices);
              }}
              dialogData={dialogData}
              setDialogData={setDialogData}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
            />
            <br/>
            <div style={{marginTop: 10, padding: 10, background: '#c6c6c6'}}>
              <Typography>
                Physical Address
              </Typography>
            </div>
            <ContactAddressForm
              address={o.address}
              handleChange={(name, value) => {
                const offices = focusedOrganisation.offices;
                if(!offices[oIndex].address) {
                  offices[oIndex].address = {};
                }
                offices[oIndex].address[name] = value;
                handleChangeFocusedOrganisation('offices', offices);
              }}
            />
            <PositionForm
              position={o.position}
              address={`${o.address?.extra ? `${o.address?.extra} ` : ''}${o.address?.number ? `${o.address?.number} ` : ''}${o.address?.street ? `${o.address?.street} ` : ''}${o.address?.suburb ? `${o.address?.suburb} ` : ''}${o.address?.city ? `${o.address?.city} ` : ''}${o.address?.country ? `${o.address?.country} ` : ''}`}
              handleChange={(name, value) => {
                if(name === 'position') {
                  const offices = focusedOrganisation.offices;
                  offices[oIndex].position = value;
                  handleChangeFocusedOrganisation('offices', offices);
                } else {
                  const offices = focusedOrganisation.offices;
                  if(!offices[oIndex].position) {
                    offices[oIndex].position = {};
                  }
                  offices[oIndex].position[name] = value;
                  handleChangeFocusedOrganisation('offices', offices);
                }
              }}
            />
            <Typography style={{marginLeft: 5}} size='subText'>
              Having your position entered (latitude and longitude) will place a pin on the maps at your office location.
            </Typography>
            <br/>
            <div style={{marginTop: 10, padding: 10, background: '#c6c6c6'}}>
              <Typography>
                Postal Address
              </Typography>
            </div>
            <CheckBox name='postalClonesAddress' label='Same As Physical Address' value={o.postalClonesAddress} onChange={(value) => {
              const offices = focusedOrganisation.offices;
              offices[oIndex].postalClonesAddress = value;
              offices[oIndex].postal = {};
              handleChangeFocusedOrganisation('offices', offices);
            }}/>
            {!o.postalClonesAddress &&
              <ContactAddressForm
                address={o.postal}
                handleChange={(name, value) => {
                  const offices = focusedOrganisation.offices;
                  if(!offices[oIndex].postal) {
                    offices[oIndex].postal = {};
                  }
                  offices[oIndex].postal[name] = value;
                  handleChangeFocusedOrganisation('offices', offices);
                }}
              />
            }
            <br/>
            <GridContainer>
              <GridCell weight={1}/>
              <GridCell>
                <Button
                  palette='primary'
                onClick={() => {
                  setDialogOpen(true);
                  setDialogData({
                    type: 'removeOffice',
                    title: 'Remove Office',
                    message: 'Do you wish to remove this office?',
                    value: {
                      index: oIndex,
                    }
                  });
                }}
              >
                Remove Office
              </Button>
            </GridCell>
          </GridContainer>
        </div>
        </div>
      ))}
    </div>
  );
};

EditOrganisationLocationsSubPanel.propTypes = {
  me: PropTypes.shape({}),
  focusedOrganisation: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedOrganisation: PropTypes.func,
  handleVerifyEmail: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleUploadImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditOrganisationLocationsSubPanel;
