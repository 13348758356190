import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {Typography, Button, Dialog, ProgressBar, SearchBar, paletteData} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

import {resetLists, setOrders, addOrders, setSearch} from '../../../../store/reducers/lists/actions.js';
import {resetReferences, setOrganisationReference} from '../../../../store/reducers/references/actions.js';

import apiRequest from '../../../../tools/apiRequest';
import handleErrorMessage from '../../../../tools/handleErrorMessage';

function OrdersHistoryPanel({
  me,
  processing,
  setProcessing,

  organisationReferences,
  setOrganisationReference,

  orders,
  setOrders,
  addOrders,

  search,
  setSearch,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [initalLoad, setInitalLoad] = useState(false);

  const queryLimit = 50;

  useEffect(() => {
    if(!initalLoad && !orders) {
      setProcessing(true);
      setInitalLoad(true);
      apiRequest({type: 'get', action: 'orders/mine', data: {query: {}, sort: {createdAt: -1}, skip: 0, limit: queryLimit}})
      .then((result) => {
        setSearch({name: 'orders', value: {text: '', filter: undefined, queryDepth: 1}});
        const providersRequest = [];
        for(let i = 0; i < result.data?.length; i += 1) {
          if(!organisationReferences[result.data[i].provider]) {
            providersRequest.push(result.data[i].provider);
          }
        }
        if(providersRequest.length === 0) {
          setProcessing(false);
          setOrders(result.data || []);
        } else {
          apiRequest({type: 'get', action: 'public_organisations', data: {query: {_id: {$in: providersRequest}}, sort: {name: 1}, skip: 0, limit: queryLimit}})
          .then((result2) => {
            for(let i = 0; i < result2.data?.length; i += 1) {
              setOrganisationReference(result2.data[i]);
            }
            setProcessing(false);
            setOrders(result.data || []);
          })
        }
      }).catch((error) => {
        setSearch({name: 'orders', value: {text: '', filter: undefined, queryDepth: 1}});
        setProcessing(false);
        setOrders([]);
        console.log(handleErrorMessage(error));
      });
    }
  }, [orders, organisationReferences, setProcessing, setOrders, setOrganisationReference, setSearch, search, initalLoad]);

  const handleSearch = (searchValue) => {
    setProcessing(true);
    const query = {};

    apiRequest({type: 'get', action: 'orders/mine', data: {query, sort: {name: 1}, skip: 0, limit: queryLimit, organisation: searchValue}})
    .then((result) => {
      setProcessing(false);
      setSearch({name: 'orders', value: {text: searchValue, filter: undefined, queryDepth: 1}});
      const providersRequest = [];
      for(let i = 0; i < result.data?.length; i += 1) {
        if(!organisationReferences[result.data[i].provider]) {
          providersRequest.push(result.data[i].provider);
        }
      }
      if(providersRequest.length === 0) {
        setProcessing(false);
        setOrders(result.data || []);
      } else {
        apiRequest({type: 'get', action: 'public_organisations', data: {query: {_id: {$in: providersRequest}}, sort: {name: 1}, skip: 0, limit: queryLimit}})
        .then((result2) => {
          for(let i = 0; i < result2.data?.length; i += 1) {
            setOrganisationReference(result2.data[i]);
          }
          setProcessing(false);
          setOrders(result.data || []);
        })
      }
    }).catch((error) => {
      setProcessing(false);
      setSearch({name: 'orders', value: {text: searchValue, filter: undefined, queryDepth: 1}});
      setOrders([]);
      setDialogData({
        type: 'message',
        title: 'View order request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleClearSearch = () => {
    setProcessing(true);
    const query = {};
    apiRequest({type: 'get', action: 'orders/mine', data: {query, sort: {name: 1}, skip: 0, limit: queryLimit}})
    .then((result) => {
      setProcessing(false);
      setSearch({name: 'orders', value: {text: '', filter: undefined, queryDepth: 1}});
      const providersRequest = [];
      for(let i = 0; i < result.data?.length; i += 1) {
        if(!organisationReferences[result.data[i].provider]) {
          providersRequest.push(result.data[i].provider);
        }
      }
      if(providersRequest.length === 0) {
        setProcessing(false);
        setOrders(result.data || []);
      } else {
        apiRequest({type: 'get', action: 'public_organisations', data: {query: {_id: {$in: providersRequest}}, sort: {name: 1}, skip: 0, limit: queryLimit}})
        .then((result2) => {
          for(let i = 0; i < result2.data?.length; i += 1) {
            setOrganisationReference(result2.data[i]);
          }
          setProcessing(false);
          setOrders(result.data || []);
        })
      }
    }).catch((error) => {
      setProcessing(false);
      setSearch({name: 'orders', value: {text: '', filter: undefined, queryDepth: 1}});
      setOrders([]);
      setDialogData({
        type: 'message',
        title: 'View order request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleAddMoreResultsToSearch = (searchValue) => {
    setProcessing(true);
    const query = {};
    apiRequest({type: 'get', action: 'orders/mine', data: {query, sort: {name: 1}, skip: (search?.orders?.queryDepth || 0) * queryLimit, limit: queryLimit, organisation: searchValue}})
    .then((result) => {
      setProcessing(false);
      setSearch({name: 'orders', value: {text: search?.orders?.text || '', filter: undefined, queryDepth: (search?.orders?.queryDepth || 0) + 1}});
      const providersRequest = [];
      for(let i = 0; i < result.data?.length; i += 1) {
        if(!organisationReferences[result.data[i].provider]) {
          providersRequest.push(result.data[i].provider);
        }
      }
      if(providersRequest.length === 0) {
        setProcessing(false);
        addOrders(result.data || []);
      } else {
        apiRequest({type: 'get', action: 'public_organisations', data: {query: {_id: {$in: providersRequest}}, sort: {name: 1}, skip: 0, limit: queryLimit}})
        .then((result2) => {
          for(let i = 0; i < result2.data?.length; i += 1) {
            setOrganisationReference(result2.data[i]);
          }
          setProcessing(false);
          addOrders(result.data || []);
        })
      }
    }).catch((error) => {
      setProcessing(false);
      setSearch({name: 'orders', value: {text: search?.orders?.text || '', filter: undefined, queryDepth: (search?.orders?.queryDepth || 0) + 1}});
      addOrders([]);
      setDialogData({
        type: 'message',
        title: 'View order request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleDownloadInvoice = async (order) => {
    setProcessing(true);
    apiRequest({
      type: 'get',
      action: `download/invoice/${order._id}`,
    })
    .then((result) => {
      setProcessing(false);
      const pfdBuffer = Buffer.from(result.data.pdfResult);
      const url = window.URL.createObjectURL(new Blob([pfdBuffer], {type: 'application/pdf'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${result.data.filename}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'download invoice request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleDownloadReceipt = (order) => {
    setProcessing(true);
    apiRequest({
      type: 'get',
      action: `download/receipt/${order._id}`,
    })
    .then((result) => {
      setProcessing(false);
      const pfdBuffer = Buffer.from(result.data.pdfResult);
      const url = window.URL.createObjectURL(new Blob([pfdBuffer], {type: 'application/pdf'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${result.data.filename}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'download receipt request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{padding: 10, background: paletteData.primary.standard.background}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Order History
        </Typography>
      </div>
      <SearchBar
        search={search?.orders?.text}
        onSearch={(searchValue) => handleSearch(searchValue)}
        onClear={() => handleClearSearch()}
        processing={processing}
      />
      {(processing || !orders) && <ProgressBar palette='secondary'/>}
      {orders?.length === 0 &&
        <div style={{padding: 5}}>
          <Typography>
            No Orders
          </Typography>
        </div>
      }
      {orders?.length > 0 && orders?.map((o, oIndex) => (
        <div
          key={oIndex}
          style={{borderStyle: 'solid', borderWidth: 1, borderRadius: 5, marginTop: 5}}
        >
          <div style={{background: paletteData.primary.standard.background, padding: 10}}>
            <GridContainer>
              <GridCell weight={1}>
                <Link to={`organisation/${organisationReferences[o.provider]._id}`} style={{textDecoration: 'none'}}>
                  <Typography style={{color: paletteData.primary.standard.foreground}}>
                    {organisationReferences[o.provider].name}
                  </Typography>
                </Link>
                <Typography size='subText' style={{color: paletteData.primary.standard.foreground}}>
                  {organisationReferences[o.provider].email}
                </Typography>
                <Typography size='subText' style={{color: paletteData.primary.standard.foreground}}>
                  {(new Date(o.createdAt)).toDateString()}
                </Typography>
              </GridCell>
              <GridCell style={{background: 'white', padding: 10, borderRadius: 5}} center={true}>
                <Typography>
                  ${o.payment.total}
                </Typography>
              </GridCell>
            </GridContainer>
          </div>
          <GridContainer>
            <GridCell weight={1}>
              <div style={{padding: 10}}>
                <Typography style={{fontWeight: 500}}>
                  Products
                </Typography>
                {o?.products?.length > 0 && o.products.map((p, pIndex) => (
                  <Typography key={pIndex}>
                    {p.selectedQuantity} x {p.name} - {o.type !== 'pop' && (p.selectedCollection === 'national' || p.selectedCollection === 'international') ? `${p.selectedCollection} post` : 'pickup'}
                  </Typography>
                ))}
              </div>
              {o?.delivery?.pickupLocation &&
                <div style={{padding: 10}}>
                  <Typography style={{fontWeight: 500}}>
                    Pickup Location
                  </Typography>
                  <Typography>
                    {o?.delivery?.pickupLocation}
                  </Typography>
                </div>
              }
            </GridCell>
            <GridCell>
              {o?.stages?.confirmed &&
                <Button disabled={processing} palette='primary' style={{margin: 10}} onClick={() => handleDownloadInvoice(o)}>
                  Download Invoice
                </Button>
              }
              {o?.stages?.paid &&
                <Button disabled={processing} palette='primary' style={{margin: 10}} onClick={() => handleDownloadReceipt(o)}>
                  Download Receipt
                </Button>
              }
            </GridCell>
          </GridContainer>
        </div>
      ))}

      {(orders?.length >= search?.orders?.queryDepth * queryLimit) &&
        <div>
          <br/>
          <Button palette='primary' onClick={() => {
            handleAddMoreResultsToSearch(search.orders.text);
          }} disabled={processing}>
            Load More
          </Button>
        </div>
      }

      {/*dialog*/}
      {dialogOpen &&
        <Dialog open={dialogOpen} handleClose={() => {
          setDialogOpen(false);
          setDialogData(undefined);
        }}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData?.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,
    orders: state.lists.orders,
    organisationReferences: state.references.organisations || {},
  };
};

export default connect(mapStateToProps, {resetReferences, setOrganisationReference, resetLists, setOrders, addOrders, setSearch})(OrdersHistoryPanel);
