import React from 'react';
import PropTypes from 'prop-types';

import {Fab} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function MenuProductSubPanel({
  me,
  focusedOrganisation,
  processing,
  urlHistory,
  setEditing,
}) {
  return (
    <GridContainer style={{flex: 1}}>
        {me?._id && (me?._id === focusedOrganisation?.owner || (me?.roles?.global && (me.roles.global['super-admin'] || me.roles.global['admin'] || me.roles.global['worker']))) &&
          <GridCell>
              <Fab 
                palette='primary' 
                disabled={processing} 
                onClick={() => setEditing(true)}
              >
                <span className="material-icons md-24" >edit_square</span>
              </Fab>
          </GridCell>
        }
        <GridCell>
            <Fab
              palette='primary'
              disabled={processing}
              onClick={() => urlHistory.goBack()}
            >
              <span className="material-icons md-24" >arrow_back</span>
            </Fab>
        </GridCell>
    </GridContainer>
  );
};

MenuProductSubPanel.propTypes = {
  area: PropTypes.shape({}),
};

export default MenuProductSubPanel;