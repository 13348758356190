import React from 'react';
import PropTypes from 'prop-types';

import {Fab} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function MenuEventsSubPanel({
  setRedirect,
  urlHistory,
}) {
  return (
    <GridContainer style={{flex: 1}}>
      <GridCell>
        <Fab
          palette='primary'
          onClick={() => {
            urlHistory.goBack();
          }}
        >
          <span className="material-icons md-24" >arrow_back</span>
        </Fab>
      </GridCell>
      <GridCell weight={1}/>
    </GridContainer>
  );
};

MenuEventsSubPanel.propTypes = {
  area: PropTypes.shape({}),
};

export default MenuEventsSubPanel;
