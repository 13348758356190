import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {Helmet} from "react-helmet";

import {Button, Typography, Dialog, ProgressBar, paletteData} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';
import Hidden from '../../../components/hidden';

import SearchEventsSubPanel from './searchEvents';
import MenuEvents from './menuEvents';

import {setEvents, addEvent, addEvents, replaceEvent, setSearch} from '../../../store/reducers/lists/actions.js';
import {setEventReference, setOrganisationReference, setAreaReference} from '../../../store/reducers/references/actions.js';
import apiRequest from '../../../tools/apiRequest';

function Events({
  me,
  search,
  setSearch,
  queryLimit,
  isMobile,
  refreshCredentialsLoaded,

  events,
  setEvents,
  addEvents,
  addEvent,
  replaceEvent,

  organisationReferences,
  setOrganisationReference,

  areaReferences,
  setAreaReference,

  categoryReferences,
}) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  //page states
  const [redirect, setRedirect] = useState(undefined);
  const urlHistory = useHistory();
  const urlQuery = useQuery();
  const organisationId = urlQuery.get('organisation');
  const categoryId = urlQuery.get('category');
  const areaId = urlQuery.get('area');

  // user feedback
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [processing, setProcessing] = useState(false);

  if(redirect) {
    return <Redirect to={redirect} push={true} />
  }

  return (
    <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - events</title>
        <meta
          name ='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community events and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community events small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>
      {/*------------ main body -----------------*/}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20,
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        background: 'rgba(255, 255, 255, 0.85)',
        overflowX: 'hidden',
      }}>
        {(organisationId || categoryId ||areaId) &&
          <div style={{
            position: 'absolute',
            top: 0,
            right: 20,
            zIndex: 21,
            margin: 10,
          }}>
            <div style={{
              borderRadius: 25,
              background: paletteData.primary.standard.background
            }}>
              <MenuEvents
                setRedirect={setRedirect}
                urlHistory={urlHistory}
              />
            </div>
          </div>
        }
        {/*------------ banner --------------*/}
        <GridContainer
          style={{
            background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundBlendMode: 'lighten',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            height: 200,
          }}
        >
          <GridCell
            weight={1}
            center={true}
            centerWeights={{top: 2, bottom: 3}}
          >
            <Typography size='heading' style={{textAlign: 'center'}}>
              Events
            </Typography>
          </GridCell>
        </GridContainer>
        {/*------------- layout -------------*/}
        <GridContainer>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
          <GridCell weight={10} style={{padding: 10}}>
            {/*------------ sub panels --------------*/}
            {refreshCredentialsLoaded &&
              <SearchEventsSubPanel
                isMobile={isMobile}
                search={search}
                setSearch={setSearch}
                processing={processing}
                setProcessing={setProcessing}
                setRedirect={setRedirect}
                queryLimit={queryLimit}
                me={me}
                dialogData={dialogData}
                setDialogData={setDialogData}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}

                events={events}
                setEvents={setEvents}
                addEvents={addEvents}
                replaceEvent={replaceEvent}
                setEventReference={setEventReference}
                organisationReferences={organisationReferences}
                setOrganisationReference={setOrganisationReference}

                areaReferences={areaReferences}
                setAreaReference={setAreaReference}

                categoryReferences={categoryReferences}
              />
            }
          </GridCell>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
        </GridContainer>
      </div>

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'addEvent' &&
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                   onClick={() => {
                     setProcessing(true);
                     const updateEvent = dialogData.value || {};
                     apiRequest({type: 'post', action: `events/create`, data: {updateEvent}})
                     .then((result) => {
                       addEvent(result.data.event);
                       setEventReference(result.data.event);
                       setProcessing(false);
                       setDialogData(undefined);
                       setDialogOpen(false);
                       if(dialogData?.data?.callback) {
                         dialogData.data.callback(result.data.event);
                       }
                     }).catch((error) => {
                       setProcessing(false);
                       setDialogData(undefined);
                       setDialogOpen(false);
                     });
                  }}
                >
                  Create
                </Button>
                {processing &&
                  <ProgressBar palette='secondary'/>
                }
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    organisationReferences: state.references.organisations || {},
    categoryReferences: state.references.categories || {},
    areaReferences: state.references.areas || {},
    search: state.lists.search,
    events: state.lists.events,
    categories: state.lists.categories,
    queryLimit: state.lists.queryLimit,
  };
};

export default connect(mapStateToProps, {setEventReference, setEvents, addEvents, setSearch, setOrganisationReference, addEvent, replaceEvent, setAreaReference})(Events);
