import React from 'react';
import PropTypes from 'prop-types';

import {GridContainer, GridCell} from '../../../grid';
import Hidden from '../../../hidden';

function Body({children}) {
  return (
    <GridContainer>
      <Hidden breakpoint='hiddenlessthan650'>
        <GridCell weight={1}/>
      </Hidden>
      <GridCell weight={10} style={{display: 'flex', flexDirection: 'column',}}>
        {children}
      </GridCell>
      <Hidden breakpoint='hiddenlessthan650'>
        <GridCell weight={1}/>
      </Hidden>
    </GridContainer>
  );
}

Body.propTypes = {
  children: PropTypes.node,
}

export default Body;
