import React from 'react';
import PropTypes from 'prop-types';

import ListingForm from '../../../../components/forms/listing';

import {paletteData, Typography} from '../../../../components/styles';

function EditProductInformationSubPanel({
    processing,
    dialogOpen,
    dialogData,
    setDialogOpen,
    setDialogData,

    focusedProduct,
    handleChangeFocusedProduct,
    focusedOrganisation,
  }) {
    return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Listing
        </Typography>
      </div>
      <ListingForm
        preview={focusedProduct}
        processing={processing}
        handleChange={(name, value) => handleChangeFocusedProduct(name, value)}
        dialogOpen={dialogOpen}
        dialogData={dialogData}
        setDialogData={setDialogData}
        setDialogOpen={setDialogOpen}
      />
      <br/><br/><br/>
    </div>
  );
};

EditProductInformationSubPanel.propTypes = {
  focusedProduct: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedProduct: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditProductInformationSubPanel;
