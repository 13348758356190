import React, {useState, useEffect} from "react";
import {useLoadScript} from '@react-google-maps/api';
import {connect} from 'react-redux';
import jwt from 'jsonwebtoken';
import {loadStripe} from '@stripe/stripe-js';
import {
  Switch,
  Route,
  Link,
} from "react-router-dom";

import Home from '../pages/public/home';
import Area from '../pages/public/area';
import Category from '../pages/public/category';
import Communities from '../pages/public/communities';
import Areas from '../pages/public/areas';
import Organisation from '../pages/public/organisation';
import Organisations from '../pages/public/organisations';
import Event from '../pages/public/event';
import Events from '../pages/public/events';
import Product from '../pages/public/product';
import Products from '../pages/public/products';
// import Cart from '../pages/public/cart';
import Unsubscribe from '../pages/public/unsubscribe';
import Login from '../pages/public/login';
import About from '../pages/public/about';
import Console from '../pages/private/console';
import NavigationError from '../pages/public/navigationError';
import EmailVerification  from '../pages/public/emailVerification';
import PasswordReset  from '../pages/public/passwordReset';
import Messages from '../pages/public/messages';

import Hidden from '../components/hidden';
import {GridContainer, GridCell} from '../components/grid';
import {Typography, LinkButton, Fab, MenuDropdown, ProgressBar, Button, paletteData} from '../components/styles';

import {setApiToken} from '../store/reducers/auth/actions.js';
import {setProfile} from '../store/reducers/user/actions.js';
import {resetLists, setCategories, setSearch} from '../store/reducers/lists/actions.js';
import {resetReferences, setCategoryReferences} from '../store/reducers/references/actions.js';
import apiRequest from '../tools/apiRequest';

const googleMapsLibraries = ['places'];

function Routes({lists, references, cart, auth, user, setApiToken, setProfile, resetLists, resetReferences, setCategories, setCategoryReferences, setSearch}) {
  const [me, setMe] = useState({});
  const [myPosition, setMyPosition] = useState(undefined);
  const [myPositionLoaded, setMyPositionLoaded] = useState(false);
  const [stripePromise, setStripePromise] = useState(undefined);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchMenuOpen, setSearchMenuOpen] = useState(false);

  const [initialLoad, setInitialLoad] = useState({
    categories: false,
    refreshCredentials: false,
    stripe: false,
  });
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [refreshCredentialsLoaded, setRefreshCredentialsLoaded] = useState(false);
  const [stripeLoaded, setStripeLoaded] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(undefined);

  useEffect(() => {
    if(isMobile === undefined) {
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    if(!allLoaded && categoriesLoaded && refreshCredentialsLoaded && stripeLoaded) {
      setAllLoaded(true);
    }
    if(!initialLoad.categories && lists.categories) {
      setInitialLoad({
        ...initialLoad,
        categories: true,
      });
      setCategoriesLoaded(true);
    } else if(!initialLoad.categories) {
      setInitialLoad({
        ...initialLoad,
        categories: true,
      });
      setSearch({name: 'categories', value: {text: '', queryDepth: 1, filter: undefined}});
      apiRequest({type: 'get', action: 'public_categories', data: {}})
      .then((result) => {
        setCategoriesLoaded(true);
        setCategories(result?.data?.categories || []);
        setCategoryReferences(result?.data?.categories || []);
      }).catch((error) => {
        setCategoriesLoaded(true);
        setCategories([]);
      });
    }

    if(!initialLoad.stripe && stripePromise) {
      setInitialLoad({
        ...initialLoad,
        stripe: true,
      });
      setStripeLoaded(true);
    } else if(!initialLoad.stripe) {
      setInitialLoad({
        ...initialLoad,
        stripe: true,
      });
      setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
      setStripeLoaded(true);
    }

    if(!initialLoad.refreshCredentials && auth?.api?.access_token) {
      setInitialLoad({
        ...initialLoad,
        refreshCredentials: true,
      });
      setRefreshCredentialsLoaded(true);
      setMe({
        ...(jwt.decode(auth?.api?.access_token))?.data,
        profile: user.profile,
      });
    } else if(!initialLoad.refreshCredentials) {
      setInitialLoad({
        ...initialLoad,
        refreshCredentials: true,
      });
      apiRequest({type: 'post', action: 'auth/refresh'}).then((refreshResult) => {
        setApiToken(refreshResult.data.auth);
        if(refreshResult.data.auth) {
          const user = (jwt.decode(refreshResult.data.auth?.access_token)).data;
          apiRequest({type: 'get', action: `users/${user._id}/profile`}).then((profileResult) => {
            setRefreshCredentialsLoaded(true);
            setProfile(profileResult.data.profile || undefined);
            setMe({
              ...(jwt.decode(refreshResult.data.auth?.access_token))?.data,
              profile: profileResult.data.profile,
            });
          }).catch((error) => {
            setRefreshCredentialsLoaded(true);
            setMe({});
            setApiToken(undefined);
            setProfile(undefined);
          });
        } else {
          setRefreshCredentialsLoaded(true);
          setMe({});
          setApiToken(undefined);
          setProfile(undefined);
        }
      }).catch((error) => {
        setRefreshCredentialsLoaded(true);
        setMe({});
        setApiToken(undefined);
        setProfile(undefined);
      });
    }
  }, [isMobile, myPositionLoaded, lists, references, auth, user, setApiToken, setProfile, stripePromise, setCategories, setSearch, initialLoad, allLoaded, refreshCredentialsLoaded, categoriesLoaded, stripeLoaded, setCategoryReferences]);

  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: googleMapsLibraries,
    nonce: document.querySelector('meta[name="CSP-NONCE"]')?.getAttribute('content'),
  });

  const requestPosition = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setMyPosition({lat: position.coords.latitude, lng: position.coords.longitude});
        setMyPositionLoaded(true);
      },
      () => setMyPositionLoaded(true),
    );
  }

  const resetCredentials = () => {
    setInitialLoad({
      categories: false,
      refresh: false,
      stripe: false,
    });
    setCategoriesLoaded(false);
    setStripeLoaded(false);
    setRefreshCredentialsLoaded(false);
    setAllLoaded(false);
  }

  if(loadError) return (<div>Error Loading Page</div>);
  if(!isLoaded) return (<div>Loading</div>);
  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
        <GridContainer style={{
          height: 63,
          background: paletteData.primary.standard.background,
        }}>
          <GridCell center={true} style={{margin: 5}}>
            <Link to='/'>
              <img
                style={{maxWidth: 45}}
                src='https://res.cloudinary.com/taitokerau-tatou/image/upload/c_scale,q_auto:good,w_300/v1617227279/media-assets/Reretahi-_Logo-V3_-_no_words_koehgh.png'
                alt='reretahi logo'
              />
            </Link>
          </GridCell>
          <GridCell center={true}>
            <Link to='/' style={{textDecoration: 'none'}}>
              <Typography size='title' style={{color: paletteData.primary.standard.foreground, marginLeft: 10, letterSpacing: 2.3}}>
                RERENGĀTAHI
              </Typography>
              <Typography size='subText' style={{color: paletteData.primary.standard.foreground, marginLeft: 10, letterSpacing: 2}}>
                NORTHLAND TOGETHER
              </Typography>
            </Link>
          </GridCell>
          <GridCell weight={1}/>
          <Hidden breakpoint='hiddenlessthan800' forceHideOnPhone={true}>
            <GridCell center={true}>
              <LinkButton to="/" style={{color: paletteData.primary.standard.foreground}}>Home</LinkButton>
            </GridCell>
          </Hidden>
          <Hidden breakpoint='hiddenlessthan800' forceHideOnPhone={true}>
            <GridCell center={true}>
              <LinkButton to="/about" style={{color: paletteData.primary.standard.foreground}}>About Us</LinkButton>
            </GridCell>
          </Hidden>
          <Hidden breakpoint='hiddenlessthan800' forceHideOnPhone={true}>
            <GridCell center={true} style={{position: 'relative'}}>
              <Button
                style={{color: paletteData.primary.standard.foreground}}
                onClick={() => {
                  setSearchMenuOpen(!searchMenuOpen);
                }}
              >
                <GridContainer>
                  <GridCell center={true}>
                    Search
                  </GridCell>
                  <GridCell center={true}>
                    <span className="material-icons md-24">expand_more</span>
                  </GridCell>
                </GridContainer>
              </Button>
              {searchMenuOpen &&
                <MenuDropdown
                  open={searchMenuOpen}
                  handleClose={() => setSearchMenuOpen(false)}
                  position={{right: 10, top: 50}}
                  style={{padding: 10, marginTop: 5, borderRadius: 5, zIndex: 100}}
                >
                  <div style={{padding: 5}}>
                    <LinkButton
                      to="/communities?filter=areas"
                      onClick={() => {
                        if(!myPositionLoaded) requestPosition();
                        setSearchMenuOpen(false)
                      }}
                    >
                      Areas
                    </LinkButton>
                  </div>
                  <div style={{padding: 5}}>
                    <LinkButton
                      to="/communities?filter=categories"
                      onClick={() => {
                        if(!myPositionLoaded) requestPosition();
                        setSearchMenuOpen(false)
                      }}
                    >
                      Groups
                    </LinkButton>
                  </div>
                  <div style={{padding: 5}}>
                    <LinkButton
                      to="/organisations"
                      onClick={() => {
                        if(!myPositionLoaded) requestPosition();
                        setSearchMenuOpen(false)
                      }}
                    >
                      Organisations
                    </LinkButton>
                  </div>
                </MenuDropdown>
              }
            </GridCell>
          </Hidden>
          {/*cart && Object.keys(cart).length > 0 &&
            <Hidden breakpoint='hiddenlessthan800' forceHideOnPhone={true}>
              <GridCell center={true}>
                <LinkButton to="/cart" style={{color: paletteData.primary.standard.foreground}}><span className="material-icons md-24">shopping_cart</span></LinkButton>
              </GridCell>
            </Hidden>
          */}
          {!me._id &&
            <Hidden breakpoint='hiddenlessthan800' forceHideOnPhone={true}>
              <GridCell center={true}>
                <LinkButton to="/login" style={{color: paletteData.primary.standard.foreground}}>
                  Login
                  {!refreshCredentialsLoaded && <ProgressBar palette='primary'/>}
                </LinkButton>
              </GridCell>
            </Hidden>
          }
          {me._id &&
            <Hidden breakpoint='hiddenlessthan800' forceHideOnPhone={true}>
              <GridCell center={true}>
                <LinkButton to="/console" style={{color: paletteData.primary.standard.foreground}}>{me?.name || 'My Console'}</LinkButton>
              </GridCell>
            </Hidden>
          }

          <Hidden breakpoint='hiddengreaterthan800' forceShowOnPhone={true}>
            <GridCell center={true} style={{padding: 10}}>
              <Fab palette='secondary' size='small' onClick={() => {
                setMenuOpen(!menuOpen);
              }}>
                <span className="material-icons md-24">menu</span>
              </Fab>
              {menuOpen &&
                <MenuDropdown
                  open={menuOpen}
                  handleClose={() => setMenuOpen(false)}
                  position={{right: 10, top: 50}}
                  style={{padding: 10, marginTop: 5, borderRadius: 5, zIndex: 100}}
                >
                  <div style={{minWidth: 100}}>
                    <div style={{padding: 5}}>
                      <LinkButton to="/" onClick={() => setMenuOpen(false)}>Home</LinkButton>
                    </div>
                    <div style={{padding: 5}}>
                      <LinkButton to="/about" onClick={() => setMenuOpen(false)}>About Us</LinkButton>
                    </div>
                    <div style={{padding: 5}}>
                      <LinkButton
                        to="/communities?filter=areas"
                        onClick={() => {
                          if(!myPositionLoaded) requestPosition();
                          setMenuOpen(false)
                        }}
                      >
                        Areas
                      </LinkButton>
                    </div>
                    <div style={{padding: 5}}>
                      <LinkButton
                        to="/communities?filter=categories"
                        onClick={() => {
                          if(!myPositionLoaded) requestPosition();
                          setMenuOpen(false)
                        }}
                      >
                        Groups
                      </LinkButton>
                    </div>
                    <div style={{padding: 5}}>
                      <LinkButton
                        to="/organisations"
                        onClick={() => {
                          if(!myPositionLoaded) requestPosition();
                          setMenuOpen(false)
                        }}
                      >
                        Organisations
                      </LinkButton>
                    </div>
                    {cart && Object.keys(cart).length > 0 &&
                      <div style={{padding: 5}}>
                        <LinkButton to="/cart" onClick={() => setMenuOpen(false)}><span className="material-icons md-24">shopping_cart</span></LinkButton>
                      </div>
                    }
                    {!me._id && refreshCredentialsLoaded &&
                      <div style={{padding: 5}}>
                        <LinkButton to="/login" onClick={() => setMenuOpen(false)}>Login</LinkButton>
                      </div>
                    }
                    {me._id &&
                      <div style={{padding: 5}}>
                        <LinkButton to="/console" onClick={() => setMenuOpen(false)}>My Console</LinkButton>
                      </div>
                    }
                  </div>
                </MenuDropdown>
              }
            </GridCell>
          </Hidden>
        </GridContainer>
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route exact path="/about">
            <About/>
          </Route>
          <Route exact path="/login">
            <Login setMe={setMe}/>
          </Route>
          <Route exact path="/messages/:type/:id">
            <Messages me={me} myPosition={myPosition} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          <Route exact path="/category/:id">
            <Category me={me} myPosition={myPosition} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          <Route exact path="/area/:id">
            <Area me={me} myPosition={myPosition} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          <Route exact path="/areas">
            <Areas me={me} myPosition={myPosition} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          <Route exact path="/communities">
            <Communities me={me} myPosition={myPosition} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          <Route exact path="/organisation/:id">
            <Organisation me={me} myPosition={myPosition} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          <Route exact path="/organisations">
            <Organisations me={me} myPosition={myPosition} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          <Route exact path="/event/:id">
            <Event me={me} myPosition={myPosition} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          <Route exact path="/events">
            <Events me={me} myPosition={myPosition} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          <Route exact path="/product/:id">
            <Product me={me} myPosition={myPosition} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          <Route exact path="/products">
            <Products me={me} myPosition={myPosition} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          {/*
            <Route exact path="/cart">
              <Cart myPosition={myPosition} me={me} setMe={setMe} auth={auth} stripePromise={stripePromise} isMobile={isMobile}/>
            </Route>
          */}
          <Route exact path="/console">
            <Console
              isMobile={isMobile}
              myPosition={myPosition}
              me={me} setMe={setMe}
              auth={auth}
              resetCredentials={resetCredentials}
              refreshCredentialsLoaded={refreshCredentialsLoaded}
            />
          </Route>
          <Route exact path="/unsubscribe">
            <Unsubscribe me={me} myPosition={myPosition} isMobile={isMobile}/>
          </Route>
          <Route exact path="/emailverification/:id">
            <EmailVerification me={me} auth={auth} setMe={setMe} isMobile={isMobile} refreshCredentialsLoaded={refreshCredentialsLoaded}/>
          </Route>
          <Route exact path="/passwordreset/:id">
            <PasswordReset me={me} isMobile={isMobile}/>
          </Route>
          <Route path="*">
            <NavigationError isMobile={isMobile}/>
          </Route>
        </Switch>
        <div style={{background: paletteData.primary.standard.background, padding: 5, textAlign: 'right'}}>
          <a
            style={{textDecoration: 'none'}}
            href='https://heiwi.co.nz/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Typography size='subText' style={{color: paletteData.primary.standard.foreground}}>
              Copyright He Iwi Kotahi Tatau Trust 2021
            </Typography>
          </a>
          <a
            style={{textDecoration: 'none'}}
            href='mailto:info@rerengatahi.nz'
          >
            <Typography size='subText' style={{color: paletteData.primary.standard.foreground}}>
              Contact: info@rerengatahi.nz
            </Typography>
          </a>
          <a
            style={{textDecoration: 'none'}}
            href='http://redincmedia.co.nz/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Typography size='subText' style={{color: paletteData.primary.standard.foreground}}>
              Created by Red Inc Transmedia
            </Typography>
          </a>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {setApiToken, setProfile, resetLists, resetReferences, setCategories, setCategoryReferences, setSearch})(Routes);
