import React from 'react';
import PropTypes from 'prop-types';

import InvoiceHeaderForm from '../../../../components/forms/invoiceHeader';
import OrganisationInformationForm from '../../../../components/forms/organisationInformation';
import OrganisationSocialForm from '../../../../components/forms/organisationSocial';

import {paletteData, Typography, Button} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function EditOrganisationInformationSubPanel({
    me,
    processing,
    dialogOpen,
    dialogData,
    setProcessing,
    setDialogOpen,
    setDialogData,

    focusedOrganisation,
    handleChangeFocusedOrganisation,

    categories,
  }) {
    return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <GridContainer>
          <GridCell weight={1}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Categories
            </Typography>
          </GridCell>
          <GridCell>
            <Button
              palette="secondary" style={{padding: 5, fontSize: '.8em'}}
              onClick={() => {
                setDialogOpen(true);
                setDialogData({
                  type: 'addCategory',
                  title: 'Add a category',
                });
              }}
            >
              Add category
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      {(!focusedOrganisation?.categories || focusedOrganisation?.categories.length === 0) &&
        <div style={{margin: 5}}>
          <Typography>
            No Categories
          </Typography>
        </div>
      }
      {focusedOrganisation?.categories?.length > 0 &&
        <div style={{margin: 5}}>
          {focusedOrganisation?.categories.map((c, cIndex) => (
            <Typography
              key={cIndex}
              style={{display: 'inline', cursor: 'pointer'}}
              onClick={() => {
                const tempCategories = focusedOrganisation?.categories;
                tempCategories.splice(cIndex, 1);
                handleChangeFocusedOrganisation('categories', tempCategories);
              }}
            >
              {c}{cIndex !== (focusedOrganisation?.categories.length - 1) ? ', ' : ''}
            </Typography>
          ))}
        </div>
      }
      <br/>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Details
        </Typography>
      </div>
      <OrganisationInformationForm
        me={me}
        processing={processing}
        focusedOrganisation={focusedOrganisation}
        handleChange={(name, value) => handleChangeFocusedOrganisation(name, value)}
      />

      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Social Media
        </Typography>
      </div>
      <OrganisationSocialForm
        social={focusedOrganisation?.social}
        handleChange={(name, value) => {
          const socialProcessed = focusedOrganisation?.social || {};
          socialProcessed[name] = value;
          handleChangeFocusedOrganisation('social', socialProcessed);
        }}
      />
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Invoice Header
        </Typography>
      </div>
      <InvoiceHeaderForm
        header={focusedOrganisation}
        processing={processing}
        handleChange={(name, value) => handleChangeFocusedOrganisation(name, value)}
        dialogOpen={dialogOpen}
        dialogData={dialogData}
        setDialogData={setDialogData}
        setDialogOpen={setDialogOpen}
      />
      <br/>
    </div>
  );
};

EditOrganisationInformationSubPanel.propTypes = {
  focusedOrganisation: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedOrganisation: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditOrganisationInformationSubPanel;
