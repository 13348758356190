import React, {useState} from 'react';
import {connect} from 'react-redux';

import {Typography, Button, Dialog, Input, ProgressBar, paletteData} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';
import AddressForm from '../../../../components/forms/contactAddress';

import {setApiToken} from '../../../../store/reducers/auth/actions.js';
import {setProfile} from '../../../../store/reducers/user/actions.js';
import {resetLists} from '../../../../store/reducers/lists/actions.js';
import {resetReferences, setUserReference, setProfileReference} from '../../../../store/reducers/references/actions.js';

import apiRequest from '../../../../tools/apiRequest';
import handleErrorMessage from '../../../../tools/handleErrorMessage';

function MyProfilePanel({
  processing,
  setProcessing,
  resetCredentials,

  resetLists,
  resetReferences,

  me,
  setMe,
  setApiToken,
  setProfile,
  setUserReference,
  setProfileReference,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleUpdateUser = (updateData) => {
    setProcessing(true);
    const userId = updateData._id;
    const updateUser = {
      name: updateData.name,
      email: updateData.email,
      whitelist: updateData.whitelist,
      blacklist: updateData.blacklist,
    };
    const updateProfile = updateData.profile;

    apiRequest({type: 'patch', action: `users/update/${userId}`, data: {updateUser, updateProfile}})
    .then((result) => {
      if(result.data.auth) {
        setApiToken(result.data.auth);
      }
      setProfile(updateProfile);
      setMe({
        ...me,
        ...updateUser,
        profile: updateProfile,
      });
      setUserReference({
        _id: userId,
        verification: updateData.verification,
        roles: updateData.roles,
         ...updateUser
       });
      setProfileReference(updateProfile);
      setProcessing(false);
      setDialogOpen(false);
      setDialogData(undefined);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Update user request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleVerifyEmail = (email) => {
    setProcessing(true);
    setDialogData({
      type: 'processingNotification',
      title: 'Processing Verification Request',
      message: 'Hold on while we process your verification request',
    });
    setDialogOpen(true);

    const updateKey = {
      name: me?.name,
      userId: me?._id,
      type: 'emailverification',
      container: 'public',
      value: email,
    }

    apiRequest({type: 'post', action: `keys/create`, data: {updateKey}})
    .then((result) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Email Verification Sent',
        message: 'Please check your emails and follow the instructions to verify this email address',
      });
      setDialogOpen(true);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Verify email request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  };

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{padding: 10, background: paletteData.primary.standard.background}}>
        <GridContainer>
          <GridCell weight={1} center={true}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              My Details
            </Typography>
          </GridCell>
          <GridCell center={true} style={{marginRight: 10}} onClick={() => {
            setDialogData({
              type: 'updateUser',
              title: 'Update Details',
              value: me,
            });
            setDialogOpen(true);
          }}>
            <Button size='small' palette='secondary'>
              Update Details
            </Button>
          </GridCell>
          <GridCell center={true} style={{marginRight: 10}}>
            <Button size='small' palette='secondary' onClick={() => {
              setDialogData({
                type: 'resetPassword',
                title: 'Reset Password',
                value: {
                  newPassword: '',
                },
              });
              setDialogOpen(true);
            }}>
              Reset Password
            </Button>
          </GridCell>
          <GridCell center={true}>
            <Button size='small' palette='secondary' onClick={() => {
              setDialogData({
                type: 'logout',
                title: 'Log Out',
                message: 'Are you sure you wish to log out?',
              });
              setDialogOpen(true);
            }}>
              Log Out
            </Button>
          </GridCell>
        </GridContainer>
      </div>
      <div style={{padding: 10}}>
        <GridContainer>
          <GridCell style={{width: 100}}>
            <Typography>
              Username:<br/>
              Email:<br/>
              <br/>
              {me?.profile?.nameFirst ? <span>Name: <br/></span> : ''}
              {me?.profile?.phone?.cell ? <span>Phone:<br/></span> : ''}
              {me?.profile?.address?.city ? <span>Address:</span> : ''}
            </Typography>
          </GridCell>
          <GridCell weight={1}>
            <Typography>
              {me?.name}<br/>
              {me?.email} {me?.email && !me?.verification?.contact[me?.email] ? <span style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {if(!processing) {handleVerifyEmail(me?.email)}}}>Verify Email</span> : ''}<br/>
              <br/>
              {me?.profile?.nameFirst} {me?.profile?.nameMiddle} {me?.profile?.nameLast}<br/>
              {me?.profile?.phone?.cell} <br/>
              {me?.profile?.address?.extra ? `${me.profile.address.extra} ` : ''}
              {me?.profile?.address?.number ? `${me.profile.address.number} ` : ''}
              {me?.profile?.address?.street ? `${me.profile.address.street} ` : ''}
              {me?.profile?.address?.suburb ? `${me.profile.address.suburb} ` : ''}
              {me?.profile?.address?.city ? `${me.profile.address.city} ` : ''}
              {me?.profile?.address?.country ? `${me.profile.address.country} ` : ''}
              {me?.profile?.address?.postcode ? `${me.profile.address.postcode} ` : ''}
            </Typography>
          </GridCell>
        </GridContainer>
      </div>


      {/*dialog*/}
      {dialogOpen &&
        <Dialog id='logout-dialog' open={dialogOpen} handleClose={() => {
          setDialogOpen(false);
          setDialogData(undefined);
        }}>
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData?.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'processingNotification' &&
            <div style={{padding: 10}}>
              <Typography>
                ...loading
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          }
          {dialogData?.type === 'resetPassword' &&
            <div style={{padding: 10}}>
              <Input
                name="newPassword"
                palette='secondary'
                label="New Password"
                type="password"
                autoComplete="new-password"
                value={dialogData?.value?.newPassword || ''}
                onChange={(value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      newPassword: value,
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              />
            </div>
          }
          {dialogData?.type === 'updateUser' &&
            <div>
              <div style={{padding: 10}}>
                <Typography style={{fontWeight: 500}}>
                  Account Details
                </Typography>
              </div>
              <div style={{padding: 5}}>
                <Input
                  name="username"
                  palette='secondary'
                  label="Username"
                  type="text"
                  value={dialogData?.value?.name || ''}
                  onChange={(value) => {
                    const dialogDataProcessed = {
                      ...dialogData,
                      value: {
                        ...(dialogData?.value || {}),
                        name: value,
                      }
                    };
                    setDialogData(dialogDataProcessed);
                  }}
                />
                <Input
                  name="email"
                  palette='secondary'
                  label="Email"
                  type="text"
                  value={dialogData?.value?.email|| ''}
                  onChange={(value) => {
                    const dialogDataProcessed = {
                      ...dialogData,
                      value: {
                        ...(dialogData?.value || {}),
                        email: value,
                      }
                    };
                    setDialogData(dialogDataProcessed);
                  }}
                />
              </div>
              <br/>
              <div style={{padding: 10}}>
                <Typography style={{fontWeight: 500}}>
                  Contact Details
                </Typography>
              </div>
              <div style={{padding: 5}}>
                <GridContainer>
                  <GridCell weight={1}>
                    <Input
                      name="nameFirst"
                      palette='secondary'
                      label="Name"
                      type="text"
                      value={dialogData?.value?.profile?.nameFirst || ''}
                      onChange={(value) => {
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            profile: {
                              ...(dialogData?.value?.profile || {}),
                              nameFirst: value,
                            },
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                  </GridCell>
                  <GridCell weight={1}>
                    <Input
                      name="nameMiddle"
                      palette='secondary'
                      label="Middle Names"
                      type="text"
                      value={dialogData?.value?.profile?.nameMiddle || ''}
                      onChange={(value) => {
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            profile: {
                              ...(dialogData?.value?.profile || {}),
                              nameMiddle: value,
                            },
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                  </GridCell>
                  <GridCell weight={1}>
                    <Input
                      name="nameLast"
                      palette='secondary'
                      label="Surname"
                      type="text"
                      value={dialogData?.value?.profile?.nameLast || ''}
                      onChange={(value) => {
                        const dialogDataProcessed = {
                          ...dialogData,
                          value: {
                            ...(dialogData?.value || {}),
                            profile: {
                              ...(dialogData?.value?.profile || {}),
                              nameLast: value,
                            },
                          }
                        };
                        setDialogData(dialogDataProcessed);
                      }}
                    />
                  </GridCell>
                </GridContainer>
                <Input
                  name="phone"
                  palette='secondary'
                  label="Phone"
                  type="text"
                  value={dialogData?.value?.profile?.phone?.cell|| ''}
                  onChange={(value) => {
                    const dialogDataProcessed = {
                      ...dialogData,
                      value: {
                        ...(dialogData?.value || {}),
                        profile: {
                          ...(dialogData?.value?.profile || {}),
                          phone: {
                            ...(dialogData?.value?.profile?.phone || {}),
                            cell: value,
                          }
                        },
                      }
                    };
                    setDialogData(dialogDataProcessed);
                  }}
                />
              </div>
              <br/>
              <div style={{padding: 10}}>
                <Typography style={{fontWeight: 500}}>
                  Address
                </Typography>
              </div>
              <div style={{padding: 5}}>
                <AddressForm
                  address={dialogData?.value?.profile?.address || {}}
                  handleChange={(name, value) => {
                    const dialogDataProcessed = {
                      ...dialogData,
                      value: {
                        ...(dialogData?.value || {}),
                        profile: {
                          ...(dialogData?.value?.profile || {}),
                          address: {
                            ...(dialogData?.value?.profile?.address || {}),
                            [name]: value,
                          }
                        },
                      }
                    };
                    setDialogData(dialogDataProcessed);
                  }}
                />
              </div>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            {dialogData?.type !== 'processingNotification' &&
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                  onClick={() => {
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
                </Button>
              </GridCell>
            }
            {dialogData?.type === 'logout' &&
              <GridCell style={{padding: 10}}>
                <Button
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    apiRequest({type: 'post', action: 'validate/logout'}).then((result) => {
                      setApiToken(undefined);
                      setProfile(undefined);
                      resetCredentials();
                      resetLists();
                      resetReferences();
                      setProcessing(false);
                      setDialogOpen(false);
                      setDialogData(undefined);
                    }).catch((error) => console.log(error));
                  }}
                >
                  Logout
                </Button>
                {processing && <ProgressBar palette='secondary' />}
              </GridCell>
            }
            {dialogData?.type === 'resetPassword' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.newPassword || dialogData?.value?.newPassword === ''}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    apiRequest({type: 'patch', action: 'users/user-reset-password', data: {password: dialogData?.value?.newPassword}})
                    .then((result) => {
                      setProcessing(false);
                      setDialogOpen(false);
                      setDialogData(undefined);
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogOpen(false);
                      setDialogData(undefined);
                    });
                  }}
                >
                  Reset Password
                </Button>
              </GridCell>
            }
            {dialogData.type === 'updateUser' &&
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  handleUpdateUser(dialogData?.value);
                }}
              >
                Update
              </Button>
              {processing &&
                <ProgressBar palette='secondary'/>
              }
            </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

export default connect(undefined, {resetReferences, setUserReference, setProfileReference, resetLists, setProfile, setApiToken})(MyProfilePanel);
