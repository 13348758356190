import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from "react-router-dom";
import {connect} from 'react-redux';
import jwt from 'jsonwebtoken';
import {Helmet} from "react-helmet";

import {setApiToken} from '../../../store/reducers/auth/actions.js';
import {setProfile} from '../../../store/reducers/user/actions.js';
import {GridContainer, GridCell} from '../../../components/grid';
import {Button, Input, Typography, Dialog, ProgressBar} from '../../../components/styles';

import {paletteData} from '../../../components/styles';
import apiRequest from '../../../tools/apiRequest';
import handleErrorMessage from '../../../tools/handleErrorMessage';
import {resetLists} from '../../../store/reducers/lists/actions.js';
import {resetReferences} from '../../../store/reducers/references/actions.js';
import {validateEmail, checkPasswordStrength} from '../../../tools/validation';

function Login({auth, setApiToken, setProfile, setMe, resetLists, resetReferences}) {
  const [processing, setProcessing] = useState(false);
  const [login, setLogin] = useState({email: '', password: ''});
  const [redirect, setRedirect] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const handleChangeLogin = (name, value) => {
    const recordProcessed = {
      ...login,
      [name]: value,
    };
    setLogin(recordProcessed);
  };

  const handleLogin = (loginData) => {
    setProcessing(true);
    apiRequest({
      type: 'post',
      action: 'auth/login',
      data: {
        email: loginData?.email || login.email,
        password: loginData?.password || login.password,
      },
    }).then((loginResult) => {
      setApiToken(loginResult.data.auth);
      const user = (jwt.decode(loginResult.data.auth?.access_token)).data;
      apiRequest({type: 'get', action: `users/${user._id}/profile`}).then((profileResult) => {
        setProfile(profileResult.data || undefined);
        setMe({
          ...(jwt.decode(loginResult.data.auth?.access_token))?.data,
          profile: profileResult.data,
        });
        resetLists();
        resetReferences();
        setProcessing(false);
        setRedirect(true);
      }).catch((error) => {
        setProcessing(false);
        setDialogData({
          type: 'message',
          title: 'login request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
      });
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'login request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  if(redirect) {
    return (<Redirect to="/console" />);
  }

  return (
    <div style={{
      background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundBlendMode: 'lighten',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      backgroundAttachment: 'fixed',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - login</title>
        <meta
          name ='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>
      <div style={{flex: 1}}/>
      <div style={{textAlign: 'center'}}>
        <Typography size='heading' style={{letterSpacing: 2.3, fontWeight: 700}}>
          RERENGĀTAHI
        </Typography>
        <Typography size='title' style={{letterSpacing: 5}}>
          NORTHLAND TOGETHER
        </Typography>
      </div>
      <br/><br/>
      <GridContainer>
        <GridCell weight={1} />
        <GridCell style={{width: 300}}>
          <div style={{background: 'white', padding: 10, borderRadius: 4}}>
            <div style={{textAlign: 'center', fontSize: '2em'}}>
              Log In
            </div>
            <br/>
            <form>
              <Input
                name="email"
                palette='secondary'
                label="Email"
                type="text"
                autoComplete='username'
                forceShrink={true}
                value={login.email}
                onChange={(value) => handleChangeLogin('email', value)}
              />
              <br/>
              <Input
                name="password"
                palette='secondary'
                label="Password"
                type="password"
                autoComplete='off'
                forceShrink={true}
                value={login.password}
                onChange={(value) => handleChangeLogin('password', value)}
              />
              <br/>
              <Button palette="primary" onClick={() => handleLogin()} disabled={processing}>
                LOG IN
              </Button>
              {processing && <ProgressBar palette='secondary'/>}
              <GridContainer>
                <GridCell weight={1}>
                  <Typography
                    size='subText'
                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => {
                      setDialogData({
                        type: 'signup',
                        title: 'Create Account',
                        value: {}
                      });
                      setDialogOpen(true);
                    }}
                  >
                    Create Account
                  </Typography>
                </GridCell>
                <GridCell weight={1} style={{textAlign: 'right'}}>
                  <Typography
                    size='subText'
                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => {
                      setDialogData({
                        type: 'passwordReset',
                        title: 'Reset Password',
                        message: 'Enter the email for password reset.'
                      });
                      setDialogOpen(true);
                    }}
                  >
                    forgot password?
                  </Typography>
                </GridCell>
              </GridContainer>
            </form>
          </div>
        </GridCell>
        <GridCell weight={1} />
      </GridContainer>
      <div style={{flex: 2}}/>

      {/*popouts and popups*/}
      <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
        <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
          <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
            {dialogData?.title}
          </Typography>
        </div>
        {dialogData?.message &&
          <div style={{padding: 10}}>
            <Typography>
              {dialogData?.message}
            </Typography>
          </div>
        }
        {dialogData?.type === 'passwordReset' &&
          <div style={{padding: 5}}>
            <Input
              name="email"
              palette='secondary'
              label="Email"
              type="text"
              forceShrink={true}
              value={login.email}
              onChange={(value) => handleChangeLogin('email', value)}
            />
          </div>
        }
        {dialogData?.type === 'signup' &&
          <div style={{padding: 10}}>
            <form>
              <GridContainer>
                <GridCell weight={1}>
                  <Input
                    required={true}
                    name="username"
                    palette='secondary'
                    label="Username"
                    type="text"
                    autocomplete="new-password"
                    value={dialogData?.value?.name || ''}
                    onChange={(value) => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          name: value,
                          duplicate: undefined,
                        }
                      });
                    }}
                  />
                  {dialogData?.value?.duplicate === undefined &&
                    <Typography size='subText' style={{paddingLeft: 5, paddingRight: 5, color: 'red'}}>
                      Please check username is available
                    </Typography>
                  }
                  {dialogData?.value?.duplicate === true &&
                    <Typography size='subText' style={{paddingLeft: 5, paddingRight: 5, color: 'red'}}>
                      This username is taken
                    </Typography>
                  }
                  {dialogData?.value?.duplicate === false &&
                    <Typography size='subText' style={{paddingLeft: 5, paddingRight: 5, color: 'green'}}>
                      This username is available
                    </Typography>
                  }
                  <Typography size='subText' style={{paddingLeft: 5, paddingRight: 5}}>
                    Your username will be used to identify you in any public interactions on our site
                  </Typography>
                </GridCell>
                <GridCell style={{marginTop: 11}}>
                  <Button disabled={processing} palette='primary' onClick={() => {
                    setProcessing(true);
                    apiRequest({type: 'get', action: 'auth/duplication', data: {name: dialogData?.value?.name}})
                    .then((result) => {
                      setProcessing(false);
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          duplicate: result.data,
                        }
                      });
                    })
                    .catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          duplicate: undefined,
                        }
                      });
                    });
                  }}>
                    Check
                  </Button>
                  {processing && <ProgressBar palette='secondary'/>}
                </GridCell>
              </GridContainer>

              <Input
                required={true}
                name="email"
                palette='secondary'
                label="Email"
                type="text"
                autocomplete="new-password"
                value={dialogData?.value?.email|| ''}
                onChange={(value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      email: value,
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              />
              {!validateEmail(dialogData?.value?.email) &&
                <Typography size='subText' style={{paddingLeft: 5, paddingRight: 5, color: 'red'}}>
                  please enter a valid email
                </Typography>
              }
              <Typography size='subText' style={{paddingLeft: 5, paddingRight: 5, color: '#c6c6c6'}}>
                You will use your email to log in
              </Typography>
              <Input
                required={true}
                name="password"
                palette='secondary'
                label="Password"
                type="password"
                autoComplete="new-password"
                value={dialogData?.value?.verification?.password || ''}
                onChange={(value) => {
                  const dialogDataProcessed = {
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      verification: {
                        ...(dialogData?.value?.verification || {}),
                        password: value,
                      }
                    }
                  };
                  setDialogData(dialogDataProcessed);
                }}
              />
              {!checkPasswordStrength(dialogData?.value?.verification?.password) &&
                <Typography size='subText' style={{paddingLeft: 5, paddingRight: 5, color: 'red'}}>
                  Password must contain an uppercase and lowercase letter, one or more numbers, and a special character.
                </Typography>
              }
              <Typography size='subText' style={{paddingLeft: 5, paddingRight: 5, color: '#c6c6c6'}}>
                You will use your password to to authorise your login
              </Typography>
            </form>
          </div>
        }
        <GridContainer>
          <GridCell center={true} weight={1} style={{padding: 10}}>
            {dialogData?.error &&
              <Typography style={{textAlign: 'right', color: 'red'}}>
                {dialogData.error}
              </Typography>
            }
          </GridCell>
          <GridCell style={{padding: 10}}>
            <Button
              disabled={processing}
              palette='primary'
              onClick={() => {
                setDialogOpen(false);
                setDialogData(undefined);
              }}
            >
              {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
            </Button>
          </GridCell>
          {dialogData?.type === 'passwordReset' &&
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setProcessing(true);
                  const updateKey = {
                    type: 'passwordreset',
                    container: 'public',
                    value: login.email,
                  };
                  apiRequest({type: 'post', action: `public_keys/create`, data: {updateKey}})
                  .then((result) => {
                    setProcessing(false);
                    setDialogData({
                      type: 'message',
                      title: 'Password Reset Sent',
                      message: 'Please check your emails and follow the instructions to reset your password',
                    });
                    setDialogOpen(true);
                  }).catch((error) => {
                    setProcessing(false);
                    setDialogData({
                      type: 'message',
                      title: 'Password Reset Sent',
                      message: 'Please check your emails and follow the instructions to reset your password',
                    });
                    setDialogOpen(true);
                  });
                }}
              >
                Send Password Reset Email
              </Button>
            </GridCell>
          }
          {dialogData?.type === 'signup' &&
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing || !validateEmail(dialogData?.value?.email) || dialogData?.value?.duplicate !== false || !dialogData?.value?.name || !dialogData?.value?.email || !dialogData?.value?.verification?.password || !checkPasswordStrength(dialogData?.value?.verification?.password)}
                palette='primary'
                onClick={() => {
                  setProcessing(true);
                  apiRequest({type: 'post', action: `auth/register`, data: {
                    email: dialogData?.value?.email,
                    name: dialogData?.value?.name,
                    verification: dialogData?.value?.verification,
                    profile: {},
                  }})
                  .then((result) => {
                    handleLogin({email: dialogData?.value?.email, password: dialogData?.value?.verification?.password});
                  }).catch((error) => {
                    setProcessing(false);
                    setDialogData({
                      ...dialogData,
                      error: handleErrorMessage(error),
                    });
                    setDialogOpen(true);
                  });
                }}
              >
                Submit
              </Button>
              {processing && <ProgressBar palette='secondary'/>}
            </GridCell>
          }

        </GridContainer>
      </Dialog>
    </div>
  );
}

Login.propTypes = {
  auth: PropTypes.shape({}),
  setApiToken: PropTypes.func,
  setProfile: PropTypes.func,
}

export default connect(undefined, {setApiToken, setProfile, resetLists, resetReferences})(Login);
