import {
  ADD_PRODUCT_CART,
  REPLACE_PRODUCT_CART,
  REMOVE_PRODUCT_CART,
  RESET_CART,
} from "./actionTypes";

export const addProductCart = (content) => ({
  type: ADD_PRODUCT_CART,
  payload: content
});

export const removeProductCart = (content) => ({
  type: REMOVE_PRODUCT_CART,
  payload: content
});

export const replaceProductCart = (content) => ({
  type: REPLACE_PRODUCT_CART,
  payload: content
});

export const resetCart = () => ({
  type: RESET_CART,
});
