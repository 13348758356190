export const SET_USERS = "lists/set_users";
export const ADD_USERS = "lists/add_users";
export const ADD_USER = "lists/add_user";
export const REPLACE_USER = "lists/replace_user";
export const REMOVE_USER = "lists/remove_user";

export const SET_ORGANISATIONS = "lists/set_organisations";
export const ADD_ORGANISATIONS = "lists/add_organisations";
export const ADD_ORGANISATION = "lists/add_organisation";
export const REPLACE_ORGANISATION = "lists/replace_organisation";
export const REMOVE_ORGANISATION = "lists/remove_organisation";

export const SET_AREAS = "lists/set_areas";
export const ADD_AREAS = "lists/add_areas";
export const ADD_AREA = "lists/add_area";
export const REPLACE_AREA = "lists/replace_area";
export const REMOVE_AREA = "lists/remove_aera";

export const SET_CATEGORIES = "lists/set_categories";
export const ADD_CATEGORIES = "lists/add_categories";
export const ADD_CATEGORY = "lists/add_category";
export const REPLACE_CATEGORY = "lists/replace_category";
export const REMOVE_CATEGORY = "lists/remove_category";

export const SET_ARTICLES = "lists/set_articles";
export const ADD_ARTICLES = "lists/add_articles";
export const ADD_ARTICLE = "lists/add_article";
export const REPLACE_ARTICLE = "lists/replace_article";
export const REMOVE_ARTICLE = "lists/remove_article";

export const SET_EVENTS = "lists/set_events";
export const ADD_EVENTS = "lists/add_events";
export const ADD_EVENT = "lists/add_event";
export const REPLACE_EVENT = "lists/replace_event";
export const REMOVE_EVENT = "lists/remove_event";

export const SET_PRODUCTS = "lists/set_products";
export const ADD_PRODUCTS = "lists/add_products";
export const ADD_PRODUCT = "lists/add_product";
export const REPLACE_PRODUCT = "lists/replace_product";
export const REMOVE_PRODUCT = "lists/remove_product";

export const SET_ORDERS = "lists/set_orders";
export const ADD_ORDERS = "lists/add_orders";
export const ADD_ORDER = "lists/add_order";
export const REPLACE_ORDER = "lists/replace_order";
export const REMOVE_ORDER = "lists/remove_order";

export const SET_REVIEWS = "lists/set_reviews";
export const ADD_REVIEWS = "lists/add_reviews";
export const ADD_REVIEW = "lists/add_review";
export const REPLACE_REVIEW = "lists/replace_review";
export const REMOVE_REVIEW = "lists/remove_review";

export const SET_CURRENT_ORDERS = "lists/set_current_orders";
export const ADD_CURRENT_ORDERS = "lists/add_current_orders";
export const ADD_CURRENT_ORDER = "lists/add_current_order";
export const REPLACE_CURRENT_ORDER = "lists/replace_current_order";
export const REMOVE_CURRENT_ORDER = "lists/remove_current_order";

export const SET_COMMUNITIES = "lists/set_communities";
export const ADD_COMMUNITY = "lists/add_community";
export const ADD_COMMUNITIES = "lists/add_communities";
export const REPLACE_COMMUNITY = "lists/replace_community";
export const REMOVE_COMMUNITY = "lists/remove_community";

export const SET_MESSAGES = "lists/set_messages";
export const ADD_MESSAGE = "lists/add_message";
export const ADD_MESSAGES = "lists/add_messages";
export const REPLACE_MESSAGE = "lists/replace_message";
export const REMOVE_MESSAGE = "lists/remove_message";

export const SET_SEARCH = "lists/set_search";
export const RESET_LISTS = "lists/reset_lists";
