import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {paletteData, Typography} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

import {setCurrentOrders} from '../../../../store/reducers/lists/actions.js';

function ConsoleMenuPanel({selected, handleChange, me, setCurrentOrders}) {
  return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10, width: 200}}>
        <GridContainer>
          <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{height: 45}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              Menu
            </Typography>
          </GridCell>
        </GridContainer>
      </div>
      <div style={{padding: 10}}>
        <Typography style={{cursor: 'pointer', color: selected === 'myProfile' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('myProfile')}>My Profile</Typography>

        {/*
        <Typography style={{cursor: 'pointer', color: selected === 'ordersCurrent' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => {
          handleChange('ordersCurrent');
          setCurrentOrders(undefined);
        }}>Current Orders</Typography>
        <Typography style={{cursor: 'pointer', color: selected === 'ordersHistory' ? paletteData.secondary.highlight.foreground : paletteData.secondary.standard.foreground}} onClick={() => handleChange('ordersHistory')}>Order History</Typography>
        */}
      </div>
    </div>
  );
}

ConsoleMenuPanel.propTypes = {
  selected: PropTypes.string,
  handleChange: PropTypes.func,
  me: PropTypes.shape({}),
}

export default connect(undefined, {setCurrentOrders})(ConsoleMenuPanel);
