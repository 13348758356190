import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import jwt from 'jsonwebtoken';
import {useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";

import {setApiToken} from '../../../store/reducers/auth/actions.js';
import {setProfile} from '../../../store/reducers/user/actions.js';
import {GridContainer, GridCell} from '../../../components/grid';
import {Button, Input, Typography, Dialog, ProgressBar} from '../../../components/styles';
import {setUserReference, setProfileReference} from '../../../store/reducers/references/actions.js';

import apiRequest from '../../../tools/apiRequest';
import handleErrorMessage from '../../../tools/handleErrorMessage';

function EmailVerification({me, auth, setApiToken, setProfile, setMe, setUserReference, setProfileReference, refreshCredentialsLoaded}) {
  const [processing, setProcessing] = useState(false);
  const [complete, setComplete] = useState(undefined);
  const [login, setLogin] = useState({name: '', password: ''});

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);

  const {id} = useParams();

  useEffect(() => {
    if(!complete && !processing && me?._id && auth?.api?.access_token) {
      setProcessing(true);
      apiRequest({type: 'get', action: `keys/${id}`})
      .then((result) => {
        const key = result.data.key;
        apiRequest({type: 'post', action: `keys/use/${id}`})
        .then((result2) => {
          const tempMe = me;
          if(!tempMe.verification) {
            tempMe.verification = {};
          }
          if(!tempMe.verification.contact) {
            tempMe.verification.contact = {};
          }
          tempMe.verification.contact[key.value] = true;
          setMe(tempMe);
          setUserReference({
            _id: tempMe._id,
            name: tempMe.name,
            email: tempMe.email,
            whitelist: tempMe.whitelist,
            blacklist: tempMe.blacklist,
            verification: tempMe.verification,
            roles: tempMe.roles,
          });
          setComplete('success');
          setProcessing(false);
        }).catch((error) => {
          setComplete('failure');
          setProcessing(false);
        });
      }).catch((error) => {
        setComplete('failure');
        setProcessing(false);
      });
    }
  }, [me, auth, id, processing, complete, setMe, setProfileReference, setUserReference]);

  const handleChangeLogin = (name, value) => {
    const recordProcessed = {
      ...login,
      [name]: value,
    };
    setLogin(recordProcessed);
  };

  const handleLogin = () => {
    setProcessing(true);
    apiRequest({
      type: 'post',
      action: 'auth/login',
      data: {
        email: login.email,
        password: login.password,
      },
    }).then((loginResult) => {
      const user = (jwt.decode(loginResult.data.auth?.access_token)).data;
      setApiToken(loginResult.data.auth);
      apiRequest({type: 'get', action: `users/${user._id}/profile`}).then((profileResult) => {
        setProfile(profileResult.data || undefined);
        setProcessing(false);
      }).catch((error) => {
        setDialogData({
          type: 'message',
          title: 'Login Request denied',
          message: handleErrorMessage(error),
        });
        setDialogOpen(true);
        setProcessing(false);
      });
    }).catch((error) => {
      setDialogData({
        type: 'message',
        title: 'Login Request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
      setProcessing(false);
    });
  }

  if(!refreshCredentialsLoaded) {
    return (
      <div style={{
        background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundBlendMode: 'lighten',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backgroundAttachment: 'fixed',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rerengatahi - email verification</title>
          <meta
            name ='description'
            content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
          />
          <meta
            name='keywords'
            content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
          />
        </Helmet>
        <div style={{flex: 1}}/>
        <div style={{textAlign: 'center'}}>
          <Typography size='heading'>
            Rerengatahi
          </Typography>
          <Typography size='subHeading'>
            Checking Your Credentials
          </Typography>
        </div>
        <br/><br/>
        <GridContainer>
          <GridCell weight={1} />
          <GridCell style={{width: 300}}>
            <div style={{background: 'white', padding: 10, borderRadius: 4}}>
              <Typography>
                ...loading
              </Typography>
              <ProgressBar palette='secondary'/>
            </div>
          </GridCell>
          <GridCell weight={1} />
        </GridContainer>
        <div style={{flex: 2}}/>
      </div>
    )
  }

  if(!me?._id || !auth?.api?.access_token) {
    return (
      <div style={{
        background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundBlendMode: 'lighten',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backgroundAttachment: 'fixed',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rerengatahi - email verification</title>
          <meta
            name ='description'
            content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
          />
          <meta
            name='keywords'
            content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
          />
        </Helmet>
        <div style={{flex: 1}}/>
        <div style={{textAlign: 'center'}}>
          <Typography size='heading'>
            Rerengatahi
          </Typography>
          <Typography size='subHeading'>
            Login to verify your email address
          </Typography>
        </div>
        <br/><br/>
        <GridContainer>
          <GridCell weight={1} />
          <GridCell style={{width: 300}}>
            <div style={{background: 'white', padding: 10, borderRadius: 4}}>
              <div style={{textAlign: 'center', fontSize: '2em'}}>
                Log In
              </div>
              <br/>
              <div>
                <Input
                  name="email"
                  palette='secondary'
                  label="Email"
                  type="text"
                  forceShrink={true}
                  value={login.email}
                  onChange={(value) => handleChangeLogin('email', value)}
                />
                <br/>
                <Input
                  name="password"
                  palette='secondary'
                  label="Password"
                  type="password"
                  forceShrink={true}
                  value={login.password}
                  onChange={(value) => handleChangeLogin('password', value)}
                />
                <br/>
                <div style={{textAlign: 'right'}}>
                  <Button palette="primary" onClick={() => handleLogin()} disabled={processing}>
                    LOG IN
                  </Button>
                </div>
              </div>
            </div>
          </GridCell>
          <GridCell weight={1} />
        </GridContainer>
        <div style={{flex: 2}}/>

        {/*popouts and popups*/}
        <Dialog open={dialogOpen} handleClose={() => setDialogOpen(false)}>
          <div style={{padding: 10}}>
            <Typography style={{fontSize: '1.5em'}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.type === 'message' &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData?.message}
              </Typography>
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setDialogOpen(false);
                  setDialogData(undefined);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      </div>
    );
  }

  return (
    <div style={{
      background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundBlendMode: 'lighten',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      backgroundAttachment: 'fixed',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - email verification</title>
        <meta
          name ='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>
      <div style={{flex: 1}}/>
      <div style={{textAlign: 'center'}}>
        <Typography size='heading'>
          Rerengatahi
        </Typography>
        <Typography size='subHeading'>
          Verifying your email address
        </Typography>
      </div>
      <br/><br/>
      <GridContainer>
        <GridCell weight={1} />
        <GridCell style={{width: 300}}>
          <div style={{background: 'white', padding: 10, borderRadius: 4}}>
            {complete === undefined &&
              <div>
                <Typography>
                  ...loading
                </Typography>
                <ProgressBar palette='secondary'/>
              </div>
            }
            {complete === 'success' &&
              <Typography>
                Email address verified
              </Typography>
            }
            {complete === 'failure' &&
              <Typography>
                Invalid Token
              </Typography>
            }
          </div>
        </GridCell>
        <GridCell weight={1} />
      </GridContainer>
      <div style={{flex: 2}}/>
    </div>
  );
}

EmailVerification.propTypes = {
  me: PropTypes.shape({}),
  auth: PropTypes.shape({}),
}

export default connect(undefined, {setApiToken, setProfile, setUserReference, setProfileReference})(EmailVerification);
