const handleErrorMessage = (error) => {
  let response = '';
  if(error.response?.data?.details?.length > 0) {
    const responses = error.response.data.details;
    for(let i = 0; i < responses.length; i += 1) {
      if(typeof responses[i] === 'string') {
        response = response + responses[i].message;
      } else {
        console.log(responses[i]);
      }
    }
  } else if(typeof error.response?.data === 'string') {
    response = error.response.data;
  } else if(typeof error.message === 'string') {
    response = error.message;
  } else {
    console.log(error);
  }
  return response;
}

export default handleErrorMessage;
