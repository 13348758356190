import React from 'react';
import PropTypes from 'prop-types';

import {Typography} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function EventForm({me, focusedEvent, handleChange, processing}) {
  return (
    <div>
      {focusedEvent?.state === 'public' &&
        <div>
          <Typography>
            Your event is currently public.  If you save any changes you will need to re-publish the event.
          </Typography>
        </div>
      }
      <div>
        <GridContainer>
          {focusedEvent?.name ?
            <GridCell center={true}>
              <span className="material-icons md-24">done</span>
            </GridCell> :
            <GridCell center={true}>
              <span className="material-icons md-24">close</span>
            </GridCell>
          }
          <GridCell center={true}>
            <Typography>
              Listing has a name
            </Typography>
          </GridCell>
        </GridContainer>

        <GridContainer>
          {focusedEvent?.thumbnail?.imageId ?
            <GridCell center={true}>
              <span className="material-icons md-24">done</span>
            </GridCell> :
            <GridCell center={true}>
              <span className="material-icons md-24">close</span>
            </GridCell>
          }
          <GridCell center={true}>
            <Typography>
              Listing has a thumbnail
            </Typography>
          </GridCell>
        </GridContainer>

        <GridContainer>
          {focusedEvent?.categories?.length > 0 ?
            <GridCell center={true}>
              <span className="material-icons md-24">done</span>
            </GridCell> :
            <GridCell center={true}>
              <span className="material-icons md-24">close</span>
            </GridCell>
          }
          <GridCell center={true}>
            <Typography>
              Event has a category
            </Typography>
          </GridCell>
        </GridContainer>

        <GridContainer>
          {(focusedEvent?.layout?.pages?.length > 0 && focusedEvent?.layout?.pages[0]?.sections?.length > 0) ?
            <GridCell center={true}>
              <span className="material-icons md-24">done</span>
            </GridCell> :
            <GridCell center={true}>
              <span className="material-icons md-24">close</span>
            </GridCell>
          }
          <GridCell center={true}>
            <Typography>
              Event has a layout
            </Typography>
          </GridCell>
        </GridContainer>

        <GridContainer>
          {focusedEvent?.locations?.length > 0 ?
            <GridCell center={true}>
              <span className="material-icons md-24">done</span>
            </GridCell> :
            <GridCell center={true}>
              <span className="material-icons md-24">close</span>
            </GridCell>
          }
          <GridCell center={true}>
            <Typography>
              Event has a location
            </Typography>
          </GridCell>
        </GridContainer>
      </div>
    </div>
  );
}

EventForm.propTypes = {
  me: PropTypes.shape({}),
  focusedEvent: PropTypes.shape({}),
  handleChange: PropTypes.func,
  processing: PropTypes.bool,
}

export default EventForm;
