import React from 'react';
import PropTypes from 'prop-types';

import {Fab} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function MenuEditSubPanel({
  processing,
  setEditing,
  setTab,
  handleUpdateProduct,
}) {
  return (
    <GridContainer>
      <GridCell>
        <Fab
          disabled={processing}
          palette='primary'
          onClick={() => setTab('layout')}
        >
          <span className="material-icons md-24">web</span>
        </Fab>
      </GridCell>
      <GridCell>
        <Fab
          disabled={processing}
          palette='primary'
          onClick={() => setTab('listing')}
        >
          <span className="material-icons md-24">list</span>
        </Fab>
      </GridCell>
      <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => setTab('information')}
          >
            <span className="material-icons md-24">info</span>
          </Fab>
        </GridCell>
      <GridCell>
        <Fab
          palette='primary'
          disabled={processing}
          onClick={() => {
            handleUpdateProduct(() => {
              setEditing(false)
            });
          }}
        >
          <span className="material-icons md-24" >done</span>
        </Fab>
      </GridCell>
    </GridContainer>
  );
};

MenuEditSubPanel.propTypes = {
  area: PropTypes.shape({}),
};

export default MenuEditSubPanel;