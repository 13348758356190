import React from 'react';
import PropTypes from 'prop-types';

import {Input} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function AddressForm({address, handleChange, required}) {
  return (
    <div>
      <div>
        <Input
          name="addressExtra"
          palette='secondary'
          label="Extra Information (eg. RD number, Unit, etc...)"
          type="text"
          value={address?.extra || ''}
          onChange={(value) => handleChange('extra', value)}
        />
      </div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            required={required}
            name="addressNumber"
            palette='secondary'
            label="Number"
            type="text"
            value={address?.number || ''}
            onChange={(value) => handleChange('number', value)}
          />
        </GridCell>
        <GridCell weight={3}>
          <Input
            required={required}
            name="addressStreet"
            palette='secondary'
            label="Street"
            type="text"
            value={address?.street || ''}
            onChange={(value) => handleChange('street', value)}
          />
        </GridCell>
      </GridContainer>
      <Input
        name="addressSuburb"
        palette='secondary'
        label="Suburb"
        type="text"
        value={address?.suburb || ''}
        onChange={(value) => handleChange('suburb', value)}
      />
      <Input
        required={required}
        name="addressCity"
        palette='secondary'
        label="City"
        type="text"
        value={address?.city || ''}
        onChange={(value) => handleChange('city', value)}
      />
      <GridContainer>
        <GridCell weight={3}>
          <Input
            required={required}
            name="addressCountry"
            palette='secondary'
            label="Country"
            type="text"
            value={address?.country || ''}
            onChange={(value) => handleChange('country', value)}
          />
        </GridCell>
        <GridCell weight={1}>
          <Input
            required={required}
            name="addressPostcode"
            palette='secondary'
            label="Postcode"
            type="text"
            value={address?.postcode || ''}
            onChange={(value) => handleChange('postcode', value)}
          />
        </GridCell>
      </GridContainer>
    </div>
  );
}

AddressForm.propTypes = {
  address: PropTypes.shape({}),
  handleChange: PropTypes.func,
  required: PropTypes.bool,
}

export default AddressForm;
