import React from 'react';
import PropTypes from 'prop-types';

import {Input, TextArea, Button, ProgressBar} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function ListingForm({preview, handleChange, processing, setDialogData, setDialogOpen}) {
  return (
    <div>
      <GridContainer>
        <GridCell style={{width: 180}}>
          <div>
            {!preview?.thumbnail?.imageId &&
              <GridContainer style={{height: 180, width: 180}}>
                <GridCell center={true} weight={1} style={{background: '#c6c6c6', textAlign: 'center', borderRadius: 5}}>
                  No Image
                </GridCell>
              </GridContainer>
            }
            {preview?.thumbnail?.imageId &&
              <GridContainer>
                <GridCell center={true} weight={1} style={{minHeight: 180}}>
                  <img
                    src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${preview.thumbnail?.imageId}`}
                    alt={preview?.thumbnail?.altText}
                    style={{width: 180}}
                  />
                </GridCell>
              </GridContainer>
            }
            <div style={{marginTop: 10, textAlign: 'center'}}>
              <Button
                disabled={processing}
                palette='primary'
                onClick={() => {
                  setDialogData({
                    type: 'selectImage',
                    title: 'Select Thumbnail',
                    data: {
                      callback: (value) => {
                        if(value.imageId) {
                          handleChange('thumbnail', value)
                        }
                      }
                    },
                    value: {
                      search: '',
                    },
                  });
                  setDialogOpen(true);
                }}
              >
                Select Thumbnail
              </Button>
            </div>
          </div>
          {(processing) &&
            <ProgressBar palette='secondary'/>
          }
        </GridCell>
        <GridCell weight={1}>
          <Input
            name="name"
            palette='secondary'
            label="Name"
            type="text"
            value={preview?.name || ''}
            onChange={(value) => handleChange('name', value)}
          />
          <Input
            name="tagline"
            palette='secondary'
            label="Tagline"
            type="text"
            value={preview?.tagline || ''}
            onChange={(value) => handleChange('tagline', value)}
          />
          <TextArea
            name="blurb"
            palette='secondary'
            label="Blurb"
            type="text"
            value={preview?.blurb || ''}
            onChange={(value) => handleChange('blurb', value)}
          />
        </GridCell>
      </GridContainer>
    </div>
  );
}

ListingForm.propTypes = {
  preview: PropTypes.shape({}),
  handleChange: PropTypes.func,
  processing: PropTypes.bool,
}

export default ListingForm;
