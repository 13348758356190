import React from 'react';
import PropTypes from 'prop-types';

import {Input, Button, Typography} from '../../styles';
import {GridContainer, GridCell} from '../../grid';

function LocationForm({location, areaReferences, handleChange, dialogData, setDialogData, dialogOpen, setDialogOpen}) {
  return (
    <div>
      <GridContainer>
        <GridCell weight={1}>
          <Input
            disabled={true}
            name="area"
            palette='secondary'
            label="Operational Area"
            type="text"
            value={location?.area ? areaReferences[location?.area]?.name || '...processing' : 'No area selected'}
          />
        </GridCell>
        <GridCell style={{marginTop: 7}}>
          <Button palette='primary' onClick={() => {
            setDialogOpen(true);
            setDialogData({
              type: 'changeArea',
              title: 'Change Area',
              data: {
                callback: (value) => {
                  handleChange('area', value);
                }
              },
              value: {
                expandSearch: false,
              }
            });
          }}>
            Change Area
          </Button>
        </GridCell>
      </GridContainer>
      <Typography size='subText' style={{marginLeft: 5, marginRight: 5}}>
        Operational Area determines which areas on the site the event turns up in
      </Typography>
    </div>
  );
}

LocationForm.propTypes = {
  location: PropTypes.shape({}),
  areaReferences: PropTypes.shape({}),
  handleChange: PropTypes.func,
}

export default LocationForm;
