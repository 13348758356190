import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import {GridContainer, GridCell} from '../../../grid';
import {Typography} from '../../../styles';
import Gallery from '../../../gallery';
import {YoutubeEmbed, VimeoEmbed} from '../../../video';
import Body from '../Body';

function LayoutDisplay({block, location, depth, onClick, onCallback}) {
  if(block.type === 'GridContainer') {
    return (
      <GridContainer
        style={{
          zIndex: depth,
          pointerEvents: 'auto',
          ...block.options?.style,
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick('');
        }}
      >
        {block.children?.length > 0 && block.children.map((c, cIndex) => (
          <LayoutDisplay
            key={cIndex}
            block={c}
            location={`${location}.children.${cIndex}`}
            depth={(depth || 0) + 1}
            onClick={(componentPath) => onClick(`children.${cIndex}${componentPath !== '' ? `.${componentPath}` : ''}`)}
            onCallback={onCallback}
          />
        ))}
      </GridContainer>
    );
  } else if(block.type === 'GridCell') {
    return (
      <GridCell
        weight={block?.options?.weight} center={block?.options?.center}
        centerWeights={block?.options?.centerWeights}
        style={{
          zIndex: depth,
          pointerEvents: 'auto',
          ...block.options?.style
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick('');
        }}
      >
        <div>
          {block.children?.length > 0 && block.children.map((c, cIndex) => (
            <LayoutDisplay
              key={cIndex}
              block={c}
              location={`${location}.children.${cIndex}`}
              depth={(depth || 0) + 1}
              onClick={(componentPath) => onClick(`children.${cIndex}${componentPath !== '' ? `.${componentPath}` : ''}`)}
              onCallback={onCallback}
            />
          ))}
        </div>
      </GridCell>
    );
  } else if(block.type === 'div'){
    return (
      <div
        id={block.options?.id}
        style={{
          zIndex: depth,
          pointerEvents: 'auto',
          ...block.options?.style
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick('');
        }}
      >
        {block.section === 'titleBlock' && (depth === 0 || !depth) &&
          <Body>
            <div>
              {block.children?.length > 0 && block.children.map((c, cIndex) =>  (
                <LayoutDisplay
                  key={cIndex}
                  block={c}
                  location={`${location}.children.${cIndex}`}
                  depth={(depth || 0) + 1}
                  onClick={(componentPath) => onClick(`children.${cIndex}${componentPath !== '' ? `.${componentPath}` : ''}`)}
                  onCallback={onCallback}
                />
              ))}
              {block.section === 'imageBlock' && block.options?.description &&
                <Typography size='subText' style={{zIndex: depth, padding: 5, fontStyle: 'italic', textAlign: 'center'}}>
                  {block.options.description}
                </Typography>
              }
            </div>
          </Body>
        }
        {block.section !== 'titleBlock' && block.children?.length > 0 && block.children.map((c, cIndex) =>  (
          <LayoutDisplay
            key={cIndex}
            block={c}
            location={`${location}.children.${cIndex}`}
            depth={(depth || 0) + 1}
            onClick={(componentPath) => onClick(`children.${cIndex}${componentPath !== '' ? `.${componentPath}` : ''}`)}
            onCallback={onCallback}
          />
        ))}
        {block.section === 'imageBlock' && block.options?.description &&
          <Typography size='subText' style={{zIndex: depth, padding: 5, fontStyle: 'italic', textAlign: 'center'}}>
            {block.options.description}
          </Typography>
        }
      </div>
    );
  } else if(block.type === 'Typography') {
    return (
      <Typography
        size={block?.options?.size}
        style={{
          zIndex: depth,
          pointerEvents:'auto',
          ...block?.options?.style
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick('');
        }}
      >
        {block.text}
      </Typography>
    );
  } else if (block.type === 'RichText') {
    return (
      <Typography
        style={{
          zIndex: depth,
          pointerEvents: 'auto',
          ...block.options.style
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick('');
        }}
      >
        <ReactMarkdown children={block.markdown} />
      </Typography>
    );
  } else if(block.type === 'Image') {
    if(!block?.options?.src) {
      return (
        <GridContainer
          style={{
            zIndex: depth,
            pointerEvents: 'auto',
            ...block.options?.style,
          }}
          onClick={(event) => {
            event.stopPropagation();
            onClick('');
          }}
        >
          <GridCell center centerWeights={{top: 1, bottom: 1}} style={{background: '#c6c6c6', minWidth: 200, minHeight: 200}}>
            <Typography style={{textAlign: 'center'}}>
              No image Selected
            </Typography>
          </GridCell>
        </GridContainer>
      );
    } else {
      return (
        <img
          src={block?.options?.src}
          alt={block?.options?.alt}
          style={{
            zIndex: depth,
            pointerEvents:'auto',
            ...block?.options?.style
          }}
          onClick={(event) => {
            event.stopPropagation();
            onClick('');
          }}
        />
      );
    }
  } else if (block.type === 'Gallery') {
    return (
      <div
        style={{
          zIndex: depth,
          pointerEvents: 'auto',
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClick('');
        }}
      >
        <Gallery
          images={block.options.images}
          handleOpenPreview={() => onCallback('galeryOpenEvent', true)}
          handleClosePreview={() => onCallback('galeryOpenEvent', false)}
        />
      </div>

    );
  } else if(block.type === 'Video') {
    if(block?.options?.video?.platform === 'youtube') {
      return (
        <div
          style={{
            zIndex: depth,
            pointerEvents: 'auto',
          }}
          onClick={(event) => {
            event.stopPropagation();
            onClick('');
          }}
        >
          <YoutubeEmbed youtubeId={block?.options?.video?.youtubeId} preview={true}/>
          {block?.options?.video?.credit && !block?.options?.video?.citation &&
            <Typography size='subText' style={{textAlign: 'right'}}>
              Created by {block.options.video.credit}
            </Typography>
          }
          {block?.options?.video?.credit && block?.options?.video?.citation &&
            <a
              href={block.options.video.citation}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Typography size='subText' style={{textAlign: 'right'}}>
                Created by {block.options.video.credit}
              </Typography>
            </a>
          }
        </div>
      );
    } else if(block?.options?.video?.platform === 'vimeo') {
      return (
        <div
          style={{
            zIndex: depth,
            pointerEvents: 'auto',
          }}
          onClick={(event) => {
            event.stopPropagation();
            onClick('');
          }}
        >
          <VimeoEmbed vimeoId={block?.options?.video?.vimeoId} preview={true} onClick={() => onClick('')}/>
        </div>
      );
    } else {
      return (
        <div style={{pointerEvents: 'none'}}/>
      )
    }
  } else {
    return (
      <div style={{pointerEvents: 'none'}}/>
    )
  }
}

LayoutDisplay.propTypes = {
  block: PropTypes.shape({}),
  location: PropTypes.string,
  depth: PropTypes.number,
  onClick: PropTypes.func,
}

export default LayoutDisplay;
