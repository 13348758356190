import React from 'react';
import PropTypes from 'prop-types';

import {Fab} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function EditMenuSubPanel({
  me,
  focusedEvent,
  focusedOrganisation,

  processing,
  tab,
  setTab,
  handleUpdateEvent,
  handleRemoveEvent,
}) {
  return (
    <div>
      <GridContainer>
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => setTab('layout')}
          >
            <span className="material-icons md-24">web</span>
          </Fab>
        </GridCell>
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => setTab('information')}
          >
            <span className="material-icons md-24">info</span>
          </Fab>
        </GridCell>
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => setTab('location')}
          >
            <span className="material-icons md-24">location_on</span>
          </Fab>
        </GridCell>
        {focusedOrganisation &&
          <GridCell>
            <Fab
              disabled={processing}
              palette='primary'
              onClick={() => setTab('image')}
            >
              <span className="material-icons md-24">image</span>
            </Fab>
          </GridCell>
        }
        {focusedOrganisation &&
          <GridCell>
            <Fab
              disabled={processing}
              palette='primary'
              onClick={() => setTab('video')}
            >
            <span className="material-icons md-24">play_circle</span>
            </Fab>
          </GridCell>
        }
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => {
              handleRemoveEvent();
            }}
          >
            <span className="material-icons md-24">close</span>
          </Fab>
        </GridCell>
        <GridCell>
          <Fab
            disabled={processing}
            palette='primary'
            onClick={() => {
              handleUpdateEvent();
            }}
          >
            <span className="material-icons md-24">done</span>
          </Fab>
        </GridCell>
      </GridContainer>
    </div>
  );
};

EditMenuSubPanel.propTypes = {
  me: PropTypes.shape({}),
  focusedEvent: PropTypes.shape({}),

  processing: PropTypes.bool,
  tab: PropTypes.string,
  setTab: PropTypes.func,
  handleUpdateEvent: PropTypes.func,
  handleRemoveEvent: PropTypes.func,
};

export default EditMenuSubPanel;
