import {
  ADD_PRODUCT_CART,
  REPLACE_PRODUCT_CART,
  REMOVE_PRODUCT_CART,
  RESET_CART,
} from "./actionTypes";

const initialState = {}

export default function listsReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_CART: {
      return {}
    }

    case ADD_PRODUCT_CART: {
      const cartProcessed = state || {};
      if(!cartProcessed[action.payload.organisationId]) {
        cartProcessed[action.payload.organisationId] = [];
      }
      cartProcessed[action.payload.organisationId].push(action.payload);
      cartProcessed[action.payload.organisationId].sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...cartProcessed,
      }
    }
    case REPLACE_PRODUCT_CART: {
      const cartProcessed = state || {};
      cartProcessed[action.payload.product.organisationId].splice(action.payload.index, 1, action.payload.product);
      return {
        ...cartProcessed,
      }
    }
    case REMOVE_PRODUCT_CART: {
      const cartProcessed = state || {};
      cartProcessed[action.payload.product.organisationId].splice(action.payload.index, 1);
      if(cartProcessed[action.payload.product.organisationId].length === 0) {
        delete cartProcessed[action.payload.product.organisationId];
      }
      return {
        ...cartProcessed,
      }
    }

    default:
      return state
  }
}
