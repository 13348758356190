import React, {useState} from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../../components/search/store';
import {paletteData, Button, Typography} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';
import Hidden from '../../../../components/hidden';

function SearchProductsSubPanel({
    search,
    queryLimit,
    processing,
    setProcessing,
    isMobile,

    products,
    setProductReference,

    setRedirect,
    organisationId,
    productGroupId,
}) {
  const [expandSearch, setExpandSearch] = useState(false);
  const [initalLoadComplete, setInitalLoadComplete] = useState(false);

  return (
    <div>
      <SearchStore
        isMobile={isMobile}
        databaseArea='public_products'
        processing={processing}
        setProcessing={setProcessing}
        expandSearch={expandSearch}
        setExpandSearch={setExpandSearch}
        setInitalLoadComplete={setInitalLoadComplete}
        filter={{organisationId, productGroupId}}
      />
      {initalLoadComplete && organisationId && products?.length > 0 &&
        <div>
          <Hidden breakpoint='hiddenlessthan1024'>
            <div>
              {products.map((p, pIndex) => (
                <GridContainer
                  key={pIndex}
                  style={{margin: 5, cursor: 'pointer'}}
                  onClick={() => {
                    setProductReference(p);
                    setRedirect(`/product/${p._id}?organisation=${organisationId}`);
                  }}
                >
                  <GridCell center={true} style={{height: 200}}>
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${p.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                      alt={p?.thumbnail?.altText}
                      style={{width: 200}}
                    />
                  </GridCell>
                  <GridCell weight={1} center style={{textAlign: 'left', padding: 10, height: 180}}>
                    <Typography size='title'>
                      {p.name}
                    </Typography>
                    <Typography style={{color: '#333333'}}>
                      {p.tagline}
                    </Typography>
                    <Typography style={{marginTop: 5}}>
                      {p.blurb?.length > 350 ? `${p.blurb.substring(0, 350)}...` : p.blurb}
                  </Typography>
                  </GridCell>
                </GridContainer>
              ))}
            </div>
          </Hidden>

          <Hidden breakpoint='hiddengreaterthan1024'>
            <div>
              <GridContainer>
                {products.map((p, pIndex) => (
                  <GridCell
                    key={pIndex}
                    style={{
                      width: isMobile ? '100%' : undefined,
                      maxWidth: isMobile ? undefined : 200,
                      textAlign: 'center',
                      borderStyle: 'solid',
                      margin: 5,
                      borderWidth: 2,
                      borderRadius: 5,
                      borderColor: paletteData.primary.standard.background,
                      display: 'flex',
                      flexDirection: 'column',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setProductReference(p);
                      setRedirect(`/product/${p._id}?organisation=${organisationId}`);
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${p.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                      alt={p?.thumbnail?.altText}
                      style={{width: '100%'}}
                    />
                    <div style={{background: paletteData.primary.standard.background, padding: 10, flex: 1}}>
                      <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                        {p.name}
                      </Typography>
                    </div>
                  </GridCell>
                ))}
              </GridContainer>
            </div>
          </Hidden>

          {products.length >= search?.products?.queryDepth * queryLimit &&
            <div style={{padding: 5}}>
              <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                Load More
              </Button>
            </div>
          }
        </div>
      }
    </div>
  );
};

SearchProductsSubPanel.propTypes = {
  search: PropTypes.shape({}),
  setSearch: PropTypes.func,
  queryLimit: PropTypes.number,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,

  products: PropTypes.arrayOf(PropTypes.shape({})),
  setProducts: PropTypes.func,
  addProducts: PropTypes.func,
  setProductReference: PropTypes.func,

  setRedirect: PropTypes.func,
  organisationId: PropTypes.string,
  productGroupId: PropTypes.string,
};

export default SearchProductsSubPanel;
