import React, {useEffect, useState, createRef} from 'react';
import {connect} from 'react-redux';
import {Redirect, useParams, useHistory, useLocation} from 'react-router-dom';
import {Helmet} from "react-helmet";
import objectPath from 'object-path';

import {paletteData, Button, Fab, Typography, Dialog, ProgressBar, Input, Select, TextArea} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';
import {LayoutDisplay, LayoutEdit} from '../../../components/layout';
import Map from '../../../components/map';
import Hidden from '../../../components/hidden';
import SearchStore from '../../../components/search/store';

import EditOrganisationImageGallery from './EditOrganisationImageGallery';
import EditOrganisationVideoGallery from './EditOrganisationVideoGallery';
import EditOrganisationInformation from './EditOrganisationInformation';
import EditOrganisationListing from './EditOrganisationListing';
import EditOrganisationLocations from './EditOrganisationLocations';

import MenuOrganisation from './MenuOrganisation';
import MenuInformation from './MenuInformation';
import MenuEdit from './MenuEdit';

import apiRequest from '../../../tools/apiRequest';
import {mapStyle1, mapStyle2} from './mapStyles';
import {setOrganisationReference, setEventReference, setEventReferences, setProductReference, setReviewReference, setAreaReference, setAreaReferences} from '../../../store/reducers/references/actions.js';
import {setEvents, setProducts, setSearch, replaceOrganisation, replaceReview, addReview} from '../../../store/reducers/lists/actions.js';
import handleErrorMessage from '../../../tools/handleErrorMessage';

function Organisation({
  me,
  myPosition,
  search,
  setSearch,
  queryLimit,
  refreshCredentialsLoaded,

  categories,

  organisations,
  organisationReferences,
  setOrganisationReference,
  replaceOrganisation,

  reviews,
  setReviewReference,
  replaceReview,
  addReview,

  events,
  setEvents,
  setEventReferences,

  areas,
  areaReferences,
  setAreaReferences,
}) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  // global variables
  const [page, setPage] = useState(0);
  const [center] = useState({lat: -35.7274938, lng: 174.3165604});
  const [markers, setMarkers] = useState(undefined);

  const {id} = useParams();
  const urlHistory = useHistory();
  const urlQuery = useQuery();
  const areaId = urlQuery.get('area');

  // page state
  const [pointerEventsInfo, setPointerEventsInfo] = useState('auto');
  const [opacityInfo, setOpacityInfo] = useState(1);
  const [redirect, setRedirect] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(false);

  const [focusedOrganisation, setFocusedOrganisation] = useState (undefined);
  const [editing, setEditing] = useState(false);
  const [addSection, setAddSection] = useState(false);
  const [advancedEdit, setAdvancedEdit] = useState(false);
  const [tab, setTab] = useState('layout');
  const [imageUploadRef] = useState(createRef());

  // first load
  const [initialLoad, setInitialLoad] = useState({
    organisationReference: false,
    officeReferences: false,
    markers: false,
    events: false,
  });
  const [allLoaded, setAllLoaded] = useState(false)
  const [organisationReferenceLoaded, setOrganisationReferenceLoaded] = useState(false)
  const [markersLoaded, setMarkersLoaded] = useState(false);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  useEffect(() => {
    // finish page load
    if(!allLoaded && organisationReferenceLoaded && markersLoaded && eventsLoaded) {
      setAllLoaded(true);
    }

    // check if organisation is loaded and fetch if not
    if(refreshCredentialsLoaded && !initialLoad.organisationReference && organisationReferences[id]) {
      setInitialLoad({
        ...initialLoad,
        organisationReference: true,
      });
      setOrganisationReferenceLoaded(true);
      if(!markers) {
        const m = [];
        const org = organisationReferences[id];
        const offices = org.offices || [];
        for(let j = 0; j < offices.length; j+= 1) {
          if(offices[j].position?.lat && offices[j].position?.lng) {
            const marker = {
              position: offices[j].position,
              infoWindow: {
                title: org.name,
                text: org.blurb,
              }
            };
            if(offices[j].area === areaId) {
              m.unshift(marker);
            } else {
              m.push(marker);
            }
          }
        }
        setMarkers(m);
      }
    } else if (refreshCredentialsLoaded && !initialLoad.organisationReference){
      setInitialLoad({
        ...initialLoad,
        organisationReference: true,
      });
      apiRequest({type: 'get', action: `public_organisations/${id}`})
      .then((result) => {
        setOrganisationReference(result.data.organisation);
        setOrganisationReferenceLoaded(true);
      }).catch((error) => {
        setMarkers([]);
      });
    }

    // check if refrenced areas are loaded
    if(organisationReferences[id] && !initialLoad.officeReferences) {
      setInitialLoad({
        ...initialLoad,
        officeReferences: true,
      });
      const offices = organisationReferences[id]?.offices || [];
      const areasToLoad = [];
      for (let i = 0; i < offices.length; i += 1) {
        if(!areaReferences[offices[i].area]) {
          areasToLoad.push(offices[i].area);
        }
      }
      if(areasToLoad.length > 0) {
        const query = {_id: {$in: areasToLoad}};
        const sort = {name: 1};
        apiRequest({type: 'get', action: 'public_areas', data: {query, sort}})
        .then((result) => {
          setAreaReferences(result.data.areas);
        }).catch((error) => {

        });
      }
    }

    // process markers
    if(!initialLoad.markers && organisationReferences[id]?.offices?.length > 0) {
      setInitialLoad({
        ...initialLoad,
        markers: true,
      });
      const markersProcessed = [];
      for(let i = 0; i < organisationReferences[id].offices.length; i += 1) {
        if(organisationReferences[id].offices[i].position?.lat &&organisationReferences[id].offices[i].position?.lng) {
          const marker = {
            position: organisationReferences[id].offices[i].position,
            infoWindow: {
              title: organisationReferences[id].name,
              text: `${(organisationReferences[id]?.blurb?.length > 200) ? `${organisationReferences[id]?.blurb.substring(0, 200)}...` : organisationReferences[id]?.blurb}`,
            }
          };
          markersProcessed.push(marker);
        }
      }
      setMarkers(markersProcessed);
      setMarkersLoaded(true);
    } else if(!initialLoad.markers && organisationReferences[id]?.offices?.length === 0) {
      setInitialLoad({
        ...initialLoad,
        markers: true,
      });
      setMarkers([]);
      setMarkersLoaded(true);
    }

    // fetch upcoming events
    if(!initialLoad.events && events?.length > 0 && search?.events?.query?.createdByOrg === id) {
      setInitialLoad({
        ...initialLoad,
        events: true,
      });
      setEventsLoaded(true);
    } else if(!initialLoad.events && (!events || events.length === 0 || search?.events?.query?.createdByOrg !== id)) {
      setInitialLoad({
        ...initialLoad,
        events: true,
      });
      const now = new Date();
      const nowTimestamp = `${now.getFullYear()}-${(now.getMonth() + 1) < 10 ? '0' : ''}${now.getMonth() + 1}-${(now.getDate()) < 10 ? '0' : ''}${now.getDate()}T00:00:00.000Z`;
      const query = {
        createdByOrg: id,
        'locations.times.date': {$gte: nowTimestamp},
      };
      const sort = {'locations.times.date': 1};
      const skip = 0;

      apiRequest({type: 'get', action: 'public_events', data: {query, sort, skip, limit: queryLimit}})
      .then((result) => {
        setSearch({name: 'events', value: {text: '', query, data: {usesQueryLimit: true},  queryDepth: 1, hasMore: result.data.hasMore}});
        setEvents(result.data.events);
        setEventReferences(result.data.events);
        setProcessing(false);
        setEventsLoaded(true);
      }).catch((error) => {
        setSearch({name: 'events', value: {text: '', query, data: {usesQueryLimit: true},  queryDepth: 1, hasMore: false}});
        setEvents([]);
        setProcessing(false);
        setEventsLoaded(true);
      });
    }
  }, [
    id,
    areaId,
    queryLimit,
    search,
    setSearch,

    markers,
    initialLoad,
    allLoaded,
    markersLoaded,

    areaReferences,
    setAreaReferences,

    organisationReferences,
    setOrganisationReference,
    organisationReferenceLoaded,

    events,
    eventsLoaded,
    setEvents,
    setEventReferences,

    refreshCredentialsLoaded,
  ]);

  const handleInfoFadeOut = () => {
    //setTransitioning(true);
    setOpacityInfo(0);
    setPointerEventsInfo('none');
    //setTimeout(function(){
    //  setTransitioning(false);
    //}, 500);
  }

  const handleInfoFadeIn = () => {
    //setTransitioning(true);
    setPointerEventsInfo('auto');
    setOpacityInfo(1);
    //setTimeout(function(){
    //  setTransitioning(false);
    //}, 500);
  }

  const handleChangeFocusedOrganisation = (name, value) => {
    setFocusedOrganisation({
      ...focusedOrganisation,
      [name]: value,
    });
  };

  const changePage = (page, type, index, path, value) => {
    if(type === 'insert') {
      const tempLayout = focusedOrganisation.layout || {
        header: {},
        multiPageEnabled: false,
        pages: [{
          title: 'home',
          sections: [],
        }],
      };
      if(!tempLayout.pages) {
        tempLayout.pages = [{
          title: 'home',
          sections: [],
        }];
      };
      tempLayout.pages[page].sections.splice(index, 0, value);
      handleChangeFocusedOrganisation('layout', tempLayout);
    } else if (type === 'update') {
      const tempLayout = focusedOrganisation.layout;
      if(path === undefined) {
        tempLayout.pages[page].sections = value;
      } else {
        if(path === '') {
          objectPath.set(tempLayout.pages[page].sections, `${index}`, value);
        } else {
          objectPath.set(tempLayout.pages[page].sections, `${index}.${path}`, value);
        }
      }
      handleChangeFocusedOrganisation('layout', tempLayout);
    }
  }

  const changeParent = (type, path, value) => {
    if(type === 'insert') {
      const tempArray = objectPath.get(focusedOrganisation, path) || [];
      tempArray.push(value);
      objectPath.set(focusedOrganisation, path, tempArray);
    }
  }

  const handleUpdateOrganisation = () => {
    setProcessing(true);
    const organisationId = focusedOrganisation._id;
    const updateOrganisation = {
      gallery: focusedOrganisation?.gallery,
      videoLibrary: focusedOrganisation?.videoLibrary,
      layout: focusedOrganisation?.layout,

      name: focusedOrganisation?.name,
      tagline: focusedOrganisation?.tagline,
      blurb: focusedOrganisation?.blurb,
      thumbnail: focusedOrganisation?.thumbnail,
      logo: focusedOrganisation?.logo,
      website: focusedOrganisation?.website,
      email: focusedOrganisation?.email,
      phone: focusedOrganisation?.phone,
      social: focusedOrganisation?.social,
      categories: focusedOrganisation?.categories,
      offices: focusedOrganisation?.offices,
      postGroups: focusedOrganisation?.postGroups,
    }

    if(typeof focusedOrganisation?.gst !== "boolean") {
      updateOrganisation.gst = focusedOrganisation.gst;
    }
    if(typeof focusedOrganisation?.bank !== "boolean") {
      updateOrganisation.bank = focusedOrganisation.bank;
    }
    if(typeof focusedOrganisation?.stripe !== "boolean" && me?.roles?.global && (me?.roles?.global['super-admin'] || me?.roles?.global['admin'] || me?.roles?.global['worker'])) {
      updateOrganisation.stripe = focusedOrganisation.stripe;
    }

    apiRequest({type: 'patch', action: `organisations/update/${organisationId}`, data: {updateOrganisation}})
    .then((result) => {
      replaceOrganisation({organisation: {_id: organisationId, ...focusedOrganisation, ...updateOrganisation}});
      setOrganisationReference({_id: organisationId, ...focusedOrganisation, ...updateOrganisation});
      setInitialLoad({
        ...initialLoad,
        markers: false,
      });
      setMarkersLoaded(false);
      setProcessing(false);
      setFocusedOrganisation(undefined);
      setEditing(false);
    }).catch((error) => {
      setProcessing(false);
      setDialogData({
        type: 'message',
        title: 'Update organisation request denied',
        message: handleErrorMessage(error),
      });
      setDialogOpen(true);
    });
  }

  const handleUploadImage = (file, callback) => {
    setProcessing(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      apiRequest({type: 'post', action: 'upload/image', data: {file: reader.result}}).then((result) => {
        setProcessing(false);
        const alt = (file.name.split('.') || ['thumbnail'])[0];
        callback({imageId: result.data.public_id, alt}, undefined);
      }).catch((error) => {
        setProcessing(false);
        callback(undefined, error);
      });
    }
  }

  if(redirect) {
    return <Redirect to={redirect} push={true} />
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
      {organisationReferences[id] &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rerengatahi - {organisationReferences[id]?.name}</title>
          <meta
            name ='description'
            content={
              organisationReferences[id]?.blurb ||
              "Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
            }
          />
          <meta
            name='keywords'
            content={
              organisationReferences[id]?.keywords ||
              'social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
            }
          />
        </Helmet>
      }

      {/*---------------- small sceen menu -------------*/}
      <Hidden breakpoint='hiddengreaterthan1024'>
        <div style={{position: 'relative', background: paletteData.primary.standard.background}}>
          <GridContainer>
            {!editing && markers?.length > 0 &&
              <GridCell>
                <Fab
                  palette='primary'
                  onClick={() => {
                    if(opacityInfo) {
                      handleInfoFadeOut();
                    } else {
                      handleInfoFadeIn();
                    }
                  }}
                >
                  {opacityInfo ? <span className="material-icons md-24">place</span> : <span className="material-icons md-24">wrong_location</span>}
                </Fab>
              </GridCell>
            }
            {editing &&
              <div>
                <MenuEdit
                  me={me}
                  focusedOrganisation={focusedOrganisation}

                  processing={processing}
                  tab={tab}
                  setTab={setTab}
                  handleUpdateOrganisation={handleUpdateOrganisation}
                />
                {processing &&
                  <div style={{marginLeft: 15, marginRight: 15, marginTop: 5}}>
                    <ProgressBar palette='primary'/>
                  </div>
                }
                {tab === 'layout' &&
                  <GridContainer>
                    <GridCell>
                      <div style={{
                        marginTop: 10,
                        borderRadius: 25,
                        background: paletteData.primary.standard.background
                      }}>
                        <GridContainer>
                          <GridCell>
                            <Fab
                              disabled={processing}
                              palette='primary'
                              onClick={() => setAddSection(true)}
                            >
                            <span className="material-icons md-24">add</span>
                            </Fab>
                          </GridCell>
                          <GridCell>
                            <Fab
                              disabled={processing}
                              palette='primary'
                              onClick={() => setAdvancedEdit(!advancedEdit)}
                            >
                            {!advancedEdit && <span className="material-icons md-24">tune</span>}
                            {advancedEdit && <span className="material-icons md-24">web_asset</span>}
                            </Fab>
                          </GridCell>
                          <GridCell weight={1}/>
                        </GridContainer>
                      </div>
                    </GridCell>
                  </GridContainer>
                }
              </div>
            }
            <GridCell weight={1}/>
            {!editing && opacityInfo !== 0 &&
              <GridCell>
                <MenuOrganisation
                  me={me}
                  focusedOrganisation={organisationReferences[id]}
                  eventsAvailable={(events?.length > 0 && search?.events?.query?.createdByOrg === id)}

                  editing={editing}
                  menuOpen={menuOpen}
                  setEditing={(value) => {
                    if(value) {
                      setFocusedOrganisation(organisationReferences[id]);
                    } else {
                      setFocusedOrganisation(undefined);
                    }
                    setEditing(value);
                  }}
                  setMenuOpen={setMenuOpen}
                  setPage={setPage}

                  setDialogData={setDialogData}
                  setDialogOpen={setDialogOpen}
                  setRedirect={setRedirect}
                  urlHistory={urlHistory}
                />
                {(organisationReferences[id]?.email || organisationReferences[id]?.phone) &&
                  <MenuInformation
                    me={me}
                    focusedOrganisation={organisationReferences[id]}

                    editing={editing}
                    menuOpen={menuOpen}
                    setEditing={(value) => {
                      if(value) {
                        setFocusedOrganisation(organisationReferences[id]);
                      } else {
                        setFocusedOrganisation(undefined);
                      }
                      setEditing(value);
                    }}
                    setMenuOpen={setMenuOpen}
                    setPage={setPage}

                    setDialogData={setDialogData}
                    setDialogOpen={setDialogOpen}
                    setRedirect={setRedirect}
                    urlHistory={urlHistory}
                  />
                }
              </GridCell>
            }

          </GridContainer>
        </div>
      </Hidden>

      <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
        {/*---------------- large sceen menu -------------*/}
        {!menuHidden &&
          <Hidden breakpoint='hiddenlessthan1024'>
            <div>
              {editing &&
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 20,
                  zIndex: 21,
                  margin: 10,

                }}>
                  <div style={{
                    borderRadius: 25,
                    background: paletteData.primary.standard.background
                  }}>
                    <MenuEdit
                      me={me}
                      focusedOrganisation={focusedOrganisation}

                      processing={processing}
                      tab={tab}
                      setTab={setTab}
                      handleUpdateOrganisation={handleUpdateOrganisation}
                    />
                  </div>
                  {processing &&
                    <div style={{marginLeft: 15, marginRight: 15, marginTop: 5}}>
                      <ProgressBar palette='primary'/>
                    </div>
                  }
                  {tab === 'layout' &&
                    <GridContainer>
                      <GridCell>
                        <div style={{
                          marginTop: 10,
                          borderRadius: 25,
                          background: paletteData.primary.standard.background
                        }}>
                          <GridContainer>
                            <GridCell>
                              <Fab
                                disabled={processing}
                                palette='primary'
                                onClick={() => setAddSection(true)}
                              >
                              <span className="material-icons md-24">add</span>
                              </Fab>
                            </GridCell>
                            <GridCell>
                              <Fab
                                disabled={processing}
                                palette='primary'
                                onClick={() => setAdvancedEdit(!advancedEdit)}
                              >
                              {!advancedEdit && <span className="material-icons md-24">tune</span>}
                              {advancedEdit && <span className="material-icons md-24">web_asset</span>}
                              </Fab>
                            </GridCell>
                            <GridCell weight={1}/>
                          </GridContainer>
                        </div>
                      </GridCell>
                    </GridContainer>
                  }
                </div>
              }
              {!editing && markers?.length > 0 &&
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 20,
                  zIndex: 21,
                  margin: 10,
                }}>
                  <Fab
                    palette='primary'
                    onClick={() => {
                      if(opacityInfo) {handleInfoFadeOut()}
                      else {handleInfoFadeIn()}
                    }}
                  >
                    {opacityInfo ? <span className="material-icons md-24">place</span> : <span className="material-icons md-24">wrong_location</span>}
                  </Fab>
                </div>
              }
              {!editing && opacityInfo !== 0 &&
                <div style={{
                  position: 'absolute',
                  top: 0,
                  right: 20,
                  zIndex: 21,
                  margin: 10,
                }}>
                  <div style={{
                    borderRadius: 25,
                    background: paletteData.primary.standard.background
                  }}>
                    <MenuOrganisation
                      me={me}
                      focusedOrganisation={organisationReferences[id]}
                      eventsAvailable={(events?.length > 0 && search?.events?.query?.createdByOrg === id)}

                      editing={editing}
                      menuOpen={menuOpen}
                      setEditing={(value) => {
                        if(value) {
                          setFocusedOrganisation(organisationReferences[id]);
                        } else {
                          setFocusedOrganisation(undefined);
                        }
                        setEditing(value);
                      }}
                      setMenuOpen={setMenuOpen}
                      setPage={setPage}

                      setDialogData={setDialogData}
                      setDialogOpen={setDialogOpen}
                      setRedirect={setRedirect}
                      urlHistory={urlHistory}
                    />
                  </div>
                  {(organisationReferences[id]?.email || organisationReferences[id]?.phone) &&
                    <div style={{
                      marginTop: 10,
                      borderRadius: 25,
                      background: paletteData.primary.standard.background
                    }}>
                      <MenuInformation
                        me={me}
                        focusedOrganisation={organisationReferences[id]}

                        editing={editing}
                        menuOpen={menuOpen}
                        setEditing={(value) => {
                          if(value) {
                            setFocusedOrganisation(organisationReferences[id]);
                          } else {
                            setFocusedOrganisation(undefined);
                          }
                          setEditing(value);
                        }}
                        setMenuOpen={setMenuOpen}
                        setPage={setPage}

                        setDialogData={setDialogData}
                        setDialogOpen={setDialogOpen}
                        setRedirect={setRedirect}
                        urlHistory={urlHistory}
                      />
                    </div>
                  }
                </div>
              }
            </div>
          </Hidden>
        }

        {/*----------------- display page ----------------*/}
        {(!organisationReferences[id]) &&
          <GridContainer style={{height: '100vh'}}>
            <GridCell weight={1}/>
            <GridCell center={true} centerWeights={{top: 2, bottom: 3}}>
              <Typography size='title' style={{textAlign: 'center'}}>
                ...Loading
              </Typography>
              <ProgressBar palette='secondary'/>
            </GridCell>
            <GridCell weight={1}/>
          </GridContainer>
        }
        <div style={{
          pointerEvents: pointerEventsInfo,
          display: opacityInfo === 0 ? 'none' : 'initial',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 20,
          width: '100%',
          height: '100%',
          overflow: 'scroll',
          background: 'rgba(255, 255, 255, 0.85)',
          overflowX: 'hidden',
        }}>
          {/*---------- organisation not loaded -------------*/}
          {!organisationReferences[id] &&
            <GridContainer style={{height: '100vh'}}>
              <GridCell weight={1}/>
              <GridCell center={true} centerWeights={{top: 2, bottom: 3}}>
                <Typography size='title' style={{textAlign: 'center'}}>
                  ...Loading
                </Typography>
                <ProgressBar palette='secondary'/>
              </GridCell>
              <GridCell weight={1}/>
            </GridContainer>
          }
          {/*---------- fallback if organisation has no layout -------------*/}
          {(!organisationReferences[id]?.layout?.pages || organisationReferences[id]?.layout?.pages?.length === 0) &&
            <GridContainer style={{
              background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundBlendMode: 'lighten',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              backgroundAttachment: 'fixed',
              flex: 1,
            }}>
              <GridCell weight={1} center centerWeights={{top: 1, bottom: 2}} style={{textAlign: 'center'}}>
                <Typography size='heading' style={{letterSpacing: 2.3, fontWeight: 700}}>
                  {organisationReferences[id]?.name}
                </Typography>
                <Typography size='title' palette='secondary' style={{letterSpacing: 5}}>
                  {organisationReferences[id]?.tagline}
                </Typography>
              </GridCell>
            </GridContainer>
          }
          {/*---------- organisation layout -------------*/}
          {!editing && organisationReferences[id]?.layout?.pages?.length > 0 &&
           organisationReferences[id]?.layout?.pages[page]?.sections?.length > 0 &&
           organisationReferences[id]?.layout?.pages[page]?.sections.map((b, bIndex) => (
             <LayoutDisplay
              key={bIndex}
              block={b}
              onClick={() => {}}
              onCallback={(name, value) => {
                if(name === 'galeryOpenEvent') {
                  setMenuHidden(value);
                }
              }}
            />
          ))}
          {/*---------- edit organisation layout -------------*/}
          {editing && tab === 'layout' &&
            <LayoutEdit
              imageLibrary={focusedOrganisation?.gallery}
              videoLibrary={focusedOrganisation?.videoLibrary || []}
              sections={(focusedOrganisation?.layout?.pages && focusedOrganisation?.layout?.pages[page]?.sections) || []}
              changeSection={(type, index, path, value) => changePage(page, type, index, path, value)}
              changeParent={(type, path, value) => changeParent(type, path, value)}
              addSection={addSection}
              setAddSection={setAddSection}
              advancedEdit={advancedEdit}
              setAdvancedEdit={setAdvancedEdit}
            />
          }
          {editing && tab === 'image' &&
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10} style={{padding: 10}}>
                <div style={{height: 60}}/>
                <EditOrganisationImageGallery
                  focusedOrganisation={focusedOrganisation}
                  processing={processing}
                  setProcessing={setProcessing}
                  handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
                  handleUploadImage={handleUploadImage}
                />
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
          }
          {editing && tab === 'video' &&
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10} style={{padding: 10}}>
                <div style={{height: 60}}/>
                <EditOrganisationVideoGallery
                  focusedOrganisation={focusedOrganisation}
                  processing={processing}
                  setProcessing={setProcessing}
                  handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}
                  handleUploadImage={handleUploadImage}
                />
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
          }
          {editing && tab === 'information' &&
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10} style={{padding: 10}}>
                <div style={{height: 60}}/>
                  <EditOrganisationInformation
                    me={me}
                    processing={processing}
                    dialogOpen={dialogOpen}
                    dialogData={dialogData}
                    setProcessing={setProcessing}
                    setDialogOpen={setDialogOpen}
                    setDialogData={setDialogData}

                    focusedOrganisation={focusedOrganisation}
                    handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}

                    categories={categories}
                  />
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
          }
          {editing && tab === 'listing' &&
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10} style={{padding: 10}}>
                <div style={{height: 60}}/>
                  <EditOrganisationListing
                    me={me}
                    processing={processing}
                    dialogOpen={dialogOpen}
                    dialogData={dialogData}
                    setProcessing={setProcessing}
                    setDialogOpen={setDialogOpen}
                    setDialogData={setDialogData}

                    focusedOrganisation={focusedOrganisation}
                    handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}

                    categories={categories}
                  />
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
          }
          {editing && tab === 'location' &&
            <GridContainer>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
              <GridCell weight={10} style={{padding: 10}}>
                <div style={{height: 60}}/>
                  <EditOrganisationLocations
                    me={me}
                    processing={processing}
                    dialogOpen={dialogOpen}
                    dialogData={dialogData}
                    setProcessing={setProcessing}
                    setDialogOpen={setDialogOpen}
                    setDialogData={setDialogData}

                    focusedOrganisation={focusedOrganisation}
                    handleChangeFocusedOrganisation={handleChangeFocusedOrganisation}

                    areas={areas}
                    areaReferences={areaReferences}
                  />
              </GridCell>
              <Hidden breakpoint='hiddenlessthan650'>
                <GridCell weight={1}/>
              </Hidden>
            </GridContainer>
          }
        </div>

        {/*------------- map ---------------*/}
        {markers?.length > 0 &&
          <Map
            style={{width: '100%', flex: 1}}
            center={(markers?.length > 0 && markers[0]?.position) || areaReferences[areaId]?.position || myPosition || center}
            zoom={15}
            markers={markers}
            options={{
              styles: opacityInfo ? mapStyle1 : mapStyle2,
              disableDefaultUI: true,
            }}
          />
        }
      </div>


      {/*---------------- popouts and popups ------------------*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {dialogData?.message &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }
          {dialogData?.type === 'register' &&
            <div style={{padding: 10}}>
              <Select
                name="type"
                palette='secondary'
                label=""
                type="text"
                value={dialogData?.value?.type || ''}
                onChange={(value) => {
                    const tempDialogData = {
                      ...(dialogData || {}),
                      value: {
                        ...(dialogData.value || {}),
                        type: value,
                      }
                    };
                    if (value === 'deregister') {
                      tempDialogData.type = 'deregister';
                      tempDialogData.title = `Leave mailing list for ${organisationReferences[id]?.name}`;
                      tempDialogData.message = `You are about to de-register for this event.  You will no longer recieve notifications or promotional emails from ${organisationReferences[id]?.name} are you sure you wish to continue?`;
                    }
                    setDialogData(tempDialogData);
                }}
              >
                <option value="register">Register</option>
                <option value="deregister">De-register</option>
              </Select>
              <Input
                name="name"
                palette='secondary'
                label="Name"
                type="text"
                value={dialogData?.value?.name || ''}
                onChange={(value) => {
                  setDialogData({
                    ...(dialogData || {}),
                    value: {
                      ...(dialogData?.value || {}),
                      name: value,
                    }
                  });
                }}
              />
              <Input
                name="email"
                palette='secondary'
                label="Email"
                type="text"
                value={dialogData?.value?.email || ''}
                onChange={(value) => {
                  setDialogData({
                    ...(dialogData || {}),
                    value: {
                      ...(dialogData?.value || {}),
                      email: value,
                    }
                  });
                }}
              />
            </div>
          }
          {dialogData?.type === 'deregister' &&
            <div style={{padding: 10}}>
              <Select
                name="type"
                palette='secondary'
                label=""
                type="text"
                value={dialogData?.value?.type || ''}
                onChange={(value) => {
                    const tempDialogData = {
                      ...(dialogData || {}),
                      value: {
                        ...(dialogData.value || {}),
                        type: value,
                      }
                    };
                    if (value === 'register') {
                      tempDialogData.type = 'register';
                      tempDialogData.title = `Join mailing list for ${organisationReferences[id]?.name}`;
                      tempDialogData.message = `By joining this mailing list you acknowledge that your email will be provided to ${organisationReferences[id]?.name} for the purposes of sending notifications and promotional emails.  Rerengatahi and ${organisationReferences[id]?.name} will only use this email for its intended purpose and will not distribute it to any 3rd party.`;
                    }
                    setDialogData(tempDialogData);
                }}
              >
                <option value="register">Register</option>
                <option value="deregister">De-register</option>
              </Select>
              <Input
                name="email"
                palette='secondary'
                label="Email"
                type="text"
                value={dialogData?.value?.email || ''}
                onChange={(value) => {
                  setDialogData({
                    ...(dialogData || {}),
                    value: {
                      ...(dialogData?.value || {}),
                      email: value,
                    }
                  });
                }}
              />
            </div>
          }
          {dialogData?.type === 'review' &&
            <div style={{padding: 10}}>
              {organisationReferences[id]?.review?.total > 0 &&
                <div style={{textAlign: 'center'}}>
                  <Typography style={{fontWeight: 500, marginBottom: 5}}>
                    Current Rating ({organisationReferences[id]?.review?.total} review{organisationReferences[id]?.review?.total > 1 ? 's' : ''})
                  </Typography>
                  <span className="material-icons md-24" style={{fontSize: 20}}>{organisationReferences[id]?.review?.stars / organisationReferences[id]?.review?.total > 0 ? 'star' : 'star_border'}</span>
                  <span className="material-icons md-24" style={{fontSize: 20}}>{organisationReferences[id]?.review?.stars / organisationReferences[id]?.review?.total > 1 ? 'star' : 'star_border'}</span>
                  <span className="material-icons md-24" style={{fontSize: 20}}>{organisationReferences[id]?.review?.stars / organisationReferences[id]?.review?.total > 2 ? 'star' : 'star_border'}</span>
                  <span className="material-icons md-24" style={{fontSize: 20}}>{organisationReferences[id]?.review?.stars / organisationReferences[id]?.review?.total > 3 ? 'star' : 'star_border'}</span>
                  <span className="material-icons md-24" style={{fontSize: 20}}>{organisationReferences[id]?.review?.stars / organisationReferences[id]?.review?.total > 4 ? 'star' : 'star_border'}</span>
                </div>
              }
              {!me?._id &&
                <Typography>
                  To leave a review you need to be registered with a verified email address
                </Typography>
              }
              {me?._id && !me?.verification?.contact[me?.email] &&
                <Typography>
                  You need to verify your email address before you can leave a review.  Please go to your console and verify your email address.
                </Typography>
              }
              {me?._id && me?.verification?.contact[me?.email] &&
                <div>
                  <Typography style={{textAlign: 'center'}}>
                    Leave your own reveiw of this organisation below
                  </Typography>
                  <br/>
                  <Typography style={{textAlign: 'center'}}>
                    Select Star Rating
                  </Typography>
                  <div style={{textAlign: 'center'}}>
                    <span
                      className="material-icons md-24" style={{cursor: 'pointer'}}
                        onClick={() => {
                        setDialogData({
                          ...(dialogData || {}),
                          value: {
                            ...(dialogData?.value || {}),
                            stars: 1,
                          }
                        });
                      }}
                    >
                      {dialogData?.value?.stars > 0 ? 'star' : 'star_border'}
                    </span>
                    <span
                      className="material-icons md-24" style={{cursor: 'pointer'}}
                        onClick={() => {
                        setDialogData({
                          ...(dialogData || {}),
                          value: {
                            ...(dialogData?.value || {}),
                            stars: 2,
                          }
                        });
                      }}
                    >
                      {dialogData?.value?.stars > 1 ? 'star' : 'star_border'}
                    </span>
                    <span
                      className="material-icons md-24" style={{cursor: 'pointer'}}
                        onClick={() => {
                        setDialogData({
                          ...(dialogData || {}),
                          value: {
                            ...(dialogData?.value || {}),
                            stars: 3,
                          }
                        });
                      }}
                    >
                      {dialogData?.value?.stars > 2 ? 'star' : 'star_border'}
                    </span>
                    <span
                      className="material-icons md-24" style={{cursor: 'pointer'}}
                        onClick={() => {
                        setDialogData({
                          ...(dialogData || {}),
                          value: {
                            ...(dialogData?.value || {}),
                            stars: 4,
                          }
                        });
                      }}
                    >
                      {dialogData?.value?.stars > 3 ? 'star' : 'star_border'}
                    </span>
                    <span
                      className="material-icons md-24" style={{cursor: 'pointer'}}
                        onClick={() => {
                        setDialogData({
                          ...(dialogData || {}),
                          value: {
                            ...(dialogData?.value || {}),
                            stars: 5,
                          }
                        });
                      }}
                    >
                      {dialogData?.value?.stars > 4 ? 'star' : 'star_border'}
                    </span>
                  </div>
                  <TextArea
                    name="name"
                    palette='secondary'
                    label="Review"
                    type="text"
                    value={dialogData?.value?.text || ''}
                    onChange={(value) => {
                      setDialogData({
                        ...(dialogData || {}),
                        value: {
                          ...(dialogData?.value || {}),
                          text: value,
                        }
                      });
                    }}
                  />
                </div>
              }
            </div>
          }
          {dialogData?.type === 'addCategory' &&
            <div style={{padding: 10}}>
              <Input
                label="search"
                value={dialogData?.value?.search || ''}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      search: value,
                    }
                  })
                }}
              />
              {!processing && categories && categories.filter((c, cIndex) => {
                return c.name.toLowerCase().includes((dialogData?.value?.search || '').toLowerCase());
              }).map((c, cIndex) => (
                <div
                  key={cIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    const tempCategories = focusedOrganisation?.categories || [];
                    let testCategories = true;
                    for(let i = 0; i < tempCategories.length; i += 1) {
                      if(tempCategories[i] === c.name) {
                        testCategories = false;
                      }
                    }
                    if(testCategories) {
                      tempCategories.push(c.name);
                      tempCategories.sort();
                      handleChangeFocusedOrganisation('categories', tempCategories);
                    }
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {c.name}
                </div>
              ))}
            </div>
          }
          {dialogData?.type === 'selectImage' &&
            <div style={{padding: 10}}>
              <Input
                label='Search Images'
                value={dialogData?.value?.search}
                onChange={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      search: value,
                    }
                  })
                }}
              />
              {processing && <ProgressBar palette='secondary'/>}
              <GridContainer>
                {dialogData?.value?.imageId &&
                  <GridCell
                    style={{width: 100, margin: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!processing) {
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_100,h_100/${dialogData?.value?.imageId || 'media-assets/hudson-hintze-vpxeE7s-my4-unsplash_axrfv6_oemuhc.jpg'}`}
                      alt={dialogData?.value?.alt}
                      style={{width: '100%'}}
                    />
                    <Typography style={{textAlign: 'center'}}>
                      Current
                    </Typography>
                  </GridCell>
                }
                <GridCell
                  style={{width: 100, margin: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!processing) {
                      imageUploadRef.current.click();
                    }
                  }}
                >
                  <input
                    ref={imageUploadRef}
                    type="file"
                    style={{display: 'none'}}
                    onChange={(event) => {
                      const file = event?.target?.files?.length > 0 ? event.target.files[0] : undefined;
                      handleUploadImage(file, (response, error) => {
                        if(!error) {
                          dialogData?.data.callback({
                            imageId: response.imageId,
                            imageOptions: dialogData?.value?.imageOptions,
                            alt: response.alt,
                          });
                          const image = {
                            imageId: response.imageId,
                            tags: '',
                            alt: response.alt,
                            description: '',
                          };
                          changeParent('insert', 'gallery', image);
                          setDialogData(undefined);
                          setDialogOpen(false);
                        } else {
                          console.log(error);
                        }
                      });
                    }}
                  />
                  <div style={{width: '100%', height: 100, textAlign: 'center', background: '#c6c6c6'}}>
                    <span className="material-icons md-24" style={{marginTop: 35}}>image</span>
                    <span className="material-icons md-24" style={{marginTop: 35}}>add</span>
                  </div>
                  <Typography style={{textAlign: 'center'}}>
                    New
                  </Typography>
                </GridCell>
                {dialogData?.value?.imageId &&
                  <GridCell
                    style={{width: 100, margin: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!processing) {
                        dialogData?.data.callback({imageId: undefined, imageOptions: undefined, alt: undefined});
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <div style={{width: '100%', height: 100, textAlign: 'center', background: '#c6c6c6'}}>
                      <span className="material-icons md-24" style={{marginTop: 35}}>image</span>
                      <span className="material-icons md-24" style={{marginTop: 35}}>remove</span>
                    </div>
                    <Typography style={{textAlign: 'center'}}>
                      Remove
                    </Typography>
                  </GridCell>
                }
                {focusedOrganisation?.gallery?.length > 0 && focusedOrganisation?.gallery.filter((i, iIndex) => {
                  return (i.alt || '').toLowerCase().includes(dialogData?.value?.search?.toLowerCase());
                }).map((i, iIndex) => (
                  <GridCell
                    key={iIndex}
                    style={{width: 100, margin: 5, cursor: 'pointer'}}
                    onClick={() => {
                      if(!processing) {
                        dialogData?.data.callback({
                          imageId: i.imageId,
                          imageOptions: dialogData?.value?.imageOptions,
                          alt: i.alt,
                        });
                        setDialogData(undefined);
                        setDialogOpen(false);
                      }
                    }}
                  >
                    <img
                      src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_100,h_100/${i.imageId}`}
                      alt={i.alt}
                      style={{width: '100%'}}
                    />
                    <Typography style={{textAlign: 'center'}}>
                      {i.alt}
                    </Typography>
                  </GridCell>
                ))}
              </GridContainer>

            </div>
          }
          {dialogData?.type === 'changeArea' &&
            <div style={{padding: 10}}>
              <SearchStore
                databaseArea='areas'
                processing={processing}
                setProcessing={setProcessing}
                expandSearch={dialogData?.value?.expandSearch}
                setExpandSearch={(value) => {
                  setDialogData({
                    ...dialogData,
                    value: {
                      ...(dialogData?.value || {}),
                      expandSearch: value,
                    }
                  });
                }}
              />
              {areas?.length > 0 && areas.map((a, aIndex) => (
                <div
                  key={aIndex}
                  style={{padding: 10, margin: 5, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, cursor: 'pointer'}}
                  onClick={() => {
                    if(!areaReferences[a._id]) {
                      setAreaReference(a);
                    }
                    dialogData?.data?.callback(a._id);
                    setDialogOpen(false);
                    setDialogData(undefined);
                  }}
                >
                  {a.name}
                </div>
              ))}
              {processing && areas?.length > 9 &&
                <div style={{padding: 10}}>
                  <Typography>
                    Loading...
                  </Typography>
                  <ProgressBar palette='secondary'/>
                </div>
              }
              {!processing && search?.areas?.hasMore &&
                <div style={{padding: 5}}>
                  <Button
                    disabled={processing}
                    palette='primary'
                    onClick={() => {
                      setDialogData({
                        ...dialogData,
                        value: {
                          ...(dialogData?.value || {}),
                          expandSearch: true,
                        }
                      });
                    }}
                  >
                    Load More
                  </Button>
                </div>
              }
            </div>
          }
          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                disabled={processing}
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
            {dialogData?.type === 'register' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.email || !dialogData?.value?.name}
                  palette='primary'
                  onClick={() => {
                     setProcessing(true);
                     apiRequest({type: 'patch', action: `public_organisations/register/${id}`, data: {name: dialogData?.value?.name, email: dialogData?.value?.email, userId: me?._id}})
                     .then((result) => {
                       setProcessing(false);
                       setDialogData({
                         type: 'message',
                         title: 'Joining mailing list complete',
                         message: `You have joined the mailing list for ${organisationReferences[id]?.name}.`,
                       });
                     }).catch((error) => {
                       setProcessing(false);
                       setDialogData({
                         type: 'message',
                         title: 'Joining mailing list complete',
                         message: `You have joined the mailing list for ${organisationReferences[id]?.name}.`,
                       });
                     });
                  }}
                >
                  Submit
                </Button>
                {processing &&
                  <ProgressBar />
                }
              </GridCell>
            }
            {dialogData?.type === 'deregister' &&
              <GridCell style={{padding: 10}}>
                <Button
                  disabled={processing || !dialogData?.value?.email}
                  palette='primary'
                  onClick={() => {
                    setProcessing(true);
                    apiRequest({type: 'patch', action: `public_organisations/deregister/${id}`, data: {email: dialogData?.value?.email}})
                    .then((result) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Leaving mailing list request processed',
                        message: `Your request to leave the ${organisationReferences[id]?.name} mailing list is now underway. Please allow for 1 hour for this process to complete, you do not need to take any further actions.  Once this is complete you will now no longer recieve notifications or promotional emails from ${organisationReferences[id]?.name}`
                      });
                    }).catch((error) => {
                      setProcessing(false);
                      setDialogData({
                        type: 'message',
                        title: 'Leaving mailing list request processed',
                        message: `Your request to leave the ${organisationReferences[id]?.name} mailing list is now underway. Please allow for 1 hour for this process to complete, you do not need to take any further actions.  Once this is complete you will now no longer recieve notifications or promotional emails from ${organisationReferences[id]?.name}`
                      });
                    });
                  }}
                >
                  Submit
                </Button>
                {processing &&
                  <ProgressBar />
                }
              </GridCell>
            }
            {dialogData?.type === 'review' &&
              <GridCell style={{padding: 10}}>
                <Button palette='primary' disabled={processing || !dialogData?.value?.text || !dialogData?.value?.stars} onClick={() => {
                  setProcessing(true);
                  let stars = dialogData?.value?.stars;
                  if(!stars) stars = 1;
                  if(stars > 5) stars = 5;
                  if(stars < 1) stars = 1;
                  const review = {
                    organisationId: id,
                    stars: stars,
                    text: dialogData?.value?.text,
                  }
                  apiRequest({type: 'post', action: `reviews/create`, data: {review}})
                  .then((result) => {
                    if(result.data?.review?._id) {
                      if(reviews?.length > 0) {
                        const reviewsTest = reviews.findIndex((r) => r._id === result.data?.review?._id);
                        if(reviewsTest === -1) {
                          addReview(result.data.review);
                        } else {
                          replaceReview({index: reviewsTest, review: result.data.review});
                        }
                      }
                      setReviewReference(result.data.review);
                    }
                    if(result.data?.organisation?._id) {
                      if(organisations?.length > 0) {
                        const organisationsTest = organisations.findIndex((o) => o._id === result.data?.organisation?._id);
                        if(organisationsTest !== -1) {
                          replaceOrganisation({index: organisationsTest, organisation: result.data.organisation});
                        }
                      }
                      setOrganisationReference(result.data.organisation);
                    }
                    setProcessing(false);
                    setDialogData({
                      type: 'message',
                      title: 'Review created',
                      message: 'Thankyou for your review it will be added shortly',
                    });
                    setDialogOpen(true);
                  }).catch((error) => {
                    setProcessing(false);
                    setDialogData({
                      type: 'message',
                      title: 'Create review request denied',
                      message: handleErrorMessage(error),
                    });
                    setDialogOpen(true);
                  });
                }}>
                  Submit
                </Button>
              </GridCell>
            }
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
    organisations: state.lists.organisations,
    events: state.lists.events,
    products: state.lists.products,
    reviews: state.lists.reviews,
    categories: state.lists.categories,
    areas: state.lists.areas,

    organisationReferences: state.references.organisations || {},
    areaReferences: state.references.areas || {},
    eventReferences: state.references.events || {},
    productReferences: state.references.products || {},
    reviewReferences: state.references.reviews || {},
  };
};

export default connect(mapStateToProps, {setOrganisationReference, setEventReference, setSearch, setEvents, setProducts, setProductReference, setReviewReference, replaceOrganisation, replaceReview, addReview, setAreaReferences, setEventReferences})(Organisation);
