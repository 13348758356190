import {
  SET_USERS,
  ADD_USERS,
  ADD_USER,
  REPLACE_USER,
  REMOVE_USER,

  SET_ORGANISATIONS,
  ADD_ORGANISATIONS,
  ADD_ORGANISATION,
  REPLACE_ORGANISATION,
  REMOVE_ORGANISATION,

  SET_AREAS,
  ADD_AREAS,
  ADD_AREA,
  REPLACE_AREA,
  REMOVE_AREA,

  SET_CATEGORIES,
  ADD_CATEGORIES,
  ADD_CATEGORY,
  REPLACE_CATEGORY,
  REMOVE_CATEGORY,

  SET_ARTICLES,
  ADD_ARTICLES,
  ADD_ARTICLE,
  REPLACE_ARTICLE,
  REMOVE_ARTICLE,

  SET_EVENTS,
  ADD_EVENTS,
  ADD_EVENT,
  REPLACE_EVENT,
  REMOVE_EVENT,

  SET_PRODUCTS,
  ADD_PRODUCTS,
  ADD_PRODUCT,
  REPLACE_PRODUCT,
  REMOVE_PRODUCT,

  SET_ORDERS,
  ADD_ORDERS,
  ADD_ORDER,
  REPLACE_ORDER,
  REMOVE_ORDER,

  SET_CURRENT_ORDERS,
  ADD_CURRENT_ORDERS,
  ADD_CURRENT_ORDER,
  REPLACE_CURRENT_ORDER,
  REMOVE_CURRENT_ORDER,

  SET_REVIEWS,
  ADD_REVIEWS,
  ADD_REVIEW,
  REPLACE_REVIEW,
  REMOVE_REVIEW,

  SET_COMMUNITIES,
  ADD_COMMUNITY,
  ADD_COMMUNITIES,
  REPLACE_COMMUNITY,
  REMOVE_COMMUNITY,

  SET_MESSAGES,
  ADD_MESSAGE,
  ADD_MESSAGES,
  REPLACE_MESSAGE,
  REMOVE_MESSAGE,

  SET_SEARCH,
  RESET_LISTS,
} from "./actionTypes";

const initialState = {
  users: undefined,
  organisations: undefined,
  areas: undefined,
  categories: undefined,
  articles: undefined,
  events: undefined,
  products: undefined,
  orders: undefined,
  currentOrders: undefined,
  reviews: undefined,
  communities: undefined,
  messages: undefined,
  queryLimit: 10,
  search: {
    users: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    organisations: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    areas: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    categories: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    articles: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    events: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    products: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    orders: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    currentOrders: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    reviews: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    communities: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
    messages: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
  }
}

export default function listsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH: {
      const searchProcessed = state.search;
      searchProcessed[action.payload.name] = action.payload.value;
      return {
        ...state,
        search: searchProcessed,
      }
    }
    case RESET_LISTS: {
      return {
        users: undefined,
        organisations: undefined,
        areas: undefined,
        categories: state.categories,
        articles: undefined,
        events: undefined,
        products: undefined,
        orders: undefined,
        currentOrders: undefined,
        reviews: undefined,
        communities: undefined,
        messages: undefined,
        queryLimit: 50,
        search: {
          users: {text: '', query: undefined, data: undefined, queryDepth: 0},
          organisations: {text: '', query: undefined, data: undefined, queryDepth: 0},
          areas: {text: '', query: undefined, data: undefined, queryDepth: 0},
          categories: {text: '', query: undefined, data: undefined, queryDepth: 0},
          articles: {text: '', query: undefined, data: undefined, queryDepth: 0},
          events: {text: '', query: undefined, data: undefined, queryDepth: 0},
          products: {text: '', query: undefined, data: undefined, queryDepth: 0},
          orders: {text: '', query: undefined, data: undefined, queryDepth: 0},
          currentOrders: {text: '', query: undefined, data: undefined, queryDepth: 0},
          reviews: {text: '', query: undefined, data: undefined, queryDepth: 0},
          communities: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
          messages: {text: '', query: undefined, data: undefined, queryDepth: 0, hasMore: true},
        }
      }
    }

    //--------------------- users --------------------
    case SET_USERS: {
      return {
        ...state,
        users: action.payload,
      }
    }
    case ADD_USER: {
      const usersProcessed = state.users || [];
      usersProcessed.push(action.payload);
      usersProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        users: usersProcessed,
      }
    }
    case ADD_USERS: {
      return {
        ...state,
        users: (state.users || []).concat(action.payload),
      }
    }
    case REPLACE_USER: {
      const usersProcessed = state.users || [];
      const uIndex = usersProcessed.findIndex((u) => {
        return action.payload.user._id === u._id;
      });
      if(uIndex !== -1) {
        usersProcessed.splice(uIndex, 1, action.payload.user);
      }
      return {
        ...state,
        users: usersProcessed,
      }
    }
    case REMOVE_USER: {
      const usersProcessed = state.users || [];
      const uIndex = usersProcessed.findIndex((u) => {
        return action.payload.user._id === u._id;
      });
      if(uIndex !== -1) {
        usersProcessed.splice(uIndex, 1);
      }
      return {
        ...state,
        users: usersProcessed,
      }
    }


    //--------------------- areas --------------------
    case SET_AREAS: {
      return {
        ...state,
        areas: action.payload,
      }
    }
    case ADD_AREA: {
      const areasProcessed = state.areas || [];
      areasProcessed.push(action.payload);
      areasProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        areas: areasProcessed,
      }
    }
    case ADD_AREAS: {
      return {
        ...state,
        areas: (state.areas || []).concat(action.payload),
      }
    }
    case REPLACE_AREA: {
      const areasProcessed = state.areas || [];
      const aIndex = areasProcessed.findIndex((u) => {
        return action.payload.area._id === u._id;
      });
      if(aIndex !== -1) {
        areasProcessed.splice(aIndex, 1, action.payload.area);
      }
      return {
        ...state,
        areas: areasProcessed,
      }
    }
    case REMOVE_AREA: {
      const areasProcessed = state.areas || [];
      const aIndex = areasProcessed.findIndex((u) => {
        return action.payload.area._id === u._id;
      });
      if(aIndex !== -1) {
        areasProcessed.splice(aIndex, 1);
      }
      return {
        ...state,
        areas: areasProcessed,
      }
    }

    //--------------------- organisations --------------------
    case SET_ORGANISATIONS: {
      return {
        ...state,
        organisations: action.payload,
      }
    }
    case ADD_ORGANISATION: {
      const organisationsProcessed = state.organisations || [];
      organisationsProcessed.push(action.payload);
      organisationsProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        organisations: organisationsProcessed,
      }
    }
    case ADD_ORGANISATIONS: {
      return {
        ...state,
        organisations: (state.organisations || []).concat(action.payload),
      }
    }
    case REPLACE_ORGANISATION: {
      const organisationsProcessed = state.organisations || [];
      const oIndex = organisationsProcessed.findIndex((o) => {
        return action.payload.organisation._id === o._id;
      });
      if(oIndex !== -1) {
        organisationsProcessed.splice(oIndex, 1, action.payload.organisation);
      }
      return {
        ...state,
        organisations: organisationsProcessed,
      }
    }
    case REMOVE_ORGANISATION: {
      const organisationsProcessed = state.organisations || [];
      const oIndex = organisationsProcessed.findIndex((o) => {
        return action.payload.organisation._id === o._id;
      });
      if(oIndex !== -1) {
        organisationsProcessed.splice(oIndex, 1);
      }
      return {
        ...state,
        organisations: organisationsProcessed,
      }
    }


    //--------------------- categories --------------------
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      }
    }
    case ADD_CATEGORY: {
      const categoriesProcessed = state.categories || [];
      categoriesProcessed.push(action.payload);
      categoriesProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        categories: categoriesProcessed,
      }
    }
    case ADD_CATEGORIES: {
      return {
        ...state,
        categories: (state.categories || []).concat(action.payload),
      }
    }
    case REPLACE_CATEGORY: {
      const categoriesProcessed = state.categories || [];
      const cIndex = categoriesProcessed.findIndex((c) => {
        return action.payload.category._id === c._id;
      });
      if(cIndex !== -1) {
        categoriesProcessed.splice(cIndex, 1, action.payload.category);
      }
      return {
        ...state,
        categories: categoriesProcessed,
      }
    }
    case REMOVE_CATEGORY: {
      const categoriesProcessed = state.categories || [];
      const cIndex = categoriesProcessed.findIndex((c) => {
        return action.payload.category._id === c._id;
      });
      if(cIndex !== -1) {
        categoriesProcessed.splice(cIndex, 1);
      }
      return {
        ...state,
        categories: categoriesProcessed,
      }
    }

    //--------------------- events --------------------
    case SET_EVENTS: {
      return {
        ...state,
        events: action.payload,
      }
    }
    case ADD_EVENT: {
      const eventsProcessed = state.events || [];
      eventsProcessed.push(action.payload);
      eventsProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        events: eventsProcessed,
      }
    }
    case ADD_EVENTS: {
      return {
        ...state,
        events: (state.events || []).concat(action.payload),
      }
    }
    case REPLACE_EVENT: {
      const eventsProcessed = state.events || [];
      const eIndex = eventsProcessed.findIndex((c) => {
        return action.payload.event._id === c._id;
      });
      if(eIndex !== -1) {
        eventsProcessed.splice(eIndex, 1, action.payload.event);
      }
      return {
        ...state,
        events: eventsProcessed,
      }
    }
    case REMOVE_EVENT: {
      const eventsProcessed = state.events || [];
      const eIndex = eventsProcessed.findIndex((c) => {
        return action.payload.event._id === c._id;
      });
      if(eIndex !== -1) {
        eventsProcessed.splice(eIndex, 1);
      }
      return {
        ...state,
        events: eventsProcessed,
      }
    }

    //--------------------- products --------------------
    case SET_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      }
    }
    case ADD_PRODUCT: {
      const productsProcessed = state.products || [];
      productsProcessed.push(action.payload);
      productsProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        products: productsProcessed,
      }
    }
    case ADD_PRODUCTS: {
      return {
        ...state,
        products: (state.products || []).concat(action.payload),
      }
    }
    case REPLACE_PRODUCT: {
      const productsProcessed = state.products || [];
      const pIndex = productsProcessed.findIndex((c) => {
        return action.payload.product._id === c._id;
      });
      if(pIndex !== -1) {
        productsProcessed.splice(pIndex, 1, action.payload.product);
      }
      return {
        ...state,
        products: productsProcessed,
      }
    }
    case REMOVE_PRODUCT: {
      const productsProcessed = state.products || [];
      const pIndex = productsProcessed.findIndex((c) => {
        return action.payload.product._id === c._id;
      });
      if(pIndex !== -1) {
        productsProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        products: productsProcessed,
      }
    }

    //--------------------- reviews --------------------
    case SET_REVIEWS: {
      return {
        ...state,
        reviews: action.payload,
      }
    }
    case ADD_REVIEW: {
      const reviewsProcessed = state.reviews || [];
      reviewsProcessed.push(action.payload);
      reviewsProcessed.sort((a, b) => b.createdAt - a.createdAt);
      return {
        ...state,
        reviews: reviewsProcessed,
      }
    }
    case ADD_REVIEWS: {
      return {
        ...state,
        reviews: (state.reviews || []).concat(action.payload),
      }
    }
    case REPLACE_REVIEW: {
      const reviewsProcessed = state.reviews || [];
      const pIndex = reviewsProcessed.findIndex((c) => {
        return action.payload.review._id === c._id;
      });
      if(pIndex !== -1) {
        reviewsProcessed.splice(pIndex, 1, action.payload.review);
      }
      return {
        ...state,
        reviews: reviewsProcessed,
      }
    }
    case REMOVE_REVIEW: {
      const reviewsProcessed = state.reviews || [];
      const pIndex = reviewsProcessed.findIndex((c) => {
        return action.payload.review._id === c._id;
      });
      if(pIndex !== -1) {
        reviewsProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        reviews: reviewsProcessed,
      }
    }


    //--------------------- articles --------------------
    case SET_ARTICLES: {
      return {
        ...state,
        articles: action.payload,
      }
    }
    case ADD_ARTICLE: {
      const articlesProcessed = state.articles || [];
      articlesProcessed.push(action.payload);
      articlesProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        articles: articlesProcessed,
      }
    }
    case ADD_ARTICLES: {
      return {
        ...state,
        articles: (state.articles || []).concat(action.payload),
      }
    }
    case REPLACE_ARTICLE: {
      const articlesProcessed = state.articles || [];
      const aIndex = articlesProcessed.findIndex((c) => {
        return action.payload.article._id === c._id;
      });
      if(aIndex !== -1) {
        articlesProcessed.splice(aIndex, 1, action.payload.article);
      }
      articlesProcessed.splice(action.payload.index, 1, action.payload.article);
      return {
        ...state,
        articles: articlesProcessed,
      }
    }
    case REMOVE_ARTICLE: {
      const articlesProcessed = state.articles || [];
      const pIndex = articlesProcessed.findIndex((c) => {
        return action.payload.article._id === c._id;
      });
      if(pIndex !== -1) {
        articlesProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        articles: articlesProcessed,
      }
    }


    //--------------------- orders --------------------
    case SET_ORDERS: {
      return {
        ...state,
        orders: action.payload,
      }
    }
    case ADD_ORDER: {
      const ordersProcessed = state.orders || [];
      ordersProcessed.push(action.payload);
      ordersProcessed.sort((a, b) => a.createdAt - b.createdAt);
      return {
        ...state,
        orders: ordersProcessed,
      }
    }
    case ADD_ORDERS: {
      return {
        ...state,
        orders: (state.orders || []).concat(action.payload),
      }
    }
    case REPLACE_ORDER: {
      const ordersProcessed = state.orders || [];
      const oIndex = ordersProcessed.findIndex((c) => {
        return action.payload.order._id === c._id;
      });
      if(oIndex !== -1) {
        ordersProcessed.splice(oIndex, 1, action.payload.order);
      }
      return {
        ...state,
        orders: ordersProcessed,
      }
    }
    case REMOVE_ORDER: {
      const ordersProcessed = state.orders || [];
      const oIndex = ordersProcessed.findIndex((c) => {
        return action.payload.order._id === c._id;
      });
      if(oIndex !== -1) {
        ordersProcessed.splice(oIndex, 1);
      }
      return {
        ...state,
        orders: ordersProcessed,
      }
    }


    // ----------------------------- current orders
    case SET_CURRENT_ORDERS: {
      return {
        ...state,
        currentOrders: action.payload,
      }
    }
    case ADD_CURRENT_ORDER: {
      const ordersProcessed = state.currentOrders || [];
      ordersProcessed.push(action.payload);
      ordersProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        currentOrders: ordersProcessed,
      }
    }
    case ADD_CURRENT_ORDERS: {
      return {
        ...state,
        currentOrders: (state.currentOrders || []).concat(action.payload),
      }
    }
    case REPLACE_CURRENT_ORDER: {
      const ordersProcessed = state.currentOrders || [];
      ordersProcessed.splice(action.payload.index, 1, action.payload.order);
      return {
        ...state,
        currentOrders: ordersProcessed,
      }
    }
    case REMOVE_CURRENT_ORDER: {
      const ordersProcessed = state.currentOrders || [];
      ordersProcessed.splice(action.payload, 1);
      return {
        ...state,
        currentOrders: ordersProcessed,
      }
    }

    //--------------------- communities --------------------
    case SET_COMMUNITIES: {
      return {
        ...state,
        communities: action.payload,
      }
    }
    case ADD_COMMUNITY: {
      const communitiesProcessed = state.communities || [];
      communitiesProcessed.push(action.payload);
      communitiesProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        communities: communitiesProcessed,
      }
    }
    case ADD_COMMUNITIES: {
      return {
        ...state,
        communities: (state.communities || []).concat(action.payload),
      }
    }
    case REPLACE_COMMUNITY: {
      const communitiesProcessed = state.communities || [];
      const aIndex = communitiesProcessed.findIndex((c) => {
        return action.payload.community._id === c._id && action.payload.community.type === c.type;
      });
      if(aIndex !== -1) {
        communitiesProcessed.splice(aIndex, 1, action.payload.community);
      }
      return {
        ...state,
        communities: communitiesProcessed,
      }
    }
    case REMOVE_COMMUNITY: {
      const communitiesProcessed = state.communities || [];
      const pIndex = communitiesProcessed.findIndex((c) => {
        return action.payload.community._id === c._id;
      });
      if(pIndex !== -1) {
        communitiesProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        communities: communitiesProcessed,
      }
    }


    //--------------------- messages --------------------
    case SET_MESSAGES: {
      return {
        ...state,
        messages: action.payload,
      }
    }
    case ADD_MESSAGE: {
      const messagesProcessed = state.messages || [];
      messagesProcessed.push(action.payload);
      messagesProcessed.sort(function(a, b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else if(a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        messages: messagesProcessed,
      }
    }
    case ADD_MESSAGES: {
      return {
        ...state,
        messages: (state.messages || []).concat(action.payload),
      }
    }
    case REPLACE_MESSAGE: {
      const messagesProcessed = state.messages || [];
      const aIndex = messagesProcessed.findIndex((c) => {
        return action.payload.message._id === c._id && action.payload.message.type === c.type;
      });
      if(aIndex !== -1) {
        messagesProcessed.splice(aIndex, 1, action.payload.message);
      }
      return {
        ...state,
        messages: messagesProcessed,
      }
    }
    case REMOVE_MESSAGE: {
      const messagesProcessed = state.messages || [];
      const pIndex = messagesProcessed.findIndex((c) => {
        return action.payload.message._id === c._id;
      });
      if(pIndex !== -1) {
        messagesProcessed.splice(pIndex, 1);
      }
      return {
        ...state,
        messages: messagesProcessed,
      }
    }


    default:
      return state
  }
}
