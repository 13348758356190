import React from 'react';
import PropTypes from 'prop-types';

import ProductInformationForm from '../../../../components/forms/productInformation';

import {paletteData, Typography, Button} from '../../../../components/styles';
import {GridContainer, GridCell} from '../../../../components/grid';

function EditProductInformationSubPanel({
    setDialogOpen,
    setDialogData,

    focusedProduct,
    handleChangeFocusedProduct,
    focusedOrganisation,
    focusedPostGroup,
  }) {
    return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Product Information
        </Typography>
      </div>
      <ProductInformationForm
        focusedProduct={focusedProduct}
        focusedOrganisation={focusedOrganisation}
        handleChange={(name, value) => handleChangeFocusedProduct(name, value)}
        setDialogData={setDialogData}
        setDialogOpen={setDialogOpen}
      />
      <br/>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Postage
        </Typography>
      </div>
      {(!focusedOrganisation?.postGroups || focusedOrganisation?.postGroups?.length === 0) &&
        <div style={{padding: 10}}>
          <Typography>
            You have not yet set up post groups for your organisation.  Post groups allow you to group your products together to calulate postage.  If you have not assigned a product to a post group the system will assume that postage is included in its listed price.
          </Typography>
          <br/>
          <Button
            palette='primary'
            onClick={() => {
              setDialogData({
                type: 'createPostGroup',
                title: 'Create Post Group',
                data: {
                  callback: (value) => {
                    const deliveryProcessed = focusedProduct?.delivery || {};
                    deliveryProcessed.postGroup = value;
                    handleChangeFocusedProduct('delivery', deliveryProcessed);
                  }
                },
                value: {
                  id: `${focusedOrganisation._id}-${(new Date()).getTime()}`,   //each organisation will be creating thier own set of post groups making 2 post groups for the same org made at exactly the same second unlikely.
                  name: '',
                  national: undefined,
                  nationalLoad: undefined,
                  international: undefined,
                  internationalLoad: undefined,
                }
              });
              setDialogOpen(true);
            }}
          >
            Create your first post group.
          </Button>
        </div>
      }
      {focusedOrganisation?.postGroups?.length > 0 &&
        <div style={{padding: 10}}>
          {focusedPostGroup &&
            <div>
                <Typography>
                  {focusedPostGroup.name}
                </Typography>
                <GridContainer>
                  <GridCell style={{marginRight: 10}}>
                    <Typography>
                      National Postage Price:<br/>
                      International Postage Price: 
                    </Typography>
                  </GridCell>
                  <GridCell>
                    <Typography>
                      ${(focusedPostGroup.national).toFixed(2)} {focusedOrganisation?.gst ? `+ GST ($${(focusedPostGroup.national*1.15).toFixed(2)} inc GST)` : ''} {focusedPostGroup?.nationalLoad > 0 ? `per ${focusedPostGroup.nationalLoad} items` : ''}<br/>
                      ${(focusedPostGroup.international).toFixed(2)} {focusedOrganisation?.gst ? `+ GST ($${(focusedPostGroup.international*1.15).toFixed(2)} inc GST)` : ''} {focusedPostGroup?.internationalLoad > 0 ? `per ${focusedPostGroup.internationalLoad} items` : ''}
                    </Typography>
                  </GridCell>
                </GridContainer>
                <br/>
                <GridContainer>
                  <GridCell style={{marginRight: 10}}>
                    <Button 
                      palette='primary'
                      onClick={() => {
                        setDialogData({
                          type: 'editPostGroup',
                          title: 'Edit Post Group',
                          data: {},
                          value: {
                            ...focusedPostGroup,
                          }
                        });
                        setDialogOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                  </GridCell>
                  <GridCell style={{marginRight: 10}}>
                    <Button
                      palette='primary'
                      onClick={() => {
                        setDialogData({
                          type: 'createPostGroup',
                          title: 'Create Post Group',
                          data: {
                            callback: (value) => {
                              const deliveryProcessed = focusedProduct?.delivery || {};
                              deliveryProcessed.postGroup = value;
                              handleChangeFocusedProduct('delivery', deliveryProcessed);
                            }
                          },
                          value: {
                            id: `${focusedOrganisation._id}-${(new Date()).getTime()}`,   //each organisation will be creating thier own set of post groups making 2 post groups for the same org made at exactly the same second unlikely.
                            name: '',
                            national: undefined,
                            nationalLoad: undefined,
                            international: undefined,
                            internationalLoad: undefined,
                          }
                        });
                        setDialogOpen(true);
                      }}
                    >
                      Create
                    </Button>
                  </GridCell>
                  <GridCell style={{marginRight: 10}}>
                    <Button 
                      palette='primary'
                      onClick={() => {
                        const deliveryProcessed = focusedProduct?.delivery || {};
                        deliveryProcessed.postGroup = null;
                        handleChangeFocusedProduct('delivery', deliveryProcessed);
                      }}
                    >
                      Remove
                    </Button>
                  </GridCell>
                  <GridCell>
                    <Button 
                      palette='primary'
                      onClick={() => {
                        setDialogData({
                          type: 'selectPostGroup',
                          title: 'Select Post Group',
                          data: {
                            callback: (value) => {
                              const deliveryProcessed = focusedProduct?.delivery || {};
                              deliveryProcessed.postGroup = value;
                              handleChangeFocusedProduct('delivery', deliveryProcessed);
                            }
                          },
                          value: {
                            search: '',
                          }
                        });
                        setDialogOpen(true);
                      }}
                    >
                      Change
                    </Button>
                  </GridCell>
                </GridContainer>
               
            </div>
          }
          {!focusedPostGroup &&
            <div>
              <Typography>
                Post groups allow you to group your products together to calulate postage.  If you have not assigned a product to a post group the system will assume that postage is included in its listed price.
              </Typography>
              <br/>
              <GridContainer>
                <GridCell style={{marginRight: 10}}>
                  <Button
                    palette='primary'
                    onClick={() => {
                      setDialogData({
                        type: 'createPostGroup',
                        title: 'Create Post Group',
                        data: {
                          callback: (value) => {
                            const deliveryProcessed = focusedProduct?.delivery || {};
                            deliveryProcessed.postGroup = value;
                            handleChangeFocusedProduct('delivery', deliveryProcessed);
                          }
                        },
                        value: {
                          id: `${focusedOrganisation._id}-${(new Date()).getTime()}`,   //each organisation will be creating thier own set of post groups making 2 post groups for the same org made at exactly the same second unlikely.
                          name: '',
                          national: undefined,
                          nationalLoad: undefined,
                          international: undefined,
                          internationalLoad: undefined,
                        }
                      });
                      setDialogOpen(true);
                    }}
                  >
                    Create post group
                  </Button>
                </GridCell>
                <GridCell>
                  <Button 
                    palette='primary'
                    onClick={() => {
                      setDialogData({
                        type: 'selectPostGroup',
                        title: 'Select Post Group',
                        data: {
                          callback: (value) => {
                            const deliveryProcessed = focusedProduct?.delivery || {};
                            deliveryProcessed.postGroup = value;
                            handleChangeFocusedProduct('delivery', deliveryProcessed);
                          }
                        },
                        value: {
                          search: '',
                        }
                      });
                      setDialogOpen(true);
                    }}
                  >
                    Select Post Group
                  </Button>
                </GridCell>
              </GridContainer>
              
            </div>
          }
        </div>
      }
      <br/><br/><br/>
    </div>
  );
};

EditProductInformationSubPanel.propTypes = {
  focusedProduct: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedProduct: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditProductInformationSubPanel;
