const generateGridCell = ({options, children}) => {
  const section = {
    type: 'GridCell',
    options: {
      style: options?.style || {},
      weight: options?.weight || undefined,
      center: options?.center || undefined,
      centerWeights: options?.centerWeights || undefined,
    },
    children: children || [],
  }
  return section;
}

export default generateGridCell;
