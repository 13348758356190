const generateParallaxImageBlock = ({imageId, styleContainer, styleImage}) => {
  const section = {
    section: 'parallaxImageBlock',
    type: 'div',
    options: {
      style: {
        height: '100%',
        width: '100%',
        ...styleContainer,
      },
    },
    children: [
      {
        type: 'div',
        options: {
          style: {
            backgroundImage: imageId ? `url("https://res.cloudinary.com/taitokerau-tatou/image/upload/q_auto:good,c_fill,w_2048/${imageId}")` : undefined,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            height: '100%',
            ...styleImage,
          },
        },
        children: [],
      }
    ],
  };

  return section;
};

export default generateParallaxImageBlock;
