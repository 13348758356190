import React from 'react';
import PropTypes from 'prop-types';

import AutoBlock from './AutoBlock';
import Body from './Body';

function LayoutEdit({
  block,
  onClick,
  onCallback,
}) {
  console.log(block);
  if(block.section !== 'splashBlock' && block.section !== 'bannerBlock' && block.section !== 'titleBlock' && block.section !== 'parallaxImageBlock') {
    return (
      <Body>
        <AutoBlock
          block={block}
          onClick={onClick}
          onCallback={onCallback}
        />
      </Body>
    )
  } else {
    return (
      <AutoBlock
        block={block}
        onClick={onClick}
        onCallback={onCallback}
      />
    );
  }
}

LayoutEdit.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
};

export default LayoutEdit;
