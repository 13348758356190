import React from 'react';
import PropTypes from 'prop-types';

import ListingForm from '../../../../components/forms/listing';

import {paletteData, Typography} from '../../../../components/styles';

function EditOrganisationListingSubPanel({
    me,
    processing,
    dialogOpen,
    dialogData,
    setProcessing,
    setDialogOpen,
    setDialogData,

    focusedOrganisation,
    handleChangeFocusedOrganisation,

    categories,
  }) {
    return (
    <div>
      <div style={{background: paletteData.primary.standard.background, padding: 10}}>
        <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
          Listing
        </Typography>
      </div>
      <ListingForm
        preview={focusedOrganisation}
        processing={processing}
        handleChange={(name, value) => handleChangeFocusedOrganisation(name, value)}
        dialogOpen={dialogOpen}
        dialogData={dialogData}
        setDialogData={setDialogData}
        setDialogOpen={setDialogOpen}
      />
      <br/>
    </div>
  );
};

EditOrganisationListingSubPanel.propTypes = {
  focusedOrganisation: PropTypes.shape({}),
  processing: PropTypes.bool,
  handleChangeFocusedOrganisation: PropTypes.func,
  handleUpdateThumbnailImage: PropTypes.func,
  handleGeneratePreview: PropTypes.func,
  setProcessing: PropTypes.func,
};

export default EditOrganisationListingSubPanel;
