import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";

import SearchStore from '../../../components/search/store';
import {Button, Fab, Typography, Dialog, ProgressBar, paletteData} from '../../../components/styles';
import {GridContainer, GridCell} from '../../../components/grid';
import Hidden from '../../../components/hidden';
import Map from '../../../components/map';

import {mapStyle1, mapStyle2} from './mapStyles';
import {setAreaReference} from '../../../store/reducers/references/actions.js';

function Areas({
  myPosition,
  search,
  setSearch,
  queryLimit,
  isMobile,

  areas,
  setAreaReference,
}) {
  // gloabal variables
  const [center] = useState({lat: -35.7274938, lng: 174.3165604});  // map fallback

  // page states
  const [pointerEventsInfo, setPointerEventsInfo] = useState('auto');
  const [opacityInfo, setOpacityInfo] = useState(1);
  const [transitioning, setTransitioning] = useState(false);
  const [redirect, setRedirect] = useState(undefined);
  const [expandSearch, setExpandSearch] = useState(false);

  // user feedback
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(undefined);
  const [processing, setProcessing] = useState(false);

  // transitions for map
  const handleInfoFadeOut = () => {
    setTransitioning(true);
    setOpacityInfo(0);
    setTimeout(function(){
      setPointerEventsInfo('none');
      setTransitioning(false);
    }, 500);
  }

  const handleInfoFadeIn = () => {
    setTransitioning(true);
    setPointerEventsInfo('auto');
    setOpacityInfo(1);
    setTimeout(function(){
      setTransitioning(false);
    }, 500);
  }

  // redirect
  if(redirect) {
    return <Redirect to={redirect} push={true} />
  }

  // page details
  return (
    <div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1}}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rerengatahi - areas</title>
        <meta
          name ='description'
          content="Rerengātahi is a community economic development project promoting local Māori and community organisations and businesses in the Northland region and creating connected and vibrant communities.  Rerengātahi will encourage people to buy, sell and trade local on a digital marketplace to create a more robust and healthy Northland economic sector. Rerengatahi will promote community engagement by promoting local events, community messages, and encouraging online discussion about important community issues through message boards.  We are a hub for interested Māori and community organisations small and micro businesses in Te Taitokerau to collaborate, organise, and grow."
        />
        <meta
          name='keywords'
          content='social, database, buy, sell, local, meet, organise, hub, caring, friends, trade, exchange, trading, te taitokerau, northland, maori, sector, voluntary, artist, sport, micro business, small business, pay online, connection, region, virtual, digital, marketplace, online, trading, events'
        />
      </Helmet>

      <div style={{
        position: 'absolute',
        top: 0,
        left: 20,
        zIndex: 21,
        margin: 10,
      }}>
        <Fab
          palette='primary'
          disabled={transitioning}
          onClick={() => {
            if(opacityInfo) {
              handleInfoFadeOut();
            } else {
              handleInfoFadeIn();
            }
          }}
        >
          {opacityInfo ? <span className="material-icons md-24">place</span> : <span className="material-icons md-24">wrong_location</span>}
        </Fab>
      </div>
      <div style={{
        pointerEvents: pointerEventsInfo,
        opacity: opacityInfo,
        transition: 'opacity .5s ease-out',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20,
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        background: 'rgba(255, 255, 255, 0.85)',
        overflowX: 'hidden',
      }}>
        <GridContainer
          style={{
            background: 'url("https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,h_2048,q_auto:good/media-assets/default_e2qiho.jpg")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundBlendMode: 'lighten',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            height: 200,
          }}
        >
          <GridCell
            weight={1}
            center={true}
            centerWeights={{top: 2, bottom: 3}}
          >
            <Typography size='heading' style={{textAlign: 'center'}}>
              Areas
            </Typography>
          </GridCell>
        </GridContainer>
        <GridContainer>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
          <GridCell weight={10} style={{padding: 10}}>
            <SearchStore
              isMobile={isMobile}
              databaseArea='public_areas'
              processing={processing}
              setProcessing={setProcessing}
              expandSearch={expandSearch}
              setExpandSearch={setExpandSearch}
            />
            {processing &&
              <div style={{marginLeft: 5, marginRight: 5}}>
                <ProgressBar palette='secondary'/>
              </div>
            }
            {areas?.length > 0 &&
              <div>
                <Hidden breakpoint='hiddenlessthan1024'>
                  <div>
                    {areas.map((a, aIndex) => (
                      <GridContainer
                        key={aIndex}
                        style={{margin: 5, cursor: 'pointer'}}
                        onClick={() => {
                          setAreaReference(a);
                          setRedirect(`/area/${a._id}`);
                        }}
                      >
                        <GridCell center={true} style={{height: 200}}>
                          <img
                            src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${a.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                            alt={a?.thumbnail?.altText}
                            style={{width: 200}}
                          />
                        </GridCell>
                        <GridCell weight={1} center style={{textAlign: 'left', padding: 10, height: 180}}>
                          <Typography size='title'>
                            {a.name}
                          </Typography>
                          <Typography style={{color: '#333333'}}>
                            {a.tagline}
                          </Typography>
                          <Typography style={{marginTop: 5}}>
                            {a.blurb?.length > 350 ? `${a.blurb.substring(0, 350)}...` : a.blurb}
                        </Typography>
                        </GridCell>
                      </GridContainer>
                    ))}
                  </div>
                </Hidden>

                <Hidden breakpoint='hiddengreaterthan1024'>
                  <div>
                    <GridContainer>
                      {areas.map((a, aIndex) => (
                        <GridCell
                          key={aIndex}
                          style={{
                            width: isMobile ? '100%' : undefined,
                            maxWidth: isMobile ? undefined : 200,
                            textAlign: 'center',
                            borderStyle: 'solid',
                            margin: 5,
                            borderWidth: 2,
                            borderRadius: 5,
                            borderColor: paletteData.primary.standard.background,
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setAreaReference(a);
                            setRedirect(`/area/${a._id}`);
                          }}
                        >
                          <img
                            src={`https://res.cloudinary.com/taitokerau-tatou/image/upload/c_fill,q_auto:good,w_400,h_400/${a.thumbnail?.imageId || '/media-assets/default_e2qiho'}`}
                            alt={a?.thumbnail?.altText}
                            style={{width: '100%'}}
                          />
                          <div style={{background: paletteData.primary.standard.background, padding: 10, flex: 1}}>
                            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
                              {a.name}
                            </Typography>
                          </div>
                        </GridCell>
                      ))}
                    </GridContainer>
                  </div>
                </Hidden>

                {processing &&
                  <div>
                    <Typography>
                      Loading...
                    </Typography>
                    <ProgressBar palette='secondary'/>
                  </div>
                }
                {!processing && (areas.length >= search?.areas?.queryDepth * queryLimit) &&
                  <div style={{padding: 5}}>
                    <Button palette='primary' onClick={() => setExpandSearch(true)} disabled={processing}>
                      Load More
                    </Button>
                  </div>
                }
              </div>
            }
          </GridCell>
          <Hidden breakpoint='hiddenlessthan650'>
            <GridCell weight={1}/>
          </Hidden>
        </GridContainer>
      </div>
      <Map
        style={{width: '100%', flex: 1}}
        center={myPosition || center}
        zoom={15}
        markers={[]}
        options={{
          styles: opacityInfo ? mapStyle1 : mapStyle2,
          disableDefaultUI: true,
        }}
      />

      {/*popouts and popups*/}
      {dialogOpen &&
        <Dialog
          open={dialogOpen}
          handleClose={() => {
           setDialogData(undefined);
           setDialogOpen(false);
         }}
        >
          <div style={{padding: 10, textAlign: 'center', background: paletteData.primary.standard.background}}>
            <Typography size='title' style={{color: paletteData.primary.standard.foreground}}>
              {dialogData?.title}
            </Typography>
          </div>
          {(dialogData?.message) &&
            <div style={{padding: 10}}>
              <Typography>
                {dialogData.message}
              </Typography>
            </div>
          }

          <GridContainer>
            <GridCell weight={1}/>
            <GridCell style={{padding: 10}}>
              <Button
                palette='primary'
                 onClick={() => {
                  setDialogData(undefined);
                  setDialogOpen(false);
                }}
              >
                {dialogData?.type === 'message' ? 'OK' : 'Cancel'}
              </Button>
            </GridCell>
          </GridContainer>
        </Dialog>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    areas: state.lists.areas,
    search: state.lists.search,
    queryLimit: state.lists.queryLimit,
  };
};

export default connect(mapStateToProps, {setAreaReference})(Areas);
